import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import * as application from "../../../_actions/application";
import {Grid, MenuItem, Select, Tooltip, withWidth} from "@material-ui/core";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import axios from "axios";
import LoadingAction from "../../../theme/LoadingAction";
import {
    BASE_URL,
    DOWNLOAD_DOCUMENT, IS_PAID, IS_SENT_TO_CLIENT,
    REQ_DOC_REPAIR_DOCUMENT,
    REQ_DOC_SIGNATURE_REPAIRER,
    REQ_DOC_VIDEO,
    REQUEST_DOC_TYPE_VIDEO,
    SM,
    XS
} from "../../../constants/constants";
import {
    API_LIST_GENERAL_DOCUMENT, API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE,
    PAGE_DOWNLOAD_REQUEST_DOCUMENT,
    REQUEST_DOCUMENT_LIST, REQUEST_DOCUMENT_LIST_BY_TOKEN
} from "../../../constants/apiSuffix";
import qs from "qs";
import GeneralModal from "../../../theme/Modal/GeneralModal";
import Pdf from "./Pdf";
import ButtonAddDocument from "../request/popover/ButtonAddDocument";
import moment from "moment";
import * as applicationAction from "../../../_actions/application";
import {convertDatetimeToDDMMYYhhmm} from "../../../functions/functions";
import downloadIcon from "../../../images/request/detail/arrow5.svg";
import showIcon from "../../../images/request/detail/Groupe 276.svg";
import viewIcon from "../../../images/request/detail/eye.svg";
import {MONTSERRAT, NUNITO} from "../../../constants/fontCss";
import {ConvertToDateD_M_Y, convertToDateH_m} from "../../../functions/datetime";
import _ from "lodash";
import {REQUEST_UPLOAD_FILE_TOKEN} from "../../../constants/links";

const styles = theme => ({
    tabRequestPage:{
        padding: '1rem 0',
        width: '100%',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '0.5rem 0',
        },
        '& .tabsRequestDocumentWrapper':{
            display: 'flex',
            flexWrap: 'nowrap',
        }
    },
    tabRequestDocumentWrapper:{
        '& .addDocWrapper1': {

            border: '1px solid transparent',
            width: 'min-content',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'center',
            padding: '6px',

            '& svg':{
                transform: 'scale(1.25) !important',
                marginTop: '5px',
                '& path':{
                fill: '#7b7b97',
                //fill: '#0071c7'
                 },
            },

            '& .addCamera':{
                color: '#7b7b97',
                // color: '#0071c7',
                lineHeight: '16px',
                paddingTop: '14px',
                fontSize: '0.8rem',
                minWidth: '125px',
            },


            '&:hover':{
                border: '1px solid #0360d9',
                cursor: 'pointer',
                '& button':{
                    background: 'none !important',

                        '& svg path':{
                            fill: '#0360d9',
                        },
                },

                '& .addCamera':{
                    color: '#0360d9',
                }


            },

            [theme.breakpoints.between(XS, SM)]: {
               margin: 'auto',
            },

        }
    },
    tabRequestDocumentLeftWrapper:{
        minWidth:'425px',
        paddingRight: '2.5rem',
        '& .selectSortDocumentList':{
            marginBottom: 10
        },

        [theme.breakpoints.between(XS, SM)]: {
            paddingRight: 0,
            width: 'inherit',
        },

        [theme.breakpoints.down(XS)]: {
            minWidth: 'initial',
            width: '100%',
        },

    },
    updateDocumentsByType: {
        borderRadius: 13,
        boxShadow: '0 3px 6px 0 rgba(62, 81, 99, 0.16)',
        background: '#dbf1fe',
        padding: '1.5rem',
        height: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 5,
            height: 5
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#C4C4C4',
            borderRadius: 3
        },
    },
    zoomInModal:{
        // marginTop: '-10px',
        width: '100%',
        minHeight: 600,
        objectFit: 'contain',
        maxHeight: '60vh',
        '&:focus':{
            outline: 'none'
        }
    },
    documentShowWrapper: {
        '& .documentShowWrapper': {
            '&.notShowDocument': {
                borderRadius: 13,
                boxShadow: '0 3px 6px 0 rgba(62, 81, 99, 0.16)',
                backgroundImage: 'linear-gradient(to bottom, #545454, #434343)',
            },
            '& .closeDocumentWrapper': {
                textAlign: 'right',
                padding: '1rem 0',
                '& .closeDocument': {
                    fontSize: '2rem',
                    lineHeight: 0.9,
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#eb2424'
                    }
                }
            }
        }
    },
    modalViewDocument:{
        height: '90% !important',
        width: '95%  !important',
        position: 'relative',
        '& $zoomInModal':{
            height: '100% '
        },
        '& .bodyModalWrapper':{
            height: 'calc(100% - 80px)',
            maxHeight: 'calc(100% - 80px)',
            overflow: 'initial'
        },
        '& .headerModalWrapper':{
            padding: 5
        }
    },
    actionDocumentByDocType:{
        padding: '0.3rem 1.5rem 0rem 1.5rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: 0
        },
        '& .labelDocumentType':{
            fontFamily: NUNITO,
            fontWeight: 900,
            fontSize: 20,
            lineHeight: 1.25,
            color: '#0073c7',
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 0 1.3rem 0',
            [theme.breakpoints.between(XS, SM)]: {
                fontSize: '1rem'
            },
            '& img': {
                paddingRight: '0.5rem',
                '&.documentIcon':{
                    height: 45,
                },
                [theme.breakpoints.between(XS, SM)]: {
                    height: 30
                },
            },
            textTransform: 'uppercase'
        },
        '& .nameDocument':{
            padding: '0.5rem 0 0.7rem 0',
            color: '#0073c7',
            fontWeight: 600,
            wordBreak: 'break-all',
            lineHeight: '1.1rem',
            paddingLeft: '4px',
        },
        '& .actionDocumentWrapper': {
            display: 'flex',
            '& a':{
                textDecoration: 'none'
            },
            '& .actionDocument': {
                minHeight: '100px',
                position: 'relative',
                overflow: 'hidden',
                '& .alertNewDocumentItem':{
                    left: -14,
                    top: 17,
                    position: 'absolute',
                    transform: 'rotate(320deg)',
                    color: '#fff',
                    fontWeight: 700,
                    fontSize: 10,
                    lineHeight: 0.9,
                    background: '#f02849',
                    padding: '1.5px 14px ',
                    textTransform: 'uppercase',
                    fontFamily: MONTSERRAT,
                },
                '&.actionViewDoc':{
                    background: '#0073c7',
                    '& .labelAction': {
                        color: '#fff'
                    },
                    '& .iconWrapper':{
                        '& svg':{
                            '& path':{
                                fill: '#fff'
                            }
                        }
                    }
                },
                borderRadius: 10,
                border: 'dashed 1px #0073c7',
                padding: '1rem',
                cursor: 'pointer',
                backgroundImage: ' linear-gradient(192deg, rgba(255, 255, 255, 0.61) 127%, rgba(255, 255, 255, 0) 11%)',
                maxWidth: 150,
                minWidth: 132,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                [theme.breakpoints.between(XS, SM)]: {
                    maxWidth: 150,
                    minWidth: 100,
                    border: 'dashed 1.5px #0073c7',
                },
                '&:hover':{
                    background:"#98c7f0",
                    transition: 'all .4s ease-in-out',
                    '& .labelAction': {
                        color: '#fff'
                    },
                    '& .iconWrapper':{
                        '& svg':{
                            '& path':{
                                fill: '#fff'
                            }
                        }
                    }
                },
                '&:first-child': {
                    marginRight: '1rem',
                },
                '& .iconWrapper': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    height: 35,
                    marginBottom: '1rem',
                    [theme.breakpoints.between(XS, SM)]: {
                        height: 20,
                        '& img':{
                            height: 20,
                        }
                    },
                }
            },
            '& .labelAction': {
                fontFamily: NUNITO,
                fontWeight: 800,
                fontSize: 13,
                lineHeight: 1,
                color: '#0073c7',
                textTransform: 'uppercase',
                [theme.breakpoints.between(XS, SM)]: {
                    fontSize: 12
                },
            }
        },
        '& .commentDocument': {
            fontFamily: NUNITO,
            fontWeight: 300,
            fontSize: 16,
            lineHeight: 1.06,
            color: '#0073c7',
            textAlign: 'left',
            paddingTop: '0.5rem',
            paddingBottom: '1rem',
            // wordBreak: 'break-all',
            [theme.breakpoints.between(XS, SM)]: {
                fontSize: 12,
            },
        }
    }
});

const viewFileRender = (props, item) => {
    const {classes, width} = props;
    if (item.hasOwnProperty('mimeType') && item.hasOwnProperty('pathUrl') && item.mimeType) {
        if (item.mimeType.includes('image')) {
            return <img alt={item.docName ?? ""} className={classes.zoomInModal} src={item.pathUrl}/>
        }
        else if (item.mimeType.includes('video')) {
            return <video className={classes.zoomInModal} autoPlay controls>
                <source src={item.pathUrl}/>
            </video>
        }
        else if (item.mimeType.includes('pdf')) {
            return <React.Fragment>
                {width === XS || width === SM ?
                    <Pdf src={item.pathUrl}/>
                    :
                    <object type="application/pdf" className={classes.zoomInModal} width="100%" height="100%"
                                   data={item.pathUrl}>
                        <embed src={item.pathUrl} type="application/pdf"  width="100%" height="100%"/>
                    </object>
                }
            </React.Fragment>
            // return <iframe className={classes.zoomInModal}
            //                name={item.docName}
            //                    src={`https://docs.google.com/viewerng/viewer?url=${item.pathUrl}&embedded=true`}/>
            // return <object type="application/pdf" className={classes.zoomInModal} width="100%" height="100%"
            //                data={item.pathUrl}>
            //     <embed src={item.pathUrl} type="application/pdf"  width="100%" height="100%"/>
            // </object>
        }
        else {
            return <iframe className={classes.zoomInModal}
                           src={item.pathUrl}/>
        }
    }
    return  ""
}

export const VIEW_DOCUMENT = "VIEW_DOCUMENT"
export const ACTION_DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT"
export const KEY_DOCUMENT_TYPE_ID = "document_type_{id}";
const  SORT_CREATED_AT_ASC = "CREATED_AT_ASC"
const  SORT_CREATED_AT_DESC = "CREATED_AT_DESC"
const  SORT_NAME_ASC = "NAME_ASC"
const  SORT_NAME_DESC = "NAME_DESC"
class TabRequestDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            arrayRequestDocuments: [],
            documentMeetingVideo: [],
            isLoading: false,
            tabDocumentTypeLeft: null,
            viewFile: null,
            openModal: false,
            tabClientActive: null,
            listGeneralDocuments: [],
            sortListDocumentByCreatedAt: SORT_CREATED_AT_DESC,
            lastViewDocumentTime: null,
            documentsNotSeenByTypeCodeList: {},
            tabDocumentNewSeen: []
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.changeTabDocumentLeft){
    //        let showTabByDocTypeCodeNew = state.arrayRequestDocTypes.filter(item=>item.code === props.changeTabDocumentLeft);
    //         props.handleChangeFieldReqDeaPage("", "changeTabDocumentLeft")
    //         if(showTabByDocTypeCodeNew.length){
    //             return {
    //                 tabDocumentTypeLeft: showTabByDocTypeCodeNew[0]
    //             }
    //         }
    //     }
    //     return null;
    // }

    handleChange = (value, field) =>{
        let newStateJson = {[field]: value}
        // if(field === "tabDocumentTypeLeft"){
        //     newStateJson = {...newStateJson,viewFile: null }
        // }
        this.setState(newStateJson);
    }

    handleChangeActionFile = (item, action) =>{
        const {documentsNotSeenByTypeCodeList, tabClientActive}= this.state;

        if(action === VIEW_DOCUMENT){
            let documentsNotSeenByTypeCodeListNew = {...documentsNotSeenByTypeCodeList}
            if(tabClientActive  && documentsNotSeenByTypeCodeListNew[tabClientActive.code] && Array.isArray(documentsNotSeenByTypeCodeListNew[tabClientActive.code])){
                documentsNotSeenByTypeCodeListNew = {...documentsNotSeenByTypeCodeListNew, [tabClientActive.code]: documentsNotSeenByTypeCodeList[tabClientActive.code].filter(itemFilter=> itemFilter !== item.id)}
            }
            this.setState({
                viewFile: item,
                documentsNotSeenByTypeCodeList: documentsNotSeenByTypeCodeListNew
            })
        }else {
            const params = {
                documentId: item.id,
                typeDownload: DOWNLOAD_DOCUMENT,
                urlPageBeforeDownload: window.location.href
            }
            window.location = BASE_URL+PAGE_DOWNLOAD_REQUEST_DOCUMENT+"?"+ qs.stringify(params)
        }
    }

    componentDidMount() {
        const {match, location,requestItem}= this.props;

        this.setState({
            isLoading: true
        })
        const requestId = (match?.params?.id) ? match.params.id : null;
        const isNotUploadFileTokenPage = REQUEST_UPLOAD_FILE_TOKEN.replace(":token",(match?.params?.token) ) !== location.pathname

        const checkIdExist = !(requestId == null || requestId == "undefined")
        let paramsDocumentList = checkIdExist ? {
            maxPerPageAll: 1,
            request: requestId
        } : {
            maxPerPageAll: 1,
        }

        if(isNotUploadFileTokenPage) {
            this.getListGeneralDocument();
        }
        // const videoCallBaseUrl = dataUser.data?.videoCallBaseUrl;
        if (isNotUploadFileTokenPage) {
            axios.all([
                axios.get(REQUEST_DOCUMENT_LIST + "?" + qs.stringify(paramsDocumentList)),
                // axios.get(REQUEST_DOCUMENT_CLIENT_NOT_SEEN_LIST + "?request=" + requestId)
            ])
                .then(response => {
                    let stateDidAmount = {
                        isLoading: false
                    }
                    let arrayRequestDocuments = [];
                    let documentMeetingVideoTemp = []
                    let requestLastViewDocumentDate = null;
                    let documentsNotSeenByTypeCodeList = {}
                    // if (response[1]?.status === 200) {
                    //     const itemsNotSeen = response[1].data.items;
                    //     requestLastViewDocumentDate = response[1].data.lastDate ? moment(response[1].data.lastDate) : null
                    //     if (requestLastViewDocumentDate && requestLastViewDocumentDate.isValid()) {
                    //         stateDidAmount = {...stateDidAmount, lastViewDocumentTime: requestLastViewDocumentDate}
                    //         let reportDocument = [];
                    //         this.getReportPdfListByRequest().forEach((item, index) => {
                    //             if (item.createdAt && item.id && moment(item.createdAt).valueOf() > requestLastViewDocumentDate.valueOf()) {
                    //                 reportDocument.push(item.id)
                    //             }
                    //         });
                    //         if (reportDocument.length) {
                    //             documentsNotSeenByTypeCodeList = {
                    //                 ...documentsNotSeenByTypeCodeList,
                    //                 CLIENT_DOC_TAB_REPORT_QUOTATION: reportDocument
                    //             }
                    //         }
                    //     }
                    //     if (typeof itemsNotSeen === 'object' && Object.keys(itemsNotSeen).length) {
                    //         Object.keys(itemsNotSeen).forEach((item, index) => {
                    //             const keyCodeTab = this.convertDocumentTypeCodeToTabCode(item)
                    //             if (documentsNotSeenByTypeCodeList[keyCodeTab]) {
                    //                 documentsNotSeenByTypeCodeList[keyCodeTab] = [...documentsNotSeenByTypeCodeList[keyCodeTab], ...itemsNotSeen[item]]
                    //             } else {
                    //                 documentsNotSeenByTypeCodeList = {
                    //                     ...documentsNotSeenByTypeCodeList,
                    //                     [keyCodeTab]: itemsNotSeen[item]
                    //                 }
                    //             }
                    //         })
                    //     }
                    // }
                    if (response[0]?.status === 200 && checkIdExist && Array.isArray(response[0].data.items)) {
                        let arrayRequestDocumentsTemp = response[0].data.items.filter(item => !item?.type || ![REQ_DOC_REPAIR_DOCUMENT].includes(item.type?.code));
                        arrayRequestDocuments = arrayRequestDocumentsTemp.filter(item => {
                            return !item.isMeetingVideo
                        })
                        documentMeetingVideoTemp = arrayRequestDocumentsTemp.filter(item => {
                            return item.isMeetingVideo && item.isShowClient
                        }).map(item => {
                            const docName = this.props.t('requestDocument.label.videoCallName', {
                                date: ConvertToDateD_M_Y(item.createdAt),
                                time: convertToDateH_m(item.createdAt)
                            });
                            return ({
                                ...item,
                                docName: docName,
                                description: ''
                            })
                        })
                        let arrayDocumentsDocTypeVideo = arrayRequestDocuments.filter(item => (!item.type || (item.type.code !== REQ_DOC_VIDEO)))
                        this.props.handleChangeFieldReqDeaPage(Array.isArray(arrayDocumentsDocTypeVideo) ? arrayDocumentsDocTypeVideo.length : 0, "countNbResultDocumentRequest")
                    }

                    stateDidAmount = {
                        ...stateDidAmount,
                        arrayRequestDocuments: arrayRequestDocuments,
                        documentMeetingVideo: documentMeetingVideoTemp,
                        documentsNotSeenByTypeCodeList: documentsNotSeenByTypeCodeList ?? {}
                    }
                    this.setState(stateDidAmount)
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                    })
                })
        } else {
            axios.get(REQUEST_DOCUMENT_LIST_BY_TOKEN + "?" + qs.stringify({
                maxPerPageAll: 1,
                request: (match?.params?.token)
            }))
                .then(res => {
                    this.setState({
                        isLoading: false,
                        arrayRequestDocuments: (res?.data?.items) ?? [],
                    })
                })
                .catch(() => {
                    this.setState({
                        isLoading: false,
                    })
                })
        }
    }

    getListGeneralDocument = () => [
        axios.get(API_LIST_GENERAL_DOCUMENT)
            .then(res => {
                const data = res.data;
                let dataItems = []
                if (data.hasOwnProperty('items') && data.items) {
                    dataItems = data.items;
                } else if (data.hasOwnProperty('hydra:member')) {
                    dataItems = data["hydra:member"];
                }
                this.setState({
                    listGeneralDocuments: Array.isArray(dataItems) ? dataItems : []
                })
            })
    ]

    handleClientTabChange = (tab) => {
        const {match}= this.props;
        const {documentsNotSeenByTypeCodeList, tabClientActive}= this.state;
        let documentsNotSeenByTypeCodeListNew = {...documentsNotSeenByTypeCodeList}
        if( tabClientActive?.code && documentsNotSeenByTypeCodeList[tabClientActive.code] && ( !tab  || (tab.code !== tabClientActive.code))){
            delete (documentsNotSeenByTypeCodeListNew[tabClientActive.code])
        }

        this.setState({
            tabClientActive: tab,
            documentsNotSeenByTypeCodeList: documentsNotSeenByTypeCodeListNew
        }, function (){
            const requestId = (match?.params?.id) ? match.params.id : null;
            if (tab?.code && requestId && Object.keys(documentsNotSeenByTypeCodeListNew).length === 1 && documentsNotSeenByTypeCodeListNew[tab.code]) {
                axios.post(API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE.replace("{id}", requestId))
                    .then(response => {
                        if (response.status === 200) {
                            this.props.removeValueToRequestDocumentManagerNotSeenRedux(requestId)
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(err => {
                    })
            }
        })
    }


    // convertDocumentTypeCodeToTabCode = (codeItem) => {
    //     let viewDoc = null;
    //     switch (codeItem) {
    //         case REQUEST_DOC_TYPE_IMAGE:
    //         case REQUEST_DOC_TYPE_VIDEO:
    //             viewDoc =  CLIENT_DOC_TAB_IMAGE_VIDEO;
    //             break;
    //         case REQUEST_DOC_TYPE_BILL:
    //             viewDoc =  CLIENT_DOC_TAB_BILL;
    //             break;
    //         case REQUEST_DOC_TYPE_QUOTATION:
    //         case REQUEST_DOC_TYPE_REPORT:
    //             viewDoc =  CLIENT_DOC_TAB_REPORT_QUOTATION;
    //             break;
    //         default:
    //             viewDoc =  CLIENT_DOC_TAB_OTHER
    //     }
    //     return viewDoc
    // }


    getReportPdfListByRequest = () =>{
        const {requestItem} = this.props;
        return requestItem && Array.isArray(requestItem.reports) ? requestItem.reports.filter(item => {
            return  !!item.reportPdfInfo && typeof item.reportPdfInfo === "object" && [IS_SENT_TO_CLIENT, IS_PAID].includes(item.reportStatus);
        }).map(item=> ({...item.reportPdfInfo,  mimeType : "application/pdf"})) : [];
    }

    viewActionShowDocument = (item, documentType, viewFile) =>{
        const { t} = this.props;
        const {documentsNotSeenByTypeCodeList, tabClientActive} = this.state;
        const isNewDocumentUpload = tabClientActive?.code && Array.isArray(documentsNotSeenByTypeCodeList[tabClientActive.code]) && documentsNotSeenByTypeCodeList[tabClientActive.code].length && documentsNotSeenByTypeCodeList[tabClientActive.code].includes(item.id)
        return  <div key={item.id}>
            <div className={"nameDocument"}>
                {item.docName ?? (item.name ?? "")} {item.createdAt?" ( "+convertDatetimeToDDMMYYhhmm(item.createdAt) +" )":""}
            </div>
            <div className={"actionDocumentWrapper"}>
                {
                    item.isMeetingVideo
                        ?
                        <>
                        </>
                        :
                        <Tooltip title={item.docName ?? ""} arrow>
                            <div className={"actionDocument"}
                                 onClick={() => this.handleChangeActionFile(item, ACTION_DOWNLOAD_DOCUMENT)}>
                                <div className={"iconWrapper"}>
                                    <CustomInlineSvg svgProps={{src: downloadIcon}}/>
                                </div>
                                <div className={"labelAction"}>
                                    {t("request.requestDetail.download")}
                                </div>
                            </div>
                        </Tooltip>
                }
                <Tooltip title={item.docName ?? ""} arrow>
                    <div className={`actionDocument ${viewFile && viewFile.id === item.id ? "actionViewDoc" : ""}`}
                         onClick={() => this.handleChangeActionFile(item, VIEW_DOCUMENT)}>
                        {isNewDocumentUpload? <div className={`alertNewDocumentItem`}>{t("requestDocument.new")}</div> : ""}

                        <div className={"iconWrapper"}>
                            <CustomInlineSvg   svgProps={{src: documentType && documentType.code === REQUEST_DOC_TYPE_VIDEO ? showIcon : viewIcon}} />
                        </div>
                        <div className={"labelAction"}>
                            {t(documentType && documentType.code === REQUEST_DOC_TYPE_VIDEO ? "request.requestDetail.slideshow" : "request.requestDetail.view")}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className={"commentDocument"}>
                {/*{item.description ?? ""}*/}
            </div>
        </div>
    }

    showActionShowAndDownload = (
        arrayRequestDocuments,
        iconTitleDoc,
        titleBlockFiles,
        documentType,
        jsonLabelTypeItem,
        viewFile,
        hideLabel
    ) => {
        const {classes} = this.props;
        if (documentType) {
            return (
                <div className={classes.actionDocumentByDocType}>
                    {!hideLabel && <div className={"labelDocumentType"}>
                        <img alt={""} src={iconTitleDoc} className={jsonLabelTypeItem && jsonLabelTypeItem.documentType && jsonLabelTypeItem.documentType.code === REQ_DOC_VIDEO?"":"documentIcon"}/>
                        {titleBlockFiles ? titleBlockFiles : ""}
                    </div>}
                    {Array.isArray(arrayRequestDocuments) && arrayRequestDocuments.length > 0 ? arrayRequestDocuments.map(item => {
                        if(jsonLabelTypeItem){
                            if(jsonLabelTypeItem && ((item.subType && item.subType.id === jsonLabelTypeItem.id) || (item.type && item.type.id === jsonLabelTypeItem.id))) {
                                return this.viewActionShowDocument(item, documentType, viewFile)
                            }
                        }else{
                            return this.viewActionShowDocument(item, documentType, viewFile)
                        }
                    }):""}
                </div>
            );
        } else {
            return ""
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { documentMeetingVideo } = this.state;
        if(Array.isArray(documentMeetingVideo) && documentMeetingVideo.length && !_.isEqual(documentMeetingVideo, prevState.documentMeetingVideo)){
            this.setState({
                documentMeetingVideo: []
            })
            this.checkMeetingVideo(documentMeetingVideo);
        }
    }
    checkMeetingVideo = (documentMeeting) => {
        documentMeeting.forEach((item, key) => {
            fetch(item.pathUrl, {
                method: 'HEAD',
            })
                .then(res => {
                    if (res.status === 200) {
                        this.setState(prev => {
                            let arrayRequestDocumentsTemp = prev.arrayRequestDocuments;
                            arrayRequestDocumentsTemp = [
                                ...arrayRequestDocumentsTemp,
                                {
                                    ...item,
                                }
                            ]
                            return {
                                arrayRequestDocuments: arrayRequestDocumentsTemp.sort((a, b) => {
                                    return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1
                                })
                            };
                        })
                    }
                })
                .catch(err => {

                })
        })
    }
    render() {
        const {classes,width, requestItem, dataUser, t, match, location, chatDocuments} = this.props;
        const {
            viewFile,
            isLoading,
            arrayRequestDocuments,
            listGeneralDocuments,
            sortListDocumentByCreatedAt
        } = this.state;

        let listDocGeneralNotTabActive = [];
        listGeneralDocuments.forEach((generalDoc) => {
            let hasDoc = null;
            if (generalDoc.enabled && Array.isArray(generalDoc.versionDocuments) && requestItem) {
                hasDoc = generalDoc.versionDocuments.reverse().find(itemDoc => {
                    return moment(itemDoc.createdAt) < moment(requestItem.createdAt)
                })
            }
            if (hasDoc) {
                    listDocGeneralNotTabActive.push( {
                        ...hasDoc,
                        docName: generalDoc.name ?? hasDoc.docName,
                    })
            }
        })
        const listReportHasPdf = this.getReportPdfListByRequest();

        const isNotUploadFileTokenPage = REQUEST_UPLOAD_FILE_TOKEN.replace(":token",(match?.params?.token) ) !== location.pathname
        let arrayRequestDocumentsAll = [...arrayRequestDocuments, ...listDocGeneralNotTabActive, ...listReportHasPdf]
        let arrayRequestDocumentsNotDocDefault = [...arrayRequestDocuments]
        if(sortListDocumentByCreatedAt){
            if(isNotUploadFileTokenPage) {
                arrayRequestDocumentsAll = arrayRequestDocumentsAll.sort((a, b) => {
                    const labelSort1 =  (sortListDocumentByCreatedAt === SORT_CREATED_AT_DESC || sortListDocumentByCreatedAt === SORT_CREATED_AT_ASC) ?  a.createdAt: (a.docName ?? a.name);
                    const labelSort2 =  (sortListDocumentByCreatedAt === SORT_CREATED_AT_DESC || sortListDocumentByCreatedAt === SORT_CREATED_AT_ASC) ?  b.createdAt: (b.docName ?? b.name);
                    const checkSort = sortListDocumentByCreatedAt === SORT_CREATED_AT_DESC ? labelSort1 >= labelSort2 : (sortListDocumentByCreatedAt === SORT_CREATED_AT_ASC ? (labelSort1 <= labelSort2) :
                        sortListDocumentByCreatedAt === SORT_NAME_ASC ? (labelSort1 <= labelSort2) : sortListDocumentByCreatedAt === SORT_NAME_DESC ? (labelSort1 >= labelSort2) : "");
                    return checkSort ? -1 : 1;
                });
            } else {
                arrayRequestDocumentsNotDocDefault = arrayRequestDocumentsNotDocDefault.sort((a, b) => {
                    const labelSort1 =  (sortListDocumentByCreatedAt === SORT_CREATED_AT_DESC || sortListDocumentByCreatedAt === SORT_CREATED_AT_ASC) ?  a.createdAt: (a.docName ?? a.name);
                    const labelSort2 =  (sortListDocumentByCreatedAt === SORT_CREATED_AT_DESC || sortListDocumentByCreatedAt === SORT_CREATED_AT_ASC) ?  b.createdAt: (b.docName ?? b.name);
                    const checkSort = sortListDocumentByCreatedAt === SORT_CREATED_AT_DESC ? labelSort1 >= labelSort2 : (sortListDocumentByCreatedAt === SORT_CREATED_AT_ASC ? (labelSort1 <= labelSort2) :
                        sortListDocumentByCreatedAt === SORT_NAME_ASC ? (labelSort1 <= labelSort2) : sortListDocumentByCreatedAt === SORT_NAME_DESC ? (labelSort1 >= labelSort2) : "");
                    return checkSort ? -1 : 1;
                })
            }
        }

        return (
                <Grid container className={classes.tabRequestPage}>
                    {isLoading && <LoadingAction />}
                    <Grid container item xs={12} className={classes.tabRequestDocumentWrapper}>
                        <Grid item xs={12} style={{
                            padding: 10
                        }}>
                            {requestItem && requestItem.id && <ButtonAddDocument loadingRequest={this.props.loadingRequest} requestId={requestItem.id}/>}
                        </Grid>
                        <Grid container item xs={12} className={"tabsRequestDocumentWrapper"}>
                            <Grid item md={5} className={classes.tabRequestDocumentLeftWrapper}>
                                <Select
                                    id="sort-list-select"
                                    value={sortListDocumentByCreatedAt}
                                    onChange={(event) => {
                                        this.setState({
                                            sortListDocumentByCreatedAt: event.target.value
                                        })
                                    }}
                                    className="selectSortDocumentList"
                                >
                                    <MenuItem value={SORT_CREATED_AT_DESC}><div>&#8595; {t("request.requestDetail.sorts.byCreatAtDesc")}</div></MenuItem>
                                    <MenuItem value={SORT_CREATED_AT_ASC}><div>&#8593; {t("request.requestDetail.sorts.byCreatAtAsc")}</div></MenuItem>
                                    <MenuItem value={SORT_NAME_DESC}><div>&#8595; {t("request.requestDetail.sorts.byNameDesc")}</div></MenuItem>
                                    <MenuItem value={SORT_NAME_ASC}><div>&#8593; {t("request.requestDetail.sorts.byNameAsc")}</div></MenuItem>

                                </Select>

                                {(isNotUploadFileTokenPage) ? <div className={classes.updateDocumentsByType}>
                                    <div className={classes.actionDocumentByDocType}>
                                        {Array.isArray(arrayRequestDocumentsAll) && arrayRequestDocumentsAll.length > 0 ? arrayRequestDocumentsAll.map(item => {
                                            return this.viewActionShowDocument(item, null, viewFile)
                                        }):""}
                                    </div>
                                </div> : <div className={classes.updateDocumentsByType}>
                                    <div className={classes.actionDocumentByDocType}>
                                        {Array.isArray(arrayRequestDocumentsNotDocDefault) && arrayRequestDocumentsNotDocDefault.length > 0 ? arrayRequestDocumentsNotDocDefault.map(item => {
                                            return this.viewActionShowDocument(item, null, viewFile)
                                        }):""}
                                    </div>
                                </div>}
                            </Grid>
                            {[SM, XS].includes(width) ?
                                viewFile ?  <GeneralModal
                                    className={classes.modalViewDocument}
                                    open={viewFile}
                                    handleClose={()=>this.handleChange(null, 'viewFile')}
                                    // title={<ClientTitleModal dataClient={dataClient}/>}
                                    // content={<ClientContentModal dataClient={dataClient}/>}
                                    content={viewFileRender(this.props, viewFile)}
                                    hideFooter={true}
                                />:""
                                : <Grid container item md={7} className={classes.documentShowWrapper}>
                                    <Grid item xs={12}
                                          className={`documentShowWrapper ${viewFile ? "" : "notShowDocument"}`}>
                                        {viewFile && <div className={"closeDocumentWrapper"}
                                                          onClick={() => this.handleChange(null, "viewFile")}><Tooltip
                                            key={"closeDocument"} title={this.props.t("button.close")} arrow interactive
                                            placement={"top"}><span
                                            className={"closeDocument"}>&#10005;</span></Tooltip></div>
                                        }
                                        {viewFile ? viewFileRender(this.props, viewFile) : ""}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
        )
    }
}

TabRequestDocument.defaultProps = {}

TabRequestDocument.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenModalAddRequestInList: (openModalAddRequestInList) => dispatch(application.setOpenModalAddRequestInList(openModalAddRequestInList) ),
        removeValueToRequestDocumentManagerNotSeenRedux: (value) => dispatch(applicationAction.removeValueToRequestDocumentManagerNotSeenRedux(value)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(TabRequestDocument);
