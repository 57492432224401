import { withStyles} from "@material-ui/core/styles";
import {Button, Grid, withWidth} from "@material-ui/core";
import ProblemStepIcon from "./../../../images/requestNew/problem_step.svg";
import InfoStepIcon from "./../../../images/requestNew/info_step.svg";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
// import DocIcon from "../../../images/request/doc.svg";
import React from "react";
import DialogForm from "../../../theme/CustomElements/DialogForm";
import UploadFile from "../../../theme/Modal/UploadFile";
import InfoForm from "./RequestNew/InfoForm";
import {
    COUNTRY_DEFAULT, EMAIL,
    FALSE_NUMBER,
    OBJECT_COMPANY,
    OBJECT_PERSONAL, SM, STRING,
    TRUE_NUMBER, USER_TYPE_CLIENT, WAITING_FOR_REPLY, XS
} from "../../../constants/constants";
import {OWNER, TENANT} from "../information/Information";
import PropTypes from "prop-types";
import * as application from "../../../_actions/application";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {
    errorMessPanel2,
    errorsMessCreateRequest,
    errorsMessCreateRequestBillingAddress,
    errorsMessCreateRequestCompany
} from "../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../functions/checkCondition";
import {
    API_CREATE_REQUEST,
    API_EDIT_REQUEST,
    API_EDIT_REQUEST_BY_TOKEN, API_GET_REQUEST_BY_TOKEN, API_GET_SHOW_REQUEST,
    API_SHOW_REQUEST_INITIAL, API_SHOW_USER
} from "../../../constants/apiSuffix";
import moment from "moment";
import axios from "axios";
import LoadingAction from "../../../theme/LoadingAction";
import UploadingAction from "../../../theme/UploadingAction";
import RepairerIcon from "./../../../images/request/repairer.svg";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import Checkbox from "@material-ui/core/Checkbox";
import CGV_FILE from "../../../docs/CGV.pdf";
import ErrorInput from "../../../theme/form/Alert/ErrorInput";
import {NUNITO} from "../../../constants/fontCss";
import {ArrowBack} from "@material-ui/icons";
import AdminInfoForm from "./RequestNew/AdminInfoForm";

const styles = theme => ({
        requestNewWrapper: {
            padding: '20px 40px 0px 40px',
            [theme.breakpoints.down(SM)]:{
                padding: 10,
            },
            '& .requestNewHeader': {
                '& .btnReturn': {
                    color: '#3a3a3a',
                    borderRadius: 12,
                    padding: '5px 10px',
                    fontSize: '0.9rem',
                    cursor: 'pointer',
                    marginBottom: 20,
                    border: 0,

                    '&:hover':{
                        border: '0px solid transparent',
                    },

                    [theme.breakpoints.down(XS)]:{
                        marginBottom: 32,

                    }
                },
                '& .progress': {
                    height: 12,
                    backgroundColor: '#e8eaf3',
                    borderRadius: 7,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    '&.successProgress':{
                        backgroundColor: '#00fcb7',
                    },
                    '& .progressItem': {
                        height: '100%',
                        flexGrow: 1,
                        // height: 1200,
                        // transform: 'rotate(45deg)',
                        '&.active': {
                            backgroundColor: '#0022ff',
                            position: 'relative',
                            '&:after': {
                                content: `''`,
                                width: 0,
                                height: 0,
                                borderTop: '0px solid transparent',
                                borderBottom: '12px solid transparent',
                                borderLeft: '12px solid #0022ff',
                                position: 'absolute',
                                right: -12,
                                top: 0,
                            },
                            '&.index_1': {
                                '&:after': {
                                    left: -12,
                                    right: 0,
                                    borderBottom: '12px solid #0022ff',
                                    borderLeft: '12px solid transparent',
                                }
                            },
                        },

                    }
                },
                '& .step': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .stepItem': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '12px 10px 25px 10px',
                        color: '#a8adcf',
                        cursor: 'pointer',
                        flexGrow: 1,
                        lineHeight: '16px',
                        fontSize: '13px',
                        textTransform: 'uppercase',
                        '&.active' : {
                            color: '#0022ff',
                            '& svg': {
                                '& path:first-child': {
                                    fill: 'none!important',
                                    stroke: '#0022ff!important',
                                },
                                '& path': {
                                    fill: '#0022ff!important',
                                    stroke: '#0022ff!important',
                                }
                            }
                        },
                        '& svg': {
                            marginRight: 20,
                            width: 45,
                            maxWidth: 30,
                            '& path:first-child': {
                                fill: 'none!important',
                                stroke: '#a8adcf!important',
                            },
                            '& path': {
                                fill: '#a8adcf!important',
                                stroke: '#a8adcf!important',
                            }
                        },
                        '&:hover:not(.active)':{
                            '& .label':{
                            color: '#7076a2',
                            },
                            '& svg path':{
                            stroke:'#7076a2 !important',
                            }

                        }
                    }
                },
                [theme.breakpoints.down(XS)]:{
                    marginBottom: '20px',

                }
            },
            '& .requestNewBody': {
                '& .viewBody': {
                    display: 'none',
                    '& .errorLabel': {
                        fontWeight: 700,
                        fontSize: '0.9rem'
                    },
                    '&.active': {
                        display: 'block'
                    },
                    '& .successMessage': {
                        backgroundColor: '#eaf1fb',
                        padding: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        '& .textMessage': {
                            color: '#0000ff',
                            fontSize: '1.5rem',
                            fontWeight: 600,
                            padding: '40px 0',
                            textAlign: 'center',
                            maxWidth: 240,
                            margin: 'auto',
                            lineHeight: '1.8rem',
                        },
                        '& .repairerMessage': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '30px',
                            // display: 'flex',
                            flexWrap: 'wrap',

                            '& img': {
                                paddingRight: '15px',
                                paddingBottom: '15px',
                            },
                            '& .repairerMessageTxt': {
                                fontSize: '1.2rem',
                                lineHeight: '1.3rem',
                                textAlign: 'center',
                                paddingBottom: '15px',
                            },
                        },
                        '& .btnClose': {
                            border: 'solid 1px #707070',
                            padding: '5px 30px',
                            textTransform: 'unset',
                        }
                    }
                }
            },
            '& .requestNewFooter': {
                padding: '20px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-between',
                [theme.breakpoints.down(XS)]:{
                    flexDirection: 'column',
                },
                '& .checkboxAccept': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    '& .labelCheckBox': {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 12,
                        paddingBottom: '20px',
                        fontFamily: NUNITO,
                        fontSize: '0.875rem',
                        color: '#001034',
                        paddingRight: '15px',
                    },
                    '& .MuiCheckbox-root path':{
                        color: '#0000ff !important',
                    },
                },
                '& .btnContinue': {
                    backgroundColor: '#0000ff',
                    color: '#fff',
                    padding: '16px 50px',
                    textTransform: 'initial',
                    marginLeft: 'auto',
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    lineHeight: '1.2rem',
                    '& span':{
                        position: 'relative',
                        left: 0,
                        transition: '0.2s ease',
                     },

                    '&:hover':{
                        backgroundColor: '#1000d2',

                        '& span':{
                          left: 8,
                          transition: '0.2s ease',
                       }
                    }
                }
            }
        },
        messageCloseForm: {

        }
    })
const PROBLEM_STEP = 'PROBLEM_STEP';
export const INFO_STEP = 'INFO_STEP';
const SUCCESS_STEP = 'SUCCESS_STEP'
const formDataInfo = {
    problem: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    phoneNumberAddress: "",
    password: "",
    confirmPassword: "",
    addressFirstName: "",
    addressLastName: "",
    addressCompany: "",
    addressCity: "",
    addressPostalCode: "",
    addressCountry: COUNTRY_DEFAULT,
    addressDescription: "",
    constructionYear: "",
    sentByAgency: "",
    checkOwner: "",
    additionalInfo: "",
    acceptCertificate: false,
    userInfoId: null,
    registeredObject: OBJECT_PERSONAL,
    builtForOver2Years: undefined,
    companyName: '',
    companyTaxCode: '',
    otherBillingAddress: false,
    billingAddressCity: '',
    billingAddressPostalCode: '',
    billingAddressCountry: COUNTRY_DEFAULT,
    billingAddressDescription: '',
    floorNumber: '',
    isThereAnElevator: undefined,
    doorPassword: '',
    ownerEmail: '',
    authorizeShareEmail: false,
    files: [],
    startingDateOfStay: null,
    advertise: null,
    subject: "",
    invoiceLastName: "",
    invoiceFirstName: "",
    sendSmsAndVoice: true,
    otherPhoneNumbersAddress: []
}

const listStep = [
    {
        code: PROBLEM_STEP,
        icon: ProblemStepIcon,
        label: 'request.tab1RequestNew'
    },
    {
        code: INFO_STEP,
        icon: InfoStepIcon,
        label: 'request.tab2RequestNew'
    }
]
class RequestNew extends React.Component {
    constructor(props) {
        super(props);
        const data = (props?.dataUser?.data ) ?? {};
        this.state = {
            stepActive: PROBLEM_STEP,
            dialogCloseForm: false,
            valueForm: {
                ...formDataInfo,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                addressCity: data.addressCity,
                addressPostalCode: data.addressPostalCode,
                addressCountry: data.addressCountry ?? COUNTRY_DEFAULT,
                addressDescription: data.addressDescription,
                isAdminOrExpertCreated: this.props.groupBaseUser !== USER_TYPE_CLIENT
            },
            errors: {},
            isLoading: false,
            isLoadingDataRequest: false,
            progress: 0,
            newItem: null,
            errorOpen: false,
            messageError: null,
            isInfoFormError: false,
            openTabInfoFormDefault: "",
            dataItem: {}
        };
        this.onUploadProgress = this.onUploadProgress.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        let updateStateByProps = {};
        if(props.stepActiveDefault && [PROBLEM_STEP, INFO_STEP].includes(props.stepActiveDefault)){
            if(props.removeStepActionDefault && typeof props.removeStepActionDefault === "function") {
                props.removeStepActionDefault()
            }
            updateStateByProps=  {...updateStateByProps,
                stepActive: props.stepActiveDefault
            };
        }
        if (props.openTabInfoFormDefault) {
            if(props.removeOpenTabInfoFormDefault && typeof props.removeOpenTabInfoFormDefault === "function") {
                props.removeOpenTabInfoFormDefault()
            }
            updateStateByProps=  {...updateStateByProps,
                openTabInfoFormDefault: props.openTabInfoFormDefault
            };
        }
        if(Object.keys(updateStateByProps).length){
            return updateStateByProps;
        }else {
            return null;
        }
    }

    componentDidMount() {
        const {editRequestById, duplicateData} = this.props
        this.setState({isLoadingDataRequest: true})
        let requestUrl = API_SHOW_REQUEST_INITIAL;
        if(typeof duplicateData === "object" && Object.keys(duplicateData).length) {
            console.log(duplicateData)
            this.setState({
                valueForm: {
                    ...this.state.valueForm, ...duplicateData
                }})
        }
        if (editRequestById) {
            requestUrl = parseInt(editRequestById) ? API_GET_SHOW_REQUEST.replace(":id", this.props.editRequestById):
                API_GET_REQUEST_BY_TOKEN.replace("{id}", this.props.editRequestById)
        }

        axios.get(requestUrl)
            .then(response => {
                if (response.status === 200) {
                    const isEditRequest = editRequestById && requestUrl !== API_SHOW_REQUEST_INITIAL
                    const dataItem = isEditRequest ? ((response?.data?.item) ?? {}) : ((response?.data?.initialRequest) ?? {});
                    const dataInitialFirst = (response?.data?.initialRequest) && response.data.initialRequest[0]? response.data.initialRequest[0]: {};
                    this.setState({
                        valueForm: {
                            ...this.state.valueForm, ...dataItem,
                            id: (dataItem?.id) ?? null,
                            leaseType: dataItem.leaseType ? {...dataItem.leaseType, value: dataItem.leaseType.id , label: dataItem.leaseType.name}: null,
                            registeredObject: dataItem.registeredObject ?? OBJECT_PERSONAL,
                            problem: isEditRequest ? dataItem.problem : "",
                            files: isEditRequest ? (dataItem.documents ?? []) : [],
                            subject: isEditRequest ? dataItem.subject : "",
                            advertise: (dataItem?.person?.advertiseFirst) ?? ((dataItem?.advertise) ?? null),
                            isThereAnElevator: dataItem.isThereAnElevator ? TRUE_NUMBER : FALSE_NUMBER,
                            sentByAgency: dataItem.sentByAgency ? TRUE_NUMBER : FALSE_NUMBER,
                            billingAddressCountry: dataItem.billingAddressCountry ?? COUNTRY_DEFAULT,
                            addressCountry: dataItem.addressCountry ?? COUNTRY_DEFAULT,
                            isAdminOrExpertCreated: this.props.groupBaseUser !== USER_TYPE_CLIENT
                        },
                        dataInitial: isEditRequest ? dataInitialFirst : (dataItem ?? {}),
                        isLoadingDataRequest: false
                    })
                } else {
                    throw new Error();
                }
            })
            .catch(error => {
                this.setState({isLoadingDataRequest: false})
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isInfoFormError) {
            this.setState({
                isInfoFormError: false
            })
        }
    }

    handleChange = (event, field )=>{
        let {valueForm, errors, dataInitial} = this.state;
        let valueFormNew = {...valueForm}
        let value = event;
        let newState = {};
        switch (field) {
            case "problem":
            case "subject":
            case "files":
            case "otherPhoneNumbersAddress":
            case "advertise":
                if(field === "files" && Array.isArray(event) && event.length && valueFormNew.sendSmsAndVoice){
                    valueFormNew = {...valueFormNew, sendSmsAndVoice: false}
                }
                value = event;
                if (errors.hasOwnProperty("advertise")) {
                    delete (errors["advertise"]);
                }
                break;
            case "sendSmsAndVoice":
            case "acceptCertificate":
            case "authorizeShareEmail":
                value = event.target.checked;
                break;
            case "otherBillingAddress":
                value = !event.target.checked;
                if(value) {
                    delete (errors["billingAddressCountry"]);
                    delete (errors["billingAddressCity"]);
                    delete (errors["billingAddressPostalCode"]);
                    delete (errors["billingAddressDescription"]);
                }else if(dataInitial) {
                    valueFormNew = {
                        ...valueFormNew,
                        invoiceFirstName: dataInitial.invoiceFirstName ?? (valueForm.firstName ?? (dataInitial.firstName ?? "")),
                        invoiceLastName: dataInitial.invoiceLastName ?? (valueForm.lastName ?? (dataInitial.lastName ?? "")),
                        billingAddressCity: dataInitial.billingAddressCity ?? "",
                        billingAddressPostalCode: dataInitial.billingAddressPostalCode ?? "",
                        billingAddressCountry: dataInitial.billingAddressCountry ?? COUNTRY_DEFAULT,
                        billingAddressDescription: dataInitial.billingAddressDescription ?? ""
                    }
                }
                break;
            case "registeredObject":
                value = event === false ? OBJECT_COMPANY : OBJECT_PERSONAL;
                if(value === OBJECT_PERSONAL) {
                    delete (errors["companyName"]);
                    delete (errors["companyTaxCode"]);
                    valueFormNew = {...valueFormNew, companyName:"", companyTaxCode: ""}
                }
                break;
            case "addressCountry":
            case "billingAddressCountry":
                value= event ?event.code:"";
                break;
            case "leaseType":
            case "startingDateOfStay":
                break;
            case "sentByAgency":
                value = event.target.value;
                if(value !== TRUE_NUMBER){
                    valueFormNew = {...valueFormNew, realEstateCenterCode: ""}
                    if (errors.hasOwnProperty("realEstateCenterCode")) {
                        delete (errors["realEstateCenterCode"]);
                    }
                }
                break;
            case "builtForOver2Years":
                value = parseInt(event.target.value);
                if (!isNaN(value)){
                    valueFormNew.builtForOver2Years = value;
                    if (errors.hasOwnProperty("builtForOver2Years")) {
                        delete (errors["builtForOver2Years"]);
                    }
                }
                break;
            case "checkOwner":
                if (event.target.value === OWNER){
                    if (errors.hasOwnProperty("leaseType")) {
                        delete (errors["leaseType"]);
                    }
                    valueFormNew = {
                        ...valueFormNew,
                        ownerEmail: "",
                        authorizeShareEmail: false,
                        leaseType: null
                    }
                }
            default:
                value = event.target.value;
                break;
        }
        if (errors.hasOwnProperty(field)) {
            delete (errors[field]);
        }
        valueFormNew[field] = value;
        newState = {
            ...newState, valueForm: valueFormNew, errors:errors
        }
        this.setState(newState)

    }
    setStepActive = (stepActive) => {
        // check Validate Tab 1
        if (stepActive === INFO_STEP) {
            const {
                t, editRequestById
            } = this.props;
            let {valueForm} = this.state;
            const adminExpertAuth = (editRequestById && valueForm.isAdminOrExpertCreated) || (!editRequestById && this.props.groupBaseUser !== USER_TYPE_CLIENT);

            const errors = {};
            if (!valueForm.problem) {
                errors.problem = t("errors.notNull");
            }
            if (!valueForm.subject) {
                errors.subject = t("errors.notNull");
            }
            if (!adminExpertAuth && (!Array.isArray(valueForm.files) || !valueForm.files.length)) {
                errors.files = t("errors.notNull");
            }
            if (Object.keys(errors).length === 0) {
                this.setState({
                    stepActive: stepActive,
                })
            } else {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        ...errors
                    },
                    errorOpen: true,
                    messageError:t("form.error.filling")
                })
            }
        } else {
            this.setState({
                stepActive: stepActive
            })
        }
    }

    setDialogCloseForm = (dialogCloseForm) => {
        this.setState({
            dialogCloseForm: dialogCloseForm
        })
    }

    onUploadProgress(event) {
        let progress = Math.round((100 * event.loaded) / event.total)
        this.setState({
            progress
        });
    }

    onSubmit() {
        const {groupBaseUser, statusTypeRequest , t, editRequestById} = this.props;
        let {valueForm, tabNext} = this.state;
        let errors = {}
        const adminExpertAuth = (editRequestById && valueForm.isAdminOrExpertCreated) || (!editRequestById && this.props.groupBaseUser !== USER_TYPE_CLIENT);

        let apiUrlRequest = API_CREATE_REQUEST;

        if (editRequestById) {
            if (parseInt(editRequestById)) {
                apiUrlRequest = API_EDIT_REQUEST.replace("{id}", editRequestById)
            } else {
                apiUrlRequest = API_EDIT_REQUEST_BY_TOKEN.replace("{id}", editRequestById)
            }
        }

        if (valueForm["addressEmail"]) {
            errors = checkSubmitSendDataERROR(valueForm["addressEmail"], EMAIL, errors, "addressEmail", ("errors.isNotValid"), t)
        }

        if(adminExpertAuth) {
            for (let [key, value] of Object.entries(valueForm)) {
                if (key !== "phoneNumberAddress" && errorMessPanel2.hasOwnProperty(key)) {
                    errors = checkSubmitSendDataERROR(value, errorMessPanel2[key].type, errors, key, errorMessPanel2[key].errorByField, t)
                }
            }
            if (!valueForm["addressEmail"]) {
                errors = checkSubmitSendDataERROR(valueForm["addressEmail"], STRING, errors, "addressEmail", ("errors.isNotValid"), t)
            }
        }else {
            if (valueForm["email"]) {
                errors = checkSubmitSendDataERROR(valueForm["email"], EMAIL, errors, "email", ("errors.isNotValid"), t)
            }else {
                errors = checkSubmitSendDataERROR(valueForm["email"], STRING, errors, "email", ("errors.isNotValid"), t)
            }

            for (let [key, value] of Object.entries(valueForm)) {
                if (errorsMessCreateRequest.hasOwnProperty(key)) {
                    errors = checkSubmitSendDataERROR(value, errorsMessCreateRequest[key].type, errors, key, errorsMessCreateRequest[key].errorByField, t)
                }
            }

            if(valueForm["addressEmail"]){
                errors = checkSubmitSendDataERROR(valueForm["addressEmail"], EMAIL, errors, "addressEmail", ("errors.isNotValid"), t)
            }

            if (!(valueForm?.advertise?.value)) {
                errors.advertise = t("errors.notNull");
            }

            if (valueForm.checkOwner === TENANT && (!valueForm.leaseType || !valueForm.leaseType.value)) {
                errors.leaseType = t("information.formTab1.errors.leaseTypeEmpty");
            }
            if (valueForm.sentByAgency === "") {
                errors.sentByAgency = t("errors.notNull");
            }
            if (valueForm.sentByAgency === TRUE_NUMBER && !valueForm.realEstateCenterCode) {
                errors.realEstateCenterCode = t("information.formTab1.errors.realEstateCenterCodeEmpty");
            }

            if (!moment(valueForm.startingDateOfStay).isValid()) {
                errors.startingDateOfStay = t("errors.notNull");
            }

            if (!valueForm.acceptCertificate) {
                errors.acceptCertificate = t("information.formTab1.errors.acceptCertificateEmpty");
            }
            if (apiUrlRequest === API_CREATE_REQUEST) {
                if (!valueForm.problem) {
                    errors.problem = t("errors.notNull");
                }
                if (!Array.isArray(valueForm.files) || !valueForm.files.length) {
                    errors.files = t("errors.notNull");
                }
            }

            if (!valueForm.registeredObject) {
                errors["registeredObject"] = t("errors.notNull")
            } else if (valueForm.registeredObject === OBJECT_COMPANY) {
                for (let [key, value] of Object.entries(errorsMessCreateRequestCompany)) {
                    errors = checkSubmitSendDataERROR(valueForm[key], value.type, errors, key, value.errorByField, t);
                }
            }

            if (valueForm.otherBillingAddress) {
                for (let [key, value] of Object.entries(errorsMessCreateRequestBillingAddress)) {
                    errors = checkSubmitSendDataERROR(valueForm[key], value.type, errors, key, value.errorByField, t);
                }
            }
        }

        if(Object.keys(errors) < 1 && apiUrlRequest){
            this.setState({isLoading: true})
            let valueFormNew = {...valueForm};
            if(!valueForm.otherBillingAddress){
                valueFormNew={
                    ...valueFormNew,
                    billingAddressCity: valueForm.addressCity,
                    billingAddressPostalCode: valueForm.addressPostalCode,
                    billingAddressCountry: valueForm.addressCountry,
                    billingAddressDescription: valueForm.addressDescription
                }
            }
            let fieldFromEditRequest = ["subject", "problem", "lastName", "firstName", "phoneNumber", "email",
                "ownerEmail", "addressLastName", "addressCompany",
                "addressFirstName", "addressCity", "addressPostalCode", "addressCountry", "addressDescription", "addressEmail",
                "constructionYear", "checkOwner", "additionalInfo", "realEstateCenterCode", "registeredObject",
                "startingDateOfStay", "sentByAgency", "leaseType", "builtForOver2Years",
                // "documents", "otherBillingAddress",
                "companyName", "companyTaxCode", "floorNumber", "isThereAnElevator", "doorPassword", "sendSmsAndVoice", "otherPhoneNumbersAddress",
                "billingAddressCity", "billingAddressPostalCode", "billingAddressCountry", "billingAddressDescription", "phoneNumberAddress", "invoiceFirstName", "invoiceLastName"
            ];
            if(adminExpertAuth) {
                fieldFromEditRequest = Object.keys(errorMessPanel2).concat(["subject", "problem", "files", "addressCompany", "doorPassword", "addressEmail", "sendSmsAndVoice", "otherPhoneNumbersAddress", "person"]);
            }
            const clientCanEditRequest = groupBaseUser === USER_TYPE_CLIENT && statusTypeRequest === WAITING_FOR_REPLY;

            const formData = new FormData();

            for (let [key, value] of Object.entries(valueFormNew)) {
                switch (key){
                    case 'person':
                        if(value && parseInt(value)) {
                            formData.append("request[" + key + "]", value ?? "");
                        }
                        break;
                    case 'leaseType':
                        formData.append("request["+key+"]", value && value.value?value.value: "");
                        break;
                    case 'startingDateOfStay':
                        formData.append("request["+key+"]", value ?moment(value).format("YYYY-MM-DD"): "");
                        break;
                    case 'sentByAgency':
                    case 'builtForOver2Years':
                        if(fieldFromEditRequest.includes(key)) {
                            formData.append("request[" + key + "]", value);
                        }
                        break;
                    case "sendSmsAndVoice":
                    case 'otherBillingAddress':
                    case 'authorizeShareEmail':
                        formData.append("request["+key+"]", value ?TRUE_NUMBER:FALSE_NUMBER);
                        break;
                    case 'files':
                        if(Array.isArray(value)) for (let index in value){
                            if(value[index] instanceof File) {
                                formData.append(`request[documents][${index}][documentFile]`, value[index])
                            }
                        }
                        break;
                    case 'advertise':
                        if(value?.value){
                            formData.append(`advertise[0]`, value.value)
                        }
                        break;
                    case "subject":
                    case "problem":
                        if((apiUrlRequest === API_CREATE_REQUEST || clientCanEditRequest) && fieldFromEditRequest.includes(key)) {
                            formData.append("request[" + key + "]", value ?? "");
                        }
                        break;
                    case 'otherPhoneNumbersAddress':
                        if(Array.isArray(value)){
                            value.forEach((phone, indexPhone)=>{
                                formData.append(`request[otherPhoneNumbersAddress][${indexPhone}]`, phone ?? "")
                            })
                        }
                        break;
                    case  "billingAddressCity":
                    case "billingAddressPostasubmitlCode":
                    case "billingAddressCountry" :
                    case "billingAddressDescription":

                        formData.append("request[" + key + "]", valueFormNew.otherBillingAddress ? (value ?? "") : "");
                        break;
                    default:
                        if(fieldFromEditRequest.includes(key)) {
                            formData.append("request[" + key + "]", value ?? "");
                        }
                        break;
                }

            }

            axios.post(apiUrlRequest, formData,{
                onUploadProgress: this.onUploadProgress
            })
                .then(response => {
                    if (response.status === 200) {
                        let newItem = response.data.item;
                        this.setState({
                            isLoading: false,
                            progress: 0,
                            stepActive: SUCCESS_STEP,
                            newItem: newItem
                        })
                        this.props.loadRequest(newItem);
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                        errorOpen: true,
                        stepActive: INFO_STEP,
                        messageError:null
                    })
                });
        } else {
            this.setState({
                errors: errors,
                errorOpen: true,
                isInfoFormError: true,
                messageError:t("form.error.filling")
            })
        }
    }
    render() {
        const {
            stepActive,
            dialogCloseForm,
            valueForm,
            isLoading,
            isLoadingDataRequest,
            errors,
            isInfoFormError,
            openTabInfoFormDefault
        } = this.state;
        const {
            classes,
            t,
            editRequestById,
            hiddenButtonReturn,
            groupBaseUser,
            statusTypeRequest
        } = this.props;
        const clientCanEditRequest = groupBaseUser === USER_TYPE_CLIENT && statusTypeRequest === WAITING_FOR_REPLY;
        const adminExpertAuth = (editRequestById && valueForm.isAdminOrExpertCreated) || (!editRequestById && this.props.groupBaseUser !== USER_TYPE_CLIENT);
        return (isLoadingDataRequest ? <LoadingAction/> :
            <div className={classes.requestNewWrapper}>
                {isLoading ? <UploadingAction progress={this.state.progress}/> : ""}
                {hiddenButtonReturn? "" :
                    editRequestById && !clientCanEditRequest ?
                    <div className={"requestNewHeader"}>
                        <Button variant={"outlined"} className="btnReturn" onClick={() => {
                                this.setDialogCloseForm(true);
                        }} startIcon={<ArrowBack/>}>{t("request.closeNewForm")}</Button>
                    </div>
                    :
                    <div className="requestNewHeader">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                            {stepActive !== SUCCESS_STEP && <Button variant={"outlined"} className="btnReturn" onClick={() => {
                                if (stepActive === PROBLEM_STEP) {
                                    this.setDialogCloseForm(true);
                                } else {
                                    this.setStepActive(PROBLEM_STEP);
                                }
                            }} startIcon={<ArrowBack/>}>{t("request.closeNewForm")}</Button>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={"progress" + (stepActive === SUCCESS_STEP ? " successProgress": "")}>
                                {
                                    listStep.map((step, index) => {
                                        return (
                                            <div className={"progressItem " +  ("index_"+ + index) + (step.code === stepActive ? " active" : "")} key={index}>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="step">
                                {
                                    listStep.map((step, key) => {
                                        return (
                                            <div key={key} className={"stepItem" + (step.code === stepActive ? " active" : "")} onClick={() => {
                                                if (stepActive !== SUCCESS_STEP) {
                                                    this.setStepActive(step.code);
                                                }
                                            }}>
                                                <CustomSvg
                                                    svgProps={{
                                                        src: step.icon,
                                                    }}
                                                />
                                                <div className="label">
                                                    {t(step.label)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>}
                <div className="requestNewBody">
                    <div className={"viewBody" + (stepActive === PROBLEM_STEP ? " active" : "")}>
                        {(!editRequestById ||  clientCanEditRequest) && <UploadFile isRequestNew={true} onlyShowDocumentOld={!!(editRequestById && parseInt(editRequestById))} errors={errors} valueForm={valueForm} handleChange={this.handleChange} checkboxSendSmsAndVoice={true} />}
                        <ErrorInput errorKey={t("modal.upload.titleObject")} textValidate={errors.subject}/>
                        <ErrorInput errorKey={t("modal.upload.titleDetail")} textValidate={errors.problem}/>
                        <ErrorInput errorKey={t("information.formTab1.documents")} textValidate={errors.files}/>
                    </div>
                    <div className={"viewBody" + (stepActive === INFO_STEP ? " active" : "")}>
                        {stepActive === INFO_STEP &&(adminExpertAuth ? <AdminInfoForm
                            isInfoFormError={isInfoFormError} errors={errors} valueForm={valueForm} handleChange={this.handleChange}
                            openTabInfoFormDefault={openTabInfoFormDefault} removeOpenTabInfoFormDefault ={()=>this.setState({openTabInfoFormDefault: ""})}
                            /> :
                            <InfoForm isInfoFormError={isInfoFormError} errors={errors} valueForm={valueForm} handleChange={this.handleChange}
                                                               openTabInfoFormDefault={openTabInfoFormDefault} removeOpenTabInfoFormDefault ={()=>this.setState({openTabInfoFormDefault: ""})}/>)
                        }
                    </div>
                    <div className={"viewBody" + (stepActive === SUCCESS_STEP ? " active" : "")}>
                            <div className="successMessage">
                                <div className="textMessage">
                                    {t('request.textMessage')}
                                </div>
                                <div className="repairerMessage">
                                    <img src={RepairerIcon} alt=""/>
                                    <div className="repairerMessageTxt">{t('request.repairerMessage')}</div>
                                </div>
                                <Button className="btnClose" onClick={() => {
                                    this.props.onClose();
                                }}>
                                    {t('request.closeNew')}
                                </Button>
                            </div>
                    </div>
                </div>
                <div className="requestNewFooter">
                    {stepActive === INFO_STEP && !adminExpertAuth && <div>
                        <div className={"checkboxAccept"}>
                            <Checkbox
                                checked={valueForm.acceptCertificate}
                                onChange={(e) => this.handleChange(e, "acceptCertificate")}
                                name="checkedB"
                            />
                            <div className={"labelCheckBox"}>{t("information.formTab1.acceptCertificate")}
                                <a className={"certificate"} target={"_blank"} rel="noreferrer"
                                   href={CGV_FILE}> {t("information.formTab1.certificate")}</a>
                            </div>
                        </div>
                        <ErrorInput textValidate={errors.acceptCertificate}/>
                    </div>}
                    {stepActive !== SUCCESS_STEP && <Button className="btnContinue" onClick={() => {
                        if (stepActive === PROBLEM_STEP) {
                            this.setStepActive(INFO_STEP);
                        } else if (stepActive === INFO_STEP) {
                            this.onSubmit();
                        }
                    }}>
                        {t(stepActive === PROBLEM_STEP?'request.btnSubmitNext': editRequestById? 'button.save':'request.btnSubmitNew')}
                    </Button>}
                </div>
                {dialogCloseForm && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: dialogCloseForm
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => {
                            this.setDialogCloseForm(false);
                        }
                    }}
                    agreeButtonProps={{
                        handleAgree: () => {
                            this.setDialogCloseForm(false);
                            this.props.onClose();
                        }
                    }}
                    messageProps={{
                        content: <span className={classes.messageCloseForm}>
                            {t('request.closeNewFormConfirm')}
                        </span>
                    }}
                />}
                <AlertCustom
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={() => {
                        this.setState({
                            errorOpen: false,
                            messageError:null
                        })
                    }}
                    messageError={this.state.messageError}
                />
            </div>
        )
    }
}
RequestNew.defaultProps = {
    editRequestById: null,
    hiddenButtonReturn: false,
    statusTypeRequest: "",
    duplicateData: {}
}

RequestNew.propTypes = {
    classes: PropTypes.object.isRequired,
    hiddenButtonReturn: PropTypes.bool,
    statusTypeRequest: PropTypes.string,
    duplicateData: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser,
        dataTab2: state.applicationReducer.dataTab2,
        groupBaseUser: state.authReducer.groupBaseUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorAlert: (successOpenAlert, message) => dispatch(application.setErrorAlert(successOpenAlert, message)),
        setDataFormTab2: (dataTab2, field) => dispatch(application.setDataFormTab2(dataTab2, field))
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(RequestNew);
