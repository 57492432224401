import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import NavBar2 from "../../NavBar2";
import List from "../../../theme/List";
import { Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import returnIcon from "../../../images/request/return.svg";
import { MONTSERRAT, NUNITO, OPENSANS } from "../../../constants/fontCss";
import noteIcon from "../../../images/request/Groupe 9056.svg";
import goIcon from "../../../images/request/status/go.svg";
import notgoIcon from "../../../images/request/requestAdmin/noGoActive.svg";
import seeIcon from "../../../images/request/see-icon.svg";
import maybeIcon from "../../../images/request/status/maybe.svg";
import waitingIcon from "../../../images/request/status/waiting.svg";
import cameraIcon from "../../../images/request/icon-files.svg";
import nogoClient from "../../../images/request/status/nogonew.svg";
import stopClient from "../../../images/request/status/stopList.svg";
import waitClient from "../../../images/request/status/hourglassList.svg";
import calendarIcon from "../../../images/request/status/calendar.svg";
import createReportIcon from "../../../images/request/Groupe 9098.svg";
import reportPaidIcon from "../../../images/request/status/Groupe 8907.svg";
import chatIcon from "./../../../images/request/chat-admin.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  API_ADD_NOTE_REQUEST,
  API_CHANGE_STATUS_REQUEST,
  API_EDIT_REQUEST,
  API_EDIT_REQUEST_CATEGORIES,
  API_PAYMENT_REQUEST_REPORT_ADMIN_SEEN,
  CHANGE_ARCHIVE_REPEAT_REQUEST,
  REQUEST_LIST,
} from "../../../constants/apiSuffix";
import PopoverEditStatus from "./popover/PopoverEditStatus";
import banner from "../../../images/banner/banner.png";
import { withRouter } from "react-router-dom";
import RepairIcon from "../../../images/repair.svg";
import {
  ACTION_ADD,
  ACTION_DELETE,
  LG,
  MAYBE,
  MD,
  NOT_BE_REPAIRED,
  OBJECT,
  REPAIRED,
  ROLE_CHANGE_REQUEST_STATUS,
  SM,
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  WAITING_FOR_REPLY,
  XS,
  STOP,
  // UNASSIGNED,
  // ASSIGNED,
  USER_TYPE_REPAIRER,
  USER_TYPE_CLIENT,
  // REQUEST_DOC_TYPE_VIDEO,
  IS_GO_FREE,
  BASE_URL,
  GO_DOC,
  TRUE_NUMBER,
  FALSE_NUMBER,
  REACT_APP_MECURE_URL,
  VIEW_TYPES,
  // IS_SENT_TO_CLIENT, UNARCHIVED_AND_NOT_REPEAT,
  STATUS_ALL_LIST,
  REPAIRING,
  COMPLETE_REPAIR,
  // ROLE_GROUP_ADMIN
} from "../../../constants/constants";
import * as links from "../../../constants/links";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import GeneralModal from "../../../theme/Modal/GeneralModal";
import ToggleShowAppreciationCell from "../../../images/request/show_appreciation_cell.png";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import PopoverAddNote from "./popover/PopoverAddNote";
import axios from "axios";
import ProblemTitleModal from "./modal/ProblemTitleModal";
import ProblemContentModal from "./modal/ProblemContentModal";
import {
  Accordion,
  AccordionDetails,
  Button,
  Paper,
  Snackbar,
  Tooltip,
  withWidth,
} from "@material-ui/core";
import ToggleShowAllAppreciation from "../../../images/request/toggle_show_appreciation.svg";
import { connect } from "react-redux";
import * as applicationActions from "../../../_actions/application";
import _ from "lodash";
import AppPersonAvatar from "../../../theme/Avatar/AppPersonAvatar";
import moment from "moment";
import * as applicationAction from "../../../_actions/application";
import LoadingAction from "../../../theme/LoadingAction";
import ClientTitleModal from "./modal/ClientTitleModal";
import addStaffIcon from "../../../images/request/requestAdmin/addStaffWrapper.svg";
import nextFolder from "../../../images/request/requestAdmin/accessFolder.svg";
import CustomCalendar from "../../../theme/Calendar/CustomCalendar";
import RequestHeaderAdmin from "./RequestHeaderAdmin";
import ModalAddRepairer from "./modal/ModalAddRepairer";
import unassignedActive from "../../../images/request/requestAdmin/unassignedActive.svg";
import assignedActive from "../../../images/request/requestAdmin/assignedActive.svg";
import listIcon from "../../../images/request/requestAdmin/list.svg";
import gridIcon from "../../../images/request/requestAdmin/grid.svg";
import AppKeyboardDatePicker from "../../../theme/form/DatePicker/AppKeyboardDatePicker";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import stopActive from "../../../images/request/requestAdmin/stopActive.svg";
// import stopStatusIcon from "../../../images/request/stop.svg"
import timeIcon from "../../../images/request/requestAdmin/timeIcon.svg";
import stopIconAction from "../../../images/request/requestAdmin/stopIconAction.svg";
import repaierGrid from "../../../images/request/requestAdmin/repaierGrid.svg";
import alertIcon from "../../../images/request/Groupe 9096.png";
import { Grid } from "@material-ui/core";
import NoteContentModal from "./modal/NoteContentModal";
import stopIcon from "../../../images/request/requestAdmin/stopActive.svg";
import DialogForm from "../../../theme/CustomElements/DialogForm";
import { ConvertToDateY_M_D } from "../../../functions/datetime";
import InfoIcon from "@material-ui/icons/Info";
import ModalAlertUseSmartPhone from "./modal/ModalAlertUseSmartPhone";
import CloseIcon from "@material-ui/icons/Close";
import FilesUploadBlock from "../../admin/FilesUploadBlock";
// import videoIcon from "../../../images/request/detail/online-video.svg";
import qs from "qs";
import { TAB_CALENDER } from "../requestDetail/RequestDetailPage";
// import documentIcon from "../../../images/request/detail/Groupe 200.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CustomInput from "../../../theme/CustomElements/CustomInput";
import reportIcon from "../../../images/request/status/invoice2.svg";
// import BookmarkIcon from '../../../images/request/archive.svg';
// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import reportHeadIcon from "../../../images/request/status/Groupe 8935.svg";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
// import SET_OK_Icon from "../../../images/request/SET_OK.svg";
import {
  convertTimezoneHour,
  checkRequestIsGoFreeAndRegistrationStartNull,
  paramsToObject,
} from "../../../functions/functions";
import {
  CLIENT_STATUS_DIAGNOSIS_RECEIVED,
  CLIENT_STATUS_NOT_ELIGIBLE,
  CLIENT_STATUS_IN_PROGRESS,
  CLIENT_STATUS_MAKE_AN_APPOINTMENT,
  CLIENT_STATUS_CANCELED,
  CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT,
  CLIENT_STATUS_SET_OK,
  CLIENT_STATUS_SCHEDULED_APPOINTMENT,
  listStatusClient,
  customRenderShowBtnAction,
  REQUEST_LIST_FILTER_COLUMNS_ENABLE,
  REQUEST_LIST_SORTING_STATE_COLUMN_EXTENSIONS,
} from "./constantRequestPage";
import DetailRowRequestList from "./DetailRowRequestList";
import * as arrayCurrencies from "country-currency-map";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PopoverAddChat from "./popover/PopoverAddChat";
import { InfoOutlined } from "@material-ui/icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import UploadFile from "../../../theme/Modal/UploadFile";
import { isBrowser } from "react-device-detect";
import iconEdit from "../../../images/request/edit-icon.svg";
import iconEditHover from "../../../images/request/edit-icon-hover.svg";
import ButtonAddDocument from "./popover/ButtonAddDocument";
import GoToDetailIcon from "./../../../images/request/right-arrow.svg";
import pencilSmallIcon from "./../../../images/request/Groupe.svg";
import Pencil2Icon from "./../../../images/request/pencil2.svg";
import ViewRequest from "./RequestNew/ViewRequest";
import { Alert } from "@material-ui/lab";
import PopoverReadOrUnReadMessageChat, {
  NOT_READ_LAST_MESSAGE_CHAT,
} from "../../chat/PopoverReadOrUnReadMessageChat";
import UpcomingColumn from "./UpcomingColumn";
import { ADMIN_REQUEST } from "../../../constants/links";
import PopoverEditActionStatusArchiveRepeat from "./popover/PopoverEditActionStatusArchiveRepeat";
import VideoCallBlock from "../../videocall/VideoCallBlock";
import PopoverShowReportPaidByRequest from "./popover/PopoverShowReportPaidByRequest";

const dateCompare = (a, b) => {
  let momentA = moment(a, "DD/MM/YYYY HH:mm");
  let momentB = moment(b, "DD/MM/YYYY HH:mm");
  if (!momentB.isValid()) {
    return 1;
  }
  if (!momentA.isValid()) {
    return -1;
  }
  return momentA.format("x") < momentB.format("x")
    ? -1
    : momentA.format("x") === momentB.format("x")
    ? 0
    : 1;
};
const styles = (theme) => ({
  requestListWrapper: {
    marginTop: "0rem",
    "&.listManagerWrapper": {
      "& .tableContainer": {
        "& table thead": {
          "& tr": {
            "&:first-child": { "& th": { paddingBottom: 0 } },
          },
        },
      },
      "& > .MuiPaper-root": {
        background: "transparent",
        boxShadow: "none",
      },
      "& .MuiTableBody-root": {
        "& tr": {
          "& td": {
            background: "#fff",
            border: "2px solid transparent",
            boxShadow: "1px 2px 1px 0px rgba(12, 66, 138, 0.10)",
            paddingTop: "22px",
            paddingBottom: "22px",

            "&:first-child": {
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            "&:last-child": {
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            },
          },

          "& .nameProduct": {
            textAlign: "center",
          },
        },
      },
    },
    "& .MuiPaper-root": {
      borderRadius: 15,
      backgroundColor: "transparent",
      boxShadow: "none !important",
    },
    "& .tableContainer": {
      "& table thead": {
        "& tr": {
          "&:first-child": {
            "& th": {
              color: "#002140",
              fontWeight: 500,
              fontFamily: NUNITO,
              opacity: 0.51,
              lineHeight: "20px",
            },
          },
          "& th": {
            borderBottom: "none !important",
            paddingTop: "0",
            paddingBottom: "0 !important",
            [theme.breakpoints.down(XS)]: {
              fontSize: "0.7rem",
            },
          },

          "& .id": {},
        },
      },
      "& table tbody": {
        "& .MuiTableRow-root": {
          "& td": {
            whiteSpace: "normal !important",
            borderBottom: "none",
            "&.action": {
              padding: "8px 0 !important",
              //minWidth: '475px',
              minWidth: "240px",
              width: "100%",
            },
          },
        },

        "& td:first-child": {},
        "& tr:last-child": {
          "& td": {
            borderBottom: "1px dashed #D4D4D4",
          },
        },
      },
    },
  },
  requestPage: {
    position: "relative",
    //background: '#f2f7fc',
    background: "f2f7fc",
    "& .headerRequestPage": {
      background: `url(${banner})`,
      backgroundSize: "cover",
      height: 154,
      position: "relative",
      "&::before": {
        content: '""',
        //width: '55%',
        height: "100%",
        position: "absolute",
        //background: 'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
        background: "#2020ac",
        opacity: "0.5",
        boxShadow: "inset 5px -38px 44px -18px rgba(0,0,0,0.54)",
        width: "100%",
      },
      [theme.breakpoints.down(SM)]: {
        height: "fit-content",
        minHeight: 150,
      },
    },
    "& .headerRequestPageClient": {
      marginBottom: "30px",

      [theme.breakpoints.down(SM)]: {
        height: 174,
      },
      //SPECIFIC CLIENT TABLE ELEMENTS
      "&~.bodyRequestPage": {
        "& .productList": {
          "& table": {
            //borderSpacing: '0px 0px !important',
            "& .MuiTableBody-root": {
              "& tr": {
                "& td": {
                  //borderBottom: '1px dashed #D4D4D4',
                  border: "2px solid transparent",
                  boxShadow: "1px 2px 1px 0px rgba(12, 66, 138, 0.10)",
                  background: "#fff",

                  "&:first-child": {
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  },
                  "&:last-child": {
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  },
                },

                "& .clientSawManagerLastChat, .managerSawClientLastChat": {
                  width: "100%",

                  "& .btnChatIcon": {
                    marginLeft: "auto",
                  },
                },
              },
              //masterDetail
              "& tr:not(.MuiTableRow-root)": {
                '& td[colspan="12"]': {
                  position: "relative",
                  top: "-17px",
                },
              },

              "& .btnChatIcon": {
                "& svg path": {
                  stroke: "#4040ff",
                  strokeWidth: "0.7px",
                },
                "& >div": {
                  paddingTop: "6px !important",
                },
              },
            },
          },
          //inside masterDetail
          "& .AccordionDetail": {
            borderTopLeftRadius: "0px !important",
            borderTopRightRadius: "0px !important",
            "& table": {
              "& td": {
                background: "transparent !important",
                boxShadow: "none !important",
                borderRadius: "0px !important",
              },
            },
          },
        },
      },
    },
    "& .requestHeader": {
      marginTop: "-3rem",
      // paddingBottom: '1rem',
      position: "relative",
      width: "calc(100% - 350px)",
      [theme.breakpoints.down(MD)]: {
        width: "100%",
        marginTop: 10,
      },
    },
    "& .bodyRequestPage": {
      background: "#f2f7fc",
      padding: "0 4rem 2rem 4rem ",
      [theme.breakpoints.between(MD, LG)]: {
        padding: "0 4rem 2rem 4rem ",
      },
      [theme.breakpoints.between(XS, MD)]: {
        padding: "0 2rem 1rem 2rem",
      },
      [theme.breakpoints.down(XS)]: {
        padding: "0 1rem 1rem 1rem",
        marginTop: "3rem",
      },
      "& .linkToDetail": {
        textDecoration: "none",

        "& .MuiButton-label": {
          display: "flex",
          flexDirection: "column",
        },

        "& .btnDetailClient": {
          textTransform: "uppercase",
          lineHeight: "15px",
          // paddingTop: '14px',
          width: "auto",
          textAlign: "center",
        },
      },
    },
    "& .productList": {
      padding: "0 1rem",
      "&.requestClient": {
        "& table thead tr": {
          "&:nth-child(2)": {
            display: "none",
          },
        },
      },
      "& .tableContainer": {
        "& table": {
          minWidth: "100%!important",
          padding: 13,
        },
      },
      [theme.breakpoints.down(XS)]: {
        padding: "0 0.75rem",
      },
    },
  },
  btnResponse: {
    background: "none !important",
    maxHeight: "81px",
    paddingRight: 35,
    paddingLeft: 0,
    width: "max-content",
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 2,
    paddingBottom: 2,

    "& .MuiButton-label": {
      fontFamily: MONTSERRAT,
      fontWeight: 500,
      fontSize: "0.875rem",
      color: "#605e72",
    },
    "&:before": {
      background: "none !important",
    },
    "&:after": {
      content: '""',
      width: 38,
      position: "absolute",
      right: "-7px",
      top: 0,
      height: "100%",
    },
    "&:hover:not(.addStaffWrapperBtn)": {
      "&:before": {
        background: "none !important",
      },
      "& p, .MuiButton-label": {
        color: "#000 !important",
      },
      "&:after": {
        content: '""',
        background: `url(${seeIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "26px auto",
        width: 38,
        height: "100%",
        backgroundPosition: "center",
      },
    },
  },
  btnGonogo: {
    "&:hover:after": {
      background: "none",
    },
  },
  btnStop: {
    //background: '#DF373C !important',
    "&::before": {
      // background: '#b30006d4 !important'
    },
    "&:hover:after": {
      background: "none",
    },
  },
  btnArchive: {
    marginTop: 5,
    "&::before": {
      background: "none",
    },
    "&:hover": {
      background: "none",
    },
    "&:hover:after": {
      background: "none",
    },
    "& path": {
      fill: "#4040ff",
    },
  },
  btnUnarchive: {
    marginTop: 5,
    "&::before": {
      background: "none",
    },
    "&:hover:after": {
      background: "none",
    },
    "& path": {
      fill: "#4040ff",
    },
  },
  nameShow: {
    marginLeft: 10,
    width: "calc(100% - 70px)",
    "& .nameProblemClient": {
      [theme.breakpoints.down(XS)]: {
        display: "grid",
      },
    },
  },
  name: {
    display: "flex",
    alignItems: "center",
    maxWidth: "200px",
    wordBreak: "break-word",
    textAlign: "left",
    // justifyContent: 'space-between',
    "& .showModalButton": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      border: "1px dashed #7A7AFF",
      marginRight: 14,
      marginLeft: 14,
      padding: "10px 2px 14px 3px",
      borderRadius: 85,

      "& .showModalButton-icon": {
        backgroundImage: `url(${iconEdit})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "53px",
        height: "20px",
        marginBottom: "2px",
      },
      "& .showModalButton-txt": {
        color: "#4040ff",
        fontSize: "11px",
        lineHeight: "12px",
        textAlign: "center",
        maxWidth: "50px",
        fontWeight: 600,
      },

      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#4040ff",

        "& .showModalButton-txt": {
          color: "#fff",
        },
        "& .showModalButton-icon": {
          backgroundImage: `url(${iconEditHover})`,
        },
      },
    },
    "& .nameProblem": {
      overflow: "hidden",
      maxWidth: "350px",
      height: 60,
      "& p:first-child, li:first-child": {
        display: "block",
      },
      "& p, li": {
        display: "none",
        paddingRight: "40px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap!important",
        [theme.breakpoints.down(XS)]: {
          paddingRight: "0px",
          whiteSpace: "initial !important",
          lineHeight: "17px",
        },
      },
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.95rem",
        "& p, & ol": {
          margin: 0,
          padding: 0,
        },
      },
      "& .nameProblemAdmin": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        fontFamily: NUNITO,
        "& .btnPlus": {
          minWidth: 20,
          minHeight: 20,
          maxHeight: 20,
          maxWidth: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#4040ff",
          borderRadius: "50%",
          border: "2px solid #4040ff",
          cursor: "pointer",
          fontWeight: 700,
        },
        "& p": {
          color: "#605e72",
          fontWeight: "500",
          fontSize: "16px",
        },
      },
    },
    "& .nameProblemClient": {
      fontSize: "16px",
      fontWeight: 500,
      // fontWeight: 600,
      // color: '#605e72',
      color: "#605e72",
      // lineHeight: 1.55,
      cursor: "pointer",
      wordBreak: "break-word",
      [theme.breakpoints.down(XS)]: {
        color: "#332e6a",
        lineHeight: 1.55,
        fontWeight: 500,
        // fontSize: '1.25rem',
      },
      "& p": {
        textAlign: "left",
        margin: 0,
      },
    },
    "& svg": {
      marginRight: "10px",
      cursor: "pointer",
      "& path": {
        fill: "#00d5ff",
      },
      "&:hover": {
        transform: "scale(1.2)!important",
        "& path": {
          fill: "#0000c7",
        },
      },
    },
    "& .nameProblemAdmin": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",

      "& .btnPlus": {
        minWidth: 20,
        minHeight: 20,
        maxHeight: 20,
        maxWidth: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#4040ff",
        borderRadius: "50%",
        border: "2px solid #4040ff",
        cursor: "pointer",
        fontWeight: 700,
      },
      "& p": {
        color: "#605e72",
        fontWeight: "500",
        fontSize: "16px",
      },
    },
  },
  noteWrapper: {
    display: "flex",
    alignItems: "stretch",
    cursor: "pointer",
    width: "100%",
    flexDirection: 'row',
    "& .extend": {
      padding: 0,
    },
    "&.hasAppreciate:hover": {
      // backgroundImage: `url(${ToggleShowAppreciationCell} )`,
      // backgroundRepeat: "no-repeat",
      // backgroundPositionX: "left",
      // backgroundPositionY: "center",
      '& .toggleNote img':{
        display: 'block'
      }
    },
    '& .toggleNote':{
      alignItems: 'center',
      width: 30,
      display: 'flex',
      '& img': {
        height: 54,
        width: 28,
        padding: 3,
        borderRadius: 6,
        display: 'none',
        '&:hover':{
          backgroundColor: 'rgba(0, 0, 0, 0.07)'
        }
      }
    },
    "& svg": {
      "&:hover": {
        "& path": {
          fill: "#0000c7 !important",
        },
      },
      "& path": {
        fill: "#00D5FF",
      },
    },
  },
  noteList: {
    padding: "0px 8px",
    width: "calc(100% - 30px)"
  },
  appreciateItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#ebf6fc",
    padding: "9px 14px",
    marginBottom: "0.5rem",
    fontFamily: '"SegoeUI", sans-serif',
    overflowX: "scroll",
    boderRadius: "7px",
    "&::-webkit-scrollbar": {
      height: 5,
      width: 0,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      background: "#1f3b7c",
      zIndex: 999,
    },

    "& .adminInfo": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .avatar": {
        width: "2rem !important",
        height: "2rem !important",
        marginRight: "0.5rem",
      },
    },
    "& .commentContent": {
      flexGrow: 1,
      fontSize: "0.9rem",
      // padding: '0.5rem',
      whiteSpace: "pre-line",
      lineHeight: "15px",
      color: "#2d477a",
      fontsize: NUNITO,
    },
  },
  hiddenNote: {
    maxHeight: 90,
    overflow: "hidden",
    position: "relative",
    "& > div": {
      maxHeight: 90,
      overflow: "hidden",
    },
    "& .commentContent": {
      maxHeight: 78,
      flexGrow: 1,
      lineHeight: "15px",
      fontSize: "0.9rem",
      padding: "0.1rem",
      overflow: "hidden",
      display: "-webkit-box",
      whiteSpace: "pre-line",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
    },
    "& .linearGradient": {
      backgroundImage: "linear-gradient(transparent 10%, #ffffff 100%)",
      height: 27,
      position: "absolute",
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
  tableHeaderCell: {
    fontFamily: NUNITO,
    fontWeight: 500,
    fontSize: "0.775rem",
    lineHeight: 1,
    color: " #001529",
    textTransform: "uppercase",
  },
  tableHeaderCellClient: {
    textAlign: "center",
  },
  noteHeaderCell: {},
  popoverProblem: {
    pointerEvents: "none",
    "& .MuiPaper-root": {
      boxShadow: "0px 4px 20px rgba(0, 0, 254, 0.05)!important",
      width: "385px",
      maxHeight: "313px",
      overflow: "hidden",
      borderRadius: "15px",
      padding: "16px 10px",
    },
  },
  btnDetailClient: {
    textDecoration: "none",
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      fontFamily: NUNITO,
      fontSize: 17,
      fontWeight: 700,
      color: "#0082c9",
      padding: "0.825rem 1rem 0.825rem 1.625rem",
      borderRadius: 25,
      border: "1px solid #2091ce",
      minWidth: "195px",
      lineHeight: "20px",
      transition: "0.1s ease",
      "& .iconDetail": {
        paddingLeft: "0.825rem",
        fontWeight: 900,
      },
      "&:hover": {
        transition: "all .4s ease-in-out",
        border: "solid 1px #0082c9",
        background: "#0082c9",
        color: "#fff",
      },

      [theme.breakpoints.down(XS)]: {
        padding: "5px 2px",
        fontSize: "0.9rem",
        justifyContent: "center",
        minWidth: "170px",
        paddingLeft: "6px",
      },
    },
  },
  aRespon: {
    textDecoration: "none",
  },
  categories: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    "& .addCategory": {
      padding: "0 0.5rem",
      cursor: "pointer",
      "& svg": {
        "&:hover": {
          "& path": {
            fill: "#04b1d3 !important",
          },
        },
        "& path": {
          fill: "#00D5FF",
        },
      },
    },
  },
  client: {
    color: "#00d5ff",
    cursor: "pointer",
    fontSize: "0.875rem",
    "&:hover": {
      color: "#0000c7",
    },
  },
  clientModal: {
    borderRadius: "15px",
    // backgroundImage: `url(${clientGround})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'bottom right',
    // minWidth: '400px',
    maxWidth: "calc(100vw - 20px)",
    height: "85%",
    overflow: "auto",
    width: 1200,
    "& .headerModalWrapper": {
      position: "relative",
      height: 80,
      "& .closeButtonModal": {
        position: "absolute",
        top: "20px",
        right: "20px",
      },
    },
    "& .bodyModalWrapper": {
      position: "relative",
      minHeight: "250px",
      maxHeight: "calc(100% - 80px)",
      "&::-webkit-scrollbar": {
        width: 9,
        height: 9,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#0000ff",
        borderRadius: 5,
      },
    },
    [theme.breakpoints.down(SM)]: {
      width: "550px!important",
    },
    [theme.breakpoints.down(XS)]: {
      width: "375px!important",
      "& .headerModalWrapper": {
        "& .closeButtonModal": {
          top: "5px!important",
          right: "5px!important",
        },
      },
    },
  },
  statusType: {
    display: "flex",
    alignItems: "center",
    "& .paidShow": {
      marginLeft: 10,
      padding: "0.5rem",
      lineHeight: 1,
      fontSize: "0.875rem",
      color: "#fff",
      borderRadius: 10,
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
      maxWidth: 90,
      overflow: "hidden",
    },
    "& .notPaid": {
      background: "#e33838",
      height: 33,
    },
    "& .paid": {
      background: "#13be2a",
    },
    "& img": {
      height: 30,
      width: 30,
    },
    "& .repairer": {
      display: "flex",
      alignItems: "center",
      marginLeft: 10,
    },
    "& .repairSvg": {
      maxWidth: 20,
      maxHeight: 20,
      "& path": {
        fill: "#0035FB!important",
      },
    },
    "& .completeIcon": {
      color: "#0071c7",
    },
  },
  statusTypeAdmin: {
    textAlign: "center",
    display: "flex",
    //justifyContent: 'center',
    "& img": {
      width: 20,
      height: 20,
    },
    "& .paidShow": {
      height: 20,
    },
    "& img.not_repaired": {
      height: 20,
      width: 20,
    },
  },
  statusTypeClient: {
    padding: "0 19px",
    justifyContent: "center",
    "& img": {
      width: "initial",
      height: "initial",
    },
  },
  startTimeWrapper: {
    color: "#00d5ff",
    fontSize: "0.875rem",
    cursor: "pointer",
    "&:hover": {
      color: "#0000c7",
    },
  },
  startTimeColumn: {
    fontWeight: 800,
  },
  addStaffWrapper: {
    borderRadius: 4,
    top: "0px",
    position: "relative",
    padding: "10px 7px 8px 2px",
    "& span": {
      fontWeight: 300,
      fontSize: "10px !important",
      paddingLeft: "5px",
      textTransform: "uppercase !important",
      fontFamily: "Nunito !important",
    },
    "&:hover": {
      background: "rgba(194, 203, 223, 0.64) !important",
      transition: "0.2s ease",
      top: "-4px",

      "& .MuiButton-label": {
        color: "#000 !important",
      },
    },
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  btnGrid: {
    marginTop: 3,
    marginBottom: 3,
    width: "108px!important",
  },
  staffPerson: {
    // color: '#00d5ff',
    color: "#323134",
    fontSize: "1rem",
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      color: "#0000c7",
    },
  },
  titleSelectDate: {
    color: "#1D243E",
    fontSize: "1rem",
    fontWeight: 700,
    paddingLeft: 10,
  },
  setDateModal: {
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 20px rgba(0, 0, 116, 0.1)",
    borderRadius: 15,
    width: "auto",
    maxWidth: 570,
    minWidth: 565,
    "& .bodyModalWrapper": {
      maxHeight: "100%",
      overflow: "initial",
    },
  },
  headerForAdmin: {
    margin: "1.8rem 6rem 0.5rem 6em",
    display: "flex",
    [theme.breakpoints.down(XS)]: {
      margin: "0.5rem 1rem",
    },
  },
  datePicker: {
    display: "flex",
    background: "#fff",
    width: 290,
    boxShadow: "0px 4px 20px rgba(0, 0, 254, 0.05)",
    borderRadius: 15,
    padding: "0 0.2rem",
    "& input": {
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.65rem",
      },
    },
    "& svg": {
      [theme.breakpoints.down(XS)]: {
        width: 18,
        height: 18,
      },
    },
    "& .startDate, & .endDate": {
      "& button:hover": {
        "& svg>path": {
          fill: "#0000FF",
        },
      },
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
    },
    "& .MuiInputBase-root": {
      [theme.breakpoints.down(XS)]: {
        paddingRight: 5,
      },
      border: "none!important",
      "& button": {
        width: 20,
        height: 20,
      },
    },
    "& .divider": {
      width: "1px",
      height: "60%",
      background: "#757575",
      margin: "auto",
    },
  },
  pageType: {
    width: 116,
    background: "#fff",
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 20px rgba(0, 0, 254, 0.05)",
    overflow: "hidden",
    marginLeft: "0.625rem",
    cursor: "pointer",
    "& .iconType": {
      width: "50%",
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  activePageType: {
    background: "#DDDDFF",
    "& svg > path": {
      fill: "#0000FF",
    },
  },
  gridComponentClient: {
    padding: "0 12px!important",
    "&:last-child": {
      "& $gridComponentWrapper": {
        //borderBottom: '1px solid #D4D4D4'
      },
    },
    "& $gridComponentWrapper": {
      borderRadius: 0,
      boxShadow: "none",
      //borderTop: '1px solid #D4D4D4',
    },
    "& .MuiAccordionDetails-root": {
      padding: "43px 20px 42px 40px",
      background: "#e4effd",
      borderRadius: "9px",
      margin: "3px",
      marginTop: "0px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      [theme.breakpoints.down(XS)]: {
        padding: 20,
      },
    },
  },
  gridComponentWrapper: {
    //background: '#fff',
    background: "transparent",
    boxShadow: "0px 4px 20px rgba(0, 0, 254, 0.05)",
    borderRadius: 15,
    padding: "1rem 1rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "3px",
    [theme.breakpoints.down(XS)]: {
      padding: 5,
    },
    "& .MuiAccordion-root.MuiAccordion-rounded": {
      boxShadow:
        "0px 2px 1px -1px rgba(145, 145, 145, 0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(151, 149, 149, 0.12)",
      borderRadius: "12px",
    },
  },
  titleWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  titleWrapperClient: {
    display: "flex",
    alignItems: "center",
    flexWrap: "no-wrap",
    // borderBottom: '1px solid #DDE4F8'

    [theme.breakpoints.down(XS)]: {
      borderTopLeftRadius: 9,
      borderBottomLeftRadius: 9,
    },
  },
  titleID: {
    textTransform: "upperCase",
    color: "#656A7B",
    marginRight: 10,
    width: 40,
    "&.titleID1": {
      width: 45,
      marginRight: 0,
      // paddingLeft: 4,
      display: "flex",
      alignItems: "center",
      height: "fit-content",
      flexWrap: "wrap",
      lineHeight: 1.1,
    },
  },
  titleCustomer: {
    color: "#656A7B",
    cursor: "pointer",
    "&:hover": {
      color: "#0000c7",
    },
  },
  statusWrapperGrid: {
    width: 55,
    display: "flex",
    alignItems: "center",
    "& img": {
      height: 33,

      [theme.breakpoints.down(XS)]: {
        height: "29px",
        marginLeft: "6px",
      },
    },
  },
  titleCustomerClient: {
    display: "flex",
    width: "calc(100% - 45px)",
    "& img": {
      padding: "0 5px",
    },
    "& .problemWrapper ": {
      // width: '100%',
      width: "calc(100% - 55px)",
    },
    "& $name": {
      display: "flex",
      alignItems: "center",
      height: 60,
      paddingLeft: 4,
      "& .nameProblem": {
        height: "auto !important",
        maxHeight: "100%",
      },
    },
    "&.rowDetail": {
      "& $name": {
        height: "100%",
        maxWidth: "unset!important",
        "& .nameProblem": {
          height: "auto !important",
          maxHeight: "100%",
          maxWidth: "unset",
          width: "100% !important",
          "& p, li, ol, ul": {
            display: "block!important",
            wordBreak: "break-all",
          },
        },
      },
    },
    "& .problemClientCustomer": {
      flexBasis: "30%!important",
      // marginRight: 5
    },
  },
  contentProblem: {
    position: "relative",
    paddingBottom: 10,
    "& .problemContentNormal": {
      height: "100px !important",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C4C4C4",
        borderRadius: 3,
      },
    },
  },
  componentBottomWrapper: {
    display: "flex",
    alignItems: "flex-end",
    "& .reportIconDetail": {
      // width: '100%',
      // padding: '1rem 0',
      display: "flex",
      height: 68,
      justifyContent: "center",
      marginBottom: "7px",
      "& .listShowTypeGrid": {
        //background: '#cdcdff',
        height: "auto !important",
        marginLeft: "auto",
        borderRadius: "10px",
        padding: "12px 20px",
        minHeight: "85px",
        background: "#004cff17",
        minWidth: "120px",
      },
      "& .listShowTypeGrid1": {
        //background: '#a7fee9',
        //padding: '0.5rem 0.5rem 0',
        height: "auto !important",
        marginLeft: "auto",
        borderRadius: "10px",
        padding: "12px 20px",
        minHeight: "85px",
        background: "#3bf2d040",
        minWidth: "120px",
        "& .toggleIcon": {
          // background: '#a7fee9',
          color: "#323262",
        },
        "& img": {
          height: "30px",
          paddingBottom: "5px",
        },
      },
      "& .toggleIcon": {
        // width: '100%',
        // background: '#cdcdff',
      },
    },
  },
  componentBottomWrapperClient: {
    flexWrap: "wrap",
    "& .dateCreate": {
      paddingRight: 8,
    },
    "& $setClientInfo": {
      maxWidth: "100%",
      height: 45,
      width: 120,
      margin: "auto",
      padding: "0 1rem",
      "& .buttonContent": {
        fontSize: "0.8rem",
        marginLeft: 5,
      },
      "& img": {
        width: "20px!important",
        height: "20px!important",
      },
      "& .infoIcon": {
        width: 30,
        height: 30,
        fontSize: "1.25rem",
        "& svg": {
          transform: "scale(0.5)!important",
        },
      },
    },
  },
  videoCallWrapper: {
    paddingRight: 40,
    paddingTop: 5,
  },
  componentBottom: {
    marginTop: 10,
    paddingTop: 10,
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      width: "100%",
      height: 1,
      background: "#DDE4F8",
    },
  },
  dataInfoClient: {
    display: "flex",
    marginBottom: 10,
    "& img": {
      marginRight: 5,
    },
  },
  btnGroup: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& $btnResponse": {
      // width: 'auto!important',
      minWidth: "105px",
    },
    "& $addStaffWrapper": {
      marginLeft: 5,
    },
  },
  viewNote: {
    background: "#fff!important",
    border: "1px solid #1D243E",
    color: "#1D243E",
    paddingRight: 3,
    paddingLeft: 3,
    fontWeight: 700,
    fontSize: "0.875rem",
    marginLeft: 5,
    minWidth: "105px!important",
    transition: 0.3,
    "&::before": {
      background: "#dcd8d8!important",
    },
  },
  viewNoteModal: {
    width: 485,
    borderRadius: 15,
    boxShadow: "0px 4px 20px rgba(0, 0, 254, 0.05)",
    "& .headerModalWrapper": {
      position: "relative",
      "&::before": {
        content: '""',
        width: "100%",
        height: "1px",
        background: "#E0E0E0",
        position: "absolute",
        bottom: 0,
        left: 0,
      },
    },
    "& .bodyModalWrapper": {
      paddingTop: 20,
    },
  },
  titleViewNote: {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
  messageDialog: {
    "& .icon": {
      "&.archive23": {
        textTransform: "uppercase",
        "& svg": {
          marginRight: 8,
        },
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: 30,
        height: 30,
        marginRight: 10,
      },
      "& span": {
        color: "#df373c",
      },
    },
  },
  repairedRow: {
    background: "#cbeeff",
  },
  repairedPaidRow: {
    background: "#D7FFF4",

    "& .clicZone": {
      border: "0px !important",

      "&:hover": {
        background: "rgb(179, 251, 240) !important",
      },
    },
  },
  nogoRow: {
    background: "#FBECED",
  },
  setClientInfoHasTime: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0px",
    "& .hasTimeLeft": {
      textAlign: "center",
      "& .title": {
        color: "#4e4eff",
        fontWeight: 700,
        fontSize: "1.2rem",
      },
      "& .buttonContent": {
        color: "#404040",
        fontWeight: 600,
        "& span:first-child": {
          paddingRight: 5,
        },
      },
      paddingRight: 10,
    },
  },
  setClientInfo: {
    marginLeft: 19,
    textDecoration: "none",
    position: "relative",
    padding: "0 1.5rem",
    display: "flex",
    height: 54,
    width: 190,
    // backgroundImage: 'linear-gradient(to bottom, #0070c7, #00c1d1, #0070c7)',
    backgroundColor: "#1c1cff",
    backgroundSize: "1px 130px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 32,
    // justifyContent: 'center',
    alignItems: "center",
    cursor: "pointer",
    color: "#fff",
    minWidth: "155px",
    "&:hover": {
      backgroundPosition: "0 70px",
      transition: "all .3s ease-in-out",
      backgroundColor: "#111cb3",
    },
    "&.goDoc": {
      background: "#1c1cff",

      "&:hover": {
        background: "#111cb3",
      },

      [theme.breakpoints.down(XS)]: {
        margin: 0,
        width: "auto",
        maxWidth: "160px",

        "& span": {
          color: "#fff",
          fontSize: "0.75rem !important",
        },
      },
    },
    "& .buttonContent": {
      fontWeight: "bold",
      fontSize: "0.9rem",
      //textTransform: 'uppercase',
      marginLeft: 10,
      lineHeight: 1,
      display: "flex",
      flexDirection: "column",
      textAlign: "start",
      color: "#fff",
    },
    "& .infoIcon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.75rem",
      fontWeight: "bold",
      width: 38,
      height: 38,
      background: "#0000ff",
      position: "absolute",
      top: 0,
      left: "-19px",
      borderRadius: "50%",
    },
    "& .goIconInfo": {
      background: "#38ca8e!important",
      "& svg": {
        "& path": {
          fill: "#fff",
          stroke: "#fff",
        },
      },
    },
    "& svg": {
      width: 29,
      "& path": {
        fill: "#fff",
        // stroke: '#fff'
      },
    },
  },
  btnReportView: {
    borderRadius: 35,
    border: "2px solid #4e4eff",
    padding: "5px 20px",
    marginLeft: 20,
    maxHeight: 60,
    minHeight: "52px",
    marginTop: "10px",

    "& .text": {
      color: "#4e4eff",
      fontWeight: "bold",
      minWidth: 120,
      textTransform: "initial",
      fontSize: "1rem",
      paddingRight: 10,
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",

      "& .textNumber": {
        fontSize: "1.5rem",
      },

      "& .textReceived": {
        width: "min-content",
        textAlign: "left",
        paddingLeft: 5,
        lineHeight: "0.95rem",
      },
    },
    "& svg": {
      "& path": {
        fill: "#4e4eff",
      },
    },
  },
  actionWrapper: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down(XS)]: {
      flexDirection: "column",
      "& button": {
        margin: "6px 2px 5px 0",
      },
    },
  },
  clientNotPaid: {
    display: "flex",
    color: "#127ec5",
    minWidth: 200,
    "& .registrationBlock": {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      marginLeft: 8,
      minWidth: 130,
      width: "min-content",
      "& .waitingConfirmation": {
        fontSize: "1rem",
        lineHeight: 0.88,
        fontWeight: 500,
      },
      "& .registrationTime": {
        fontSize: "1.125rem",
        fontWeight: 900,
        lineHeight: 1.39,
      },
    },
    "& .editTimeBtn": {
      background: "#fff",
      width: 33,
      height: 52,
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .iconBtn": {
        color: "#127ec5",
      },
      "&:hover": {
        background: "#81b9e4",
        "& .iconBtn": {
          color: "#fff",
        },
      },
    },
  },
  dateColumn: {
    display: "flex",
    color: "#605e72",
    flexDirection: "column",
    "&.dateColumn1": {
      "& .dateCreate": {
        fontSize: "1rem",
        fontWeight: 500,
      },
      "& .hourCreate": {
        fontSize: "1rem",
        fontWeight: 300,
      },
    },
    "& .dateCreate": {
      paddingRight: 3,
    },
  },
  idColumn: {
    color: "#605e72",
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  columnClient: {
    textAlign: "center",
  },
  tableRowClient: {
    boxSizing: "border-box",
    // '&:hover':{
    //     outline:'3px solid #008cca',
    //     '& td':{
    //         '& .nameProduct, & .nameProblemClient, & .dateCreate, & .hourCreate':{
    //             fontWeight: '800!important'
    //         }
    //     }
    // }
    "& .nameProduct": {
      width: "max-content",
      wordBreak: "break-all",
      lineHeight: "1.2rem",
      margin: "auto",
      textAlign: "center",
    },
  },
  filesCell: {
    display: "flex",
    flexWrap: "wrap",
    "& .btnCreateReport": {
      height: "100%",
      paddingLeft: 5,
      "& $btnCreateReport": {
        marginTop: 0,
        padding: "14px 8px",
      },
    },
    "& .btnUpload": {
      backgroundColor: "rgba(230, 237, 252,0.9)",
      borderRadius: 10,
      padding: "6px 30px",
      "&:hover": {
        background: "#ccd0db",
      },
    },
    "& a": {
      textDecoration: "none",
    },
  },
  btnCreateReport: {
    backgroundColor: "rgba(230, 237, 252,0.9)",
    borderRadius: 10,
    padding: "8px",
    textAlign: "center",
    marginTop: 5,
    lineHeight: 1.1,
    color: "#00519e",
    "&:hover": {
      background: "#ccd0db",
    },
  },
  filesModal: {
    backgroundColor: "#e6edfc",
    minWidth: "calc(100% - 20px)",
    width: "calc(100% - 150px)",
    height: "calc(100vh - 150px)",
    // maxHeight: 700,
    // minHeight: 600,
    borderRadius: 34,
    [theme.breakpoints.down(XS)]: {
      height: "calc(100vh - 20px)",
    },
    "& .closeIcon": {
      "& svg": {
        // color: '#fff',
        "& path": {
          fill: "#2048a7",
          stroke: "#2048a7",
        },
        "&:hover": {
          background: "#fff",
          "& path": {
            stroke: "#6d8cbd",
          },
        },
      },
    },
    "& .headerModalWrapper": {
      padding: "10px 20px 0px 20px",
    },
    "& .bodyModalWrapper": {
      maxHeight: "calc(100% - 80px)",
      // [theme.breakpoints.down(XS)]: {
      //     maxHeight: 'calc(100% - 80px)',
      // },
    },
  },
  buttonVideoMp4: {
    width: 132,
    height: 52,
    borderRadius: 26,
    display: "flex",
    padding: "0.5rem 1rem",
    margin: "auto",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.16)",
    backgroundImage: "linear-gradient(180deg, #fff, #f2f7fc)",
    textTransform: "uppercase",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "0.75rem",
    color: "#000",
    fontWeight: 500,
    fontFamily: NUNITO,

    "& img": {
      paddingRight: "0.5rem",
      height: 25,
    },
    "&:hover": {
      background: "#e4edf8",
      transition: "all .4s ease-in-out",
    },
  },
  otherDocWrapper: {
    display: "flex",
    justifyContent: "center!important",
    alignItems: "flex-start !important",
    "& > div": {
      padding: 8,
      margin: "0 !important",
    },
    "& .btnDetail": {
      padding: 5,
    },
    "& g": {
      clipPath: "initial !important",
    },
    "& .addDocWrapper1": {
      margin: 0,
      "& .dropAvatar > div": {
        maxWidth: 100,
      },
    },
    "& .iconBtnAddCameraWrapper": {
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      paddingBottom: 8,
    },
    // '& button': {
    //     width: '85px',
    //     margin: 0
    // },
    "& .linkToDetail": {
      borderRight: "1px solid rgba(113, 109, 135, 0.25)",
      // paddingRight: '10px',
    },
    "& .labelButtonWrapper": {
      display: "none",
    },
    [theme.breakpoints.down(XS)]: {
      "& div": {
        fontSize: "0.6875rem !important",
      },
      // '& button':{
      //     margin: '0 13px',
      // },
      // '& .MuiButton-label':{
      //     display: 'flex',
      //     flexDirection: 'column',
      //     alignItems: 'flex-start !important',
      //     '& div':{
      //         lineHeight: '0.9rem',
      //         fontSize: '0.6875rem !important',
      //         // paddingTop: '13px',
      //     }
      //
      // },
      "& .linkToDetail:last-child": {
        borderRight: 0,
      },
    },
  },
  openPopoverMenuThreeDots: {
    "& $actionChatWrapper": {
      display: "block",
      background: "rgba(0,0,0,0.1)",
      borderRadius: "10px",
    },
  },
  btnChat: {
    "&.btnDetail": {
      padding: "8px 12px",
      textTransform: "capitalize",
      "& > div": {
        width: "calc( 100% - 23px)",
        paddingTop: 0,
        // paddingLeft: 5
      },
      flexDirection: "column",

      [theme.breakpoints.down(XS)]: {
        // margin: '0 13px',

        "& .btnDetailClient": {
          width: "min-content",
          fontSize: "0.6875rem !important",
          lineHeight: "0.9rem",
          color: "#2C3F79",
        },
      },
    },
    padding: "8px 20px",
    // minWidth: 'unset',
    textTransform: "initial",
    color: "#716d87",
    fontSize: "0.7rem",
    minWidth: 80,
    maxWidth: 120,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    position: "relative",
    "&.btnChat1": {
      width: "auto",
      "& svg path": {
        stroke: "#4040ff",
        strokeWidth: "0.7px",
      },
    },
    "& .iconBtnWrapper": {
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      paddingBottom: 8,
    },
    "& .iconBtnBlock": {
      width: "fit-content",
      position: "relative",
      display: "flex",
      alignItems: "flex-end",
      height: 30,
    },
    "& svg path": {
      fill: "#4040ff",
    },
    // '& .MuiButton-label': {
    //     // position: 'relative',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    "& > div": {
      lineHeight: "16px",
      // paddingTop: '8px',
      textTransform: "uppercase",
    },

    // },
    "& .hasNewChat": {
      width: 24,
      height: 24,
      // backgroundColor: '#f02849',
      // borderRadius: '50%',
      position: "absolute",
      top: -4,
      right: -4,
      backgroundImage: `url(${alertIcon})`,
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down(XS)]: {
        top: 8,
        right: 13,
      },
    },
    "&:hover": {
      background: "#dbedff7d",
      borderRadius: "10px",
      "& $actionChatWrapper": {
        display: "block",
      },
      "& div": {
        color: "#1c1cff",
      },
    },
  },
  actionChatWrapper: {
    transform: "rotate(270deg)",
    position: "absolute",
    top: 0,
    right: -10,
    padding: 5,
    zIndex: 1,
    width: 25,
    height: 25,
    display: "none",
    "& .detailICon": {
      position: "relative",
      "& .threeDot": {
        position: "absolute",
        fontSize: 20,
        lineHeight: "5px !important",
        fontWeight: 800,
      },
    },
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
      borderRadius: "10px",
      "& div": {
        color: "#000",
      },
    },
  },
  iconAlertNotSeen: {
    width: 27,
    height: 27,
    position: "absolute",
    top: -7,
    right: -7,
    backgroundImage: `url(${alertIcon})`,
    backgroundRepeat: "no-repeat",
    "&.iconAlertNotSeen2": {
      top: -7,
      right: -15,
    },
  },
  //MC remi
  // iconAlertNotSeen: {
  //     top: '-9px',
  //     right: '30px',
  //     width: '18px',
  //     height: '18px',
  //     position: 'absolute',
  //     borderRadius: '50%',
  //     backgroundColor: '#ff1e68',
  //     border: '3px solid #fff',
  //     zIndex: 1,

  //     '&~svg path':{
  //         fill: '#ff1e68 !important',
  //     }
  // },
  otherDoc: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    width: "fit-content",
    margin: "auto",
    "& span": {
      position: "absolute",
      display: "flex",
      width: 26,
      height: 26,
      borderRadius: "50%",
      background: "#007ec8",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#fff",
      right: -11,
      top: -6,
      justifyContent: "center",
      alignItems: "center",
    },
    "&:hover": {
      "& span": {
        background: "#00549c",
      },
    },
  },
  clientListRow: {
    display: "flex",
    alignItems: "center",
    margin: "0.5rem 0",

    "& .titleListRow": {
      color: "#656A7B",
      fontWeight: 800,
      fontSize: "0.9rem",
    },
    "& $otherDoc span": {
      color: "#fff",
    },
    "& .iconListRow": {
      textAlign: "center",
      marginTop: 7,
      "& img": {
        width: "auto",
        height: 25,
      },
      "& .not_repaired": {
        width: 18,
        height: 18,
      },
      "& $otherDoc": {
        "& img": {
          width: 35,
          height: 35,
        },
        "& span": {
          width: 20,
          height: 20,
          fontSize: "0.875rem",
          right: -8,
        },
      },

      [theme.breakpoints.down(XS)]: {
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-start",
      },
    },
    "& .textListRow": {
      textAlign: "center",
      [theme.breakpoints.down(XS)]: {
        textAlign: "left",
      },
    },
    "& $clientNotPaid": {
      alignItems: "center",
      justifyContent: "center",
      minWidth: "unset",
      "& img": {
        width: 25,
        height: 25,
      },
      "& .registrationBlock": {
        minWidth: 95,
        width: 95,
        "& .waitingConfirmation": {
          fontSize: "0.875rem!important",
          minWidth: 110,
        },
        "& .registrationTime": {
          fontSize: "0.8rem!important",
        },
      },
      "& .editTimeBtn": {
        height: 40,
      },
    },
    "& $buttonVideoMp4": {
      width: 115,
      height: 40,
      padding: "0.25rem 0.5rem",
    },
    "& .nameProblem": {
      fontSize: "0.875rem",
    },
    "& p, span": {
      color: "#656A7B",
    },

    [theme.breakpoints.down(XS)]: {
      alignItems: "center",
      //borderBottom: '1px dashed #999',
      //paddingBottom: '19px',
      "& .titleListRow": {
        color: "#2C3F79",
      },
      "& span": {
        color: "#2C3F79",
      },
    },
  },
  filterClientGrid: {
    // textAlign: 'center',
    "& .searchInput": {
      width: "90%",
    },
  },
  toggleCellWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    "&.listShowTypeGrid": {
      [theme.breakpoints.between(XS, SM)]: {
        height: 30,
        width: 80,
        "& img": {
          maxWidth: 80,
          "&.reportIcon": {
            height: 25,
          },
        },
        "& .toggleIcon": {
          fontSize: 13,
        },
        "& svg": {
          fontSize: 16,
        },
      },
    },
    "&.reportPaid": {},
    "& .toggleIcon": {
      fontSize: 13,
      color: "#3031f4",
      fontFamily: NUNITO,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  rulesWrapper: {
    fontFamily: NUNITO,
    "& .labelOk": {
      fontSize: 17,
      color: "#6d6d6d",
    },
    "& .amountPaid": {
      fontSize: 20,
      color: "#00d1a6",
      fontWeight: 600,
      display: "flex",
    },
    "& .btnReportPaid": {
      cursor: "pointer",
      padding: 5,
      borderRadius: 15,
      marginBottom: 5,
      width: "fit-content",
      "& div": {
        lineHeight: 1.2,
      },
      "&:hover": {
        background: "rgba(0,0,0,0.1)",
      },
    },
  },
  btnSeenRequestAlertWrapper: {
    cursor: "pointer",
    backgroundColor: "#0000f9",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: 15,
    textTransform: "uppercase",
    fontWeight: 600,
    width: "fit-content",
    opacity: 0.85,
    "&:hover": {
      opacity: 1,
    },
  },
  editRequest: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  iconAction: {
    width: 30,
    height: 30,
  },
  statusWrapper: {
    // border: 'none!important',
    padding: "0 !important",
    position: "relative",
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    "& svg, img": {
      height: 32,
    },
    "&.waitingForReply": {
      backgroundColor: "#ffecf9",
      "& svg": {
        "& path": {
          fill: "#eb35b4",
        },
      },
    },

    "& .statusContent": {
      display: "flex",
      alignItems: "center",
      padding: "0 20px 0 25px",
      [theme.breakpoints.down(MD)]: {
        padding: "0 8px 0 25px",
      },
      "& .statusText": {
        color: "#38387d",
        fontSize: "1rem",
        lineHeight: "1.1rem",
        padding: "13px 0px 13px 17px",
        maxWidth: 135,
        display: "flex",
        alignItems: "center",
        wordBreak: "break-word",
        width: "max-content",
      },
      "& .statusImg": {
        display: "flex",
        justifyContent: "right",
        width: 50,
      },
    },
    "& .statusReportBlock": {
      [theme.breakpoints.down(MD)]: {
        minWidth: 145,
      },
      minWidth: 165,
    },
    "& .clicZone": {
      cursor: "pointer",
      border: "1px solid #0000f7",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 0,
      "&:hover": {
        background: "rgb(206, 226, 255)",
      },

      "& .statusText": {
        fontWeight: "bold",
      },
    },
    "& .infoIcon": {
      display: "flex",
      fontSize: "1.75rem",
      fontWeight: "bold",
      width: 38,
      height: 38,
      background: "#0000ff",
      position: "absolute",
      cursor: "pointer",
      top: "30%",
      left: "-12px",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      border: "1px solid transparent",
      zIndex: 1,

      "&:hover": {
        background: "#fff",
        color: "#0000ff",
        border: "1px solid #0000ff",
      },
    },
  },
  infoDiagnosisReceived: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px 40px",
    "& .text1": {
      fontSize: "1.3rem",
      fontWeight: 700,
      marginBottom: 20,
      textAlign: "center",
    },
    "& .text2": {
      fontSize: "1rem",
      lineHeight: "1.1rem",
      fontWeight: 700,
      marginBottom: 20,
      textAlign: "center",
    },
    "& .text3": {
      marginBottom: 20,
      textAlign: "center",
      fontSize: "1rem",
      lineHeight: "1.1rem",
    },
    "& .actionDiagnosisReceived": {
      color: "#fff",
      textTransform: "initial",
      padding: "5px 20px",
      borderRadius: 5,
      border: "1px solid #fff",
      fontWeight: 600,
    },
    "& button:hover": {
      background: "#fff !important",
      border: "1px solid transparent !important",
      color: "#4e4eff",
    },
  },
  infoMakeAnAppointment: {
    padding: "20px 40px",
    fontSize: "1rem",
    textAlign: "center",
    "& .textInfo": {
      marginBottom: 20,
    },
    "& a": {
      textDecoration: "none",
    },
    "& .btnRegisterTime": {
      color: "#fff",
      textTransform: "initial",
      padding: "5px 20px",
      borderRadius: 5,
      border: "1px solid #fff",
      fontWeight: 600,
    },
  },
  modalReportListByRequestWrapper: {
    width: "100vw !important",
    "& .headerModalWrapper": {
      padding: "0.5rem 1rem",
    },
    "& .bodyModalWrapper": {
      width: "100% !important",
      height: "100% !important",
      maxWidth: "initial !important",
      maxHeight: "initial !important",
      padding: 10,
      "& .tableReportByRequestContentWrapper": {
        width: "100%",
        overflow: "auto",
      },
    },
  },
  lastItemActionWrapper: {
    borderLeft: "1px dotted #48489f",
    display: "inherit",
    "& svg": {
      height: 25,
    },
  },
  actionRequestAdminWrapper: {
    display: "flex",
    // flexWrap: 'wrap',
    "& .btnActionOtherBlock": {
      display: "flex",
      // flexWrap: "wrap",
      paddingRight: 10,
      marginLeft: "auto",
      flex: 1,
      "& .videoCallWrapperStyle": {
        paddingRight: 45,
        paddingBottom: 8,
        maxWidth: 190,
        "& .buttonVideoCallAdminWrapper": {
          padding: 0,
          paddingRight: 5,
        },
      },
    },
    "& .btnOtherBlock": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .btnActionWrapper": {
      padding: "10px 7px 8px 7px",
      // margin: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textDecoration: "none",
      cursor: "pointer",
      borderRadius: 4,
      position: "relative",
      transition: "0.2s ease",
      top: "0px",
      "&.btnActionChatWrapper": {
        position: "relative",
        "&:hover": {
          "& $actionChatWrapper": {
            display: "block",
            background: "#f2f2ff",
            right: "-16px",
            borderRadius: "25px",

            "&:hover": {
              background: "#bcc3db",
            },
          },
        },
      },
      "&.btnResponse": {
        // '& .titleIcon':{
        //     color: '#c1c1de'
        // }
      },
      "&.btnStop": {
        "& .titleIcon": {
          color: "#c1c1de",
        },
        "&:hover": {
          background: "rgba(186, 187, 198, 0.26) !important",
          "& .titleIcon": {
            color: "#1e1e1e",
          },
          "& svg path": {
            fill: "#1e1e1e !important",
          },
        },
      },
      "&.btnArchived": {
        "& .titleIcon": {
          color: "#c1c1de",
        },
        "&:hover": {
          background: "rgba(186, 187, 198, 0.26) !important",
          "& .titleIcon": {
            color: "#1e1e1e !important",
          },
          "& svg path": {
            fill: "#1e1e1e !important",
          },
        },
      },
      "&.btnShowReport": {
        display: "flex",
        marginLeft: 5,
        padding: "8px 20px 15px 12px",
        position: "relative",
        "&.dataNumberNull": {
          cursor: "unset",
          "&:hover": {
            background: "transparent !important",
            pointerEvents: "none",
            padding: "8px 12px 15px 12px",
            top: "0px !important",

            "& svg path": {
              fill: "initial !important",
            },
          },
          "& .numberIconWrapper": {
            color: "#c1c1de",
          },
        },
        "& .iconWrapper": {
          padding: 0,
          "& svg": {
            marginRight: 5,
          },
        },
        "& .numberIconWrapper": {
          fontFamily: OPENSANS,
          fontSize: "1rem",
          fontWeight: 600,
          color: "#1414ff",
        },

        "&:hover:not(.dataNumberNull)": {
          background: "transparent !important",
          padding: "0px 20px 0 12px !important",
          "&:after": {
            content: '""',
            background: `url(${nextFolder})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "20px auto",
            width: 39,
            height: "29px",
            backgroundPosition: "center",
            position: "absolute",
            top: "48px",
          },
          "& .numberIconWrapper": {
            color: "#333",
          },
          "& svg path": {
            fill: "#000 !important",
          },
        },
      },
      "&:hover": {
        background: "rgba(91, 94, 255, 0.08)",
        transition: "0.2s ease",
        top: "-4px",
        "& .titleIcon": {
          color: "#0035FB",
          // top:"-4px",
          transition: "0.2s ease",
        },
        "& svg path": {
          fill: "#0035FB !important",
        },
        "& .iconWrapper": {
          //  padding: '0 8px 10px 8px',
          transition: "0.2s ease",
        },
      },
      "& .iconWrapper": {
        position: "relative",
        width: "fit-content",
        padding: "0 8px 6px 8px",
        height: 32,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        transition: "0.1s ease",
        "& svg": {
          transform: "scale(0.9) !important",
          maxHeight: "28px",
          maxWidth: "28px",
          "& path": {
            fill: "#0035FB",
          },
        },
      },
      "& .titleIcon": {
        fontFamily: NUNITO,
        fontSize: 10,
        color: "#716d87",
        textAlign: "center",
        textTransform: "uppercase",
        position: "relative",
        transition: "0.2s ease",
        top: "0px",
      },
    },
  },
  threeDotBlock: {
    transform: "rotate(270deg)",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    width: 24,
    height: 24,
    "& .threeDotAction": {
      fontSize: 24,
      lineHeight: 1,
      fontWeight: 800,
      color: "rgb(20, 20, 255)",
      marginBottom: 5,
    },
  },
  titleGridRequestWrapper: {
    display: "flex",
  },
});

const useStyles = makeStyles(styles);

const TableRow = ({ row, style, ...restProps }) => {
  const classes = useStyles();
  let classNames = classes.tableRowClient;
  if (row.status && row.status.statusType && row.status.statusType.code) {
    let statusType = row.status.statusType.code;
    if (statusType === REPAIRED && row.payment) {
      if (row.payment && row.payment.paid)
        classNames += " " + classes.repairedPaidRow;
      else classNames += " " + classes.repairedRow;
    } else if (row.requestReportInfo && row.requestReportInfo.paid) {
      classNames += " " + classes.repairedPaidRow;
    }
    if (statusType === NOT_BE_REPAIRED) classNames += " " + classes.nogoRow;
  }
  return (
    <Table.Row {...restProps} style={{ ...style }} className={classNames} />
  );
};
const TableRowAdmin = ({ row, style, ...restProps }) => {
  const classes = useStyles();
  let classNames = classes.tableRow;
  return (
    <Table.Row {...restProps} style={{ ...style }} className={classNames} />
  );
};

const TableCell = (props) => {
  const classes = useStyles();
  let classesCell = classes.tableCell;
  let columnName = props?.column?.name;
  if (
    columnName &&
    [
      "id",
      "status",
      "pdf",
      "otherDoc",
      "detail",
      "video",
      "clientSawManagerLastChat",
      "managerSawClientLastChat",
    ].includes(columnName)
  ) {
    classesCell += " " + columnName;
  }
  return (
    <Table.Cell className={classesCell}>
      <div>{props.value}</div>
    </Table.Cell>
  );
};
const TableCellAdmin = (props) => {
  const classes = useStyles();
  let classesCell = classes.tableCell;
  let columnName = props?.column?.name;
  if (columnName && ["action"].includes(columnName)) {
    classesCell += " " + columnName;
  }
  return (
    <Table.Cell className={classesCell}>
      <div>{props.value}</div>
    </Table.Cell>
  );
};

const TableHeaderCellRoot = (props, showAllNote, setShowAllNote, dataGroup) => {
  const classes = useStyles();

  if (props.column.name === "note") {
    return (
      <TableHeaderRow.Cell
        {...props}
        className={`${classes.tableHeaderCell} ${classes.noteHeaderCell}`}
      >
        <div className="title">{props.column.title}</div>
        <Button className="extend">
          {showAllNote ? (
            <img
              src={ToggleShowAllAppreciation}
              alt={""}
              onClick={() => setShowAllNote(false)}
            />
          ) : (
            <img
              src={ToggleShowAllAppreciation}
              alt={""}
              onClick={() => setShowAllNote(true)}
            />
          )}
        </Button>
      </TableHeaderRow.Cell>
    );
  } else {
    return (
      <TableHeaderRow.Cell
        {...props}
        className={`
                ${props.column.name}
                ${classes.tableHeaderCell}
                ${
                  dataGroup === USER_TYPE_CLIENT &&
                  !["problem", "createdDate"].includes(props.column.name)
                    ? classes.tableHeaderCellClient
                    : ""
                }`}
        // column={props.column + "itityte"}
      />
    );
  }
};

const TableHeaderRowCustom = ({ row, style, ...restProps }) => {
  const classes = useStyles();
  return (
    <TableHeaderRow.Row
      {...restProps}
      style={{ ...style }}
      className={classes.tableHeaderRow}
    />
  );
};

// const TableHeaderCell = compose(connect(mapStateToProps,mapDispatchToProps))(TableHeaderCellRoot);
const TooltipHeaderSort = ({ getMessage, column, ...restProps }) => {
  return (
    <TableHeaderRow.SortLabel
      column={column}
      getMessage={() => {
        return `${column.title}`;
      }}
      {...restProps}
    />
  );
};

const DiagnosisReceivedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#4e4eff",
    color: "#fff",
    borderRadius: 8,
    // fontSize: 13,
    // maxHeight: 400,
    // overflow: 'auto',
  },
  arrow: {
    color: "#4e4eff",
  },
}))(Tooltip);

export const returnStatusInColumnToggle = (row) => {
  const statusType = row.status.statusType ? row.status.statusType.code : "";
  let statusClient = null;
  if (!!(!row.hasReportSelectedForRequest && row.hasReportPaid)) {
    statusClient = CLIENT_STATUS_SET_OK;
  } else if (row?.hasReportSelectedForRequest) {
    statusClient = CLIENT_STATUS_DIAGNOSIS_RECEIVED;
  } else if (row.reportAnalysisByTheExpert.length > 0) {
    statusClient = CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT;
  } else if ([REPAIRED, GO_DOC].includes(statusType)) {
    if (!checkRequestIsGoFreeAndRegistrationStartNull(row)) {
      statusClient = CLIENT_STATUS_SCHEDULED_APPOINTMENT;
    } else {
      statusClient = CLIENT_STATUS_MAKE_AN_APPOINTMENT;
    }
  } else if (statusType === WAITING_FOR_REPLY) {
    statusClient = CLIENT_STATUS_IN_PROGRESS;
  } else if (statusType === NOT_BE_REPAIRED) {
    statusClient = CLIENT_STATUS_NOT_ELIGIBLE;
  }
  return statusClient;
};

const ToggleCellComponent = ({
  row,
  onToggle,
  expanded,
  t,
  groupUser,
  ...restProps
}) => {
  const classes = useStyles();
  const statusClient = returnStatusInColumnToggle(row);
  return (
    <React.Fragment>
      {listStatusClient.hasOwnProperty(statusClient) ? (
        <td
          className={classes.statusWrapper}
          style={{
            backgroundColor: listStatusClient[statusClient].backgroundColor,
          }}
        >
          <div className={"statusReportBlock"}>
            {CLIENT_STATUS_DIAGNOSIS_RECEIVED === statusClient && (
              <DiagnosisReceivedTooltip
                title={
                  <div className={classes.infoDiagnosisReceived}>
                    <div className="text1">
                      {t("request.infoDiagnosisReceived.text1")}
                    </div>
                    <div className="text2">
                      {t("request.infoDiagnosisReceived.text2")}
                    </div>
                    <div className="text3">
                      {t("request.infoDiagnosisReceived.text3")}
                    </div>
                    <Button className="actionDiagnosisReceived">
                      {t(
                        "request.infoDiagnosisReceived.actionDiagnosisReceived"
                      )}
                    </Button>
                  </div>
                }
                interactive
                arrow
              >
                <span className="infoIcon">!</span>
              </DiagnosisReceivedTooltip>
            )}
            {CLIENT_STATUS_MAKE_AN_APPOINTMENT === statusClient && (
              <DiagnosisReceivedTooltip
                title={
                  <div className={classes.infoMakeAnAppointment}>
                    <div className="textInfo">
                      {t("request.infoMakeAnAppointment.text")}
                    </div>
                    <NavLink
                      to={
                        links.REQUEST_INFORMATION_DETAIL.replace(
                          ":id",
                          row.id
                        ) +
                        "?" +
                        qs.stringify({ tabShowRequest: TAB_CALENDER })
                      }
                    >
                      <Button className="btnRegisterTime">
                        {t("request.infoMakeAnAppointment.registerTime")}
                      </Button>
                    </NavLink>
                  </div>
                }
                interactive
                arrow
              >
                <span className="infoIcon">!</span>
              </DiagnosisReceivedTooltip>
            )}

            <div
              className={`statusContent ${
                [
                  CLIENT_STATUS_DIAGNOSIS_RECEIVED,
                  CLIENT_STATUS_SET_OK,
                ].includes(statusClient)
                  ? "clicZone"
                  : ""
              }`}
              onClick={() => {
                if (
                  [
                    CLIENT_STATUS_DIAGNOSIS_RECEIVED,
                    CLIENT_STATUS_SET_OK,
                  ].includes(statusClient)
                ) {
                  onToggle();
                }
              }}
            >
              <div className="statusImg">
                <img src={listStatusClient[statusClient].icon} alt="" />
              </div>
              <div className="statusText">
                {t(listStatusClient[statusClient].label)}
              </div>

              {[
                CLIENT_STATUS_DIAGNOSIS_RECEIVED,
                CLIENT_STATUS_SET_OK,
              ].includes(statusClient) && (
                <>
                  {expanded ? (
                    <ExpandLessRoundedIcon />
                  ) : (
                    <ExpandMoreRoundedIcon />
                  )}
                </>
              )}
            </div>
          </div>
        </td>
      ) : (
        <td></td>
      )}
    </React.Fragment>
  );
  if (!(row.requestReportInfo && row.requestReportInfo.paid)) {
    return (
      <div onClick={onToggle} className={classes.toggleCellWrapper}>
        <img alt={""} src={reportHeadIcon} />
        <img alt={""} src={reportIcon} />
        <div className={"toggleIcon"}>
          {t("client.requestDetail.show")}
          {expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </div>
      </div>
    );
  } else {
    return (
      <td>
        <div onClick={onToggle} className={classes.toggleCellWrapper}>
          <img alt={""} src={reportPaidIcon} />
        </div>
      </td>
    );
  }
};

const POPOVER_ADD_NOTE = "popover-add-note-";
export const POPOVER_ADD_CHAT = "popover-add-chat-";
export const POPOVER_READ_OR_UNREAD_CHAT = "popover-read-or-unread-chat";
const POPOVER_CATEGORY_REQUEST = "popover-add-category-";
const POPOVER_EDIT_ACTION_STATUS_ARCHIVE_REPEAT =
  "popover-EditActionStatusArchiveRepeat-";
const POPOVER_CHANGE_STATUS = "popover-change-status-";
const MODAL_ASSIGN_REPAIRER = "MODAL_ASSIGN_REPAIRER";
const MODAL_ALERT_USE_SMARTPHONE = "MODAL_ALERT_USE_SMARTPHONE";
const LIST = "list";
const GRID = "grid";
const MODAL_REPORT_LIST_BY_REQUEST = "MODAL_REPORT_LIST_BY_REQUEST";
export const POPOVER_REPORT_PAID_BY_REQUEST =
  "popover-report_paid_by_request_{id}";
export const PARAM_REQUEST_LIST_LOCALSTORAGE =
  "list_" +
  REQUEST_LIST +
  "_" +
  window.location.pathname.replace("/", "_") +
  "_params";
class RequestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingList: false,
      forceUpdate: false,
      isLoading: false,
      errorOpen: false,
      successOpen: false,
      openPopover: false,
      anchorEl: null,
      formValuePopover: {},
      openDetail: false,
      dataDetailProblem: {},
      noteForRequest: "",
      popoverType: "",
      errors: {},
      changeStatus: "",
      showColumnNoteCell: [],
      anchorElProblem: null,
      idAnchorElProblem: null,
      dataPopoverProblem: {},
      price: "",
      comment: "",
      isGoFree: false,
      newItem: null,
      // statusCode:  props?.location?.pathname === links.ADMIN_REQUEST ? STATUS_ALL_LIST :[],
      categories: [],
      hiddenColumns: [],
      openClientModal: false,
      dataClient: {},
      openSetDate: false,
      dataChangeDate: {},
      openModal: null,
      modalValue: {},
      startDate: moment().subtract(1, "year"),
      endDate: moment(),
      pageType: LIST,
      categoryModal: null,
      categoryEditedRow: [],
      messageSuccess: null,
      messageError: "",
      rowEdited: null,
      openViewNote: false,
      dataViewNote: {},
      dialogForm: false,
      idDialogForm: null,
      openModalFiles: true,
      requestId: null,
      newDocumentByManager: false,
      countRequestNotPaid: 0,
      filterGrid: {},
      notificationChat: null,
      dialogFormSave: null,
      dataRow: null,
      expandedRowIds: [],
      // openDetailRequestListTypeGrid:[],
      dataVideoCall: {},
    };
    this.apiNameListDefault = REQUEST_LIST;
    this.handleOpenPopOver = this.handleOpenPopOver.bind(this);
    this.handleClosePopOver = this.handleClosePopOver.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      _.intersection(state.showColumnNoteCell, props.showNoteCell).length > 0
    ) {
      return {
        ...state,
        showColumnNoteCell: props.showNoteCell,
      };
    }
    return null;
  }

  handSearchGrid = (event, field) => {
    let { filterGrid } = this.state;
    let value = event.target.value;
    if (typeof value === "string" && value.length === 0) {
      delete filterGrid[field];
    } else filterGrid[field] = value;
    this.setState({
      filterGrid: filterGrid,
      forceUpdate: true,
    });
  };

  handleOpenRequestEdit = (data) => {
    this.setState({
      editRequestModal: true,
      dataEditRequest: data,
    });
  };

  callbackWidth = (listColumn) => {
    let columnChangeWidth = listColumn.filter(
      (item) => item.columnName === "problem"
    );
    if (columnChangeWidth.length > 0) {
      this.setState({
        problem: columnChangeWidth[0].width,
      });
    }
  };
  // handlePopoverProblemOpen = (event, data) => {
  //     this.setState({
  //         anchorElProblem: event.currentTarget,
  //         dataPopoverProblem: data
  //     })
  // }
  // handlePopoverProblemClose = () => {
  //     this.setState({
  //         anchorElProblem: null
  //     })
  // }
  checkProblem = (row, notTooltip) => {
    let { dataPopoverProblem, anchorElProblem, categoryEditedRow } = this.state;
    const editedItem = categoryEditedRow.find((item) => item.rowId === row.id);
    let { classes, dataUser } = this.props;
    const status =
      row.status && row.status.statusType && row.status.statusType.code
        ? row.status.statusType.code
        : "";
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    let widthTitle = this.state.problem;
    let problemTitle = "";
    problemTitle = parse(row.subject ? row.subject : row.problem ?? "");
    // if (problemTitle.length > 0 && typeof problemTitle !== 'string') {
    //     problemTitle = problemTitle[0]
    // }
    // console.log(problemTitle)
    // if(problemTitle.props && problemTitle.props.children &&  problemTitle.props.children.length>0 && typeof problemTitle.props.children !== 'string'){
    //     problemTitle = problemTitle.props.children[0]
    // }
    // console.log(problemTitle)
    // if(dataUser && dataUser.data && dataUser.data.group){
    // if([USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(dataUser.data.group)) {
    //     problemTitle = (editedItem && editedItem.hasOwnProperty('label')) ? editedItem.label :(row.categories && row.categories[0] && row.categories[0].name) ?? ""
    // }
    // if(dataUser.data.group === USER_TYPE_CLIENT){
    //     problemTitle = parse(row.problem ?? "")
    //     if (problemTitle.length > 0 && typeof problemTitle !== 'string') {
    //         problemTitle = problemTitle[0]
    //     }
    //     if(problemTitle.props && problemTitle.props.children &&  problemTitle.props.children.length>0 && typeof problemTitle.props.children !== 'string'){
    //         problemTitle = problemTitle.props.children[0]
    //     }
    // }
    // }
    let widthText =
      problemTitle.props &&
      problemTitle.props.children &&
      problemTitle.props.children.length
        ? problemTitle.props.children.length
        : null;
    let categories =
      editedItem && editedItem.hasOwnProperty("label")
        ? { value: editedItem.value, name: editedItem.label }
        : row.categories && row.categories[0]
        ? { ...row.categories[0], value: row.categories[0].id }
        : {};
    if (JSON.stringify(categories) !== "{}") categories.label = categories.name;
    return (
      <div className={classes.name}>
        {!groupUser === USER_TYPE_CLIENT && (
          <div
            className={"showModalButton"}
            onClick={() =>
              [WAITING_FOR_REPLY, MAYBE, REPAIRED].includes(status)
                ? this.handleOpenRequestEdit({
                    problemDetail: row.problem,
                    filesInitial: row.documents,
                    idRequest: row.id,
                    status: status === MAYBE ? WAITING_FOR_REPLY : status,
                  })
                : this.handleOpenModalDetail({
                    id: row.id,
                    categories: categories,
                    title: problemTitle,
                    payment: row.payment,
                    documents: row.documents,
                    problem: row.problem,
                    status: row.status,
                    assignStaff: row.manager,
                    registrationStart: row.registrationStart,
                  })
            }
          >
            <div className={"showModalButton-icon"}></div>
            <div className={"showModalButton-txt"}>
              {this.props.t("button.edit")}
            </div>
          </div>
        )}

        {notTooltip ? (
          <div
            className={` ${
              groupUser === USER_TYPE_CLIENT
                ? "nameProblemClient"
                : "nameProblemAdmin"
            }`}
            // style={{maxWidth: widthTitle - 10 + 'px'}}
            onClick={() =>
              groupUser === USER_TYPE_CLIENT
                ? this.handleOpenClientModal(row)
                : () => null
            }
          >
            {problemTitle}
            {/* {groupUser !== USER_TYPE_CLIENT && <div className="btnPlus" onClick={() => {
                            this.handleOpenClientModal(row)
                        }}>+</div>} */}
          </div>
        ) : (
          <Tooltip title={problemTitle}>
            <div
              className={` ${
                groupUser === USER_TYPE_CLIENT
                  ? "nameProblemClient"
                  : "nameProblemAdmin"
              }`}
              // style={{maxWidth: widthTitle - 10 + 'px'}}
              onClick={() =>
                groupUser === USER_TYPE_CLIENT
                  ? this.handleOpenClientModal(row)
                  : () => null
              }
            >
              {problemTitle}
              {/* {groupUser !== USER_TYPE_CLIENT && <div className="btnPlus" onClick={() => {
                                this.handleOpenClientModal(row)
                            }}>+</div>} */}
            </div>
          </Tooltip>
        )}
        {/*{open && dataPopoverProblem && dataPopoverProblem.id === row.id && <Popover id={"problem-popover" + row.id}*/}
        {/*                                                                            open={open}*/}
        {/*                                                                            anchorEl={anchorElProblem}*/}
        {/*                                                                            anchorOrigin={{*/}
        {/*                                                                                vertical: 'top',*/}
        {/*                                                                                horizontal: 'left',*/}
        {/*                                                                            }}*/}
        {/*                                                                            transformOrigin={{*/}
        {/*                                                                                vertical: 'bottom',*/}
        {/*                                                                                horizontal: 'left',*/}
        {/*                                                                            }}*/}
        {/*                                                                            onClose={() => this.handlePopoverProblemClose()}*/}
        {/*                                                                            disableRestoreFocus={true}*/}
        {/*                                                                            className={classes.popoverProblem}*/}
        {/*>*/}
        {/*    <ProblemTitleModal*/}
        {/*        status={dataPopoverProblem && dataPopoverProblem.status ? dataPopoverProblem.status : null}*/}
        {/*        title = {(dataPopoverProblem && dataPopoverProblem.title) ?? null}*/}
        {/*    />*/}
        {/*    <ProblemContentModal dataDetailProblem={dataPopoverProblem}/>*/}
        {/*</Popover>}*/}
      </div>
    );
  };

  openDialogFormSave = (dialogType, dataRow) => {
    this.setState({ dialogFormSave: { ...dataRow, dialogType: dialogType } });
  };

  handleOpenPopOver(event, row, type) {
    const { dataVideoCall } = this.state;
    const dataByRequest =
      dataVideoCall && dataVideoCall[row.id] ? dataVideoCall[row.id] : null;
    const changeStatusCurrent =
      row.status && row.status.statusType && row.status.statusType.code
        ? row.status.statusType.code
        : "";
    let categories = [];
    if (
      row.categories &&
      row.categories.length &&
      type === POPOVER_CATEGORY_REQUEST
    ) {
      categories = row.categories.map((item) => {
        return { ...item, value: item.id, label: item.name };
      });
    }
    let filterStatusRepairedLast = null;
    if (row.status.statusType.code === REPAIRED) {
      filterStatusRepairedLast = row.status;
    }
    this.setState({
      anchorEl: event.currentTarget,
      formValuePopover: dataByRequest
        ? { ...row, videoCalling: dataByRequest }
        : row,
      popoverType: type,
      changeStatus: changeStatusCurrent,
      categories: categories,
      price: row.payment && row.payment.price ? row.payment.price : "",
      comment: "",
    });
  }

  handleClosePopOver() {
    const { countChatNotSeen, location, countPaymentRequestReport } =
      this.props;
    let stateNEw = {
      anchorEl: null,
      formValuePopover: {},
      noteForRequest: "",
      popoverType: "",
      changeStatus: "",
      anchorElProblem: null,
      price: "",
      comment: "",
      categories: [],
      errors: {},
    };
    let urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (
      this.state.anchorEl &&
      this.state.popoverType === POPOVER_ADD_CHAT &&
      countChatNotSeen.length < 1
    ) {
      if (
        urlParams.hasOwnProperty("filters") &&
        (urlParams["filters"].hasOwnProperty("managerSawClientLastChat") ||
          urlParams["filters"].hasOwnProperty("clientSawManagerLastChat"))
      ) {
        delete urlParams["filters"]["managerSawClientLastChat"];
        delete urlParams["filters"]["clientSawManagerLastChat"];
        this.props.history.push(
          location.pathname + "?" + qs.stringify(urlParams)
        );
        stateNEw = { ...stateNEw, loadingList: true };
      }
    }
    if (
      this.state.anchorEl &&
      this.state.popoverType === POPOVER_REPORT_PAID_BY_REQUEST &&
      countPaymentRequestReport.length < 1
    ) {
      if (urlParams.hasOwnProperty("adminNotSawPaymentReport")) {
        delete urlParams["adminNotSawPaymentReport"];
        this.props.history.push(
          location.pathname + "?" + qs.stringify(urlParams)
        );
        stateNEw = { ...stateNEw, loadingList: true };
      }
    }
    this.setState(stateNEw);
  }
  handleOpenModal = (openModal, data) => {
    this.setState({
      openModal: openModal,
      modalValue: data,
    });
  };

  handleOpenModalDetail = (data) => {
    this.setState({
      openDetail: true,
      dataDetailProblem: data,
    });
  };

  handleCloseModalDetail = () => {
    this.setState({
      openDetail: false,
      categoryModal: null,
    });
  };

  handleChangeApiParams = (value, field, action) => {
    let { apiParams } = this.state;
    if (field === "notPaid") {
      if (action === ACTION_ADD) {
        apiParams = { ...apiParams, [field]: value, statusCode: REPAIRED };
      } else if (action === ACTION_DELETE && apiParams.hasOwnProperty(field)) {
        delete apiParams[field];
        delete apiParams["statusCode"];
      }
    }
    this.setState({ apiParams: apiParams, forceUpdate: true });
  };

  handleChangeField = (value, field) => {
    let { errors } = this.state;
    let valueNew = value;
    if (field === "isGoFree" && valueNew && errors.hasOwnProperty("price")) {
      delete errors["price"];
    }
    if (field === "changeStatus") {
      errors = {};
    } else {
      if (errors.hasOwnProperty(field)) {
        delete errors[field];
      }
    }

    this.setState({
      [field]: valueNew,
      errors: errors,
    });
  };
  addNote = (e) => {
    let { noteForRequest } = this.state;
    if (noteForRequest) {
      this.setState({ isLoading: true });
      const formData = new FormData();
      formData.append("content", noteForRequest);
      axios
        .post(
          API_ADD_NOTE_REQUEST.replace(
            "{requestId}",
            this.state.formValuePopover.id
          ),
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              anchorEl: null,
              formValuePopover: {},
              noteForRequest: "",
              popoverType: "",
              forceUpdate: true,
              isLoading: false,
            });
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } else {
      let errors = { ...this.state.errors };
      errors.noteForRequest = this.props.t(
        "request.popoverAddNote.errors.noteEmpty"
      );
      this.setState({
        errors: errors,
        errorOpen: true,
      });
    }
  };

  addCategoriesForRequest = () => {
    const formData = new FormData();
    let { categoryModal, categoryEditedRow } = this.state;
    if (categoryModal && categoryModal.value && categoryModal.rowId) {
      formData.append("categories[" + 0 + "]", categoryModal.value);
    }
    this.setState({ isLoading: true });
    axios
      .post(
        API_EDIT_REQUEST_CATEGORIES.replace("{id}", categoryModal.rowId),
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          const found = categoryEditedRow.find(
            (item) => item.rowId === categoryModal.rowId
          );
          if (found) {
            categoryEditedRow = categoryEditedRow.filter(
              (item) => item.rowId !== categoryModal.rowId
            );
          }
          categoryEditedRow.push(categoryModal);
          this.setState({
            categoryEditedRow: categoryEditedRow,
            successOpen: true,
            categoryModal: null,
            rowEdited: null,
            forceUpdate: true,
          });
        } else throw new Error();
      })
      .catch((err) => console.log(err))
      .finally(() => this.setState({ isLoading: false }));
  };

  changeStatus = (e) => {
    const formData = new FormData();
    let { errors } = this.state;
    let { changeStatus, formValuePopover, price, comment, isGoFree } =
      this.state;
    if (e && e.changeStatus) {
      changeStatus = e.changeStatus;
    }
    const statusTypeOld =
      formValuePopover.status &&
      formValuePopover.status.statusType &&
      formValuePopover.status.statusType.code
        ? formValuePopover.status.statusType.code
        : "";
    if (
      changeStatus &&
      // && (changeStatus === REPAIRED || changeStatus !== statusTypeOld)
      Object.keys(errors).length < 1
    ) {
      this.setState({ isLoading: true });
      formData.append("statusCode", changeStatus);
      if (changeStatus === REPAIRED) {
        if (!isGoFree) {
          let regexPriceDecimalAdd0 = /^[0-9]+[.]$/g;
          if (price.toString().match(regexPriceDecimalAdd0)) {
            price = price + "0";
          }
          formData.append("price", price);
        }
        // formData.append("isGoFree",isGoFree? IS_GO_FREE:"");
        formData.append("isGoFree", IS_GO_FREE);
      }
      if ([REPAIRED, GO_DOC].includes(changeStatus)) {
        const urlEnableUser = window.location
          ? window.location.origin + links.EDIT_REQUEST_NOT_LOGIN
          : "";
        formData.append("linkEditRequestWithToken", urlEnableUser);
      }
      if (comment) {
        formData.append("comment", comment);
      }
      axios
        .post(
          API_CHANGE_STATUS_REQUEST.replace(
            "{id}",
            this.state.formValuePopover.id ?? e.id
          ),
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                anchorEl: null,
                formValuePopover: {},
                popoverType: "",
                forceUpdate: true,
                price: "",
                comment: "",
                isLoading: false,
                idDialogForm: null,
                dialogForm: false,
                successOpen: true,
              },
              function () {
                // this.props.getCountRequestByStatus()
              }
            );
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, errorOpen: false });
        });
    } else {
      if (Object.keys(errors).length < 1) {
        errors.changeStatus = this.props.t(
          "request.popoverChangeStatus.errors.changePasswordSelection"
        );
      }

      this.setState({
        errors: errors,
        errorOpen: true,
      });
    }
  };
  getNewItem = (newItem) => {
    this.setState({
      newItem: newItem,
      forceUpdate: true,
    });
  };
  componentDidMount() {
    const { startDate, endDate } = this.state;
    const { dataUser, location } = this.props;
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    let apiParamCount = urlParams ?? {};
    if (
      dataUser.data &&
      [USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(
        dataUser.data.group
      )
    ) {
      if (endDate.isValid()) {
        apiParamCount = {
          ...apiParamCount,
          endDate: ConvertToDateY_M_D(endDate),
        };
      }
      if (startDate.isValid()) {
        apiParamCount = {
          ...apiParamCount,
          startDate: ConvertToDateY_M_D(startDate),
        };
      }
    } else {
      this.props.getCountReportRequestNotPaid();
    }
    if (groupUser === USER_TYPE_CLIENT && this.props.width === XS) {
      this.setState({
        pageType: GRID,
      });
    }
    let notUseSmartPhone = localStorage.getItem("notUseSmartPhone");
    if (isBrowser && groupUser === USER_TYPE_CLIENT && !notUseSmartPhone) {
      this.handleOpenModal(MODAL_ALERT_USE_SMARTPHONE);
    }
    this.countRequestNotPaidHeader();
    this.props.getRequestDocumentManagerNotSeen(apiParamCount);
    if (groupUser !== USER_TYPE_CLIENT) {
      this.props.getCountChatNotSeen(apiParamCount);
      this.props.getCountRequestByStatus(apiParamCount);
      this.props.getCountUpcomingAppointment(apiParamCount);
      this.props.getCountPaymentRequestReport(apiParamCount);
    } else {
      this.props.getCountChatNotSeen();
    }

    try {
      const eventSource = new EventSource(
        REACT_APP_MECURE_URL + "?topic=uploadRequestDocument",
        {
          withCredentials: false,
        }
      );
      eventSource.onmessage = (event) => {
        const dataClienUpdateDoc = JSON.parse(event.data);
        const infoDocument = dataClienUpdateDoc["uploadRequestDocument"];
        if (infoDocument.requestId && parseInt(infoDocument.requestId)) {
          if (
            (groupUser === USER_TYPE_CLIENT &&
              infoDocument["userRequestId"] === dataUser.data.id &&
              infoDocument["roleUpload"] === "manager") ||
            (groupUser !== USER_TYPE_CLIENT &&
              infoDocument["roleUpload"] !== "manager" &&
              (groupUser !== USER_TYPE_REPAIRER ||
                (infoDocument.userManager &&
                  parseInt(infoDocument.userManager) === dataUser.data.id)))
          ) {
            this.props.addValueToRequestDocumentManagerNotSeenRedux(
              parseInt(infoDocument.requestId)
            );
          }
        }
      };
      eventSource.onerror = () => {
        eventSource.close();
      };
    } catch (e) {}

    try {
      const eventSourceChat = new EventSource(
        REACT_APP_MECURE_URL + "?topic=chat",
        {
          withCredentials: false,
        }
      );
      eventSourceChat.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const chat = data["chat"];
        const groupUser = dataUser.data?.group;
        const currentUserId = dataUser.data?.id;
        const requestId = chat?.request?.id;
        const userId = chat?.request?.userId;
        const instanceofSender = chat?.sender.instanceof;
        if (
          requestId &&
          ((USER_TYPE_CLIENT === groupUser &&
            userId === currentUserId &&
            instanceofSender !== USER_TYPE_CLIENT) ||
            ([USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(groupUser) &&
              instanceofSender === USER_TYPE_CLIENT))
        ) {
          this.setState({
            notificationChat: requestId,
          });
          this.handleWhenMercureChat(requestId);
        }
      };
      eventSourceChat.onerror = () => {
        eventSourceChat.close();
      };
      if (groupUser !== USER_TYPE_CLIENT) {
        const eventSourcePayment = new EventSource(
          REACT_APP_MECURE_URL + "?topic=paymentReport",
          {
            withCredentials: false,
          }
        );
        eventSourcePayment.onmessage = (event) => {
          const data = JSON.parse(event.data);
          const arrayRequestId = data["paymentReport"] ?? [];
          this.setPaymentRequestReportMercuce(
            Array.isArray(arrayRequestId) ? arrayRequestId : arrayRequestId
          );
        };
        eventSourcePayment.onerror = () => {
          eventSourcePayment.close();
        };
      }
    } catch (err) {
      // console.log(err);
    }
    try {
      const eventSourceVideocall = new EventSource(
        REACT_APP_MECURE_URL + "?topic=videoCall",
        {
          withCredentials: false,
        }
      );
      eventSourceVideocall.onmessage = (event) => {
        const dataVideoCall = JSON.parse(event.data);
        const videoCall = dataVideoCall["videoCall"];
        if (videoCall.request) {
          let dataVideoCallTemp = { ...this.state.dataVideoCall };
          dataVideoCallTemp[videoCall.request] = {
            meetingId: videoCall.meetingId,
            status: videoCall.status,
          };
          this.setState({
            dataVideoCall: dataVideoCallTemp,
          });
        }
      };
      eventSourceVideocall.onerror = () => {
        eventSourceVideocall.close();
      };
    } catch (e) {
      // console.log(e);
    }
  }

  setPaymentRequestReportMercuce = (arrayRequestId = []) => {
    const { countPaymentRequestReport, dataUser } = this.props;
    const groupUserRepairer =
      dataUser?.data?.group && dataUser.data.group === USER_TYPE_REPAIRER;
    const userId = dataUser?.data?.id ?? "";
    if (Array.isArray(arrayRequestId) && arrayRequestId.length) {
      let valueArrayNew = [...countPaymentRequestReport];
      arrayRequestId.forEach((value) => {
        if (
          value.requestId &&
          !countPaymentRequestReport.includes(parseInt(value.requestId)) &&
          (!groupUserRepairer ||
            (groupUserRepairer && value?.managerRequestId === userId))
        ) {
          valueArrayNew.push(value.requestId);
        }
      });
      countPaymentRequestReport.concat(
        arrayRequestId.filter(
          (value) => !countPaymentRequestReport.includes(value)
        )
      );
      this.props.setCountPaymentRequestReport(valueArrayNew);
    }
  };

  handleWhenMercureChat = (requestId) => {
    const { anchorEl, formValuePopover, popoverType } = this.state;
    if (
      !(
        anchorEl &&
        popoverType === POPOVER_ADD_CHAT &&
        formValuePopover &&
        formValuePopover.id === requestId
      ) &&
      Array.isArray(this.props.countChatNotSeen) &&
      !this.props.countChatNotSeen.includes(requestId)
    ) {
      this.props.setCountChatNotSeen([
        ...this.props.countChatNotSeen,
        requestId,
      ]);
    }
  };

  countRequestNotPaidHeader = () => {
    axios
      .get(REQUEST_LIST + "?maxPerPage=1&notPaid=1&statusCode=" + REPAIRED)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            countRequestNotPaid: response.data.nbResults,
          });
        }
      });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { startDate, endDate } = this.state;
    const { match, dataUser, location } = this.props;

    // Detect changes in route params
    if (location.search !== prevProps.location.search) {
      this.handleParamsChange(new URLSearchParams(location.search));
    }
    const newState = {};
    if (this.state.forceUpdate || this.state.loadingList) {
      const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      let apiParamCount = urlParams ?? {};
      if (
        dataUser.data &&
        [USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(
          dataUser.data.group
        )
      ) {
        if (endDate.isValid()) {
          apiParamCount = {
            ...apiParamCount,
            endDate: ConvertToDateY_M_D(endDate),
          };
        }
        if (startDate.isValid()) {
          apiParamCount = {
            ...apiParamCount,
            startDate: ConvertToDateY_M_D(startDate),
          };
        }
        this.props.getCountRequestByStatus(apiParamCount);
      }
      newState.forceUpdate = false;
      newState.loadingList = false;
    }
    if (prevState.pageType !== this.state.pageType) {
      newState.categoryModal = null;
      newState.rowEdited = null;
    }
    if (dataUser.data && dataUser.data.group === USER_TYPE_CLIENT) {
      if (this.props.width === XS && this.state.pageType !== GRID) {
        newState.pageType = GRID;
      }
      if (this.props.width !== XS && this.state.pageType !== LIST) {
        newState.pageType = LIST;
      }
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  handleParamsChange(queryParams) {
    const { dataUser } = this.props;
    const { startDate, endDate } = this.state;
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    let apiParamCount = Object.fromEntries(queryParams.entries());
    if (
      dataUser.data &&
      [USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(
        dataUser.data.group
      )
    ) {
      if (endDate.isValid()) {
        apiParamCount = {
          ...apiParamCount,
          endDate: ConvertToDateY_M_D(endDate),
        };
      }
      if (startDate.isValid()) {
        apiParamCount = {
          ...apiParamCount,
          startDate: ConvertToDateY_M_D(startDate),
        };
      }
    }
    if (groupUser !== USER_TYPE_CLIENT) {
      this.props.getCountChatNotSeen(apiParamCount);
      this.props.getCountRequestByStatus(apiParamCount);
      this.props.getCountUpcomingAppointment(apiParamCount);
      this.props.getCountPaymentRequestReport(apiParamCount);
    } else {
      this.props.getCountChatNotSeen();
    }
  }

  getDataListInLocalStorage = () => {
    let pageInfos = localStorage.getItem(PARAM_REQUEST_LIST_LOCALSTORAGE);
    if (!pageInfos) {
      pageInfos = {};
    } else {
      pageInfos = JSON.parse(pageInfos);
    }
    return pageInfos;
  };

  getStatusCodeInLocalStorage = () => {
    let pageInfos = this.getDataListInLocalStorage();
    return pageInfos["filters"] &&
      pageInfos["filters"]["statusCode"] &&
      Array.isArray(pageInfos["filters"]["statusCode"]) &&
      pageInfos["filters"]["statusCode"].length
      ? pageInfos["filters"]["statusCode"]
      : STATUS_ALL_LIST;
  };

  changeStatusFilter = (value) => {
    let pageInfos = this.getDataListInLocalStorage();
    let valueNew =
      pageInfos["filters"] &&
      pageInfos["filters"]["statusCode"] &&
      Array.isArray(pageInfos["filters"]["statusCode"]) &&
      pageInfos["filters"]["statusCode"].length
        ? pageInfos["filters"]["statusCode"]
        : STATUS_ALL_LIST;

    if (valueNew.includes(value)) {
      valueNew = valueNew.filter((item) => item !== value);
    } else {
      valueNew.push(value);
    }
    if (valueNew.length < 1) {
      valueNew = STATUS_ALL_LIST;
    }
    pageInfos = {
      ...pageInfos,
      filters: {
        statusCode: valueNew,
      },
    };
    localStorage.setItem(
      PARAM_REQUEST_LIST_LOCALSTORAGE,
      JSON.stringify(pageInfos)
    );

    this.setState({
      forceUpdate: true,
    });
  };
  changeAllStatusFilter = () => {
    // let urlParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    // urlParams = {...urlParams, statusCode: STATUS_ALL_LIST}
    // this.props.history.push(this.props.location.pathname + '?' + qs.stringify(urlParams));

    this.setState({
      forceUpdate: true,
    });
  };
  handleOpenClientModal = (data, directInfo = false) => {
    const dataClient =
      typeof data === OBJECT
        ? {
            ...data,
            leaseType: data.leaseType
              ? { value: data.leaseType.id, label: data.leaseType.name }
              : data.leaseType,
            // builtForOver2Years: data.builtForOver2Years !== null ? data.builtForOver2Years ? TRUE_NUMBER : FALSE_NUMBER : data.builtForOver2Years,
            sentByAgency:
              data.sentByAgency !== null
                ? data.sentByAgency
                  ? TRUE_NUMBER
                  : FALSE_NUMBER
                : data.sentByAgency,
          }
        : data;
    this.setState({
      directInfo,
      openClientModal: true,
      dataClient: dataClient,
    });
  };

  handleCloseModal = (field, stateUpdate) => {
    const { location, requestDocumentManagerNotSeen } = this.props;
    let stateUpdateNew = {
      [field]: false,
      modalValue: {},
    };
    if (!!stateUpdate && typeof stateUpdate === OBJECT) {
      stateUpdateNew = Object.assign(stateUpdateNew, stateUpdate);
    }
    if (
      field === "openModalFiles" &&
      requestDocumentManagerNotSeen.length < 1
    ) {
      let urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (urlParams.hasOwnProperty("managerSawLastDocumentClientUpload")) {
        delete urlParams["managerSawLastDocumentClientUpload"];
        this.props.history.push(
          location.pathname + "?" + qs.stringify(urlParams)
        );
        stateUpdateNew = { ...stateUpdateNew, loadingList: true };
      }
    }
    this.setState(stateUpdateNew);
  };
  handleOpenSetDateModal = (data) => {
    this.setState({
      openSetDate: true,
      dataChangeDate: data,
    });
  };
  handleOpenViewNoteModal = (data) => {
    this.setState({
      openViewNote: true,
      dataViewNote: data,
    });
  };
  callBackFromCalendar = (isLoading, forceUpdate, successOpen) => {
    const { startDate, endDate } = this.state;
    const { dataUser, location } = this.props;
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    let apiParamCount = urlParams ?? {};
    if (
      dataUser.data &&
      [USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(
        dataUser.data.group
      )
    ) {
      if (endDate.isValid()) {
        apiParamCount = {
          ...apiParamCount,
          endDate: ConvertToDateY_M_D(endDate),
        };
      }
      if (startDate.isValid()) {
        apiParamCount = {
          ...apiParamCount,
          startDate: ConvertToDateY_M_D(startDate),
        };
      }
    }
    this.props.getCountUpcomingAppointment(apiParamCount);
    this.setState({
      isLoading: isLoading,
      forceUpdate: forceUpdate,
      successOpen: successOpen,
    });
  };
  setDateFilter = (e, field) => {
    const { startDate, endDate } = this.state;
    if (
      e.isValid() &&
      ((field === "startDate" && endDate.isValid()) ||
        (field === "endDate" && startDate.isValid()))
    ) {
      this.setState(
        {
          forceUpdate: true,
        },
        function () {
          let urlParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
          });
          const startDateNew = field === "startDate" ? e : startDate;
          const endDateNew = field === "endDate" ? e : endDate;
          let apiParamCount = urlParams ?? {};
          if (moment(endDateNew) && endDateNew.isValid()) {
            apiParamCount = {
              ...apiParamCount,
              endDate: ConvertToDateY_M_D(endDate),
            };
          }
          if (moment(startDateNew) && startDateNew.isValid()) {
            apiParamCount = {
              ...apiParamCount,
              startDate: ConvertToDateY_M_D(startDateNew),
            };
          }
          this.props.getCountChatNotSeen(apiParamCount);
          this.props.getRequestDocumentManagerNotSeen(apiParamCount);
          this.props.getCountUpcomingAppointment(apiParamCount);
          this.props.getCountPaymentRequestReport(apiParamCount);
        }
      );
    }
    this.setState({
      [field]: e,
    });
  };
  handleCloseNotice = () => {
    this.setState({
      successOpen: false,
      errorOpen: false,
      messageSuccess: null,
      messageError: ""
    });
  };
  editRow = (rowEdited) => {
    this.setState({
      rowEdited: rowEdited,
    });
  };
  callBackAddNote = (forceUpdate) => {
    this.setState({
      forceUpdate: forceUpdate,
    });
  };

  submitEditRequest = (id) => {
    // event.preventDefault()
    const formData = new FormData();
    let { errorEditRequest } = this.state;
    let seft = this;
    errorEditRequest = {};
    let dataRequest = this.props.problemRequest;
    if (
      (!dataRequest.hasOwnProperty("files") ||
        (dataRequest.hasOwnProperty("files") &&
          dataRequest.files.length === 0)) &&
      (!dataRequest.hasOwnProperty("filesInitial") ||
        (dataRequest.arrayRemoveFiles &&
          dataRequest.filesInitial &&
          dataRequest.arrayRemoveFiles.length &&
          dataRequest.arrayRemoveFiles.length ===
            dataRequest.filesInitial.length))
    ) {
      errorEditRequest = this.props.t("modal.upload.errorInfo");
    }
    if (
      !dataRequest.hasOwnProperty("problemDetail") ||
      (dataRequest.hasOwnProperty("problemDetail") &&
        dataRequest.problemDetail === "")
    ) {
      errorEditRequest = this.props.t("modal.upload.errorInfo");
    }
    if (JSON.stringify(errorEditRequest) === "{}") {
      formData.append("request[problem]", dataRequest.problemDetail);
      if (dataRequest.files && dataRequest.files.length > 0)
        for (let index in dataRequest.files) {
          if (dataRequest.files[index] instanceof File) {
            formData.append(
              `request[documents][${index}][documentFile]`,
              dataRequest.files[index]
            );
          }
        }
      if (
        dataRequest.arrayRemoveFiles &&
        dataRequest.arrayRemoveFiles.length > 0
      )
        for (let index in dataRequest.arrayRemoveFiles) {
          if (
            dataRequest.arrayRemoveFiles[index] &&
            dataRequest.arrayRemoveFiles[index].id
          ) {
            formData.append(
              `request[removeDocuments][${index}]`,
              dataRequest.arrayRemoveFiles[index].id
            );
          }
        }
      // this.props.setIsLoading(true);
      this.setState({ isLoading: true });
      axios
        .post(BASE_URL + API_EDIT_REQUEST.replace("{id}", id), formData)
        .then((response) => {
          // console.log(response)
          this.setState({
            editRequestModal: false,
            successOpen: true,
            forceUpdate: true,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.form
          ) {
            this.setState({
              errorEditRequest: error.response.data.errors.form,
            });
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.setState({
              errorEditRequest: error.response.data.message,
            });
          }
          // console.log(errorEditRequest)
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
    this.setState({
      errorEditRequest: errorEditRequest,
    });
  };

  changeArchiveRepeatRequest = (dataRequest) => {
    const { dialogFormSave } = this.state;
    this.setState({
      isLoading: true,
      dialogFormSave: null,
      anchorEl: null,
      popoverType: "",
    });
    let formData = new FormData();
    formData.append("action", dialogFormSave.dialogType);
    axios
      .post(
        CHANGE_ARCHIVE_REPEAT_REQUEST.replace("{id}", dialogFormSave.id),
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            successOpen: true,
          });
          setTimeout(() => {
            this.props.getCountRequestByStatus();
            this.setState({
              forceUpdate: true,
            });
          }, 500);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        this.setState({
          errorOpen: true,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  actionSeenReportPaidNew = (requestId) => {
    const { countPaymentRequestReport, location } = this.props;
    const { endDate, startDate } = this.state;
    this.setState({ isLoading: true });
    axios
      .post(
        API_PAYMENT_REQUEST_REPORT_ADMIN_SEEN.replace("{requestId}", requestId)
      )
      .then((response) => {
        if (response.status === 200) {
          let newState = {
            successOpen: true,
            isLoading: false,
          };
          const paymentRequestPaidAlertNew = countPaymentRequestReport.filter(
            (item) => item !== requestId
          );
          let urlParams = qs.parse(location.search, {
            ignoreQueryPrefix: true,
          });
          if (paymentRequestPaidAlertNew.length < 1) {
            if (urlParams.hasOwnProperty("adminNotSawPaymentReport")) {
              delete urlParams["adminNotSawPaymentReport"];
              this.props.history.push(
                location.pathname + "?" + qs.stringify(urlParams)
              );
              newState = { ...newState, forceUpdate: true };
            }
          } else {
            let apiParamCount = urlParams ?? {};
            if (moment(endDate) && endDate.isValid()) {
              apiParamCount = {
                ...apiParamCount,
                endDate: ConvertToDateY_M_D(endDate),
              };
            }
            if (moment(startDate) && startDate.isValid()) {
              apiParamCount = {
                ...apiParamCount,
                startDate: ConvertToDateY_M_D(startDate),
              };
            }
            setTimeout(() => {
              this.props.getCountRequestByStatus(apiParamCount);
            }, 600);
          }
          this.props.setCountPaymentRequestReport(paymentRequestPaidAlertNew);

          this.setState(newState);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        this.setState({
          errorOpen: true,
          isLoading: false,
        });
      });
  };

  renderColumnTotalPaymentReportInList = (row) => {
    const { currency, classes, t, groupBaseUser, countPaymentRequestReport } =
      this.props;
    return row?.totalAmountPayment ? (
      // <MaxWidth600Tooltip title={this.renderTooltipTotalPaymentReport(row)} interactive arrow >
      <div className={`rulesWrapper ${classes.rulesWrapper}`}>
        <div
          className={"btnReportPaid"}
          aria-owns={POPOVER_REPORT_PAID_BY_REQUEST + row.id}
          aria-haspopup="true"
          // onMouseEnter={(e) => this.handleOpenPopOver(e, row, POPOVER_REPORT_PAID_BY_REQUEST)}
          // onMouseLeave={()=>this.handleClosePopOver()}
          onClick={(e) =>
            this.handleOpenPopOver(e, row, POPOVER_REPORT_PAID_BY_REQUEST)
          }
        >
          <div className={"labelOk"}>{t("request.list.reportPaid")}</div>
          <div className={"amountPaid"}>
            {" "}
            {Number(row.totalAmountPayment.value ?? 0).toFixed(2)}{" "}
            {currency ? currency.unit : ""}
          </div>
        </div>
        {[USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(groupBaseUser) &&
        countPaymentRequestReport.includes(row.id) ? (
          <div
            className={classes.btnSeenRequestAlertWrapper}
            onClick={() => this.actionSeenReportPaidNew(row.id)}
          >
            {t("request.list.seenReportPaid")}
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      // </MaxWidth600Tooltip>
      <div />
    );
  };

  renderShowColumnChat = (item) => {
    const { countChatNotSeen, t, classes } = this.props;
    return (
      <div
        onClick={(e) => this.handleOpenPopOver(e, item, POPOVER_ADD_CHAT)}
        className={`${classes.btnChat} btnChat1`}
      >
        <div className={"iconBtnWrapper"}>
          <CustomSvg
            svgProps={{
              src: chatIcon,
            }}
          />
        </div>
        {Array.isArray(countChatNotSeen) &&
          countChatNotSeen.includes(item.id) && (
            <div className="hasNewChat"></div>
          )}
        {/*{((groupUser === USER_TYPE_CLIENT && !item.clientSawManagerLastChat) ||(groupUser !== USER_TYPE_CLIENT && !item.managerSawClientLastChat)) && <div className="hasNewChat"></div>}*/}
        <div className={"labelButtonWrapper"}>{t("request.list.chat")}</div>
      </div>
    );
  };
  renderShowColumnDetailUserTypeClient = (
    row,
    addComponentButtonRender = null
  ) => {
    const { classes, t, groupBaseUser, requestDocumentManagerNotSeen } =
      this.props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {addComponentButtonRender ? addComponentButtonRender : ""}
        <ButtonAddDocument requestId={row.id}  loadingRequest={(openAlert, mess) => {
          this.setState({
            successOpen: !!openAlert,
            successMessage: mess ?? ""
          })
        }} setAlertError={(openErr, mess)=>{
          this.setState({
            errorOpen: !!openErr,
            messageError: mess ?? ""
          })
        }} />
        <NavLink
          className={"linkToDetail"}
          to={links.REQUEST_INFORMATION_DETAIL.replace(":id", row.id)}
        >
          <div className={classes.btnChat + " btnDetail"}>
            <div className={"iconBtnWrapper"}>
              <CustomSvg
                svgProps={{
                  src: GoToDetailIcon,
                }}
              />
            </div>
            <div className="btnDetailClient labelButtonWrapper">
              {t("request.list.btnDetailClient")}{" "}
            </div>
          </div>
        </NavLink>
      </div>
    );
  };
  renderShowColumnActionUserTypeClient = (row) => {
    const { classes, t, width } = this.props;
    const statusType = row.status.statusType ? row.status.statusType.code : "";
    const registrationStart = row.registrationStart;
    const registrationTimeStart = row.registrationTimeStart;
    let isBtnResponse =
      (statusType === GO_DOC && !row.checkModified) ||
      (statusType === REPAIRED &&
        (!row.payment ||
          !row.payment.paid ||
          checkRequestIsGoFreeAndRegistrationStartNull(row)));
    // const navLinkBtn = isBtnResponse ? links.INFORMATION_PAGE : links.REQUEST_INFORMATION_DETAIL;
    const navLinkBtn =
      links.REQUEST_INFORMATION_DETAIL + "?tabShowRequest=" + TAB_CALENDER;
    const statusClient = returnStatusInColumnToggle(row);
    let btnReportEl = null;
    const expanded = this.state.expandedRowIds.includes(row.indexRow);
    // console.log(row)
    if (
      ![XS, SM].includes(width) &&
      row.countReportSentToClient &&
      ([CLIENT_STATUS_DIAGNOSIS_RECEIVED, CLIENT_STATUS_SET_OK].includes(
        statusClient
      ) ||
        (row.requestReportInfo && row.requestReportInfo.paid))
    ) {
      btnReportEl = (
        <Button
          className={classes.btnReportView}
          onClick={() => {
            if (Array.isArray(this.state.expandedRowIds)) {
              if (!this.state.expandedRowIds.includes(row.indexRow)) {
                this.setState({
                  expandedRowIds: this.state.expandedRowIds.concat([
                    row.indexRow,
                  ]),
                });
              } else {
                this.setState({
                  expandedRowIds: this.state.expandedRowIds.filter(
                    (item) => item != row.indexRow
                  ),
                });
              }
            }
          }}
        >
          <div className="text">
            <div className="textNumber">{row.countReportSentToClient ?? 0}</div>
            <div className="textReceived">
              {t("request.list.reportReceived")}
            </div>
          </div>
          {expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </Button>
      );
    }
    if (statusType === GO_DOC && !row.checkModified) {
      return (
        <div className={classes.actionWrapper}>
          <NavLink
            className={classes.setClientInfo + " goDoc"}
            to={navLinkBtn.replace(":id", row.id)}
          >
            <InfoOutlined className={classes.iconAction} fontSize={"large"} />
            <span className={"buttonContent"}>
              {t("request.list.complete_information")}
            </span>
          </NavLink>
          {btnReportEl ?? <></>}
        </div>
      );
    }
    return (
      <div className={classes.actionWrapper}>
        {registrationStart && registrationTimeStart ? (
          <NavLink
            className={classes.setClientInfoHasTime}
            to={
              links.REQUEST_INFORMATION_DETAIL.replace(":id", row.id) +
              "?" +
              qs.stringify({ tabShowRequest: TAB_CALENDER })
            }
          >
            {/*<CustomInlineSvg svgProps={{src: calendarIcon}}/>*/}
            <div className="hasTimeLeft">
              <div className="title">RV Visio</div>
              <span className={"buttonContent"}>
                {registrationStart && (
                  <span>
                    {moment(row.registrationStart).format("DD/MM/YYYY")}
                  </span>
                )}
                {registrationTimeStart && (
                  <span>
                    {moment()
                      .hour(
                        convertTimezoneHour(
                          registrationTimeStart,
                          row.registrationStart
                        ) / 60
                      )
                      .minute(
                        convertTimezoneHour(
                          registrationTimeStart,
                          row.registrationStart
                        ) % 60
                      )
                      .second(0)
                      .format("HH:mm")}
                  </span>
                )}
                {!registrationStart && <span>{t("request.list.prendre")}</span>}
              </span>
            </div>
            <CustomSvg
              svgProps={{
                src: Pencil2Icon,
              }}
            />
          </NavLink>
        ) : (
          // registrationStart && registrationTimeStart ?
          //     <span className={classes.clientNotPaid}>
          //                     {/*<CustomInlineSvg svgProps={{src: calendarIcon2}}/>*/}
          //         <img className={classes.iconAction} src={calendarIcon2} alt="calendar icon"/>
          //                     <span className={'registrationBlock'}>
          //                         <span
          //                             className={'waitingConfirmation'}>{t('request.list.waitingConfirmation')}</span>
          //                         <span
          //                             className={'registrationTime'}>{t('request.list.the')} {moment(registrationStart).format('DD/MM/YYYY')}</span>
          //                     </span>
          //                     <NavLink className={'editTimeBtn'}
          //                              to={navLinkBtn.replace(":id", row.id)}><CreateOutlinedIcon
          //                         className={'iconBtn'}/></NavLink>
          //                 </span>
          //     :
          <NavLink
            className={classes.setClientInfo}
            to={navLinkBtn.replace(":id", row.id)}
          >
            {/*<span className={'infoIcon'}>!</span>*/}
            {/*<CustomInlineSvg svgProps={{src: calendarIcon}}/>*/}
            <img
              className={classes.iconAction}
              src={calendarIcon}
              alt="calendar icon"
            />
            <span className={"buttonContent"}>{t("request.list.prendre")}</span>{" "}
            <span> {">"} </span>
          </NavLink>
        )}
        {btnReportEl ?? <></>}
      </div>
    );
  };

  showVideoCallBlock = (request, isAdmin) => {
    const { classes } = this.props;
    const { dataVideoCall } = this.state;
    const dataByRequest =
      dataVideoCall && dataVideoCall[request.id]
        ? dataVideoCall[request.id]
        : null;
    // if (isAdmin) {
    return (
      <div
        className={
          isAdmin ? `${classes.videoCallWrapper} videoCallWrapperStyle` : ""
        }
      >
        <VideoCallBlock
          request={
            dataByRequest
              ? { ...request, videoCalling: dataByRequest }
              : request
          }
          centerButton={true}
          changeDataRequestItem={(requestNew) => {
            request = requestNew;
          }}
        />
      </div>
    );
    // }
    // return (
    //     <VideoCallBlock
    //         request={dataByRequest ? {...request, videoCalling: dataByRequest} : request}
    //         centerButton={true}
    //         changeDataRequestItem={(requestNew) => {
    //             request = requestNew
    //         }}
    //     />
    // )
  };

  render() {
    const {
      t,
      location,
      showNoteCell,
      dataUser,
      currency,
      requestDocumentManagerNotSeen,
      countChatNotSeen,
    } = this.props;
    const { classes, ...other } = this.props;
    const {
      formValuePopover,
      openModal,
      modalValue,
      noteForRequest,
      popoverType,
      anchorEl,
      // statusCode,
      errors,
      openDetail,
      dataDetailProblem,
      changeStatus,
      hiddenColumns,
      openClientModal,
      dataClient,
      openSetDate,
      dataChangeDate,
      startDate,
      endDate,
      pageType,
      categoryModal,
      openViewNote,
      dataViewNote,
      openModalFiles,
      requestId,
      newDocumentByManager,
      countRequestNotPaid,
      filterGrid,
      editRequestModal,
      dataEditRequest,
      errorEditRequest,
    } = this.state;
    const pathPage = location && location.pathname ? location.pathname : "";
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    // console.log(this.state.dataVideoCall)
    let columns = [
      {
        name: "id",
        title: "request.list.id",
        getCellValue: (row) => {
          return (
            <div
              className={`${
                groupUser === USER_TYPE_CLIENT ? classes.idColumn : ""
              }`}
            >
              <div className={"nameProduct"}>{row.id ?? ""}</div>
            </div>
          );
        },
      },
      {
        name: "problem",
        title: "request.list.subject",
        getCellValue: (row) => {
          // return <span className={classes.client}
          //              onClick={() => this.handleOpenClientModal(row,false)}>{this.checkProblem(row)||'Voir'}</span>

          return (
            <CustomButton
              className={classes.btnResponse}
              title={this.checkProblem(row) || "Voir"}
              aria-haspopup="true"
              onClick={(e) => this.handleOpenClientModal(row, false)}
              // buttonProps={{
              //     startIcon: <React.Fragment><img
              //         src={returnIcon} className={"iconAdd"} alt=""/></React.Fragment>
              // }}
            />
          );
        },
      },
      {
        name: "client",
        title: "request.list.client",
        getCellValue: (row) => {
          return (
            <CustomButton
              className={classes.btnResponse}
              title={
                row?.fullName
                  ? row.fullName
                  : `${row.addressFirstName ?? ""} ${row.addressLastName ?? ""}`
              }
              aria-haspopup="true"
              onClick={(e) => this.handleOpenClientModal(row, true)}
            />
          );
        },
      },
      {
        name: "createdDate",
        title: "request.list.date",
        getCellValue: (row) => {
          return (
            <div
              className={`${classes.dateColumn} ${
                groupUser === USER_TYPE_CLIENT ? "dateColumn1" : ""
              } `}
            >
              <span className={"dateCreate"}>
                {t("request.list.the")}{" "}
                {row.createdDate
                  ? moment(row.createdDate).format("DD/MM/YYYY")
                  : ""}
              </span>
              <span className={"hourCreate"}>
                {row.createdDate ? moment(row.createdDate).format("HH:mm") : ""}
              </span>
            </div>
          );
        },
      },
      {
        name: "registrationStart",
        title: "request.list.startTime",
        getCellValue: (row) => {
          if (
            row.registrationStart &&
            row.registrationTimeStart &&
            row.registrationTimeEnd
          ) {
            const isFuture = moment(row.registrationStart) >= moment();
            let startTime = convertTimezoneHour(
              row.registrationTimeStart,
              row.registrationStart
            );
            let time = moment()
              .hour(startTime / 60)
              .minute(startTime % 60)
              .second(0)
              .format("HH:mm");
            if (isFuture) {
              return (
                <UpcomingColumn
                  handleOpenSetDateModal={this.handleOpenSetDateModal}
                  time={time}
                  row={row}
                />
              );
            }
            return (
              <div
                className={`${classes.dateColumn} `}
                onClick={() =>
                  this.handleOpenSetDateModal({
                    id: row.id,
                    dateSelected: row.registrationStart,
                    startTime: convertTimezoneHour(
                      row.registrationTimeStart,
                      row.registrationStart
                    ),
                    endTime: convertTimezoneHour(
                      row.registrationTimeEnd,
                      row.registrationStart
                    ),
                  })
                }
              >
                <span className={"dateCreate"}>
                  {t("request.list.the")}{" "}
                  {row.registrationStart
                    ? moment(row.registrationStart).format("DD/MM/YYYY")
                    : ""}
                </span>
                <span className={"hourCreate"}>{time}</span>
              </div>
            );
            // <span className={classes.startTimeWrapper} onClick={()=>this.handleOpenSetDateModal({id:row.id, dateSelected: row.registrationStart, startTime: convertTimezoneHour(row.registrationTimeStart, row.registrationStart), endTime: convertTimezoneHour(row.registrationTimeEnd, row.registrationStart)})}>
            //     <span className={classes.startTimeColumn}>{time}</span>
            //     <span> {moment(row.registrationStart).format("DD/MM/YYYY")}</span>
            // </span>
          } else return <span></span>;
        },
      },
      {
        name: "staff",
        title: "request.list.staff",
        getCellValue: (row) => {
          const statusType = row.status.statusType
            ? row.status.statusType.code
            : "";
          const payment = !!(
            row.payment &&
            row.payment.paid &&
            row.registrationStart &&
            row.registrationTimeStart
          );
          // if([REPAIRED, STOP].includes(statusType)  && payment){
          return row.manager ? (
            <span
              className={classes.staffPerson}
              onClick={() => this.handleOpenModal(MODAL_ASSIGN_REPAIRER, row)}
            >
              {row.manager.person && row.manager.person.fullName
                ? row.manager.person.fullName
                : row.manager.email}
            </span>
          ) : (
            <CustomButton
              className={`addStaffWrapperBtn ${classes.addStaffWrapper} ${classes.btnResponse}`}
              title={t("request.list.assign")}
              onClick={() => this.handleOpenModal(MODAL_ASSIGN_REPAIRER, row)}
              buttonProps={{
                startIcon: <img src={addStaffIcon} alt="" />,
              }}
            />
          );
          // }
        },
      },
      {
        name: "note",
        title: "request.list.note",
        getCellValue: (row) => {
          if (row.notesByCondition) {
            const rowId = row.id.toString();
            return (
              <div
                className={`${classes.noteWrapper} ${
                  row.notesByCondition.length > 0 ? "hasAppreciate" : ""
                }`}

              >
                <div className={"toggleNote"} onClick={() =>
                    this.props.setShowNoteCell(
                        row.id,
                        showNoteCell.hasOwnProperty(rowId)
                            ? ACTION_DELETE
                            : ACTION_ADD
                    )
                }>
                  <img src={ToggleShowAppreciationCell} alt={""}/>
                </div>
                <div
                  className={`${classes.noteList}  ${
                    this.props.showAllNote || showNoteCell.hasOwnProperty(rowId)
                      ? ""
                      : classes.hiddenNote
                  }`}
                >
                  {pathPage !== links.INFORMATION_PAGE ? (
                    row.notesByCondition
                      .sort(function (a, b) {
                        return a.createdAt >= b.createdAt ? -1 : 1;
                      })
                      .map((item, index) => {
                        return (
                          <div className={classes.appreciateItem} key={index}>
                            <Tooltip
                              title={
                                <React.Fragment>
                                  {pathPage !== links.REQUEST && (
                                    <React.Fragment>
                                      <div>
                                        {item.user &&
                                          item.user.person &&
                                          item.user.person.fullName}
                                      </div>
                                      <div>
                                        {item.user ? item.user.email : ""}
                                      </div>
                                    </React.Fragment>
                                  )}
                                  <div>
                                    {item.createdAt
                                      ? moment(item.createdAt).format(
                                          "DD/MM/YYYY HH:mm"
                                        )
                                      : ""}
                                  </div>
                                </React.Fragment>
                              }
                              enterDelay={100}
                              leaveDelay={200}
                              tooltipstyle={{
                                backgroundColor: "#34558B",
                                fontSize: "0.8rem",
                                padding: "0.3rem",
                              }}
                              interactive
                            >
                              <div className="adminInfo">
                                <AppPersonAvatar
                                  name={
                                    (item.user &&
                                      item.user.person &&
                                      item.user.person.lastName) ??
                                    ""
                                  }
                                  title={" "}
                                  alt={item.user.email}
                                  variant="rounded"
                                  src={
                                    item.user.person
                                      ? item.user.person.avatar &&
                                        item.user.person.avatar.pathUrl
                                        ? item.user.person.avatar.pathUrl
                                        : ""
                                      : ""
                                  }
                                  avatarImgClassName={"avatar"}
                                />
                              </div>
                            </Tooltip>
                            <div className="commentContent">
                              {item.content ? item.content : ""}
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div className="adminInfo">
                      <div className="commentContent">
                        {row.note ? row.note.content : ""}
                      </div>
                    </div>
                  )}
                  {row.notesByCondition && row.notesByCondition.length > 1 ? (
                    <div className="linearGradient"></div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }
          return "";
        },
      },
    ];
    if (pathPage === links.REQUEST) {
      const columnsNew = [
        ...columns.slice(0, 2),
        {
          name: "reportPaid",
          title: "request.list.rules",
          getCellValue: (row) => this.renderColumnTotalPaymentReportInList(row),
        },
        ...columns.slice(2),
        {
          name:
            groupUser === USER_TYPE_CLIENT
              ? "clientSawManagerLastChat"
              : "managerSawClientLastChat",
          title: "request.list.chat",
          getCellValue: (row) => {
            const isNotRead =
              Array.isArray(countChatNotSeen) &&
              countChatNotSeen.includes(row.id);
            let disableOnClickChat = false;
            return (
              <div
                onClick={(e) => {
                  if (!disableOnClickChat) {
                    this.handleOpenPopOver(e, row, POPOVER_ADD_CHAT);
                  }
                }}
                className={`btnChatIcon ${classes.btnChat} ${
                  anchorEl &&
                  popoverType === POPOVER_READ_OR_UNREAD_CHAT &&
                  formValuePopover?.id === row.id
                    ? classes.openPopoverMenuThreeDots
                    : ""
                }`}
              >
                <div className={"iconBtnBlock"}>
                  <CustomSvg
                    svgProps={{
                      src: chatIcon,
                    }}
                  />
                  {isNotRead && (
                    <div
                      className={`${classes.iconAlertNotSeen} iconAlertNotSeen2`}
                    />
                  )}
                </div>
                <div>{t("request.list.chat")}</div>
                {row.managerSent && (
                  <div
                    className={`${classes.actionChatWrapper}  ${
                      "actionChatWrapperClient" + row.id
                    }`}
                    onMouseMove={() => {
                      disableOnClickChat = true;
                    }}
                    onMouseOut={() => {
                      disableOnClickChat = false;
                    }}
                    onClick={(e) => {
                      if (disableOnClickChat) {
                        this.handleOpenPopOver(
                          e,
                          {
                            ...row,
                            isNotRead: isNotRead,
                          },
                          POPOVER_READ_OR_UNREAD_CHAT
                        );
                      }
                    }}
                  >
                    <div className={"detailICon"}>
                      <div className={"threeDot"}>...</div>
                    </div>
                  </div>
                )}
              </div>
            );
          },
          filterOptions: {
            type: "select",
            withNoneOption: false,
            choices: [
              {
                tooltip: this.props.t("request.list.statusChat.seen"),
                value: "true",
                label: this.props.t("request.list.statusChat.seen"),
              },
              {
                tooltip: this.props.t("request.list.statusChat.notSeen"),
                value: "false",
                label: this.props.t("request.list.statusChat.notSeen"),
              },
            ],
          },
        },
        {
          name: "detail",
          title: "request.list.detail",
          getCellValue: (row) => {
            if (groupUser === USER_TYPE_CLIENT) {
              return this.renderShowColumnDetailUserTypeClient(row);
            } else {
              return <div></div>;
            }
          },
        },
      ];
      columns = [...columnsNew];
    }
    if (groupUser !== USER_TYPE_CLIENT) {
      const columnsNew2 = [
        ...columns.slice(0, 2),
        {
          name: "status",
          title: "request.list.status",
          getCellValue: (row) => {
            if (row.status) {
              const statusType = row.status.statusType
                ? row.status.statusType.code
                : "";
              const paid = row.payment ? row.payment.paid : false;
              const repairer = row.manager ? row.manager.id : null;
              return (
                <div
                  className={`${classes.statusType} ${
                    [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(groupUser)
                      ? classes.statusTypeAdmin
                      : classes.statusTypeClient
                  }`}
                >
                  {[REPAIRED, GO_DOC].includes(statusType) ? (
                    <img src={goIcon} alt={""} />
                  ) : statusType === NOT_BE_REPAIRED ? (
                    <img
                      src={
                        groupUser === USER_TYPE_CLIENT ? nogoClient : notgoIcon
                      }
                      className={"not_repaired"}
                      alt={""}
                    />
                  ) : statusType === WAITING_FOR_REPLY ? (
                    <img
                      src={
                        groupUser === USER_TYPE_CLIENT
                          ? waitClient
                          : waitingIcon
                      }
                      alt={""}
                    />
                  ) : statusType === MAYBE ? (
                    groupUser !== USER_TYPE_CLIENT ? (
                      <img src={maybeIcon} alt={""} />
                    ) : (
                      <img src={waitClient} alt={""} />
                    )
                  ) : statusType === STOP ? (
                    <img
                      alt={"stop"}
                      src={
                        groupUser === USER_TYPE_CLIENT ? stopClient : stopIcon
                      }
                    />
                  ) : statusType === REPAIRING ? (
                    <CustomInlineSvg
                      svgProps={{
                        src: RepairIcon,
                        className: "repairSvg",
                      }}
                      scale="1"
                    />
                  ) : statusType === COMPLETE_REPAIR ? (
                    <CheckCircleIcon className="completeIcon" />
                  ) : (
                    <></>
                  )}
                  {[USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(
                    groupUser
                  ) &&
                    paid && (
                      <span className={"repairer"}>
                        <CustomInlineSvg
                          svgProps={{
                            src: repairer ? assignedActive : unassignedActive,
                          }}
                          scale="1"
                        />
                      </span>
                    )}
                </div>
              );
            } else {
              return "";
            }
          },
        },
        {
          name: "reportPaid",
          title: "request.list.rules",
          getCellValue: (row) => this.renderColumnTotalPaymentReportInList(row),
        },
        ...columns.slice(2),
      ];
      columns = [...columnsNew2];
    }

    columns.push({
      name: "action",
      title: "request.list.action",
      getCellValue: (row) => {
        const statusType =
          row.status && row.status.statusType ? row.status.statusType.code : "";
        const rowStatuses = Array.isArray(row?.requestStatuses)
          ? row.requestStatuses
          : [];
        const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
        const rolesUser =
          dataUser.data &&
          dataUser.data.rolesOfInstanceOf &&
          Array.isArray(dataUser.data.rolesOfInstanceOf)
            ? dataUser.data.rolesOfInstanceOf
            : [];
        if (pathPage !== links.REQUEST) {
          if (
            [
              USER_TYPE_ADMIN,
              USER_TYPE_SUPER_ADMIN,
              USER_TYPE_REPAIRER,
            ].includes(groupUser)
          ) {
            let disableOnClickChat = false;
            return (
              <div className={classes.actionRequestAdminWrapper}>
                <div className={"btnActionOtherBlock"}>
                  {this.showVideoCallBlock(row, true)}
                  <div className={"btnOtherBlock"}>
                    <div
                      className={"btnActionWrapper "}
                      aria-owns={POPOVER_ADD_NOTE + row.id}
                      aria-haspopup="true"
                      onClick={(e) =>
                        this.handleOpenPopOver(e, row, POPOVER_ADD_NOTE)
                      }
                    >
                      <div className={"iconWrapper"}>
                        <CustomSvg
                          svgProps={{
                            src: noteIcon,
                            title: t("request.list.btnAddNote"),
                          }}
                        />
                      </div>
                      <div className={"titleIcon"}>
                        {t("request.list.btnAddNote")}
                      </div>
                    </div>
                    {customRenderShowBtnAction({
                      onClick: (e) => {
                        if (!disableOnClickChat)
                          this.handleOpenPopOver(e, row, POPOVER_ADD_CHAT);
                      },
                      icon: chatIcon,
                      iconComponent: Array.isArray(countChatNotSeen) &&
                        countChatNotSeen.includes(row.id) && (
                          <div className={classes.iconAlertNotSeen} />
                        ),
                      componentBottomTitle: row.clientSent ? (
                        <div
                          className={`${classes.actionChatWrapper}  ${
                            "actionChatWrapperManager" + row.id
                          }`}
                          onMouseMove={() => {
                            disableOnClickChat = true;
                          }}
                          onMouseOut={() => {
                            disableOnClickChat = false;
                          }}
                          onClick={(e) =>
                            this.handleOpenPopOver(
                              e,
                              {
                                ...row,
                                isNotRead:
                                  Array.isArray(countChatNotSeen) &&
                                  countChatNotSeen.includes(row.id),
                              },
                              POPOVER_READ_OR_UNREAD_CHAT
                            )
                          }
                        >
                          <div className={"detailICon"}>
                            <div className={"threeDot"}>...</div>
                          </div>
                        </div>
                      ) : (
                        ""
                      ),
                      className: `btnActionChatWrapper ${
                        anchorEl &&
                        popoverType === POPOVER_READ_OR_UNREAD_CHAT &&
                        formValuePopover?.id === row.id
                          ? classes.openPopoverMenuThreeDots
                          : ""
                      }`,
                      title: t("request.list.chat"),
                    })}
                    {customRenderShowBtnAction({
                      navLink: links.DIAGNOSTIC.replace(":id", row.id),
                      icon: createReportIcon,
                      title: t("request.list.createReport"),
                    })}

                    {customRenderShowBtnAction({
                      icon: cameraIcon,
                      onClick: () => {
                        this.setState({
                          openModalFiles: true,
                          requestId: row.id ?? null,
                          newDocumentByManager: row.newDocumentByManager,
                          dataClient: { ...row },
                        });
                      },
                      iconComponent: !!(
                        Array.isArray(requestDocumentManagerNotSeen) &&
                        requestDocumentManagerNotSeen.length &&
                        requestDocumentManagerNotSeen.includes(row.id)
                      ) ? (
                        <div className={classes.iconAlertNotSeen} />
                      ) : (
                        ""
                      ),
                      title: t("request.list.viewDocument"),
                    })}

                    {/* {([MAYBE, WAITING_FOR_REPLY].includes(statusType) || (rolesUser.includes(ROLE_CHANGE_REQUEST_STATUS) && [NOT_BE_REPAIRED, STOP].includes(statusType))) && */}
                    <div
                      className={"btnActionWrapper btnResponse"}
                      aria-owns={POPOVER_CHANGE_STATUS + row.id}
                      aria-haspopup="true"
                      onClick={(e) =>
                        this.handleOpenPopOver(e, row, POPOVER_CHANGE_STATUS)
                      }
                    >
                      <div className={"iconWrapper"}>
                        <CustomSvg
                          svgProps={{
                            src: returnIcon,
                            title: t("request.list.btnResponse"),
                          }}
                        />
                      </div>
                      <div className={"titleIcon"}>
                        {t("request.list.btnResponse")}
                      </div>
                    </div>
                    {/* } */}

                    {/* {(statusType === REPAIRED || statusType === GO_DOC) && customRenderShowBtnAction({
                                    onClick: (e) => this.setState({dialogForm: true, idDialogForm: row.id}),
                                    icon: stopStatusIcon,
                                    title: t("request.list.btnStop"),
                                    className: "btnStop",
                                })} */}
                    {([
                      REPAIRED,
                      GO_DOC,
                      REPAIRING,
                      COMPLETE_REPAIR,
                      MAYBE,
                    ].includes(statusType) ||
                      !!rowStatuses.find(
                        (itemStatus) =>
                          itemStatus?.statusType?.code &&
                          [
                            REPAIRED,
                            GO_DOC,
                            REPAIRING,
                            COMPLETE_REPAIR,
                            MAYBE,
                          ].includes(itemStatus.statusType.code)
                      )) &&
                      row.repairToken && (
                        <>
                          {customRenderShowBtnAction({
                            icon: RepairIcon,
                            navLink: links.REQUEST_REPAIR.replace(
                              ":token",
                              row.repairToken
                            ),
                            title: t("requestRepair.repair"),
                          })}
                        </>
                      )}

                    {customRenderShowBtnAction({
                      className: "btnArchived",
                      onClick: (e) =>
                        this.handleOpenPopOver(
                          e,
                          row,
                          POPOVER_EDIT_ACTION_STATUS_ARCHIVE_REPEAT
                        ),
                      iconComponent: (
                        <div className={classes.threeDotBlock}>
                          <div className="threeDotAction">...</div>
                        </div>
                      ),
                      ariaOwns:
                        POPOVER_EDIT_ACTION_STATUS_ARCHIVE_REPEAT + row.id,
                      // title: t("request.list.btnArchive")
                    })}
                    {/*{customRenderShowBtnAction({*/}
                    {/*    className: "btnArchived",*/}
                    {/*    onClick: () =>  this.setState({dialogFormSave: true, dataRow: row}),*/}
                    {/*    icon: BookmarkIcon,*/}
                    {/*    title: row.archivedByAdmin ? t("request.list.btnUnarchive") : t("request.list.btnArchive")*/}
                    {/*})}*/}
                  </div>
                </div>

                <div className={classes.lastItemActionWrapper}>
                  {customRenderShowBtnAction({
                    icon: pencilSmallIcon,
                    navLink:
                      row.reportAnalysisByTheExpert > 0
                        ? links.REPORT_REQUEST.replace(":id", row.id)
                        : "",
                    titleToolTip: t("request.list.viewAllReport"),
                    iconComponent: (
                      <div className={"numberIconWrapper"}>
                        {row.reportAnalysisByTheExpert ?? 0}
                      </div>
                    ),
                    className: `btnShowReport ${
                      !(row.reportAnalysisByTheExpert > 0)
                        ? "dataNumberNull"
                        : ""
                    }`,
                  })}
                </div>
              </div>
            );

            // }
          } else if (
            row.status &&
            row.status.statusType &&
            [REPAIRED, STOP].includes(statusType)
          ) {
            const navLinkBtn = links.REQUEST_INFORMATION_DETAIL;
            return (
              <>
                <NavLink
                  to={navLinkBtn.replace(":id", row.id)}
                  className={classes.aRespon}
                >
                  <CustomButton
                    className={classes.btnResponse}
                    title={t("request.list.btnDetail")}
                    buttonProps={{
                      startIcon: (
                        <React.Fragment>
                          <InfoIcon />
                        </React.Fragment>
                      ),
                    }}
                  />
                </NavLink>
                {this.showVideoCallBlock(row)}
              </>
            );
          } else {
            return this.showVideoCallBlock(row);
          }
        } else {
          return (
            <>
              {row.status &&
              row.status.statusType &&
              [REPAIRED, STOP, GO_DOC].includes(statusType)
                ? this.renderShowColumnActionUserTypeClient(row)
                : ""}
              {this.showVideoCallBlock(row)}
            </>
          );
        }
      },
    });
    let apiParamsNew = { ...this.state.apiParams };
    try {
      const urlParams = new URLSearchParams(
        this.props.location.search.substring(1)
      );
      const entries = urlParams.entries();
      const params = paramsToObject(entries);
      if (params.hasOwnProperty("upcomingAppointment")) {
        apiParamsNew = {
          ...apiParamsNew,
          upcomingAppointment: 1,
        };
      }
      if (params.hasOwnProperty("adminNotSawPaymentReport")) {
        apiParamsNew = {
          ...apiParamsNew,
          adminNotSawPaymentReport: 1,
        };
      }
      if (params.hasOwnProperty("managerSawLastDocumentClientUpload")) {
        apiParamsNew = {
          ...apiParamsNew,
          managerSawLastDocumentClientUpload: "false",
        };
      }
      if (params.hasOwnProperty("clientSawLastDocumentManagerUpload")) {
        apiParamsNew = {
          ...apiParamsNew,
          clientSawLastDocumentManagerUpload: "false",
        };
      }
      if (params.hasOwnProperty("clientSawManagerLastChat")) {
        apiParamsNew = {
          ...apiParamsNew,
          clientSawManagerLastChat: "false",
        };
      }
      if (params.hasOwnProperty("managerSawClientLastChat")) {
        apiParamsNew = {
          ...apiParamsNew,
          managerSawClientLastChat: "false",
        };
      }
    } catch (e) {}

    if (filterGrid) {
      if (filterGrid.id)
        apiParamsNew = {
          ...apiParamsNew,
          filters: { ...apiParamsNew.filters, id: filterGrid.id },
        };
      if (filterGrid.createdDate)
        apiParamsNew = {
          ...apiParamsNew,
          filters: {
            ...apiParamsNew.filters,
            createdDate: filterGrid.createdDate,
          },
        };
    }
    let statusFilter = this.getStatusCodeInLocalStorage();
    if (pathPage === links.ADMIN_REQUEST) {
      apiParamsNew = {
        ...apiParamsNew,
        statusCode: statusFilter,
      };
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid() &&
      endDate.isValid() &&
      [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_REPAIRER].includes(
        dataUser.data.group
      )
    ) {
      let startDateParam = moment(startDate).format("YYYY/MM/DD");
      let endDateParam = moment(endDate).format("YYYY/MM/DD");
      apiParamsNew = {
        ...apiParamsNew,
        startDate: startDateParam,
        endDate: endDateParam,
      };
    } else delete apiParamsNew[("startDate", "endDate")];
    if (
      dataUser &&
      dataUser.data &&
      ![USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(dataUser.data.group)
    ) {
      hiddenColumns.push("startTime", "staff");
      if (dataUser.data.group === USER_TYPE_CLIENT) {
        // const itemColumnStatus = columns.find(valueColumn => valueColumn.name === "status");
        const itemColumnAction = columns.find(
          (valueColumn) => valueColumn.name === "action"
        );

        // if(itemColumnStatus){
        //     columns = columns.filter(valueColumn =>  ![ "action"].includes(valueColumn.name));
        //     // columns.unshift(itemColumnStatus)
        //     columns.splice(5, 0, itemColumnAction)
        // }
        columns = columns.filter(
          (valueColumn) => !["action"].includes(valueColumn.name)
        );
        columns.splice(5, 0, itemColumnAction);
        hiddenColumns.push(
          "registrationStart",
          "note",
          "pdf",
          "files",
          "client"
        );
      }
      if (dataUser.data.group === USER_TYPE_REPAIRER)
        hiddenColumns.push("video", "pdf", "otherDoc", "detail");
    } else hiddenColumns.push("video", "pdf", "otherDoc", "detail");
    // console.log(apiParamsNew)
    let componentState = Object.assign(
      {
        iconList: "",
        iconNew: "",
        classList: `productList ${
          pathPage === links.REQUEST ? "requestClient" : ""
        }`,
        columns: columns,
        apiParams: apiParamsNew,
        sortingColumnStates: [{ columnName: "date", compare: dateCompare }],
        filterColumnsEnabled: REQUEST_LIST_FILTER_COLUMNS_ENABLE,
        sortingStateColumnExtensions:
          REQUEST_LIST_SORTING_STATE_COLUMN_EXTENSIONS,
        defaultColumnWidths: [{ columnName: "status", width: 40 }],
        tableProps: {
          rowComponent:
            dataUser &&
            dataUser.data &&
            dataUser.data.group === USER_TYPE_CLIENT
              ? TableRow
              : TableRowAdmin,
          cellComponent:
            dataUser &&
            dataUser.data &&
            dataUser.data.group === USER_TYPE_CLIENT
              ? TableCell
              : TableCellAdmin,
        },
        CellHeaderProps: {
          sortLabelComponent: TooltipHeaderSort,
          rowComponent: TableHeaderRowCustom,
          cellComponent: (props) =>
            TableHeaderCellRoot(
              props,
              this.props.showAllNote,
              this.props.setShowAllNote,
              groupUser
            ),
        },
        // CellSelectionProps: {cellComponent: TableSelectionCell},
        titleList: "",
        /*api link*/
        apiNameList: this.apiNameListDefault,
        hiddenColumns: hiddenColumns,
        columnSetDefaultWidths: [
          { columnName: "status", width: 35 },
          { columnName: "id", width: 30 },
          { columnName: "action", width: 450 },
          { columnName: "createdDate", width: 143 },
          { columnName: "registrationStart", width: 143 },
          { columnName: "staff", width: 150 },
          { columnName: "client", width: 150 },
        ],
        /*route link*/
      },
      this.props.componentState ? this.props.componentState : {}
    );
    if (this.state.newItem) {
      componentState.newItem = this.state.newItem;
    }
    const gridComponent = (item) => {
      let dataUser = this.props.dataUser;
      const statusType =
        item.status && item.status.statusType
          ? item.status.statusType.code
          : "";
      const rolesUser =
        dataUser.data &&
        dataUser.data.rolesOfInstanceOf &&
        Array.isArray(dataUser.data.rolesOfInstanceOf)
          ? dataUser.data.rolesOfInstanceOf
          : [];
      const payment = item.payment ? item.payment.paid : false;
      let categories = (item && item.categories[0]) ?? null;
      if (categories) categories.label = categories.name;
      let time = item.registrationTimeStart
        ? moment()
            .hour(
              convertTimezoneHour(
                item.registrationTimeStart,
                item.registrationStart
              ) / 60
            )
            .minute(
              convertTimezoneHour(
                item.registrationTimeStart,
                item.registrationStart
              ) % 60
            )
            .second(0)
            .format("HH:mm")
        : null;
      let document = item.documents;
      if (document && document.length > 0)
        document = document.filter(
          (item) => item.mimeType && !item.mimeType.includes("video")
        );
      const TableGridRow = (row) => {
        let classNames = "";
        if (row.status && row.status.statusType && row.status.statusType.code) {
          let statusType = row.status.statusType.code;
          // if(statusType===REPAIRED && row.payment){
          //     if(row.payment.paid) classNames += " " + classes.repairedPaidRow
          //     else classNames += " " + classes.repairedRow
          // }
          if (statusType === NOT_BE_REPAIRED)
            classNames += " " + classes.nogoRow;
        }
        return classNames;
      };
      const statusKeyInStatusList = returnStatusInColumnToggle(item);
      const itemIdString = item.id.toString();
      return (
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          className={`${
            groupUser === USER_TYPE_CLIENT && this.props.width === XS
              ? classes.gridComponentClient
              : ""
          }`}
        >
          <Paper
            className={`${classes.gridComponentWrapper} ${
              groupUser === USER_TYPE_CLIENT && this.props.width === XS
                ? TableGridRow(item)
                : ""
            }`}
          >
            {groupUser !== USER_TYPE_CLIENT && (
              <React.Fragment>
                <Grid xs={12}>
                  {[
                    USER_TYPE_ADMIN,
                    USER_TYPE_SUPER_ADMIN,
                    USER_TYPE_REPAIRER,
                  ].includes(groupUser) && (
                    <Grid xs={12} className={classes.titleGridRequestWrapper}>
                      <ProblemTitleModal
                        id={item.id ?? null}
                        status={item.status ?? null}
                        requestItem={item}
                        payment={(item && item.payment) ?? null}
                        category={[
                          USER_TYPE_ADMIN,
                          USER_TYPE_SUPER_ADMIN,
                          USER_TYPE_REPAIRER,
                        ].includes(groupUser)}
                        categories={
                          (item &&
                            item.categories[0] && {
                              ...item.categories[0],
                              value: item.categories[0].id,
                            }) ??
                          null
                        }
                        assignStaff={
                          (item.manager && item.manager.person) ?? null
                        }
                        handleChangeField={this.handleChangeField}
                        gridList={true}
                        editRow={this.editRow}
                        hasChangeCategory={!!(this.state.rowEdited === item.id)}
                        handleSave={this.addCategoriesForRequest}
                        saveButton={categoryModal}
                      />
                      {this.showVideoCallBlock(item)}
                    </Grid>
                  )}
                  <Grid
                    xs={12}
                    container
                    className={`${classes.titleWrapper} && ${
                      groupUser === USER_TYPE_CLIENT && this.props.width === XS
                        ? classes.titleWrapperClient
                        : ""
                    } `}
                  >
                    <div className={classes.titleID}>
                      <span style={{ fontWeight: 800 }}>
                        {t("request.list.id")}
                      </span>
                      : {item.id}
                    </div>
                    <div
                      className={classes.titleCustomer}
                      onClick={() => this.handleOpenClientModal(item)}
                    >
                      <span style={{ fontWeight: 800 }}>
                        {t("request.list.client")}
                      </span>
                      : {(item.person && item.person.fullName) ?? ""}
                    </div>
                  </Grid>
                  {[
                    USER_TYPE_ADMIN,
                    USER_TYPE_SUPER_ADMIN,
                    USER_TYPE_REPAIRER,
                  ].includes(groupUser) && (
                    <Grid xs={12} className={classes.contentProblem}>
                      <ProblemContentModal
                        dataDetailProblem={{
                          documents: item.documents,
                          problem: item.problem,
                        }}
                        zoomIn={true}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid xs={12} className={`${classes.componentBottomWrapper}`}>
                  <Grid xs={12} container className={classes.componentBottom}>
                    <Grid xs={4}>
                      <Grid
                        xs={12}
                        className={classes.dataInfoClient}
                        onClick={() =>
                          this.handleOpenSetDateModal({
                            id: item.id,
                            dateSelected: item.registrationStart,
                            startTime: item.registrationTimeStart,
                            endTime: item.registrationTimeEnd,
                          })
                        }
                      >
                        <img src={timeIcon} alt="" />
                        <span className={classes.startTimeWrapper}>
                          <span className={classes.startTimeColumn}>
                            {time}
                          </span>
                          {item.registrationStart ? (
                            <span>
                              {" "}
                              {moment(item.registrationStart).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          ) : item.checkModified &&
                            item.payment &&
                            item.payment.paid &&
                            item.registrationCancel ? (
                            <span
                              className={classes.startTimeWrapper}
                              onClick={() =>
                                this.handleOpenSetDateModal({
                                  id: item.id,
                                  dateSelected: null,
                                  startTime: null,
                                  endTime: null,
                                })
                              }
                            >
                              {t("request.list.setStartTime")}
                            </span>
                          ) : null}
                        </span>
                      </Grid>
                      {[USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(
                        groupUser
                      ) && (
                        <Grid xs={12} className={classes.dataInfoClient}>
                          <img src={repaierGrid} alt="" />
                          {item.manager &&
                            item.manager.person &&
                            item.manager.person.fullName && (
                              <span
                                className={classes.startTimeWrapper}
                                onClick={() =>
                                  this.handleOpenModal(
                                    MODAL_ASSIGN_REPAIRER,
                                    item
                                  )
                                }
                              >
                                {item.manager.person.fullName}
                              </span>
                            )}
                        </Grid>
                      )}
                    </Grid>
                    <Grid xs={8} className={classes.btnGroup}>
                      {/*{([MAYBE, WAITING_FOR_REPLY].includes(statusType)) ?*/}
                      {[USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(
                        groupUser
                      ) ? (
                        [MAYBE, WAITING_FOR_REPLY].includes(statusType) ||
                        (rolesUser.includes(ROLE_CHANGE_REQUEST_STATUS) &&
                          [NOT_BE_REPAIRED, STOP].includes(statusType)) ? (
                          <CustomButton
                            className={`${classes.btnResponse} ${classes.btnGonogo} `}
                            title={t(
                              payment
                                ? "request.list.btnDetail"
                                : "request.list.btnResponse"
                            )}
                            aria-owns={POPOVER_CHANGE_STATUS + item.id}
                            aria-haspopup="true"
                            onClick={(e) =>
                              this.handleOpenPopOver(
                                e,
                                item,
                                POPOVER_CHANGE_STATUS
                              )
                            }
                            buttonProps={{
                              startIcon: (
                                <React.Fragment>
                                  <img
                                    src={returnIcon}
                                    className={"iconAdd"}
                                    alt=""
                                  />
                                </React.Fragment>
                              ),
                            }}
                          />
                        ) : statusType === REPAIRED ? (
                          <React.Fragment>
                            <CustomButton
                              className={`${classes.btnResponse} ${classes.btnStop} ${classes.btnGrid}`}
                              title={t("request.list.btnStop")}
                              buttonProps={{
                                startIcon: <img src={stopIconAction} alt="" />,
                              }}
                              // onClick={()=>this.changeStatus({changeStatus: STOP, id: item.id})}
                              onClick={() =>
                                this.setState({
                                  dialogForm: true,
                                  idDialogForm: item.id,
                                })
                              }
                            />
                            {payment &&
                              !(item.manager && item.manager.person) && (
                                <CustomButton
                                  className={`${classes.btnResponse} ${classes.addStaffWrapper} ${classes.btnGrid}`}
                                  onClick={() =>
                                    this.handleOpenModal(
                                      MODAL_ASSIGN_REPAIRER,
                                      item
                                    )
                                  }
                                  title={t("request.list.assign")}
                                  buttonProps={{
                                    startIcon: (
                                      <img src={addStaffIcon} alt="" />
                                    ),
                                  }}
                                />
                              )}
                          </React.Fragment>
                        ) : null
                      ) : null}
                      <CustomButton
                        title={t("request.list.viewNote")}
                        className={`${classes.viewNote} ${classes.btnGrid}`}
                        onClick={() =>
                          this.handleOpenViewNoteModal({
                            id: item.id ?? null,
                            categories: categories ?? null,
                            payment: item.payment ?? null,
                            status: item.status ?? null,
                            notes: item.notesByCondition ?? null,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {groupUser === USER_TYPE_CLIENT && (
              <React.Fragment>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid
                      xs={12}
                      container
                      item
                      className={`SectionTitleWrapper && ${
                        this.props.width === XS
                          ? classes.titleWrapperClient
                          : ""
                      } `}
                      style={
                        statusKeyInStatusList
                          ? {
                              background:
                                listStatusClient[statusKeyInStatusList]
                                  .backgroundColor,
                            }
                          : {}
                      }
                    >
                      <div className={classes.titleCustomerClient}>
                        {listStatusClient.hasOwnProperty(
                          statusKeyInStatusList
                        ) ? (
                          <div className={classes.statusWrapperGrid}>
                            <img
                              src={listStatusClient[statusKeyInStatusList].icon}
                              className=""
                              alt={t(
                                listStatusClient[statusKeyInStatusList].label
                              )}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          className={`problemWrapper ${
                            !!(statusType === REPAIRED && item?.payment?.paid)
                              ? classes.repairedPaidRow
                              : ""
                          }`}
                        >
                          {this.checkProblem(item)}
                        </div>
                      </div>
                      <div
                        className={`${classes.titleID} titleID1 ${
                          !!(statusType === REPAIRED && item?.payment?.paid)
                            ? classes.repairedPaidRow
                            : ""
                        }`}
                      >
                        <span style={{ fontWeight: 800 }}>
                          {t("request.list.id")}
                        </span>
                        {itemIdString.length > 5 ? (
                          <Tooltip title={item.id} arrow>
                            <span className={"itemIdNumber"}>
                              {itemIdString.slice(0, 3) + "..."}
                            </span>
                          </Tooltip>
                        ) : itemIdString.length === 3 ? (
                          ":" + itemIdString
                        ) : itemIdString.length > 2 ? (
                          itemIdString
                        ) : (
                          ": " + itemIdString
                        )}
                      </div>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    style={
                      statusKeyInStatusList
                        ? {
                            background:
                              listStatusClient[statusKeyInStatusList]
                                .backgroundColor,
                          }
                        : {}
                    }
                  >
                    <Grid
                      xs={12}
                      className={`${classes.componentBottomWrapper} ${classes.componentBottomWrapperClient}`}
                    >
                      {item.hasReportSelectedForRequest ? (
                        <Grid xs={12} className={"reportIconDetail"}>
                          <div
                            onClick={() =>
                              this.handleOpenModal(
                                MODAL_REPORT_LIST_BY_REQUEST,
                                item
                              )
                            }
                            className={`${classes.toggleCellWrapper} ${
                              item?.totalAmountPayment
                                ? "listShowTypeGrid1"
                                : "listShowTypeGrid"
                            }`}
                          >
                            {item?.totalAmountPayment ? (
                              <React.Fragment>
                                <img alt={""} src={reportPaidIcon} />
                                <div className={"toggleIcon"}>
                                  {" "}
                                  {item?.totalAmountPayment?.value ?? 0}{" "}
                                  {!item?.totalAmountPayment?.unit ||
                                  (currency &&
                                    item?.totalAmountPayment?.unit ===
                                      currency.value) ? (
                                    currency?.unit
                                  ) : arrayCurrencies.getCurrency(
                                      item?.totalAmountPayment?.unit
                                    ) ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: arrayCurrencies
                                          .getCurrency(
                                            item?.totalAmountPayment?.unit
                                          )
                                          .symbolFormat.replace(
                                            /\s*\{#\}\s*/gi,
                                            ""
                                          ),
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {" "}
                                <img alt={""} src={reportHeadIcon} />
                                <img
                                  alt={""}
                                  src={reportIcon}
                                  className={"reportIcon"}
                                />
                                <div className={"toggleIcon"}>
                                  {t("client.requestDetail.show")}
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid xs={12} container className={classes.clientListRow}>
                        <Grid xs={12} sm={3} item className={"titleListRow"}>
                          {t("request.list.subject")}
                        </Grid>
                        <Grid xs={12} sm={9} item className={"textListRow"}>
                          <div
                            className={
                              classes.titleCustomerClient + " rowDetail"
                            }
                          >
                            {this.checkProblem(item, true)}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid xs={12} container className={classes.clientListRow}>
                        <Grid xs={12} sm={3} item className={"titleListRow"}>
                          {t("request.list.date")}
                        </Grid>
                        <Grid xs={12} sm={9} item className={"textListRow"}>
                          <span className={"dateCreate"}>
                            {t("request.list.the")}{" "}
                            {item.createdDate
                              ? moment(item.createdDate).format("DD/MM/YYYY")
                              : ""}
                          </span>
                          <span className={"hourCreate"}>
                            {item.createdDate
                              ? moment(item.createdDate).format("HH:mm")
                              : ""}
                          </span>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          className={classes.clientListRow}
                        >
                          <Grid xs={12} sm={3} item className={"titleListRow"}>
                            {t("request.list.date")}
                          </Grid>
                          <Grid xs={12} sm={9} item className={"textListRow"}>
                            <span className={"dateCreate"}>
                              {t("request.list.the")}{" "}
                              {item.createdDate
                                ? moment(item.createdDate).format("DD/MM/YYYY")
                                : ""}
                            </span>
                            <span className={"hourCreate"}>
                              {item.createdDate
                                ? moment(item.createdDate).format("HH:mm")
                                : ""}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          className={classes.clientListRow}
                        >
                          <Grid xs={12} sm={3} item className={"titleListRow"}>
                            {t("request.list.status")}
                          </Grid>
                          <Grid xs={12} sm={9} item className={"iconListRow"}>
                            {statusType === REPAIRED ||
                            statusType === GO_DOC ? (
                              this.renderShowColumnActionUserTypeClient(item)
                            ) : listStatusClient.hasOwnProperty(
                                statusKeyInStatusList
                              ) ? (
                              <div className={classes.statusWrapperGrid}>
                                <img
                                  src={
                                    listStatusClient[statusKeyInStatusList].icon
                                  }
                                  className=""
                                  alt={t(
                                    listStatusClient[statusKeyInStatusList]
                                      .label
                                  )}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.showVideoCallBlock(item)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12} container className={classes.clientListRow}>
                        <Grid
                          xs={12}
                          className={
                            classes.otherDocWrapper + " " + "iconListRow"
                          }
                        >
                          {/*<div*/}
                          {/*    onClick={(e) => this.handleOpenPopOver(e,item, POPOVER_ADD_CHAT)}*/}
                          {/*    className={`${classes.btnChat} btnChat1`}*/}
                          {/*>*/}
                          {/*    <div className={"iconBtnWrapper"}>*/}
                          {/*        <CustomSvg*/}
                          {/*            svgProps={{*/}
                          {/*                src: chatIcon*/}
                          {/*            }}*/}
                          {/*        />*/}
                          {/*    </div>*/}
                          {/*    { Array.isArray(countChatNotSeen) && countChatNotSeen.includes(item.id) && <div className="hasNewChat"></div>}*/}
                          {/*    /!*{((groupUser === USER_TYPE_CLIENT && !item.clientSawManagerLastChat) ||(groupUser !== USER_TYPE_CLIENT && !item.managerSawClientLastChat)) && <div className="hasNewChat"></div>}*!/*/}
                          {/*    <div>{t("request.list.chat")}</div>*/}
                          {/*</div>*/}
                          {/*{document && document.length > 0 && statusType === REPAIRED  ?*/}
                          {/*    <NavLink className={classes.otherDoc} to={links.REQUEST_INFORMATION_DETAIL.replace(":id", item.id) + "?" + qs.stringify({tabDocumentType: TAB_DOCUMENT})}>*/}
                          {/*        <div className={"iconBtnWrapper"}>*/}
                          {/*            <img src={documentIcon} alt=""/>*/}
                          {/*        </div>*/}
                          {/*        <span>{document.length}</span>*/}
                          {/*    </NavLink>*/}
                          {/*    :*/}
                          {/*    item.existDocumentVideo && statusType === REPAIRED ?*/}
                          {/*        <NavLink to={links.REQUEST_INFORMATION_DETAIL.replace(":id", item.id) + "?" + qs.stringify({tabDocumentType: REQUEST_DOC_TYPE_VIDEO})}*/}
                          {/*                 className={classes.buttonVideoMp4}>*/}
                          {/*            <div className={"iconBtnWrapper"}>*/}
                          {/*                <img alt={""} src={videoIcon}/>*/}
                          {/*            </div>*/}
                          {/*            <span>{t("request.requestDetail.videoMp4")}</span>*/}
                          {/*        </NavLink> : */}
                          {this.renderShowColumnDetailUserTypeClient(
                            item,
                            this.renderShowColumnChat(item)
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {groupUser === USER_TYPE_CLIENT &&
                  openModal === MODAL_REPORT_LIST_BY_REQUEST &&
                  modalValue &&
                  modalValue.id === item.id ? (
                    <GeneralModal
                      className={classes.modalReportListByRequestWrapper}
                      open={!!openModal}
                      handleClose={() => this.handleOpenModal("", null)}
                      hideFooter={true}
                      title={" "}
                      // iconCloseModal={<span>&#10005;</span>}
                      content={
                        <DetailRowRequestList
                          dataRow={item}
                          colspan={1}
                          listShowTypeGrid={true}
                          currency={this.props.currency}
                        />
                      }
                    />
                  ) : (
                    ""
                  )}
                </Accordion>
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      );
    };
    return (
      <div className={classes.requestPage}>
        {this.state.isLoading && <LoadingAction />}
        <div
          className={`headerRequestPage ${
            groupUser === USER_TYPE_CLIENT ? "headerRequestPageClient" : ""
          }`}
        >
          {[
            USER_TYPE_ADMIN,
            USER_TYPE_SUPER_ADMIN,
            USER_TYPE_REPAIRER,
          ].includes(groupUser) && location.pathname === ADMIN_REQUEST ? (
            <RequestHeaderAdmin
              statusCode={statusFilter}
              changeStatusClick={this.changeStatusFilter}
              changeStatusToAllFilter={() => this.changeAllStatusFilter()}
              apiParamsList={apiParamsNew}
            />
          ) : (
            <NavBar2
              countRequestNotPaid={countRequestNotPaid}
              apiParams={apiParamsNew}
              handleChangeApiParams={this.handleChangeApiParams}
              getNewItem={this.getNewItem}
            />
          )}
        </div>

        {[USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_REPAIRER].includes(
          groupUser
        ) && (
          <div className={classes.headerForAdmin}>
            <div className={classes.datePicker}>
              <Tooltip title={t("request.list.startDate")} arrow>
                <AppKeyboardDatePicker
                  className={"startDate"}
                  value={startDate}
                  maxDate={endDate}
                  onChange={(e) => this.setDateFilter(e, "startDate")}
                  todayLabel={false}
                />
              </Tooltip>
              <span className={"divider"}></span>
              <Tooltip title={t("request.list.endDate")} arrow>
                <AppKeyboardDatePicker
                  className={"endDate"}
                  value={endDate}
                  minDate={startDate}
                  onChange={(e) => this.setDateFilter(e, "endDate")}
                  todayLabel={false}
                />
              </Tooltip>
            </div>
            <div className={classes.pageType}>
              <div
                onClick={() => this.setState({ pageType: GRID })}
                className={`iconType ${
                  pageType === GRID ? classes.activePageType : ""
                }`}
              >
                <CustomInlineSvg
                  svgProps={{
                    src: gridIcon,
                  }}
                />
              </div>
              <div
                onClick={() => this.setState({ pageType: LIST })}
                className={`iconType ${
                  pageType === LIST ? classes.activePageType : ""
                }`}
              >
                <CustomInlineSvg
                  svgProps={{
                    src: listIcon,
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="bodyRequestPage">
          {groupUser === USER_TYPE_CLIENT &&
            pageType === GRID &&
            this.props.width === XS && (
              <Grid xs={12} container>
                <Grid xs={6} item className={classes.filterClientGrid}>
                  <CustomInput
                    className={"searchInput"}
                    placeholder={t("request.list.id")}
                    onChange={(e) => this.handSearchGrid(e, "id")}
                    // onKeyPress={(e)=>this.handSearchGrid(e, e.target.value, "search")}
                    value={filterGrid.id}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon onClick={() => this.getData(true)} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={6} item className={classes.filterClientGrid}>
                  <CustomInput
                    className={"searchInput"}
                    placeholder={t("request.list.date")}
                    onChange={(e) => this.handSearchGrid(e, "createdDate")}
                    // onKeyPress={(e)=>this.handleChangeApiParam(e, e.target.value, "search")}
                    value={filterGrid.createdDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon onClick={() => this.getData(true)} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}
          {this.state.loadingList ? (
            ""
          ) : pageType === LIST ? (
            <List
              {...other}
              rowDetail={
                groupUser === USER_TYPE_CLIENT
                  ? (e) => (
                      <DetailRowRequestList
                        dataRow={e.row}
                        colspan={columns.length + 1}
                        currency={this.props.currency}
                      />
                    )
                  : undefined
              }
              toggleCellComponent={(restProps) => (
                <ToggleCellComponent
                  {...restProps}
                  groupUser={groupUser}
                  t={t}
                />
              )}
              componentState={componentState}
              forceUpdate={this.state.forceUpdate}
              className={`${classes.requestListWrapper} ${
                groupUser === USER_TYPE_CLIENT ? "" : "listManagerWrapper"
              }`}
              callbackWidth={this.callbackWidth}
              customPaginate={groupUser === USER_TYPE_CLIENT}
              noResultText={
                groupUser === USER_TYPE_CLIENT ? t("list.noResultCustom") : null
              }
              defaultSorting={{ createdDate: "desc" }}
              expandedListIds={this.state.expandedRowIds}
              expandedRowIds={true}
              onChangeExpandedListIds={(data) => {
                this.setState({
                  expandedRowIds: data,
                });
              }}
              onCurrentPageChange={() => {
                this.setState({
                  expandedRowIds: [],
                });
              }}
            />
          ) : pageType === GRID ? (
            <List
              {...this.props}
              forceUpdate={this.state.forceUpdate}
              gridList={true}
              componentState={{
                apiParams: apiParamsNew,
                apiNameList: REQUEST_LIST,
              }}
              gridComponent={gridComponent}
            />
          ) : (
            ""
          )}
          {anchorEl &&
            formValuePopover &&
            popoverType === POPOVER_CHANGE_STATUS &&
            changeStatus && (
              <PopoverEditStatus
                popoverAriaOwns={POPOVER_CHANGE_STATUS + formValuePopover.id}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopOver}
                formValuePopover={this.state.formValuePopover}
                changeStatus={changeStatus}
                handleChangeField={this.handleChangeField}
                handleSave={this.changeStatus}
                errors={errors}
                price={this.state.price}
                comment={this.state.comment}
              />
            )}
          {anchorEl && formValuePopover && popoverType === POPOVER_ADD_NOTE && (
            <PopoverAddNote
              popoverAriaOwns={POPOVER_ADD_NOTE + formValuePopover.id}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClosePopOver}
              noteForRequest={noteForRequest}
              formValuePopover={formValuePopover}
              handleChangeField={this.handleChangeField}
              handleSave={this.addNote}
              errors={errors}
            />
          )}
          {anchorEl && formValuePopover && popoverType === POPOVER_ADD_CHAT && (
            <PopoverAddChat
              anchorEl={this.state.anchorEl}
              onClose={this.handleClosePopOver}
              noteForRequest={noteForRequest}
              formValuePopover={formValuePopover}
            />
          )}

          {anchorEl &&
            formValuePopover &&
            popoverType === POPOVER_EDIT_ACTION_STATUS_ARCHIVE_REPEAT && (
              <PopoverEditActionStatusArchiveRepeat
                popoverAriaOwns={
                  POPOVER_EDIT_ACTION_STATUS_ARCHIVE_REPEAT +
                  formValuePopover.id
                }
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopOver}
                formValuePopover={formValuePopover}
                handleSave={(chooseStatus) =>
                  this.openDialogFormSave(chooseStatus, formValuePopover)
                }
              />
            )}
          {anchorEl &&
            formValuePopover &&
            popoverType === POPOVER_READ_OR_UNREAD_CHAT && (
              <PopoverReadOrUnReadMessageChat
                onClose={this.handleClosePopOver}
                anchorEl={anchorEl}
                isStatusUnRead={formValuePopover.isNotRead}
                requestId={formValuePopover.id}
                unReadMessageId={
                  !formValuePopover.isNotRead
                    ? NOT_READ_LAST_MESSAGE_CHAT
                    : null
                }
                submitReadOrUnReadMessage={() => {
                  this.handleClosePopOver();
                  if (
                    Array.isArray(countChatNotSeen) &&
                    !countChatNotSeen.includes(formValuePopover.id) &&
                    !formValuePopover.isNotRead
                  ) {
                    this.props.setCountChatNotSeen([
                      ...countChatNotSeen,
                      formValuePopover.id,
                    ]);
                  } else if (
                    formValuePopover.isNotRead &&
                    Array.isArray(countChatNotSeen) &&
                    countChatNotSeen.includes(formValuePopover.id)
                  ) {
                    const notSeenNew = countChatNotSeen.filter((item) => {
                      return item !== formValuePopover.id;
                    });
                    if (notSeenNew.length < 1) {
                      let urlParams = qs.parse(location.search, {
                        ignoreQueryPrefix: true,
                      });
                      if (
                        urlParams.hasOwnProperty("filters") &&
                        (urlParams["filters"].hasOwnProperty(
                          "managerSawClientLastChat"
                        ) ||
                          urlParams["filters"].hasOwnProperty(
                            "clientSawManagerLastChat"
                          ))
                      ) {
                        delete urlParams["filters"]["managerSawClientLastChat"];
                        delete urlParams["filters"]["clientSawManagerLastChat"];
                        this.props.history.push(
                          location.pathname + "?" + qs.stringify(urlParams)
                        );
                        this.setState({ loadingList: true });
                      }
                    }
                    this.props.setCountChatNotSeen(notSeenNew);
                  }
                }}
              />
            )}

          {anchorEl &&
            formValuePopover &&
            popoverType === POPOVER_REPORT_PAID_BY_REQUEST && (
              <PopoverShowReportPaidByRequest
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopOver}
                formValuePopover={formValuePopover}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          {/*{anchorEl && formValuePopover && popoverType === POPOVER_CATEGORY_REQUEST &&*/}
          {/*<PopoverAddCategory popoverAriaOwns={POPOVER_CATEGORY_REQUEST + formValuePopover.id}*/}
          {/*                    anchorEl={this.state.anchorEl}*/}
          {/*                    onClose={this.handleClosePopOver}*/}
          {/*                    formValuePopover={formValuePopover}*/}
          {/*                    handleChangeField={this.handleChangeField}*/}
          {/*                    handleSave={this.addCategoriesForRequest}*/}
          {/*                    errors={errors}*/}
          {/*                    categories={this.state.categories}*/}
          {/*/>*/}
          {/*}*/}
        </div>
        {openDetail && (
          <GeneralModal
            className={"problemModal"}
            open={openDetail}
            handleClose={this.handleCloseModalDetail}
            title={
              <ProblemTitleModal
                id={dataDetailProblem.id ?? null}
                status={dataDetailProblem ? dataDetailProblem.status : null}
                title={
                  ([
                    USER_TYPE_ADMIN,
                    USER_TYPE_SUPER_ADMIN,
                    USER_TYPE_REPAIRER,
                  ].includes(groupUser) &&
                    dataDetailProblem &&
                    dataDetailProblem.title) ??
                  null
                }
                payment={
                  (dataDetailProblem && dataDetailProblem.payment) ?? null
                }
                category={[
                  USER_TYPE_ADMIN,
                  USER_TYPE_SUPER_ADMIN,
                  USER_TYPE_REPAIRER,
                ].includes(groupUser)}
                categories={
                  (dataDetailProblem && dataDetailProblem.categories) ?? null
                }
                assignStaff={
                  (dataDetailProblem.assignStaff &&
                    dataDetailProblem.assignStaff.person) ??
                  null
                }
                handleChangeField={this.handleChangeField}
                registrationStart={
                  (dataDetailProblem && dataDetailProblem.registrationStart) ??
                  null
                }
              />
            }
            content={
              <ProblemContentModal
                dataDetailProblem={dataDetailProblem}
                zoomIn={true}
              />
            }
            enableOnClose={false}
            saveButton={
              [
                USER_TYPE_ADMIN,
                USER_TYPE_SUPER_ADMIN,
                USER_TYPE_REPAIRER,
              ].includes(groupUser) && categoryModal
            }
            cancelButton={
              [
                USER_TYPE_ADMIN,
                USER_TYPE_SUPER_ADMIN,
                USER_TYPE_REPAIRER,
              ].includes(groupUser) && categoryModal
            }
            handleSave={this.addCategoriesForRequest}
          />
        )}
        {editRequestModal && (
          <GeneralModal
            open={editRequestModal}
            handleClose={() => this.handleCloseModal("editRequestModal")}
            content={
              <UploadFile
                initialData={dataEditRequest}
                checkboxSendSmsAndVoice={true}
              />
            }
            error={errorEditRequest}
            handleSave={() => this.submitEditRequest(dataEditRequest.idRequest)}
            title={
              <div className={classes.editRequest}>
                {t("modal.editRequest")}
              </div>
            }
          />
        )}
        {openClientModal && (
          <GeneralModal
            className={classes.clientModal}
            open={openClientModal}
            handleClose={() => this.handleCloseModal("openClientModal")}
            title={
              <ClientTitleModal
                dataClient={dataClient}
                dataUser={dataUser}
                updateList={() => this.setState({ forceUpdate: true })}
                setUploadFileToken={(uploadFileToken) =>
                  this.setState({
                    dataClient: {
                      ...dataClient,
                      uploadFileToken: uploadFileToken,
                    },
                  })
                }
              />
            }
            // content={<ClientContentModal dataClient={dataClient}/>}
            content={
              <ViewRequest
                viewType={VIEW_TYPES.SUMMARY}
                updateList={() => this.setState({ forceUpdate: true })}
                directInfo={this.state.directInfo}
                valueForm={dataClient}
                setDataClient={(newValueItem)=> {
                  if(typeof newValueItem === "object" && newValueItem) {
                    this.setState({dataClient: newValueItem})
                  }
                }}
              />
            }
            saveButton={false}
            cancelButton={false}
            hideFooter={true}
          />
        )}
        {openSetDate && (
          <GeneralModal
            className={classes.setDateModal}
            open={openSetDate}
            handleClose={() => this.handleCloseModal("openSetDate")}
            enableOnClose={false}
            title={
              <span className={classes.titleSelectDate}>
                {t("information.formTab2.selectDate")}
              </span>
            }
            content={
              <CustomCalendar
                dataDateTime={dataChangeDate}
                closeModal={() => this.handleCloseModal("openSetDate")}
                callBackFromCalendar={this.callBackFromCalendar}
                admin={groupUser !== USER_TYPE_CLIENT}
              />
            }
            cancelButton={false}
            saveButton={false}
            hideFooter={true}
          />
        )}
        {openModal && openModal === MODAL_ASSIGN_REPAIRER && (
          <ModalAddRepairer
            openModal={openModal}
            modalValue={modalValue}
            handleCloseModal={() => this.handleCloseModal("openModal")}
            addForRequest={true}
            setForceUpdate={() => this.setState({ forceUpdate: true })}
          />
        )}
        {openModal && openModal === MODAL_ALERT_USE_SMARTPHONE && (
          <ModalAlertUseSmartPhone
            openModal={openModal}
            // modalValue={modalValue}
            handleCloseModal={() => this.handleCloseModal("openModal")}
            // addForRequest={true}
          />
        )}
        {openViewNote && (
          <GeneralModal
            className={classes.viewNoteModal}
            open={openViewNote}
            handleClose={() => this.handleCloseModal("openViewNote")}
            enableOnClose={false}
            title={
              <span className={classes.titleViewNote}>
                {dataViewNote &&
                  dataViewNote.categories &&
                  dataViewNote.categories.label}
              </span>
            }
            content={
              <NoteContentModal
                data={dataViewNote}
                noteForRequest={noteForRequest}
                formValuePopover={formValuePopover}
                callBackAddNote={this.callBackAddNote}
                // handleChangeField={this.handleChangeField}
                // errors={errors}
              />
            }
            cancelButton={false}
            saveButton={false}
          />
        )}
        {this.state.dialogForm && (
          <DialogForm
            styleNumber={2}
            dialogProps={{
              open: this.state.dialogForm,
            }}
            disagreeButtonProps={{
              handleDisagree: () =>
                this.setState({ dialogForm: false, idDialogForm: null }),
            }}
            agreeButtonProps={{
              handleAgree: () =>
                this.changeStatus({
                  changeStatus: STOP,
                  id: this.state.idDialogForm,
                }),
            }}
            messageProps={{
              content: (
                <div className={classes.messageDialog}>
                  <div className={"icon"}>
                    <img src={stopActive} alt="" />
                    <span>{this.props.t("request.status.STOP")}</span>
                  </div>
                  {this.props.t("request.list.stopRequest")}
                </div>
              ),
            }}
          />
        )}

        {!!this.state.dialogFormSave && (
          <DialogForm
            styleNumber={2}
            dialogProps={{
              open: !!this.state.dialogFormSave,
            }}
            disagreeButtonProps={{
              handleDisagree: () => this.setState({ dialogFormSave: null }),
            }}
            agreeButtonProps={{
              handleAgree: () =>
                this.changeArchiveRepeatRequest(this.state.dialogFormSave),
            }}
            // messageProps={{
            //     content: <div className={classes.messageDialog}>
            //         <div className={'icon archive23'}>
            //             {this.state.dialogFormSave.archivedByAdmin ? <BookmarkBorderIcon /> : <CustomSvg
            //                 svgProps={{
            //                     src: BookmarkIcon,
            //                 }}
            //             />}
            //             {t("request.list.id")} : {this.state.dialogFormSave.id}
            //         </div>
            //         {this.state.dialogFormSave.archivedByAdmin ?  this.props.t('request.list.unarchiveRequest') : this.props.t('request.list.archiveRequest')}
            //     </div>
            // }}
          />
        )}
        {openModalFiles && requestId && (
          <GeneralModal
            className={classes.filesModal}
            open={openModalFiles}
            handleClose={() => this.handleCloseModal("openModalFiles")}
            content={
              <FilesUploadBlock
                dataClient={dataClient}
                requestId={requestId}
                checkNewDoc={newDocumentByManager}
              />
            }
            saveButton={false}
            cancelButton={false}
            hideFooter={true}
            iconCloseModal={
              <div className="closeIcon">
                <CloseIcon />
              </div>
            }
          />
        )}
        <AlertCustom
          successOpen={this.state.successOpen}
          errorOpen={this.state.errorOpen}
          handleCloseNotice={this.handleCloseNotice}
          messageSuccess={this.state.messageSuccess ?? ""}
          messageError={this.state.messageError ?? ""}
        />
        <Snackbar
          open={!!this.state.notificationChat}
          autoHideDuration={1000}
          onClose={() => {
            this.setState({
              notificationChat: null,
            });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({
                notificationChat: null,
              });
            }}
            severity="info"
            sx={{ width: "100%" }}
          >
            {t("chat.chatBox.hasNewMessage", {
              id: this.state.notificationChat,
            })}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

RequestPage.defaultProps = {};

RequestPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataUser: state.authReducer.dataUser,
    groupBaseUser: state.authReducer.groupBaseUser,
    showAllNote: state.applicationReducer.showAllNote,
    showNoteCell: state.applicationReducer.showNoteCell,
    currency: state.applicationReducer.currency,
    problemRequest: state.applicationReducer.problemRequest,
    requestDocumentManagerNotSeen:
      state.applicationReducer.requestDocumentManagerNotSeen,
    countChatNotSeen: state.applicationReducer.countChatNotSeen,
    countPaymentRequestReport:
      state.applicationReducer.countPaymentRequestReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowAllNote: (boolean) =>
      dispatch(applicationActions.setShowAllNote(boolean)),
    setShowNoteCell: (rowId, action) =>
      dispatch(applicationActions.setShowNoteCell(rowId, action)),
    getCountRequestByStatus: (apiParamCount) =>
      dispatch(applicationAction.getCountRequestByStatus(apiParamCount)),
    getCountReportRequestNotPaid: () =>
      dispatch(applicationAction.getCountReportRequestNotPaid()),
    getCountChatNotSeen: (apiParams) =>
      dispatch(applicationAction.getCountChatNotSeen(apiParams)),
    getCountPaymentRequestReport: (apiParams) =>
      dispatch(applicationAction.getCountPaymentRequestReport(apiParams)),
    setCountPaymentRequestReport: (value) =>
      dispatch(applicationAction.setCountPaymentRequestReport(value)),
    getRequestDocumentManagerNotSeen: (apiParams) =>
      dispatch(applicationAction.getRequestDocumentManagerNotSeen(apiParams)),
    getCountUpcomingAppointment: () =>
      dispatch(applicationAction.getCountUpcomingAppointment()),
    addValueToRequestDocumentManagerNotSeenRedux: (value) =>
      dispatch(
        applicationAction.addValueToRequestDocumentManagerNotSeenRedux(value)
      ),
    setIsLoading: (isLoading) =>
      dispatch(applicationAction.setIsLoading(isLoading)),
    setCountChatNotSeen: (value) =>
      dispatch(applicationAction.setCountChatNotSeen(value)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  withRouter,
  withWidth()
)(RequestPage);
