import moment from "moment";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

const CurrentTime = ({ t }) => {
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      {date.format("DD/MM/YYYY HH:mm")}
    </>
  );
};
export default withTranslation()(CurrentTime);
