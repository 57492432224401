import { withStyles} from "@material-ui/core/styles";
import { Grid, withWidth} from "@material-ui/core";
import CustomSvg from "../../../../theme/CustomElements/CustomSvg";
import React from "react";
import InfoForm from "./InfoForm";
import InfoSummary from "./InfoSummary";
import PropTypes from "prop-types";
import * as application from "../../../../_actions/application";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {NUNITO} from "../../../../constants/fontCss";
import ProblemStepIcon from "./../../../../images/requestNew/problem_step.svg";
import InfoStepIcon from "./../../../../images/requestNew/info_step.svg";
import UploadFile from "../../../../theme/Modal/UploadFile";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import RequestNew from "../RequestNew";
import {API_GET_SHOW_REQUEST} from "../../../../constants/apiSuffix";
import axios from "axios";
import LoadingAction from "../../../../theme/LoadingAction";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";
import {FALSE_NUMBER, TRUE_NUMBER, USER_TYPE_CLIENT, VIEW_TYPES, XS} from "../../../../constants/constants";
import AdminInfoSummary from "./AdminInfoSummary";
const styles = theme => ({
    ViewRequestWrapper: {
        height: '100%',
        padding: '20px 40px',
        '& .ViewRequestHeader': {
            height: 80,
            '& .progress': {
                height: 12,
                backgroundColor: '#e8eaf3',
                borderRadius: 7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                '&.successProgress':{
                    backgroundColor: '#00fcb7',
                },
                '& .progressItem': {
                    height: '100%',
                    flexGrow: 1,
                    // height: 1200,
                    // transform: 'rotate(45deg)',
                    '&.active': {
                        backgroundColor: '#0022ff',
                        position: 'relative',
                        '&:after': {
                            content: `''`,
                            width: 0,
                            height: 0,
                            borderTop: '0px solid transparent',
                            borderBottom: '12px solid transparent',
                            borderLeft: '12px solid #0022ff',
                            position: 'absolute',
                            right: -12,
                            top: 0,
                        },
                        '&.index_1': {
                            '&:after': {
                                left: -12,
                                right: 0,
                                borderBottom: '12px solid #0022ff',
                                borderLeft: '12px solid transparent',
                            }
                        },
                    },
                }
            },
            '& .step': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .stepItem': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '12px 10px 25px 10px',
                    color: '#a8adcf',
                    cursor: 'pointer',
                    flexGrow: 1,
                    [theme.breakpoints.down(XS)]:{
                        '& .label':{
                            display: 'none'
                        },
                    },
                    '&.active' : {
                        color: '#0022ff',
                        '& svg': {
                            '& path:first-child': {
                                fill: 'none!important',
                                stroke: '#0022ff!important',
                            },
                            '& path': {
                                fill: '#0022ff!important',
                                stroke: '#0022ff!important',
                            }
                        }
                    },
                    '& svg': {
                        marginRight: 20,
                        '& path:first-child': {
                            fill: 'none!important',
                            stroke: '#a8adcf!important',
                        },
                        '& path': {
                            fill: '#a8adcf!important',
                            stroke: '#a8adcf!important',
                        }
                    },
                    '&:hover':{
                        '& .label':{
                        color: '#7076a2',
                        },
                        '& svg path':{
                        color: '#7076a2',
                        fill: '#7076a2 !important',
                        stroke:'#7076a2 !important',
                        strokeWidth: '.4px',
                        }

                    }
                }
            }
        },
        '& .ViewRequestBody': {
            height: 'calc(100% - 80px)',
            overflowY: 'auto',
            '& .viewBody': {
                display: 'none',
                '& .errorLabel': {
                    fontWeight: 700,
                    fontSize: '0.9rem'
                },
                '&.active': {
                    display: 'block'
                },
                '& .successMessage': {
                    backgroundColor: '#eaf1fb',
                    padding: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& .textMessage': {
                        color: '#0000ff',
                        fontSize: '1.5rem',
                        fontWeight: 600,
                        padding: '40px 0',
                        textAlign: 'center',
                        maxWidth: 240,
                        margin: 'auto',
                        lineHeight: '1.8rem',
                    },
                    '& .repairerMessage': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '30px',

                        '& img': {
                            paddingRight: '15px',
                        },
                    },
                    '& .btnClose': {
                        border: 'solid 1px #707070',
                        padding: '5px 30px',
                        textTransform: 'unset',
                    }
                }
            }
        },
        '& .ViewRequestFooter': {
            padding: '20px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent:'space-between',
            '& .checkboxAccept': {
                display: 'flex',
                alignItems: 'flex-start',
                '& .labelCheckBox': {
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 12,
                    fontFamily: NUNITO,
                    fontSize: '0.875rem',
                    color: '#001034',
                },
                '& .MuiCheckbox-root path':{
                    color: '#0000ff !important',
                },
            },
            '& .btnContinue': {
                backgroundColor: '#0000ff',
                color: '#fff',
                padding: '16px 50px',
                textTransform: 'initial',
                marginLeft: 'auto',
                fontSize: '1.1rem',
                fontWeight: 'bold',
                lineHeight: '1.2rem',
                '& span':{
                    position: 'relative',
                    left: 0,
                    transition: '0.2s ease',
                },

                '&:hover':{
                    backgroundColor: '#1000d2',

                    '& span':{
                        left: 8,
                        transition: '0.2s ease',
                    }
                }
            }
        }
    },
    messageCloseForm: {

    },
    requestNewModal: {
        width: 1200,
        maxWidth: 'calc(100% - 60px)',
        backgroundColor: '#f5f7fd',
        '& .bodyModalWrapper': {
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 9,
                height: 9
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#0000ff',
                borderRadius: 5
            },
        },

        [theme.breakpoints.down(XS)]:{
            maxWidth: 'calc(100% - 10px)',
        }
    },
})
const PROBLEM_STEP = 'PROBLEM_STEP';
const INFO_STEP = 'INFO_STEP';
const SUCCESS_STEP = 'SUCCESS_STEP'

const listStep = [
    {
        code: PROBLEM_STEP,
        icon: ProblemStepIcon,
        label: 'request.tab1RequestNew'
    },
    {
        code: INFO_STEP,
        icon: InfoStepIcon,
        label: 'request.tab2RequestNew'
    }
]
class ViewRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepActive: (props.directInfo) ? INFO_STEP : PROBLEM_STEP,
            viewType: props.viewType || VIEW_TYPES.ACCORDION,
            openTabInfoFormDefault: '',
            openModalEditRequest: null,
            stepActiveDefault: "",
            valueForm:{},
            isLoading: false,
            successOpen: false
        };
    }

    getDataRequest = () => {
        const {valueForm} = this.props
        this.setState({
            isLoading: true
        })
        if (valueForm && parseInt(valueForm.id)) {
            axios.get(API_GET_SHOW_REQUEST.replace(":id", valueForm.id))
                .then(response => {
                    if (response.status === 200) {
                        const dataItem = (response?.data?.item) ?? {};
                        this.setState({
                            valueForm: {...dataItem},
                            isLoading: false
                        }, function (){
                            if(typeof this.props.setDataClient === "function" && dataItem.id){
                                this.props.setDataClient({...dataItem})
                            }
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.setState({
                        isLoading: false
                    })
                })
        }
    }

    componentDidMount() {
        this.getDataRequest()
    }

    setStepActive = (stepActive) => {
        this.setState({
            stepActive: stepActive
        })
    }

    onCloseModal =()=>{
        this.setState({
            openModalEditRequest: null
        })
    }
    render() {
        const {
            stepActive,
            openTabInfoFormDefault,
            openModalEditRequest,
            stepActiveDefault,
            error,
            valueForm,
            isLoading
        } = this.state;
        const {
            classes,
            t
        } = this.props;

        // const adminExpertAuth =  !(valueForm?.person);
        const adminExpertAuth = (valueForm?.id && valueForm.isAdminOrExpertCreated) || (!valueForm?.id && this.props.groupBaseUser !== USER_TYPE_CLIENT);
        return ( isLoading ? <LoadingAction isLoadingBlock={true}/> :
            <div className={classes.ViewRequestWrapper}>
                <div className="ViewRequestHeader">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={"progress" + (stepActive === SUCCESS_STEP ? " successProgress": "")}>
                                {
                                    listStep.map((step, index) => {
                                        return (
                                            <div key={index} className={"progressItem " +  ("index_" + index) + (step.code === stepActive ? " active" : "")}>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="step">
                                {
                                    listStep.map((step, indexStep) => {
                                        return (
                                            <div key={indexStep} className={"stepItem" + (step.code === stepActive ? " active" : "")} onClick={() => {
                                                if (stepActive !== SUCCESS_STEP) {
                                                    this.setStepActive(step.code);
                                                }
                                            }}>
                                                <CustomSvg
                                                    svgProps={{
                                                        src: step.icon,
                                                    }}
                                                />
                                                <div className="label">
                                                    {t(step.label)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="ViewRequestBody">
                    <div className={"viewBody" + (stepActive === PROBLEM_STEP ? " active" : "")}>
                        <UploadFile isModalShowClientDetail={true} valueForm={valueForm} updateList={this.props.updateList} checkboxSendSmsAndVoice={true}
                                    handleEditValue = {()=>this.setState({openModalEditRequest: PROBLEM_STEP, stepActiveDefault:PROBLEM_STEP})}
                        />
                    </div>
                    <div className={"viewBody" + (stepActive === INFO_STEP ? " active" : "")}>
                        {this.state.viewType === VIEW_TYPES.ACCORDION ?
                            <InfoForm isModalShowClientDetail={true} valueForm={{
                                ...valueForm,
                                isThereAnElevator: valueForm.isThereAnElevator ? TRUE_NUMBER : FALSE_NUMBER
                            }}/>
                            : (adminExpertAuth ? <AdminInfoSummary isModalShowClientDetail={true} valueForm={{
                                    ...valueForm,
                                    isThereAnElevator: valueForm.isThereAnElevator ? TRUE_NUMBER : FALSE_NUMBER
                                }} handleEditValue={(openTabInfoFormDefault) => this.setState({
                                    openModalEditRequest: INFO_STEP,
                                    stepActiveDefault: INFO_STEP,
                                    openTabInfoFormDefault: openTabInfoFormDefault
                                })}/> :
                                <InfoSummary isModalShowClientDetail={true} valueForm={{
                                    ...valueForm,
                                    isThereAnElevator: valueForm.isThereAnElevator ? TRUE_NUMBER : FALSE_NUMBER
                                }} handleEditValue={(openTabInfoFormDefault) => this.setState({
                                    openModalEditRequest: INFO_STEP,
                                    stepActiveDefault: INFO_STEP,
                                    openTabInfoFormDefault: openTabInfoFormDefault
                                })}/>)
                        }
                    </div>
                </div>
                {!!(openModalEditRequest) && <GeneralModal
                    open={true}
                    hideHeaderModal={true}
                    content={<RequestNew onClose={() => this.onCloseModal()} loadRequest={(newItem) => {
                        this.setState({
                            openModalEditRequest: null,
                            successOpen: true
                        })
                        this.getDataRequest()}}
                                         statusTypeRequest= {( valueForm?.status?.statusType?.code) ? valueForm.status.statusType.code:""}
                                         stepActiveDefault={stepActiveDefault}
                                         removeStepActionDefault={() => this.setState({stepActiveDefault: ""})}
                                         openTabInfoFormDefault={openTabInfoFormDefault}
                                         removeOpenTabInfoFormDefault={() => this.setState({openTabInfoFormDefault: ""})}
                                         editRequestById={valueForm.id}
                    />}
                    saveButton={false}
                    cancelButton={false}
                    enableOnClose={false}
                    error={error}
                    className={classes.requestNewModal}
                />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    handleCloseNotice={() => {
                        this.setState({
                            successOpen: true
                        })
                    }}
                    messageError={this.state.messageError}
                />
            </div>
        )
    }
}
ViewRequest.defaultProps = {
    updateList: ()=>{}
}

ViewRequest.propTypes = {
    classes: PropTypes.object.isRequired,
    updateList: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser,
        dataTab2: state.applicationReducer.dataTab2,
        groupBaseUser: state.authReducer.groupBaseUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorAlert: (successOpenAlert, message) => dispatch(application.setErrorAlert(successOpenAlert, message)),
        setDataFormTab2: (dataTab2, field) => dispatch(application.setDataFormTab2(dataTab2, field))
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(ViewRequest);
