import React, { Component } from "react";
import Dropzone from "react-dropzone";
import titleModal from "../../images/theme/titleUpload.svg";
import { CircularProgress, withStyles, withWidth } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import PropTypes from "prop-types";
import { NUNITO } from "../../constants/fontCss";
// import pictureUpload from "../../images/theme/pictureUpload.svg"
import CustomButton from "../CustomElements/CustomButton";
import sendImage from "../../images/theme/send.svg";
import detailIcon from "../../images/request/pencil2.svg";
import subjectIcon from "../../images/request/subject.svg";
// import PreviewComponent from "./PreviewComponent";
import { connect } from "react-redux";
import {
  getDocumentRequestValid,
  setProblemDescribe,
} from "../../_actions/application";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import {DEFAULT_LANGUAGE} from "../../i18n";
import i18next from "i18next";
import {
  // SIGN_UP,
  SM,
  // STRING,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
  USER_TYPE_REPAIRER,
  USER_TYPE_SUPER_ADMIN,
  WAITING_FOR_REPLY,
  XS,
} from "../../constants/constants";
import CameraAltRoundedIcon from "@material-ui/icons/CameraAltRounded";
// import userIcon from "../../images/profile/user.svg";
// import excelIcon from "../../images/staff/excel.svg";
// import docIcon from "../../images/staff/doc.svg";
// import DocumentPdfIon from "../../images/staff/documentPdf.svg";
// import {convertByteToNumberUnit} from "../../functions/functions";
import CustomInlineSvg from "../CustomElements/CustomInlineSvg";
// import deleteIcon from "../../images/staff/delete.svg";
// import uploadFileIcon from "../../images/profile/uploadFile.svg";
import CustomSvg from "../CustomElements/CustomSvg";
import playButton from "../../images/theme/playButton.svg";
import zoomIn from "../../images/theme/zoomIn.svg";
import GeneralModal from "./GeneralModal";
// import ContentPreviewModal from "./ContentPreviewModal";
import ContentPreviewModal2 from "./ContentPreviewModal2";
import closeButton from "../../images/theme/close.svg";
import DocIcon from "../../images/request/doc.svg";
import DownloadDoc from "../../images/request/download-arrow.svg";
// import _ from 'lodash';
import CustomInput from "../CustomElements/CustomInput";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import { API_EDIT_SUBJECT_REQUEST } from "../../constants/apiSuffix";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import AlertCustom from "../form/Alert/AlertCustom";
import { v4 as uuidv4 } from "uuid";
import checkIcon from "../../images/diagnostic/checked2.svg";
import Checkbox from "@material-ui/core/Checkbox";
import CGV_FILE from "../../docs/CGV.pdf";

const styles = (theme) => ({
  uploadFile: {
    //border: '1px solid #E0E0E0',
    borderRadius: 6,
    "& .subjectWrapper": {
      padding: "10px 15px",
      display: "flex",

      "& .MuiFormControl-root": {
        border: "0px",
      },

      "& input": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "1rem",
        padding: "10px 10px 10px 25px",
        background: "#fff",
        //border: '2px solid #eaeaf0',
        boxShadow: "1px 1px 3px -1px rgba(93,101,163,0.35)",

        "&::placeholder": {
          color: "rgb(26, 48, 89)",
        },
      },
      "&:hover": {
        "& .btnHiddenBtnEditSubject": {
          display: "flex !important",
        },
      },
      "& > div": {
        borderColor: "#c4c4c4",
      },
    },
    "& .ck.ck-editor": {
      margin: "7px 15px 0",
      //border: '2px solid #eaeaf0',
      boxShadow: "1px 1px 3px -1px rgba(93,101,163,0.35)",
      borderRadius: "6px",
    },

    "& .ck-placeholder::before": {
      color: "rgba(26, 48, 89, 0.39) !important",
      fontSize: "1rem",
      maxWidth: "800px",
      display: "block",
      lineHeight: "1.2rem",
    },

    "& .ck-content": {
      border: "0px",
    },

    "& .ck.ck-toolbar": {
      borderRadius: "6px 6px 0 0!important",
      border: "0px",
    },
    "& .ck-editor__editable": {
      height: "155px",
      borderBottomLeftRadius: "6px !important",
      borderBottomRightRadius: "6px !important",
      padding: "5px 25px",
      fontSize: "1rem",

      "& .ck-placeholder": {
        "&:before": {
          color: "#B6B6B6",
        },
      },
      "& p": {
        marginTop: "0px !important",
        marginBottom: "0px !important",
        "&:first-child": {
          marginTop: "15px !important",
        },
      },
    },
    "& .uploadBlock": {
      padding: "10px 15px",
      position: "relative",
      marginBottom: 10,
      "& .uploadBlock_title": {
        fontSize: "1.2rem",
        paddingTop: "15px",
        maxWidth: "700px",
        textAlign: "center",
        margin: "auto",
        //color: '#00f',
        //color: '#3b64a4',
        color: "#0028ff",
        lineHeight: "1.45rem",
      },
      "& .dzu-dropzone": {
        border: "1px dashed #BDBDBD",
        borderRadius: 4,
        overflow: "auto",
        minHeight: 61,
        flexDirection: "row",
        alignItems: "end",
        position: "initial",
        "& .dzu-inputLabel": {
          position: "initial",
          margin: "auto",
        },
      },

      [theme.breakpoints.down(XS)]: {
        "& div": {
          fontSize: "1rem",
          lineHeight: "1.2rem",
        },
      },
    },

    "& .titleType": {
      fontSize: "18px",
      padding: "17px 8px 0px 16px",
      fontWeight: "bold",
      //   color: '#3b3bfb',
      color: "#444",
      //  color: '#202d46',
      display: "flex",
      alignItems: "center",
      paddingBottom: "8px",
      "&.titleTypeObject:before": {
        content: "''",
        display: "block",
        width: "40px",
        height: "24px",
        paddingRight: "7px",
        maskImage: `url(${subjectIcon})`,
        maskRepeat: "no-repeat",
        maskSize: "contain",
        // backgroundColor: '#00c2ee',
        //    backgroundColor: '#324c53',
        backgroundColor: "#3b3bfb",
      },
      "&.titleTypeDetail:before": {
        content: "''",
        display: "block",
        width: "40px",
        height: "21px",
        paddingRight: "7px",
        maskImage: `url(${detailIcon})`,
        maskRepeat: "no-repeat",
        maskSize: "contain",
        // backgroundColor: '#00c2ee',
        //    backgroundColor: '#324c53',
        backgroundColor: "#3b3bfb",
      }
    },
    '& .sendSmsAndVoice': {
      display: 'flex',
      alignItems: 'center',
      fontSize: "16px",
      fontWeight: "bold",
      padding: "16px 16px 0 3px",
      color: "#444",
    },
    [theme.breakpoints.down(XS)]: {
      "& .ck-content": {
        fontSize: "0.95rem",
        lineHeight: "1.3rem",
      },
      "& .uploadBlock": {
        "& .uploadBlock_title": {
          fontSize: "0.975rem!important",
        },
      },
    },
  },
  titleUploadFile: {
    display: "flex",
    fontSize: "1.125rem",
    "& span": {
      fontWeight: 700,
      marginLeft: "9px",
    },
    [theme.breakpoints.down(XS)]: {
      fontSize: "0.875rem",
    },
  },
  uploadBlock_label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: `${NUNITO}`,
    padding: "0 0.5rem",
    cursor: "pointer",
    "& div": {
      color: "#7d7d7d",
      fontWeight: "normal",
      fontSize: "0.875rem",
      marginLeft: 10,
      "& span": {
        fontWeight: 700,
        color: "#0000ff",
      },
    },
    "& .iconCamera": {
      color: "#0000ff",
      width: "52px",
      height: "36px",
      display: "block",
    },
    [theme.breakpoints.down(XS)]: {
      "& div": {
        fontSize: "0.75rem",
      },
    },
  },
  submitUploadButton: {
    background: "#0000FF!important",
  },
  submitRequest: {
    display: "flex",
    textTransform: "none",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "1rem",
    "& p": {
      margin: "0 0 0 5.83px",
    },
  },
  checkHasFile: {
    "& .dzu-dropzone": {
      minHeight: "90px!important",
      maxHeight: "180px!important",
      alignItems: "baseline",
      marginBottom: "60px",
      flexWrap: "wrap",
      "& .dzu-previewContainer": {
        alignItems: "baseline",
      },
      "& label": {
        position: "absolute",
        bottom: "20px",
      },
      "& .dzu-inputLabelWithFiles": {
        marginLeft: 0,
        background: "#f3f3f3",
        "&:hover": {
          background: "#c1c1c1",
          transition: 0.5,
          "& div": {
            color: "#001529",
          },
        },
        [theme.breakpoints.down(XS)]: {
          padding: 0,
        },
      },
    },
    "& $errorFile": {
      position: "absolute",
      // left: '50%',
      // transform: 'translateX(-50%)',
      bottom: "-5px",
      width: "100%",
    },
  },
  errorFile: {
    color: "red",
    fontStyle: "italic",
    marginTop: 5,
  },
  circle: {
    textAlign: "center",
    margin: "5px",
  },
  showDocumentWrapper: {
    display: "flex",
    border: "1px dashed #BDBDBD",
    padding: "0.5rem 0rem",
    marginBottom: "1rem",
    flexWrap: "wrap",
    minHeight: "90px!important",
    maxHeight: "180px!important",
    overflowY: "auto",
    "& img, & video": {
      width: 100,
      height: 100,
      position: "absolute",
      zIndex: 0,
      objectFit: "contain",
    },
    "&:hover > .playButton": {
      opacity: 1,
      visibility: "visible",
      filter: "blur(4px)",
    },
    "& .playButton": {
      // content:'""',
      width: "100px",
      height: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // position:'absolute',
      zIndex: 1000,
      // top: '50%',
      // left: '50%',
      // transform: 'translateY(-50%) translateX(-50%)',
      opacity: 0,
      // visibility: 'hidden',
      "& svg": {
        fill: "#635151!important",
      },
      [theme.breakpoints.down(XS)]: {
        opacity: 1,
        visibility: "visible",
      },
    },
    "& .closeButton": {
      position: "absolute",
      top: 0,
      right: "5px",
      opacity: 0,
      visibility: "hidden",
      zIndex: 1001,
      "& svg": {
        background: "red",
        borderRadius: "50%",
        padding: "3px",
      },
    },
  },
  documentWrapper: {
    position: "relative",
    display: "flex",
    padding: "0.25rem",
    cursor: "pointer",
    "&:hover": {
      // filter: 'blur(4px)',
      "& .playButton": {
        opacity: 1,
      },
      "& .closeButton": {
        opacity: 1,
        visibility: "visible",
      },
      "& img, & video, & .doc": {
        filter: "blur(4px)",
      },
    },
    [theme.breakpoints.down(XS)]: {
      "& img, & video, & .doc": {
        filter: "blur(2px)",
      },
    },
  },
  dropzoneWrapper: {
    //background: '#f3f3f3',

    background: "#e9f4ff",
    border: "2px dashed #7f8cbf",
    marginTop: "16px",

    "& div": {
      color: "#4864b7 !important",
    },
    "&:hover": {
      backgroundColor: "#d8edff",
    },
    "& .documentsUploadListWrapper": {
      padding: 30,
      cursor: "pointer",
    },
  },
  previewImage: {
    width: "100%",
    height: "100%",
    // borderRadius: '20px',
    objectFit: "contain",
  },
  previewDoc: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    "& svg": {
      "& path": {
        stroke: "#0070c7",
      },
    },
  },
  previewVideo: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "cover!important",
    [theme.breakpoints.down(XS)]: {
      background: "#dedede",
    },
  },
  actionEditSubject: {
    display: "flex",
    maxWidth: 200,
    alignItems: "center",
    "&.btnHiddenBtnEditSubject": {
      display: "none",
    },
    "& > div": {
      "&.btnSave": {
        "& svg": {
          color: "#0022ff",
        },
      },
      padding: 3,
      borderRadius: 5,
      margin: 5,
      height: 30,
      cursor: "pointer",
      "&:hover": {
        background: "rgba(0,0,0,0.1)",
      },
    },
  },
  btnEditOnTitleLabel: {
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    "& > div": {
      "&.btnSave": {
        "& svg": {
          color: "#0022ff",
        },
      },
      marginLeft: 5,
      padding: 3,
      borderRadius: 5,
      height: 30,
      cursor: "pointer",
      "&:hover": {
        background: "rgba(0,0,0,0.1)",
      },
    },
  },
});

const TitleUploadFile = (props) => {
  const { classes, t } = props;
  return (
    <div className={classes.titleUploadFile}>
      <img alt={"title upload"} src={titleModal} />
      <span>{t("modal.upload.describeProblem")}</span>
    </div>
  );
};

export const TitleUpload = compose(
  withStyles(styles),
  withTranslation()
)(TitleUploadFile);

export const UploadBlock = (props) => {
  const { classes, t, width } = props;
  return (
    <div className={classes.uploadBlock_label}>
      {/*<img src={pictureUpload} alt={'picture label'}/>*/}
      <CameraAltRoundedIcon className={"iconCamera"} />
      <div>
        {[XS, SM].includes(width)
          ? t("modal.upload.attackLabelMobile")
          : t("modal.upload.attackLabel")}{" "}
        <span>{t("modal.upload.chooseThem")}</span>
      </div>
    </div>
  );
};

const submitUpload = (props) => {
  let { classes, t } = props;
  return (
    <CustomButton
      {...props}
      type="submit"
      className={classes.submitUploadButton}
      title={
        <div className={classes.submitRequest}>
          <img src={sendImage} alt={"send problem"} />
          <p>{t("modal.upload.submitRequest")}</p>
        </div>
      }
    />
  );
};
export const SubmitUploadButton = compose(
  withStyles(styles),
  withTranslation()
)(submitUpload);

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preparing: 0,
      done: 0,
      removed: 0,
      maxFile: false,
      errorFileType: false,
      errorMessage: "",
      problemRequest: props.initialData ?? {},
      initialFileUrl: [],
      arrayDocuments: props.onlyShowDocumentOld
        ? props.valueForm.documents ?? []
        : props.isModalShowClientDetail
        ? props.valueForm.documents
        : props.initialData && props.initialData.filesInitial
        ? props.initialData.filesInitial
        : [],
      openModal: false,
      arrayRemoveFiles: [],
      subject: "",
      managerEditSubject: false,
      errorOpen: false,
      successOpen: false,
    };
  }

  handleChangeStatus = (action, status, allFiles) => {
    let { preparing, done, maxFile, errorFileType, errorMessage /*, files*/ } =
      this.state;
    let { documentRequestType } = this.props;
    // console.log(action, status)
    if (status === "preparing") {
      preparing += 1;
      errorFileType = false;
    }
    if (status === "done") {
      done += 1;
    }
    if (status === "error_file_size") {
      preparing -= 1;
      action.remove();
      errorFileType = true;
      errorMessage = this.props.t("modal.upload.errorSize", {
        size:
          documentRequestType &&
          documentRequestType.size &&
          documentRequestType.size.maxSize,
      });
    }
    // if(status === "removed") preparing -= 1;
    if (status === "rejected_max_files") maxFile = true;
    if (status === "rejected_file_type") {
      errorFileType = true;
      errorMessage = this.props.t("modal.upload.errorType");
    }
    // console.log(preparing, done)
    if (done === preparing) {
      this.setState(
        {
          problemRequest: {
            ...this.state.problemRequest,
            files: allFiles,
          },
        },
        () => {
          this.props.setProblemDescribe(this.state.problemRequest);
        }
      );
    }
    this.setState({
      preparing: preparing,
      done: done,
      maxFile: maxFile,
      errorFileType: errorFileType,
      errorMessage: errorMessage,
    });
  };
  // checkFile = (file) => {
  //     let {errorFileType, errorMessage} = this.state
  //     if(file.meta.duration > 10){
  //         file.remove()
  //         errorFileType = true;
  //         errorMessage = this.props.t('modal.upload.errorVideo')
  //     }
  //     this.setState({
  //         errorFileType: errorFileType,
  //         errorMessage: errorMessage
  //     })
  // }
  problemDetail = (event, editor) => {
    const { isRequestNew } = this.props;
    let problem = editor.getData();
    if (isRequestNew) {
      this.props.handleChange(problem, "problem");
      // this.setState({
      //     problemRequest:{
      //         ...this.state.problemRequest,
      //         problemDetail : problem
      //     }
      // }, ()=>this.props.handleChange(problem, "problem"))
    } else {
      this.setState(
        {
          problemRequest: {
            ...this.state.problemRequest,
            problemDetail: problem,
          },
        },
        () => this.props.setProblemDescribe(this.state.problemRequest)
      );
    }
  };
  documentType = () => {
    let { documentRequestType } = this.props;
    // let type=""
    let types = [];
    documentRequestType["type"].forEach((itemFile) => {
      if (
        itemFile.hasOwnProperty("fileFormat") &&
        itemFile.hasOwnProperty("fileType")
      ) {
        itemFile.fileFormat.forEach((fileFormat) => {
          types.push(
            fileFormat === "MOV" || fileFormat === "mov"
              ? ".mov"
              : `${itemFile.fileType}/${fileFormat}`
          );
          // type = (fileFormat === "MOV" || fileFormat === "mov") ? `${type},.mov` : `${type},${itemFile.fileType}/${fileFormat}`
        });
      }
    });
    // return type = type.substr(1)
    return types.join(",");
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    const newState = {};
    const { maxFile /*, errorFileType, updateError*/ } = this.state;
    if (maxFile) newState.maxFile = false;
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }
  componentDidMount() {
    let { initialData, valueForm } = this.props;
    if (!!valueForm?.subject) {
      this.setState({
        subject: valueForm.subject,
      });
    }
    if (typeof this.props.setProblemDescribe === "function") {
      this.props.setProblemDescribe(this.state.problemRequest);
    }
    if (JSON.stringify(this.props.documentRequestType) === "{}") {
      this.props.getDocumentRequestValid();
    }
    if (initialData && initialData.filesInitial) {
      let initialFile = initialData.filesInitial;
      let initialFileUrl = [];
      initialFile.forEach((item) => {
        fetch(item.pathUrl)
          .then((res) => {
            res.arrayBuffer().then((buf) => {
              let file = new File([buf], item.fileName, {
                type: item.mimeType,
              });
              initialFileUrl.push(file);
              if (initialFileUrl.length === initialFile.length) {
                this.setState({
                  initialFileUrl,
                });
              }
            });
          })
          .catch((e) => {});
      });
    }
  }
  // getFile = async () => {
  //     let {initialData} = this.props
  //     if(initialData && initialData.filesInitial) {
  //         let initialFile = initialData.filesInitial
  //         let url = []
  //         initialFile.forEach(item => url.push(fetch(item.pathUrl)))
  //         console.log('co vo day')
  //         Promise.all(url).then(res=>{
  //             console.log(res)
  //             let initialFileUrl = []
  //             res.forEach((resChild, index)=>{
  //                 if(resChild.status === 200){
  //                     let fileName = resChild.url.split('/')
  //                     fileName = fileName[fileName.length - 1]
  //                     console.log(fileName)
  //                     resChild.arrayBuffer().then(buf => {
  //                         let file = new File([buf], fileName)
  //                         initialFileUrl.push(file)
  //                         console.log(initialFileUrl)
  //                     })
  //                 }
  //             })
  //             console.log(initialFileUrl)
  //         }).catch(err=>{
  //             console.log(err)
  //         })
  //     }
  // }
  handleOpenModal = (data) => {
    // console.log('co vo day')
    this.setState({
      openModal: true,
      valueModal: data,
    });
  };
  handleCloseModal = () => {
    this.setState({ openModal: false, valueModal: null });
  };

  handleChange = (value) => {
    const { isRequestNew } = this.props;
    let { arrayDocuments, problemRequest } = this.state;
    const arrayDocumentsNew = arrayDocuments.concat(value);
    problemRequest = {
      ...this.state.problemRequest,
      files: arrayDocumentsNew,
    };
    this.setState(
      {
        arrayDocuments: arrayDocumentsNew,
        problemRequest: problemRequest,
      },
      () => {
        if (isRequestNew) {
          this.props.handleChange(arrayDocumentsNew, "files");
        } else {
          this.props.setProblemDescribe(problemRequest);
        }
      }
    );
  };
  removeFile = (keyDoc) => {
    if (this.props.isUploadRepairDoc && typeof this.props.removeFile === 'function') {
      this.props.removeFile(keyDoc);
      return;
    }
    let newState = {};
    let { arrayRemoveFiles, arrayDocuments, problemRequest } = this.state;
    if (arrayDocuments[keyDoc] && arrayDocuments[keyDoc].id) {
      arrayRemoveFiles.push(arrayDocuments[keyDoc]);
      newState = { ...newState, arrayRemoveFiles: arrayRemoveFiles };
    }
    const arrayDocumentsNew = arrayDocuments.filter(
      (item, key) => key !== keyDoc
    );
    newState = { ...newState, arrayDocuments: arrayDocumentsNew };
    const problemRequestNew = {
      ...problemRequest,
      files: arrayDocumentsNew,
      arrayRemoveFiles: arrayRemoveFiles,
    };
    newState = { ...newState, problemRequest: problemRequestNew };
    // console.log(newState)
    if (this.props.isRequestNew) {
      this.props.handleChange(arrayDocumentsNew, "files");
    }
    this.setState(newState, () => {
      this.props.setProblemDescribe(problemRequestNew);
    });
  };

  submitChangeSubject = () => {
    const { dataUser, isModalShowClientDetail, valueForm } = this.props;
    const { subject } = this.state;
    const isManagerRequest =
      dataUser?.data?.group &&
      [USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(dataUser.data.group);
    if (
      isModalShowClientDetail &&
      isManagerRequest &&
      valueForm?.id &&
      subject
    ) {
      const formData = new FormData();
      formData.append("subject", subject);
      axios
        .post(API_EDIT_SUBJECT_REQUEST.replace("{id}", valueForm?.id), formData)
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                successOpen: true,
                managerEditSubject: false,
              },
              function () {
                if (this.props.updateList) {
                  this.props.updateList();
                }
              }
            );
          }
        })
        .catch((err) => {
          this.setState({
            errorOpen: true,
          });
        });
    }
  };

  canEditRequestItem = () => {
    const { classes, groupBaseUser, valueForm, isModalShowClientDetail } =
      this.props;
    const canEditValue =
      groupBaseUser === USER_TYPE_CLIENT &&
      valueForm?.status?.statusType?.code &&
      valueForm.status.statusType.code === WAITING_FOR_REPLY && !(valueForm?.isAdminOrExpertCreated);
    return canEditValue && isModalShowClientDetail ? (
      <div className={`${classes.btnEditOnTitleLabel} btnHiddenBtnEditSubject`}>
        <div className={"btnSave"} onClick={() => this.props.handleEditValue()}>
          <EditIcon />
        </div>
      </div>
    ) : (
      ""
    );
  };

  render() {
    const {
      t,
      classes,
      valueForm,
      documentRequestType,
      width,
      initialData,
      isRequestNew,
      isModalShowClientDetail,
      hiddenUploadFile,
      dataUser,
      isUploadRepairDoc,
    } = this.props;
    const {
      errorFileType,
      errorMessage,
      /*errorSubject,*/ managerEditSubject,
      problemRequest,
      /*initialFileUrl,*/ arrayDocuments,
      openModal,
      subject,
    } = this.state;
    const isManagerRequest =
      dataUser?.data?.group &&
      [USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_REPAIRER].includes(
        dataUser.data.group
      );
    // const adminExpertAuth = !valueForm.person;
    const adminExpertAuth =
      (valueForm?.id && valueForm.isAdminOrExpertCreated) ||
      (!valueForm?.id && this.props.groupBaseUser !== USER_TYPE_CLIENT);
    return (
      <div className={classes.uploadFile}>
        {!isUploadRepairDoc && (
          <>
            <div className="titleType titleTypeObject">
              {t("modal.upload.titleObject")} {this.canEditRequestItem()}
            </div>
            <div className={"subjectWrapper"}>
              <CustomInput
                value={subject}
                onChange={(e) => {
                  const valueSubject = e.target.value;
                  if (!(valueSubject && valueSubject.length > 50)) {
                    this.setState({ subject: e.target.value }, function () {
                      if (isRequestNew) {
                        this.props.handleChange(e.target.value, "subject");
                      }
                    });
                  }
                }}
                disabled={
                  isModalShowClientDetail && !managerEditSubject
                    ? true
                    : initialData && initialData.status
                    ? initialData.status !== WAITING_FOR_REPLY
                    : false
                }
                placeholder={t("information.formTab1.problem")}
              />
              {isManagerRequest ? (
                managerEditSubject ? (
                  <div className={classes.actionEditSubject}>
                    <div
                      className={"btnSave"}
                      onClick={this.submitChangeSubject}
                    >
                      <SaveIcon className={"iconAdd"} />
                    </div>

                    <div
                      className={"btnCancel"}
                      onClick={() => {
                        this.setState({
                          managerEditSubject: false,
                          subject: valueForm.subject,
                        });
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${classes.actionEditSubject} btnHiddenBtnEditSubject`}
                  >
                    <div
                      className={"btnSave"}
                      onClick={() =>
                        this.setState({
                          managerEditSubject: true,
                        })
                      }
                    >
                      <EditIcon />
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
            <div className="titleType titleTypeDetail">
              {t("modal.upload.titleDetail")} {this.canEditRequestItem()}
            </div>
            <CKEditor
              editor={ClassicEditor}
              onReady={(editor) => {}}
              onChange={this.problemDetail}
              className={"textDescribe"}
              config={{
                toolbar: [
                  "bold",
                  "italic",
                  "underline",
                  "numberedList",
                  "bulletedList",
                  "|",
                  "undo",
                  "redo",
                ],
                placeholder: `${t("modal.upload.detailProblem")}`,
                language: i18next.language,
                locale: i18next.language,
                // enterMode : "CKEDITOR.ENTER_BR",
              }}
              data={
                isRequestNew || isModalShowClientDetail
                  ? valueForm.problem
                  : initialData && initialData.problemDetail
                  ? initialData.problemDetail
                  : ""
              }
              disabled={
                isModalShowClientDetail
                  ? true
                  : initialData && initialData.status
                  ? initialData.status !== WAITING_FOR_REPLY
                  : false
              }
            />
          </>
        )}

        {adminExpertAuth && this.props.checkboxSendSmsAndVoice && <div className={'sendSmsAndVoice'}>
          <Checkbox
              checked={!!(valueForm?.sendSmsAndVoice)}
              onChange={(e) => {
                    if (isRequestNew) {
                      this.props.handleChange(e, "sendSmsAndVoice");
                    }
              }}
              disabled={
                isModalShowClientDetail
                    ? true
                    : initialData && initialData.status
                    ? initialData.status !== WAITING_FOR_REPLY
                    : false
              }
              name="checkedB"
          />{t("information.formTab1.sendSmsAndVoice")}
        </div>}

          <div
            className={`uploadBlock ${
              problemRequest.hasOwnProperty("files") &&
              problemRequest.files.length > 0
                ? classes.checkHasFile
                : ""
            }`}
          >
            {!isUploadRepairDoc && (!adminExpertAuth || !valueForm?.id) && (
              <div className={"uploadBlock_title"}>
                {t("modal.upload.attackTitle")}
              </div>
            )}
            {/*<Dropzone*/}
            {/*    inputContent={<UploadBlock classes={classes} t={t} width={width}/>}*/}
            {/*    accept={JSON.stringify(this.props.documentRequestType) !== "{}"? this.documentType() : "image/png, video/mp4"}*/}
            {/*    // onChangeStatus={this.handleChangeStatus}*/}
            {/*    // onSubmit={this.handleSubmit}*/}
            {/*    // validate={this.checkFile}*/}
            {/*    // PreviewComponent={PreviewComponent}*/}
            {/*    inputWithFilesContent={<UploadBlock classes={classes} t={t} width={width}/>}*/}
            {/*    maxSizeBytes={documentRequestType && documentRequestType.size && documentRequestType.size.maxSize ? documentRequestType.size.maxSize * 1024 * 1024 : 209715200}*/}
            {/*    initialFiles={initialFileUrl.length? initialFileUrl : undefined}*/}
            {/*    />*/}
            {JSON.stringify(documentRequestType) === "{}" ? (
              <div className={classes.circle}>
                <CircularProgress />
              </div>
            ) : (
              <div>
                {!!(arrayDocuments && arrayDocuments.length) ? (
                  <div className={classes.showDocumentWrapper}>
                    {arrayDocuments.map((item, key) => {
                      // console.log(item.id)
                      const mimeType =
                        item instanceof File ? item.type : item.mimeType;
                      const url =
                        item instanceof File
                          ? URL.createObjectURL(item)
                          : item.pathUrl;
                      if (mimeType && mimeType.includes("video")) {
                        // let videoUpload = files.filter(item => item.file && item.file.name === meta.name)
                        // url = URL.createObjectURL(videoUpload[0].file)
                        return (
                          <React.Fragment>
                            <div className={classes.documentWrapper} key={key}>
                              {((!item.id &&
                                item.id !== 0 &&
                                initialData.status !== WAITING_FOR_REPLY) ||
                                initialData.status === WAITING_FOR_REPLY) && (
                                <div
                                  className={"closeButton"}
                                  onClick={() => this.removeFile(key)}
                                >
                                  <CustomInlineSvg
                                    svgProps={{
                                      src: closeButton,
                                    }}
                                    scale="0.8"
                                  />
                                </div>
                              )}
                              <video
                                controls={false}
                                className={classes.previewVideo}
                              >
                                <source src={url} />
                              </video>
                              <div
                                onClick={() => this.handleOpenModal(key)}
                                className={"playButton"}
                              >
                                {/*<img src={playButton} alt=""/>*/}
                                <CustomInlineSvg
                                  svgProps={{
                                    src: playButton,
                                  }}
                                  scale="0.3"
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      } else if (mimeType && mimeType.includes("image")) {
                        return (
                          <React.Fragment>
                            <div className={classes.documentWrapper} key={key}>
                              {((!item.id &&
                                item.id !== 0 &&
                                initialData.status !== WAITING_FOR_REPLY) ||
                                initialData.status === WAITING_FOR_REPLY) && (
                                <div
                                  className={"closeButton"}
                                  onClick={() => this.removeFile(key)}
                                >
                                  <CustomInlineSvg
                                    svgProps={{
                                      src: closeButton,
                                    }}
                                    scale="0.8"
                                  />
                                </div>
                              )}
                              <img
                                className={classes.previewImage}
                                alt={"file upload"}
                                src={url}
                              />
                              <div
                                onClick={() => this.handleOpenModal(key)}
                                className={"playButton"}
                              >
                                {/*<img src={zoomIn} alt=""/>*/}
                                <CustomInlineSvg
                                  svgProps={{
                                    src: zoomIn,
                                  }}
                                  scale="0.2"
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      } else
                        return (
                          <div className={classes.documentWrapper} key={key}>
                            {((!item.id &&
                              item.id !== 0 &&
                              initialData.status !== WAITING_FOR_REPLY) ||
                              initialData.status === WAITING_FOR_REPLY) && (
                              <div
                                className={"closeButton"}
                                onClick={() => this.removeFile(key)}
                              >
                                <CustomInlineSvg
                                  svgProps={{
                                    src: closeButton,
                                  }}
                                  scale="0.8"
                                />
                              </div>
                            )}
                            <div className={classes.previewDoc + " doc"}>
                              <CustomSvg
                                svgProps={{
                                  src: DocIcon,
                                }}
                              />
                            </div>
                            {mimeType && mimeType.includes("pdf") ? (
                              <div
                                onClick={() => this.handleOpenModal(key)}
                                className={"playButton"}
                              >
                                <CustomInlineSvg
                                  svgProps={{
                                    src: zoomIn,
                                  }}
                                  scale="0.2"
                                />
                              </div>
                            ) : (
                              <a
                                href={url}
                                target="_blank"
                                rel="noreferrer"
                                className={"playButton"}
                              >
                                <CustomInlineSvg
                                  svgProps={{
                                    src: DownloadDoc,
                                  }}
                                  scale="0.5"
                                />
                              </a>
                            )}
                          </div>
                        );
                    })}
                  </div>
                ) : (
                  ""
                )}
                {!hiddenUploadFile && (
                  <Dropzone
                    // addClassNames={preparing>0 ? {dropzone: classes.checkHasFile} : {}}
                    // inputContent={<UploadBlock classes={classes} t={t} width={width}/>}
                    accept={
                      JSON.stringify(this.props.documentRequestType) !== "{}"
                        ? this.documentType() + ",.doc, .docx, .pdf"
                        : "image/png, video/mp4"
                    }
                    // onChangeStatus={this.handleChangeStatus}
                    // // onSubmit={this.handleSubmit}
                    // // validate={this.checkFile}
                    // PreviewComponent={PreviewComponent}
                    // inputWithFilesContent={<UploadBlock classes={classes} t={t} width={width}/>}
                    // maxSizeBytes={documentRequestType && documentRequestType.size && documentRequestType.size.maxSize ? documentRequestType.size.maxSize * 1024 * 1024 : 209715200}
                    // initialFiles={initialFileUrl.length? initialFileUrl : undefined}
                    disabled={!!isModalShowClientDetail}
                    minSize={0}
                    // maxSize={documentRequestType && documentRequestType.size && documentRequestType.size.maxSize ? documentRequestType.size.maxSize * 1024 * 1024 : 209715200}
                    multiple={true}
                    maxFiles={10}
                    onDrop={(acceptedFiles) => this.handleChange(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => {
                      return (
                        <section
                          className={classes.dropzoneWrapper}
                          style={
                            isModalShowClientDetail
                              ? {
                                  display: "none",
                                }
                              : {}
                          }
                        >
                          <div
                            {...getRootProps()}
                            className={"documentsUploadListWrapper"}
                          >
                            <div className={"emptyDocWrapper"}>
                              <input {...getInputProps()} />
                              {/*<img src={uploadFileIcon} alt={""}*/}
                              {/*     className={"avatar"}/>*/}
                              {/*<div*/}
                              {/*    className={"dropFile"}>{t("profile.form.dropFileOrClickUpload")}*/}
                              {/*    <span*/}
                              {/*        className={"browse"}>{t("profile.form.browse")}</span></div>*/}
                              <UploadBlock
                                classes={classes}
                                t={t}
                                width={width}
                              />
                            </div>
                          </div>
                        </section>
                      );
                    }}
                  </Dropzone>
                )}
              </div>
            )}
            {errorFileType && (
              <div className={classes.errorFile}>{errorMessage}</div>
            )}
          </div>
        {openModal && (
          <GeneralModal
            className={"previewModal"}
            open={openModal}
            handleClose={this.handleCloseModal}
            // title={<TitleUpload/>}
            // buttonModal={<SubmitUploadButton/>}
            content={
              <ContentPreviewModal2
                {...this.props}
                files={arrayDocuments}
                keyFile={this.state.valueModal}
              />
            }
            saveButton={false}
            cancelButton={false}
            // enableOnClose={false}
          />
        )}
        <AlertCustom
          successOpen={this.state.successOpen}
          errorOpen={this.state.errorOpen}
          handleCloseNotice={() => {
            this.setState({
              errorOpen: false,
              successOpen: false,
            });
          }}
        />
      </div>
    );
  }
}
UploadFile.defaultProps = {
  initialData: {},
  onlyShowDocumentOld: false,
  handleEditValue: () => {},
  hiddenUploadFile: false,
  checkboxSendSmsAndVoice: false
};
UploadFile.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onlyShowDocumentOld: PropTypes.bool,
  handleEditValue: PropTypes.func,
  hiddenUploadFile: PropTypes.bool,
  checkboxSendSmsAndVoice: PropTypes.bool,
};
const mapStateToProps = (state) => {
  return {
    dataUser: state.authReducer.dataUser,
    groupBaseUser: state.authReducer.groupBaseUser,
    documentRequestType: state.applicationReducer.documentRequestType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProblemDescribe: (problemRequest) =>
      dispatch(setProblemDescribe(problemRequest)),
    getDocumentRequestValid: () => dispatch(getDocumentRequestValid()),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  withWidth()
)(UploadFile);
