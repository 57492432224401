import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import DateBox from "devextreme-react/date-box";
import notify from "devextreme/ui/notify";
import FileList from "./share/fileList.js";
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
import {
  Button,
  Popover,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Input,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import VideoCategoryIcon from "../../images/request/online-video.svg";
import {
  BASE_URL,
  CLIENT_DOC_TAB_BILL,
  CLIENT_DOC_TAB_IMAGE_VIDEO,
  CLIENT_DOC_TAB_OTHER,
  CLIENT_DOC_TAB_REPORT_QUOTATION,
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_DOCUMENT_BY_REQUEST_AND_ZIP,
  FILE_SUBTYPE_PDF,
  FILE_TYPE_APPLICATION,
  FILE_TYPE_IMAGE,
  FILE_TYPE_VIDEO,
  IS_PAID,
  IS_SENT_TO_CLIENT,
  REQ_DOC_REPAIR_DOCUMENT,
  REQ_DOC_SIGNATURE_REPAIRER,
  REQ_DOC_SIGNATURE_WITHOUT_TEXT_REPAIRER,
  REQUEST_DOC_TYPE_QUOTATION,
  USER_TYPE_ADMIN,
  USER_TYPE_REPAIRER,
  USER_TYPE_SUPER_ADMIN,
  XS,
} from "../../constants/constants";
import Dropzone from "react-dropzone-uploader";
import UploadDoc from "../../images/request/document/upload_doc.svg";
import { MONTSERRAT, NUNITO } from "../../constants/fontCss";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import {
  API_GET_LIST_DOCUMENT_CLIENT_CREATE_NOT_SEEN_BY_REQUEST,
  API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE,
  GET_REQUEST_DOCUMENT_TYPE_LIST,
  GET_SUB_DOCUMENT_TYPE_LIST,
  NEW_DOC_MAIL_CUSTOMER,
  PAGE_DOWNLOAD_REQUEST_DOCUMENT,
  REQUEST_DOCUMENT_DELETE,
  REQUEST_DOCUMENT_EDIT,
  REQUEST_DOCUMENT_LIST,
  UPLOAD_REQUEST_DOCUMENT,
  API_SHARE_DOCUMENTS,
  API_SHARE_DOCUMENTS_CREATE,
  API_LIST_GENERAL_DOCUMENT,
  API_GET_SHOW_REQUEST,
  API_GET_PARAMETER_SHARE_DOCUMENT_EXPIRE_NUMBER_DAY,
} from "../../constants/apiSuffix";
import DocTypeIcon from "../../images/request/document/doc_type.svg";
import LoadingAction from "../../theme/LoadingAction";
import FormControl from "@material-ui/core/FormControl";
import DeleteIcon from "../../images/request/document/delete.svg";
import DialogForm from "../../theme/CustomElements/DialogForm";
import EditIcon from "../../images/request/document/edit.svg";
import SaveIcon from "../../images/request/document/icon-save3.svg";
import SendIcon from "../../images/request/document/envelope.svg";
import SaveMuiIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import ChatIcon from "@material-ui/icons/Chat";
import moment from "moment";
import qs from "qs";
import DocPdfIcon from "../../images/request/document/doc_pdf.svg";
import DocIcon from "../../images/request/document/doc.svg";
import ErrorAlert from "../../theme/form/Alert/ErrorAlert";
import {
  getDocumentRequestValid,
  removeValueToRequestDocumentManagerNotSeenRedux,
} from "../../_actions/application";
import { connect } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { KEY_DOCUMENT_TYPE_ID } from "../client/requestDetail/TabRequestDocument";
import { v4 as uuidv4 } from "uuid";
import closeButton from "../../images/theme/close.svg";
import { MENU_DOCUMENT_TYPE_LIST } from "../client/request/constantRequestPage";
import GeneralModal from "../../theme/Modal/GeneralModal";
import ContentPreviewModal2 from "../../theme/Modal/ContentPreviewModal2";
import { ConvertToDateD_M_Y, convertToDateH_m } from "../../functions/datetime";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SuccessAlert from "../../theme/form/Alert/SuccessAlert.js";

const styles = (theme) => ({
  filesUploadBlock: {
    "& .title": {
      color: "#8da9d6",
      fontSize: "1.25rem",
      padding: "0 20px 10px 20px",
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.9rem",
      },
    },
    "& .sharePopup .dx-fieldset .copy": {
      cursor: "pointer",
      width: "max-content",
    },
    "& .dataCopyAll": {
      color: "white",
      fontSize: "1px",
    },
    "& .sharePopup": {
      "& .dx-popup-normal": {
        backgroundColor: "#e6edfc",
      },
      "& div, span, input, td": {
        color: "#3939b7",
      },
      "& .dx-datebox-datetime": {
        backgroundColor: "transparent",
        border: "1px solid #3939b7",
      },
      "& .dx-icon": {
        color: "#3939b7",
      },
    },
  },
  uploadFiles: {
    padding: "10px 20px",
    "& header": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "& .MuiTab-root": {
      margin: "0 5px",
      backgroundColor: "#d6e4fc",
      minWidth: 100,
      maxWidth: 100,
      textTransform: "initial",
      [theme.breakpoints.down(XS)]: {
        minWidth: 90,
        maxWidth: 90,
      },
    },
    "& .Mui-selected": {
      backgroundColor: "#bed5f9",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#bed5f9",
    },
    // dropzone Style
    "& .dzu-dropzone": {
      minHeight: 400,
      height: "calc(100% - 90px)",
      overflow: "unset",
      backgroundColor: "#bed5f9",
      border: "none",
      position: "relative",
      [theme.breakpoints.down(XS)]: {
        height: 270,
      },
      "& .dzu-inputLabel": {
        border: "1px dashed #737abd",
        position: "absolute",
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
        "& input": {
          display: "none",
        },
      },
    },
  },
  categoryFiles: {
    padding: 10,
    [theme.breakpoints.down(XS)]: {
      padding: 0,
      wordBreak: "break-word",
    },
    "& img": {
      height: 35,
      [theme.breakpoints.down(XS)]: {
        height: 20,
      },
    },
    "& .categoryName": {
      color: "#48489f",
      fontWeight: "bold",
      paddingTop: 10,
      fontSize: "0.875rem",
      lineHeight: 1,
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.8rem",
      },
    },
    position: "relative",
  },
  alertNotSeen: {
    left: -23,
    top: 15,
    position: "absolute",
    transform: "rotate(320deg)",
    color: "#fff",
    fontWeight: 700,
    fontSize: 10,
    lineHeight: 0.9,
    background: "#f02849",
    padding: "1.5px 20px ",
    textTransform: "uppercase",
    fontFamily: MONTSERRAT,
  },
  inputUploadFile: {
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      [theme.breakpoints.down(XS)]: {
        height: 100,
      },
    },
    "& .textLabel": {
      width: 160,
      fontSize: "1.25rem",
      textAlign: "center",
      fontWeight: "bold",
      color: "rgba(50, 50, 146, 0.57)",
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.9rem",
      },
    },
  },
  filesUploadManager: {
    padding: "10px 0",
    "& .headerFileMana": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexGrow: 1,
      paddingBottom: 20,
      "& .filterStatus": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexGrow: 1,
        "& .itemStatusFile": {
          textAlign: "center",
          padding: "0 15px",
          borderLeft: "1px solid #7579bb",
          borderRight: "1px solid #7579bb",
          "&.first": {
            borderLeft: "none",
          },
          "&.last": {
            borderRight: "none",
          },
          "& .count": {
            color: "#0099cc",
            fontWeight: "bold",
            fontSize: "1.5rem",
            [theme.breakpoints.down(XS)]: {
              fontSize: "1.2rem",
            },
          },
          "& .label": {
            color: "#48489f",
            fontSize: "0.65rem",
            textTransform: "uppercase",
            fontWeight: "bold",
            [theme.breakpoints.down(XS)]: {
              fontSize: "0.6rem",
            },
          },
        },
      },
      "& .hasNewDocMailToCustomer": {
        textTransform: "initial",
        color: "#fff",
        fontWeight: 700,
        backgroundColor: "#0000FF",
        borderRadius: 9,
        padding: "5px 10px",
        marginRight: 10,
      },
    },
    "& .listFiles": {
      maxHeight: 400,
      overflowY: "auto",
      padding: "0 20px 20px 20px",
      borderBottom: "1px dashed #8da9d6",
      "&::-webkit-scrollbar": {
        height: 9,
        width: 9,
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        background: "#7579bb",
        zIndex: 999,
      },
      "& .noDocument": {
        padding: 20,
        textAlign: "center",
      },
      "& thead": {
        "& tr": {
          "& th": {
            backgroundColor: "#e6edfc",
            borderBottom: "1px dashed #8da9d6",
            padding: 5,
            color: "#7579bb",
            textTransform: "uppercase",
            [theme.breakpoints.down(XS)]: {
              fontSize: "0.8rem",
            },
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            border: "none",
            padding: 5,
            borderTop: "5px solid #e6edfc",
            borderBottom: "5px solid #e6edfc",
            height: 60,
          },
        },
        "& .sentTr": {},
        "& .notSentTr": {
          "& td": {
            backgroundColor: "#fff",
          },
          // '& td:not(:first-child)': {
          //     backgroundColor: '#fff'
          // }
        },
        "& .btnComment": {
          "&.hasComment": {
            "& svg": {
              cursor: "pointer",
              "& path": {
                fill: "#6a96ef",
              },
            },
          },
          "& svg": {
            cursor: "pointer",
            "& path": {
              fill: "#c2c2df",
            },
          },
          "&.commentActive": {
            backgroundColor: "#6a96ef",
            borderRadius: "8px 8px 0 0",
            "& svg": {
              cursor: "pointer",
              "& path": {
                fill: "#fff",
              },
            },
          },
        },
        "& .deleteAction": {
          "& svg": {
            cursor: "pointer",
            "& path": {
              fill: "#c2c2df",
            },
          },
          "& .showClientLabel": {
            color: "#c2c2df",
            fontSize: "0.8rem",
          },
        },
        "& .columnDocInfoWrapper": {
          position: "relative",
          overflowY: "hidden",
        },
        "& .docInfo": {
          display: "flex",
          "& .fileView": {
            width: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .image": {
              height: 36,
              width: 60,
              objectFit: "cover",
            },
          },
          "& .docName": {
            width: 240,
            padding: "10px 40px",
            color: "#3939b7",
            fontWeight: "bold",
            fontSize: "0.875rem",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            cursor: "pointer",
            borderRadius: 5,
            "&.docNameNotEdit": {
              "&:hover": {
                position: "relative",
                "&:after": {
                  content: " '' !important",
                  position: "relative",
                  left: 0,
                },
              },
            },
            [theme.breakpoints.down(XS)]: {
              fontSize: "0.75rem",
            },
            "&:hover": {
              backgroundColor: "rgba(231, 239, 254, 0.44)",
              position: "relative",
              "&:after": {
                content: `url(${EditIcon})`,
                position: "absolute",
                left: 5,
              },
            },
          },
          "& .docNameInput": {
            width: 240,
            position: "relative",
            "& > div": {
              width: "100%",
            },
            "& input": {
              backgroundColor: "rgb(231, 239, 254)",
              color: "#3939b7",
              fontWeight: "bold",
              fontSize: "0.875rem",
              padding: "10px 60px 10px 10px",
              [theme.breakpoints.down(XS)]: {
                fontSize: "0.75rem",
              },
            },
            "& .actionDocName": {
              position: "absolute",
              right: 0,
              width: 50,
              top: 0,
            },
            "& .btnActionDocName": {
              padding: 5,
              minWidth: "unset",
              height: 24,
              width: 24,
              "& svg": {
                height: 18,
                width: 18,
                "& path": {
                  fill: "#fff",
                },
              },
              "&.btnSaveDocName": {
                backgroundColor: "#3232de",
              },
              "&.btnCancelDocName": {
                backgroundColor: "#DF373C",
              },
            },
          },
        },
        "& .dateView": {
          color: "#323292",
          fontSize: "0.8rem",
          fontWeight: 600,
          [theme.breakpoints.down(XS)]: {
            fontSize: "0.75rem",
          },
        },
      },
    },
  },
  actionFilesUpload: {
    textAlign: "right",
    padding: "20px 0",
    "&.sendMail": {
      padding: "0",
      "& .btnAction": {
        margin: "0",
      },
    },
    "& .btnAction": {
      borderRadius: 65,
      border: "1px solid #3232de",
      textTransform: "initial",
      padding: "10px 30px",
      margin: "0 10px",
      [theme.breakpoints.down(XS)]: {
        margin: "0 5px",
        padding: "5px 10px",
      },
      "& svg": {
        width: 30,
        [theme.breakpoints.down(XS)]: {
          width: 20,
        },
      },
      "& .btnText": {
        fontSize: "1.1rem",
        fontWeight: "bold",
        fontFamily: NUNITO,
        paddingLeft: 10,
        [theme.breakpoints.down(XS)]: {
          fontSize: "0.9rem",
        },
      },
      "&.btnSave": {
        backgroundColor: "transparent",
        "& .btnText": {
          color: "#3232de",
        },
        "& svg": {
          "& path": {
            fill: "#3232de",
          },
        },
      },
      "&.btnShare": {
        backgroundColor: "transparent",
        "& .btnText": {
          color: "#3232de",
        },
        "& svg": {
          "& path": {
            fill: "#3232de",
          },
        },
      },
      "&.btnSend": {
        backgroundColor: "#3232de",
        "& .btnText": {
          color: "#fff",
        },
        "& svg": {
          "& path": {
            fill: "#fff",
          },
        },
      },
    },
  },
  menuFilter: {
    "& .MuiPopover-paper": {
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    },
  },
  listFilter: {
    width: 150,
    padding: 10,
    paddingBottom: 20,
    overflowY: "auto",
    maxHeight: 240,
    "& .itemFilter": {
      fontSize: "1rem",
      color: "#48489f",
      fontWeight: 500,
      textAlign: "right",
      padding: "5px 5px",
      cursor: "pointer",
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.8rem",
      },
      "&:hover": {
        backgroundColor: "#e6edfc",
      },
      "&.last": {
        borderBottom: "1px dotted #48489f",
      },
    },
  },
  errorFile: {
    color: "red",
    fontStyle: "italic",
  },
  checkBoxDoc: {
    padding: 0,
    backgroundColor: "#fff",
    width: 24,
    height: 24,
    "& svg": {
      width: 20,
      "& path": {
        fill: "#fff",
      },
    },
    "&.Mui-checked": {
      "& svg": {
        width: 20,
        "& path": {
          fill: "#1ddb1d!important",
        },
      },
    },
    "& .MuiIconButton-label": {
      backgroundColor: "#fff",
    },
  },
  typeItem: {
    fontSize: "0.875rem",
    color: "#323292",
    [theme.breakpoints.down(XS)]: {
      fontSize: "0.75rem",
    },
  },
  formType: {
    backgroundColor: "#e7effe",
    borderRadius: 6,
    padding: "2px 10px",
    width: 100,
    "& .MuiSelect-root": {
      color: "#323292",
      [theme.breakpoints.down(XS)]: {
        fontSize: "0.75rem",
      },
    },
    "& svg": {
      "& path": {
        fill: "#323292",
        stroke: "#323292",
      },
    },
  },
  messageDialog: {
    fontSize: "1.2rem",
    "& .icon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: 30,
        height: 30,
        marginRight: 10,
      },
      "& span": {
        color: "#df373c",
        [theme.breakpoints.down(XS)]: {
          fontSize: "1rem",
        },
      },
    },
  },
  popoverComment: {
    [theme.breakpoints.down(XS)]: {
      maxHeight: "calc(100vh - 20px)",
    },
    "& .MuiPopover-paper": {
      borderRadius: "14px 0px 14px 14px",
    },
  },
  commentWrapper: {
    width: 500,
    height: 150,
    backgroundColor: "#6a96ef",
    borderRadius: "14px 0px 14px 14px",
    padding: "10px 20px",
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .inputDes": {
      width: "calc(100% - 24px)",
      height: 130,
      paddingRight: 10,
    },
    "& textarea": {
      color: "#fff",
      fontSize: "0.9rem",
      height: "100%!important",
      overflowY: "auto!important",
      "&::-webkit-scrollbar": {
        height: 9,
        width: 9,
      },
      "&::-webkit-scrollbar-track": {
        // background: '#fff',
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        background: "#fff",
        zIndex: 999,
      },
    },
    "& .btnSaveDescription": {
      backgroundColor: "#5072ee",
      "&.btnSendDescription": {
        width: 50,
        height: 30,
        "& svg": {
          height: 20,
          width: "auto",
        },
      },
      padding: 5,
      minWidth: "unset",
      height: 24,
      width: 24,
      "& svg": {
        height: 18,
        width: 18,
        "& path": {
          fill: "#fff",
        },
      },
    },
    "& .closeButton": {
      position: "absolute",
      top: 10,
      right: 10,
      borderRadius: "50%",
      width: 25,
      height: 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& svg": {
        fill: "#fff",
      },
      "&:hover": {
        backgroundColor: "#5072ee",
      },
    },
  },
  loadingList: {
    padding: 20,
    textAlign: "center",
  },
  copyAll: {
    // border: '1px solid silver',
    margin: "0 0 10px 0",
    backgroundColor: "#fff",
  },
});
const useStyles = makeStyles(styles);
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

// const OPEN_MODAL_FILE_UPLOAD_BY_REQUEST = "openModalFileUploadByRequest";
const TAB_TYPE_HAS_DOC_NEW = "type_{id}";

const ItemVideoCall = (props) => {
  const { children, url } = props;
  const [isVideo, setIsVideo] = useState(false);
  useEffect(() => {
    fetch(url)
      .then((res) => {
        if (res.status === 200) {
          setIsVideo(true);
        }
      })
      // .then(res => {
      //     console.log(item,res)
      // })
      .catch((err) => { });
  }, []);
  if (isVideo) {
    return <>{children}</>;
  }
  return <></>;
};

class FilesUploadBlock extends React.Component {
  constructor(props) {
    super(props);
    let docDescriptionEdit = "";
    this.copyAllRef = React.createRef();
    try {
      docDescriptionEdit = localStorage.getItem("commentSendMail");
    } catch (e) { }
    this.state = {
      requestItem: {},
      menuDocTypeTabs: MENU_DOCUMENT_TYPE_LIST.map((item) => {
        return { ...item, name: props.t(item.name) };
      }),
      // dropzone
      preparing: 0,
      done: 0,
      removed: 0,
      maxFile: false,
      errorFileType: false,
      errorMessage: "",
      loadingDropzone: false,
      isLoading: false,

      // state of request doc list
      listCheckBox: [],
      requestDocumentList: [],
      generalDocumentsList: [],
      requestDocId: null,
      dialogDelete: false,
      docNameEdit: "",
      editDocName: false,
      popoverComment: null,
      docDescriptionEdit: docDescriptionEdit,

      // videoTypeList
      loadingList: true,
      typeFilter: {
        type: "all",
        value: "all",
        label: this.props.t("label.all"),
      },
      documentTypeFilter: false,
      errorOpen: false,
      downloadAllDocuments: [],
      downloadAllDocumentsFinished: false,
      checkNewDoc: props.checkNewDoc,
      documentUserCreatedNotSeen: [],
      gotDocumentNew: null,

      popupShareVisible: false,
      selectedItemKeys: [],
      shareData: {
        token: null,
        files: [],
        password: null,
        validityDate: null,
      },
      fileView: null,
      videoCallList: [],
      documentMeetingVideo: [],
      openUploadDocSuccessAlert: false,
    };
    this.shareButtonOptions = {
      icon: "share",
      text: props.t("requestDocument.sharePopup.generate"),
      onClick: this.shareDocuments.bind(this),
    };
    this.deleteButtonOptions = {
      icon: "trash",
      text: props.t("requestDocument.sharePopup.delete"),
      visible: true,
      onClick: this.deleteShareDocuments.bind(this),
    };
    this.handleChangeDropzoneStatus =
      this.handleChangeDropzoneStatus.bind(this);
    this.deleteRequestDocument = this.deleteRequestDocument.bind(this);
    this.downloadAllFileDocument = this.downloadAllFileDocument.bind(this);
    this.shareAllFileDocument = this.shareAllFileDocument.bind(this);
    this.updateLastSeenDocument = this.updateLastSeenDocument.bind(this);

    this.initShareData = this.initShareData.bind(this);
    this.showSharePopup = this.showSharePopup.bind(this);
    this.hideSharePopup = this.hideSharePopup.bind(this);
    this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);
    this.onValidityDateChange = this.onValidityDateChange.bind(this);
    this.copyField = this.copyField.bind(this);
    this.newCopy = this.newCopy.bind(this);

    this.getShareDocuments = this.getShareDocuments.bind(this);

    document.addEventListener("copy", this.newCopy);
  }
  handleCloseModal = () => {
    this.setState({
      fileView: null,
    });
  };

  getRequestDocumentList(params) {
    const { requestId, dataUser } = this.props;
    const { typeFilter, documentTypeFilter, requestItem } = this.state;
    let paramsDocumentList = {
      maxPerPageAll: 1,
      request: requestId,
    };
    if (documentTypeFilter && typeFilter && typeFilter.value) {
      paramsDocumentList = {
        ...paramsDocumentList,
        type: typeFilter.value,
      };
    }
    let apiParamsListGenaral = {};
    if (documentTypeFilter && typeFilter && typeFilter.value) {
      apiParamsListGenaral = {
        ...apiParamsListGenaral,
        "type.id": typeFilter.value,
      };
    }
    axios
      .all([
        axios.get(
          REQUEST_DOCUMENT_LIST + "?" + qs.stringify(paramsDocumentList)
        ),
        axios.get(
          API_LIST_GENERAL_DOCUMENT + "?" + qs.stringify(apiParamsListGenaral)
        ),
      ])
      .then((res) => {
        let requestDocumentListNew = [];
        let documentMeetingVideoTemp = [];
        if (res[0].status === 200 && res[0].data) {
          let requestDocumentListTemp = Array.isArray(res[0].data.items)
            ? res[0].data.items
            : [];
          requestDocumentListNew = requestDocumentListTemp.filter((item) => {
            return !item.isMeetingVideo;
          });
          documentMeetingVideoTemp = requestDocumentListTemp
            .filter((item) => {
              return item.isMeetingVideo;
            })
            .map((item) => {
              const docName = this.props.t(
                "requestDocument.label.videoCallName",
                {
                  date: ConvertToDateD_M_Y(item.createdAt),
                  time: convertToDateH_m(item.createdAt),
                }
              );
              return {
                ...item,
                docName: docName,
                canNotEdit: true,
              };
            });
        }
        if (res[1].status === 200 && res[1].data) {
          let dataItems = [];
          if (res[1].data.hasOwnProperty("items") && res[1].data.items) {
            dataItems = res[1].items;
          } else if (res[1].data.hasOwnProperty("hydra:member")) {
            dataItems = res[1]["data"]["hydra:member"];
          }
          if (Array.isArray(dataItems)) {
            dataItems.forEach((generalDoc) => {
              let hasDoc = null;
              if (
                generalDoc.enabled &&
                Array.isArray(generalDoc.versionDocuments) &&
                requestItem
              ) {
                hasDoc = generalDoc.versionDocuments
                  .reverse()
                  .find((itemDoc) => {
                    return (
                      moment(itemDoc.createdAt) < moment(requestItem.createdAt)
                    );
                  });
              }
              if (hasDoc) {
                requestDocumentListNew.push({
                  ...hasDoc,
                  docName: generalDoc.name ?? hasDoc.docName,
                  canNotEdit: true,
                });
              }
            });
          }
        }

        if (
          requestItem &&
          Array.isArray(requestItem.reports) &&
          (!typeFilter ||
            (typeFilter.value &&
              (typeFilter.value === "all" ||
                (Array.isArray(typeFilter.code) &&
                  typeFilter.code.includes(REQUEST_DOC_TYPE_QUOTATION)))))
        ) {
          requestItem.reports.forEach((item) => {
            if (
              !!item.reportPdfInfo &&
              [IS_SENT_TO_CLIENT, IS_PAID].includes(item.reportStatus)
            ) {
              requestDocumentListNew.push({
                ...item.reportPdfInfo,
                canNotEdit: true,
              });
            }
          });
        }
        const groupUser = this.props.dataUser?.data?.group;
        // if (groupUser === USER_TYPE_REPAIRER) {
        requestDocumentListNew = requestDocumentListNew.map((doc) => {
          if (
            [
              REQ_DOC_SIGNATURE_REPAIRER,
              REQ_DOC_SIGNATURE_WITHOUT_TEXT_REPAIRER,
              REQ_DOC_REPAIR_DOCUMENT,
            ].includes(doc?.type?.code)
          ) {
            if (!(groupUser === USER_TYPE_ADMIN || groupUser === USER_TYPE_SUPER_ADMIN
              || groupUser === USER_TYPE_REPAIRER
              //  || this.props.dataUser?.data?.id === doc.createBy?.id
            )) {
              return {
                ...doc,
                canNotEdit: true,
              };
            } else {
              return { ...doc, canNotDelete: !!requestItem?.addRepairDocAt };
            }
          }
          return { ...doc };
        });
        // }
        this.setState({
          requestDocumentList: requestDocumentListNew,
          documentMeetingVideo: documentMeetingVideoTemp,
          loadingList: false,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          loadingList: false,
        });
      });
  }

  componentDidMount() {
    const { requestId, dataUser } = this.props;
    this.props.getDocumentRequestValid();
    axios
      .all([
        axios.get(API_GET_SHOW_REQUEST.replace(":id", requestId)),
        axios.get(
          API_GET_LIST_DOCUMENT_CLIENT_CREATE_NOT_SEEN_BY_REQUEST.replace(
            "{id}",
            requestId
          )
        ),
      ])
      .then((response) => {
        let newState = {};
        if (response[0].status === 200) {
          const requestItemTemp = response[0].data.item;
          newState = {
            ...newState,
            requestItem: requestItemTemp,
            gotDocumentNew: true,
          };
        }
        if (response[1].status === 200) {
          newState = {
            ...newState,
            documentUserCreatedNotSeen: response[1].data.items,
            gotDocumentNew: true,
          };
        }

        this.setState(newState, function () {
          this.getRequestDocumentList();
        });
      })
      .catch((error) => {
        this.setState({
          gotDocumentNew: true,
        });
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { documentMeetingVideo } = this.state;
    if (this.state.loadingDropzone) {
      this.setState({
        loadingDropzone: false,
      });
    }

    if (this.state.gotDocumentNew) {
      this.setState(
        {
          gotDocumentNew: false,
        },
        function () {
          this.updateLastSeenDocument();
        }
      );
    }
    if (
      Array.isArray(documentMeetingVideo) &&
      documentMeetingVideo.length &&
      !_.isEqual(documentMeetingVideo, prevState.documentMeetingVideo)
    ) {
      this.setState({
        documentMeetingVideo: [],
      });
      this.checkMeetingVideo(documentMeetingVideo);
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      valueDocType: newValue,
    });
  };

  updateLastSeenDocument() {
    const { requestId } = this.props;
    axios
      .post(API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE.replace("{id}", requestId))
      .then((response) => {
        if (response.status === 200) {
          this.props.removeValueToRequestDocumentManagerNotSeenRedux(
            parseInt(requestId)
          );
        } else {
          throw new Error();
        }
      })
      .catch(() => { });
  }

  handleChangeDropzoneStatus(action, status, allFiles) {
    const { requestId, documentRequestType } = this.props;
    let { preparing, done, maxFile, errorFileType, errorMessage } = this.state;
    if (status === "preparing") {
      preparing += 1;
      errorFileType = false;
    }
    if (status === "done") {
      done += 1;
    }
    if (status === "error_file_size") {
      preparing -= 1;
      action.remove();
      errorFileType = true;
      errorMessage = this.props.t("modal.upload.errorSize", {
        size:
          documentRequestType &&
          documentRequestType.size &&
          documentRequestType.size.maxSize,
      });
    }
    if (status === "remove") preparing -= 1;
    if (status === "rejected_max_files") maxFile = true;
    if (status === "rejected_file_type") {
      errorFileType = true;
      errorMessage = this.props.t("modal.upload.errorType");
    }
    this.setState({
      preparing: preparing,
      done: done,
      maxFile: maxFile,
      errorFileType: errorFileType,
      errorMessage: errorMessage,
      isLoading: true,
    });
    if (preparing === done) {
      // upload File
      const formData = new FormData();
      allFiles.map((file, index) => {
        if (file && file.file) {
          formData.append("documents[" + index + "][documentFile]", file.file);
        }
      });
      axios
        .post(UPLOAD_REQUEST_DOCUMENT.replace("{id}", requestId), formData)
        .then((res) => {
          if (res.status === 200) {
            // upload request document success
            // update list request
            this.setState({
              loadingDropzone: true,
              checkNewDoc: true,
              openUploadDocSuccessAlert: true,
            });
            this.fileUpload = null;
            setTimeout(() => {
              this.getRequestDocumentList();
            }, 1000);
            console.log("upload request document success");
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            errorOpen: true,
          });
        });
    }
  }

  editRequestDocument(id, dataJson) {
    this.setState({
      isLoading: true,
      popoverComment: null,
      docDescriptionEdit: "",
    });
    if (dataJson && Object.keys(dataJson).length) {
      const formData = new FormData();
      for (let [key, value] of Object.entries(dataJson)) {
        formData.append(key, value);
      }
      axios
        .post(REQUEST_DOCUMENT_EDIT.replace("{id}", id), formData)
        .then((res) => {
          if (res.status === 200) {
            this.getRequestDocumentList();
            this.setState({
              docNameEdit: "",
              editDocName: false,
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  deleteRequestDocument() {
    const { requestDocId } = this.state;
    if (requestDocId) {
      this.setState({
        isLoading: true,
        dialogDelete: false,
      });
      axios
        .post(REQUEST_DOCUMENT_DELETE.replace("{id}", requestDocId))
        .then((res) => {
          if (res.status === 200) {
            this.getRequestDocumentList();
            this.setState({
              requestDocId: null,
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.setState({
        dialogDelete: false,
      });
    }
  }

  getTypeAnSubTypeFromMimeType(mimeType) {
    if (mimeType) {
      const indexSlash = mimeType.indexOf("/");
      return {
        fileType: mimeType.substring(0, indexSlash !== -1 ? indexSlash : 0),
        fileSubType: mimeType.substring(indexSlash !== -1 ? indexSlash + 1 : 0),
      };
    }
    return {
      fileType: "",
      fileSubType: "",
    };
  }

  downloadFileAction = (item) => {
    let hrefPage = window.location.href;
    // if(hrefPage.indexOf("?") > -1){
    //     hrefPage+=`&${OPEN_MODAL_FILE_UPLOAD_BY_REQUEST}=1&requestId=${this.props.requestId}&page=`
    // }
    const params = {
      documentId: item.id,
      typeDownload: DOWNLOAD_DOCUMENT,
      urlPageBeforeDownload: hrefPage,
    };
    window.location =
      BASE_URL + PAGE_DOWNLOAD_REQUEST_DOCUMENT + "?" + qs.stringify(params);
  };

  viewFileAction = (item) => {
    this.setState({
      fileView: item,
    });
  };

  downloadAllFileDocument() {
    const { requestDocumentList, downloadAllDocuments } = this.state;
    if (requestDocumentList.length > 1) {
      let hrefPage = window.location.href;
      // if(hrefPage.indexOf("?") > -1){
      //     hrefPage+=`&${OPEN_MODAL_FILE_UPLOAD_BY_REQUEST}=1&page=`
      // }
      const params = {
        requestId: this.props.requestId,
        typeDownload: DOWNLOAD_DOCUMENT_BY_REQUEST_AND_ZIP,
        urlPageBeforeDownload: hrefPage,
      };
      window.location =
        BASE_URL + PAGE_DOWNLOAD_REQUEST_DOCUMENT + "?" + qs.stringify(params);
    } else {
      this.downloadFileAction(requestDocumentList[0]);
    }
  }

  generatePassword() {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789=!;,:-",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  onSelectedItemKeysChange(selectedItems) {
    // console.log("onSelectedItemKeysChange",selectedItems)
    this.setState({
      selectedItemKeys: selectedItems,
    });
  }

  onValidityDateChange(e) {
    this.setState({
      shareData: {
        ...this.state.shareData,
        validityDate: e.value,
      },
    });
  }

  newCopy(e) {
    // console.log("newCopy", e, e.target.className === 'dataCopyAll')
    if (e.target && e.target.className === "dataCopyAll") {
      e.clipboardData.setData(
        "text/plain",
        `lien : ${this.usePathname()}/share/${this.state.shareData.token}
Mot de passe : ${this.state.shareData.password}
Valide jusqu'au : ${moment(this.state.shareData.validityDate).format(
          "DD/MM/YYYY - HH:mm"
        )}`
      );
    } else e.clipboardData.setData("text/plain", e.target.innerText);
    e.preventDefault();
  }

  copyField(e) {
    // console.log("copyField", e)
    if (e && e.hasOwnProperty("preventDefault")) e.preventDefault();

    if (document.selection) {
      // console.log("document.selection")
      let range = document.body.createTextRange();
      range.moveToElementText(e.target || e);
      range.select().createTextRange();
      document.execCommand("copy");
    } else if (window.getSelection) {
      // console.log("window.getSelection")
      let range = document.createRange();
      range.selectNode(e.target || e);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
  }

  async initShareData(data = null) {
    let minValidityDate = new Date();
    // console.log("-------------------------------------------")
    // console.log("initShareData",data)
    // console.log("-------------------------------------------")
    if (data) {
      return this.setState({
        selectedItemKeys: data.files,
        shareData: {
          token: data.token,
          password: data.password,
          minValidityDate,
          validityDate: new Date(data.validityDate),
          files: [
            ...this.state.requestDocumentList.map((item, index) => ({
              ...item,
            })),
            ...this.state.videoCallList,
          ],
        },
      });
    }

    const getExpiredNumberDays = await axios.get(
      API_GET_PARAMETER_SHARE_DOCUMENT_EXPIRE_NUMBER_DAY
    );
    let expiredNumberDays = 30;

    if (
      getExpiredNumberDays.status === 200 &&
      getExpiredNumberDays?.data?.value &&
      !isNaN(parseInt(getExpiredNumberDays.data.value))
    ) {
      expiredNumberDays = getExpiredNumberDays.data.value;
    }

    let validityDate = moment();
    validityDate.add(expiredNumberDays, "days");

    await this.setState({
      selectedItemKeys: [],
      shareData: {
        token: uuidv4(),
        password: this.generatePassword(),
        minValidityDate,
        validityDate: validityDate.toDate(),
        files: [
          ...this.state.requestDocumentList.map((item, index) => ({ ...item })),
          ...this.state.videoCallList,
        ],
      },
    });
  }

  showSharePopup() {
    this.setState({ popupShareVisible: true });
  }

  hideSharePopup() {
    this.setState({ popupShareVisible: false });
  }

  async shareAllFileDocument() {
    const retrievedData = await this.getShareDocuments();

    // console.log("-------------------------------------------")
    // console.log("retrievedData",retrievedData)
    // console.log("-------------------------------------------")
    if (retrievedData && retrievedData.success) {
      notify("Récupération du partage avec succès", "success", 800);
    } else {
      notify("Création du partage avec succès", "success", 800);
    }

    this.initShareData(retrievedData);
    this.showSharePopup();
  }

  shareDocuments() {
    const { requestId } = this.props;

    let shareData = { ...this.state.shareData };
    let selectedItemKeys = [...this.state.selectedItemKeys];
    shareData.files = shareData.files.filter((file) =>
      selectedItemKeys.includes(file.id)
    );

    const formData = new FormData();
    Object.keys(shareData).forEach((key, index) => {
      if (key !== "minValidityDate") {
        formData.append(
          key,
          key === "validityDate"
            ? shareData[key].toISOString()
            : JSON.stringify(shareData[key])
        );
      }
    });

    axios
      .post(API_SHARE_DOCUMENTS_CREATE.replace("{id}", requestId), formData)
      .then((response) => {
        // console.log("POST RESPONSE:",response.data)
        notify("Sauvegarde du partage avec succès", "success", 800);
        this.hideSharePopup();
      })
      .catch((err) => {
        // console.log("POST ERROR:",err,err.message)
        notify("Erreur de sauvegarde du partage", "error", 500);
      });
  }

  deleteShareDocuments() { }

  async getShareDocuments() {
    const { requestId } = this.props;

    return await axios
      .get(API_SHARE_DOCUMENTS.replace("{id}", requestId))
      .then((response) => {
        // console.log("GET RESPONSE:",response.data)
        return response.data.success ? response.data.data : null;
      });
  }

  documentType = () => {
    let { documentRequestType } = this.props;
    let type = "";
    documentRequestType["type"].forEach((itemFile) => {
      if (
        itemFile.hasOwnProperty("fileFormat") &&
        itemFile.hasOwnProperty("fileType")
      ) {
        itemFile.fileFormat.forEach((fileFormat) => {
          if (fileFormat === "MOV" || fileFormat === "mov")
            type = type + "," + ".mov";
          else type = type + "," + itemFile.fileType + "/" + fileFormat;
        });
      }
    });
    return (type = type.substr(1));
  };

  usePathname() {
    return `${window.location.protocol}//${window.location.host}`;
  }

  sendMailToCustomer = () => {
    this.setState({
      isLoading: true,
    });
    const formData = new FormData();
    formData.append("commentSendMail", this.state.docDescriptionEdit);
    axios
      .post(
        NEW_DOC_MAIL_CUSTOMER.replace("{id}", this.props.requestId),
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            docNameEdit: "",
            popoverComment: null,
            docDescriptionEdit: "",
            editDocName: false,
            isLoading: false,
            checkNewDoc: false,
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  checkExtensionFile = (fileMineType, fileName = "") => {
    const { documentRequestType } = this.props;
    let formatVideoType =
      documentRequestType && Array.isArray(documentRequestType.type)
        ? documentRequestType.type.filter((item) => {
          return item.fileType === fileMineType;
        })
        : [];
    const arrayFileName = fileName ? fileName.split(".").pop() : "";
    return formatVideoType.length &&
      Array.isArray(formatVideoType[0].fileFormat)
      ? !!formatVideoType[0].fileFormat.find(
        (item, index) => arrayFileName === item
      )
      : false;
  };

  getUploadAcceptFile = (fileMineType) => {
    const { documentRequestType } = this.props;
    let formatVideoType =
      documentRequestType && Array.isArray(documentRequestType.type)
        ? documentRequestType.type.filter((item) => {
          return item.fileType === fileMineType;
        })
        : [];
    return formatVideoType.length
      ? Array.isArray(formatVideoType[0].fileFormat)
        ? formatVideoType[0].fileFormat
          .map((item, index) => {
            return "." + item;
          })
          .toString()
        : []
      : [];
  };

  checkMeetingVideo = (documentMeeting) => {
    documentMeeting.forEach((item, key) => {
      fetch(item.pathUrl, {
        method: "HEAD",
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState((prev) => {
              let requestDocumentListTemp = prev.requestDocumentList;
              requestDocumentListTemp = [
                ...requestDocumentListTemp,
                {
                  ...item,
                },
              ];
              return {
                requestDocumentList: requestDocumentListTemp.sort((a, b) => {
                  return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1;
                }),
              };
            });
          }
        })
        .catch((err) => { });
    });
  };

  render() {
    const { classes, t, documentRequestType, dataUser } = this.props;
    const {
      errorFileType,
      errorMessage,
      requestDocumentList,
      loadingDropzone,
      isLoading,
      editDocName,
      requestDocId,
      docNameEdit,
      popoverComment,
      docDescriptionEdit,
      loadingList,
      errorOpen,
      checkNewDoc,
      documentUserCreatedNotSeen,
      selectedItemKeys,
      fileView,
      documentMeetingVideo,
    } = this.state;

    const listFilterStatus = [
      {
        value: requestDocumentList.length,
        label: "Total",
      },
    ];
    // const acceptDropzoneUploadDocument = [".jpeg", ".jpg", ".png", ".doc", ".docx", ".dotx", ".xls", ".xlsx", ".xlsm", ".pdf", ".txt"];

    // const dataSource = new ArrayStore({
    //     key: 'id',
    //     data: this.state.shareData.files,
    //   });

    // const videoCalls = Array.isArray(this.state.requestItem?.videoCalls) ? this.state.requestItem.videoCalls : [];

    // const videoCallBaseUrl = dataUser.data?.videoCallBaseUrl;
    return (
      <div className={classes.filesUploadBlock}>
        {isLoading && <LoadingAction />}
        <Popup
          className="sharePopup"
          visible={this.state.popupShareVisible}
          onHiding={this.hideSharePopup}
          dragEnabled={false}
          // closeOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          title={this.props.t("requestDocument.sharePopup.title")}
          container=".dx-viewport"
          width={"50%"}
          height={"70%"}
        >
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={this.shareButtonOptions}
          />
          {/* <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={this.deleteButtonOptions}
                    /> */}
          <ScrollView width="100%" height="100%">
            <div className="dx-fieldset">
              <div className="dx-fieldset-header">
                {this.props.t("requestDocument.sharePopup.properties")}
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {this.props.t("requestDocument.sharePopup.url")}
                </div>
                <div
                  className="dx-field-value-static copy"
                  onClick={this.copyField}
                >{`${this.usePathname()}/share/${this.state.shareData.token
                  }`}</div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {this.props.t("requestDocument.sharePopup.password")}
                </div>
                <div
                  className="dx-field-value-static copy"
                  onClick={this.copyField}
                >
                  {this.state.shareData.password}
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {this.props.t("requestDocument.sharePopup.validityDate")}
                </div>
                <div
                  className="dx-field-value-static copy"
                  onClick={this.copyField}
                >
                  <DateBox
                    min={this.state.shareData.minValidityDate}
                    value={this.state.shareData.validityDate}
                    type="datetime"
                    onValueChanged={this.onValidityDateChange}
                  />
                </div>
              </div>
              <div className={"dataCopyAll"} ref={this.copyAllRef}>
                {`lien : ${this.usePathname()}/share/${this.state.shareData.token
                  }`}
                <br />
                {`Mot de passe : ${this.state.shareData.password}`}
                <br />
                {`Valide jusqu'au : ${moment(
                  this.state.shareData.validityDate
                ).format("DD/MM/YYYY - HH:mm")}`}
                <br />
              </div>
              <Button
                className={classes.copyAll}
                onClick={() => this.copyField(this.copyAllRef.current)}
              >
                Copier Tout
              </Button>
              <div className="dx-fieldset-header">
                {this.props.t("requestDocument.sharePopup.selectFiles")}
              </div>
              {/* <div>{this.state.selectedItemKeys.join(', ')}</div> */}
              {this.state.popupShareVisible && (
                <FileList
                  selectedItemKeys={selectedItemKeys}
                  onSelectionChange={this.onSelectedItemKeysChange}
                  files={this.state.shareData.files}
                />
              )}
            </div>
          </ScrollView>
        </Popup>
        <Grid container>
          <Grid item xs={12}>
            <div className="title">
              {this.props.t("requestDocument.title")}
              {this.props.dataClient &&
                this.props.dataClient.person &&
                this.props.dataClient.person.fullName
                ? ` - ${this.props.dataClient.person.fullName}`
                : ""}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={5} className={classes.uploadFiles}>
            {!loadingDropzone && (
              <Dropzone
                inputContent={
                  <div className={classes.inputUploadFile}>
                    <img src={UploadDoc} alt="" />
                    <div className="textLabel">
                      {this.props.t("requestDocument.label.uploadFile")}
                    </div>
                  </div>
                }
                ref={(fileUpload) => {
                  this.fileUpload = fileUpload;
                }}
                accept={"*"}
                // accept={requestDocType.code === CLIENT_DOC_TAB_IMAGE_VIDEO ? (this.getUploadAcceptFile(FILE_TYPE_VIDEO)+", "+this.getUploadAcceptFile(FILE_TYPE_IMAGE)) : '*'}
                minSize={0}
                maxSizeBytes={
                  documentRequestType &&
                    documentRequestType.size &&
                    documentRequestType.size.maxSize
                    ? documentRequestType.size.maxSize * 1024 * 1024
                    : 209715200
                }
                multiple={true}
                onChangeStatus={this.handleChangeDropzoneStatus}
              />
            )}
            {errorFileType && (
              <div className={classes.errorFile}>{errorMessage}</div>
            )}
            <ErrorAlert
              snackbarProps={{
                open: errorOpen,
                onClose: () => {
                  this.setState({
                    errorOpen: false,
                  });
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={7}
            className={classes.filesUploadManager}
          >
            <div className="headerFileMana">
              <div className="filterStatus">
                {listFilterStatus.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        "itemStatusFile" +
                        (index === 0
                          ? " first"
                          : listFilterStatus.length === index + 1
                            ? " last"
                            : "")
                      }
                    >
                      <div className="count">{item.value}</div>
                      <div className="label">{item.label}</div>
                    </div>
                  );
                })}
              </div>
              {checkNewDoc && (
                <Button
                  className="hasNewDocMailToCustomer"
                  style={
                    popoverComment
                      ? {
                        borderRadius: "9px 9px 0 0",
                      }
                      : {}
                  }
                  onClick={(event) => {
                    this.setState({
                      popoverComment: event.currentTarget,
                      // docDescriptionEdit: "",
                      requestDocId: null,
                    });
                    // this.sendMailToCustomer()
                  }}
                >
                  {this.props.t(
                    "request.filesUploadBlock.hasNewDocMailToCustomer"
                  )}
                </Button>
              )}
            </div>
            <div className="listFiles">
              <Table
              // stickyHeader
              >
                <TableHead>
                  <TableRow>
                    {/*<TableCell width={50}></TableCell>*/}
                    <TableCell
                      style={{
                        minWidth: 180,
                      }}
                    >
                      {this.props.t("requestDocument.label.files")}
                    </TableCell>
                    <TableCell>
                      {this.props.t("requestDocument.label.date")}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {!loadingList && (
                  <React.Fragment>
                    <TableBody>
                      {requestDocumentList.map((item, index) => {
                        let { fileType, fileSubType } =
                          this.getTypeAnSubTypeFromMimeType(item.mimeType);
                        return (
                          <TableRow className="notSentTr" key={index}>
                            <TableCell className={"columnDocInfoWrapper"}>
                              <div className="docInfo">
                                <div className="fileView">
                                  {fileType === FILE_TYPE_VIDEO ? (
                                    <div className="video">
                                      <a
                                        href={item.pathUrl}
                                        download
                                        target="_blank"
                                      >
                                        <img src={VideoCategoryIcon} alt="" />
                                      </a>
                                    </div>
                                  ) : fileType === FILE_TYPE_IMAGE ? (
                                    <a
                                      href={item.pathUrl}
                                      download
                                      target="_blank"
                                    >
                                      <img
                                        src={item.pathUrl}
                                        alt=""
                                        className="image"
                                      />
                                    </a>
                                  ) : fileType === FILE_TYPE_APPLICATION ? (
                                    <React.Fragment>
                                      {fileSubType === FILE_SUBTYPE_PDF ? (
                                        <a
                                          href={item.pathUrl}
                                          download
                                          target="_blank"
                                        >
                                          <img src={DocPdfIcon} alt="" />
                                        </a>
                                      ) : (
                                        <a
                                          href={item.pathUrl}
                                          download
                                          target="_blank"
                                        >
                                          <img src={DocIcon} alt="" />
                                        </a>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <a
                                      href={item.pathUrl}
                                      download
                                      target="_blank"
                                    >
                                      <img src={DocIcon} alt="" />
                                    </a>
                                  )}
                                </div>
                                {requestDocId === item.id && editDocName ? (
                                  <div className="docNameInput">
                                    <Input
                                      onChange={(event) => {
                                        this.setState({
                                          docNameEdit: event.target.value,
                                        });
                                      }}
                                      onKeyUp={(event) => {
                                        const keyCode = event.keyCode;
                                        if (
                                          keyCode === "Enter" ||
                                          keyCode === 13
                                        ) {
                                          this.editRequestDocument(
                                            requestDocId,
                                            {
                                              docName: docNameEdit,
                                            }
                                          );
                                        }
                                      }}
                                      name={"docNameEdit"}
                                      value={docNameEdit}
                                      type={"text"}
                                      placeholder={"doc name"}
                                      inputProps={{ maxlength: 200 }}
                                    />
                                    <div className="actionDocName">
                                      <Button
                                        className="btnActionDocName btnSaveDocName"
                                        onClick={() => {
                                          this.editRequestDocument(
                                            requestDocId,
                                            {
                                              docName: docNameEdit,
                                            }
                                          );
                                        }}
                                      >
                                        <SaveMuiIcon />
                                      </Button>
                                      <Button
                                        className="btnActionDocName btnCancelDocName"
                                        onClick={() => {
                                          this.setState({
                                            requestDocId: null,
                                            docNameEdit: "",
                                            editDocName: false,
                                          });
                                        }}
                                      >
                                        <CancelIcon />
                                      </Button>
                                    </div>
                                  </div>
                                ) : !item.canNotEdit ? (
                                  <Tooltip title={item.docName} arrow>
                                    <div
                                      className="docName"
                                      onClick={() => {
                                        this.setState({
                                          requestDocId: item.id,
                                          docNameEdit: item.docName,
                                          editDocName: true,
                                        });
                                      }}
                                    >
                                      {item.docName ?? ""}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={item.docName} arrow>
                                    <div className="docName docNameNotEdit">
                                      {item.docName ?? ""}
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                              {!!(
                                Array.isArray(documentUserCreatedNotSeen) &&
                                documentUserCreatedNotSeen.includes(item.id)
                              ) ? (
                                <div
                                  className={`${classes.alertNotSeen} alertDocumentName`}
                                >
                                  {t("requestDocument.new")}
                                </div>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell>
                              <div className="dateView">
                                {item.createdAt
                                  ? moment(item.createdAt).format("DD.MM.YYYY")
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              {item.canNotEdit ? (
                                ""
                              ) : item.description !== null ? (
                                <Tooltip title={item.description}>
                                  <Button
                                    className={
                                      "btnComment hasComment" +
                                      (requestDocId === item.id &&
                                        popoverComment
                                        ? " commentActive"
                                        : "")
                                    }
                                    onClick={(event) => {
                                      this.setState({
                                        requestDocId: item.id,
                                        popoverComment: event.currentTarget,
                                        docDescriptionEdit:
                                          item.description ?? "",
                                      });
                                    }}
                                  >
                                    <ChatIcon />
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Button
                                  className={
                                    "btnComment" +
                                    (requestDocId === item.id && popoverComment
                                      ? " commentActive"
                                      : "")
                                  }
                                  onClick={(event) => {
                                    this.setState({
                                      requestDocId: item.id,
                                      popoverComment: event.currentTarget,
                                      docDescriptionEdit:
                                        item.description ?? "",
                                    });
                                  }}
                                >
                                  <ChatIcon />
                                </Button>
                              )}
                            </TableCell>
                            <TableCell className="deleteAction">
                              {item.isMeetingVideo ? (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!!item.isShowClient}
                                      onChange={(e) => {
                                        this.editRequestDocument(item.id, {
                                          isShowClient: !item.isShowClient
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    <div className="showClientLabel">
                                      {t("requestDocument.label.showClient")}
                                    </div>
                                  }
                                />
                              ) : (
                                <IconButton
                                  onClick={() => this.downloadFileAction(item)}
                                >
                                  <GetAppIcon />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell className="deleteAction">
                              <IconButton
                                onClick={() => this.viewFileAction(item)}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell className="deleteAction">
                              {/*{*/}
                              {/*    !(*/}
                              {/*        item.createdBy === null*/}
                              {/*    ||*/}
                              {/*    (*/}
                              {/*        item.createdBy*/}
                              {/*        && item.createdBy.instanceof === USER_TYPE_CLIENT*/}
                              {/*    )) &&*/}
                              {(!item.canNotEdit && !item.canNotDelete) && (
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      requestDocId: item.id,
                                      dialogDelete: true,
                                    });
                                  }}
                                >
                                  <CustomInlineSvg
                                    svgProps={{
                                      src: DeleteIcon,
                                    }}
                                  />
                                </IconButton>
                              )}
                              {/*}*/}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </React.Fragment>
                )}
              </Table>
              {loadingList && (
                <div className={classes.loadingList}>
                  <CircularProgress />
                </div>
              )}
              {!loadingList && requestDocumentList.length === 0 && (
                <div className="noDocument">
                  {this.props.t("text.noResult")}
                </div>
              )}
              {this.state.dialogDelete && (
                <DialogForm
                  styleNumber={2}
                  dialogProps={{
                    open: this.state.dialogDelete,
                  }}
                  disagreeButtonProps={{
                    handleDisagree: () =>
                      this.setState({
                        dialogDelete: false,
                        requestDocId: null,
                      }),
                  }}
                  agreeButtonProps={{
                    handleAgree: () => {
                      this.deleteRequestDocument();
                    },
                  }}
                  messageProps={{
                    content: (
                      <div className={classes.messageDialog}>
                        <div className={"icon"}>
                          <span>{this.props.t("requestDocument.delete")}</span>
                        </div>
                        {this.props.t("request.list.stopRequest")}
                      </div>
                    ),
                  }}
                />
              )}
              {popoverComment && !isLoading && (
                <Popover
                  open={true}
                  anchorEl={popoverComment}
                  onClose={() => {
                    // this.setState({
                    //     popoverComment: null,
                    //     docDescriptionEdit: '',
                    //     requestDocId: null
                    // })
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.popoverComment}
                >
                  <div className={classes.commentWrapper}>
                    <div
                      className={"closeButton"}
                      onClick={() => {
                        localStorage.setItem(
                          "commentSendMail",
                          this.state.docDescriptionEdit
                        );
                        this.setState({
                          popoverComment: null,
                          // docDescriptionEdit: '',
                          requestDocId: null,
                        });
                      }}
                    >
                      <CustomInlineSvg
                        svgProps={{
                          src: closeButton,
                        }}
                        scale="0.6"
                      />
                    </div>
                    <Input
                      onChange={(event) => {
                        this.setState({
                          docDescriptionEdit: event.target.value,
                        });
                      }}
                      name={"docDescriptionEdit"}
                      value={docDescriptionEdit}
                      type={"text"}
                      placeholder={t(
                        requestDocId
                          ? "requestDocument.commentDocument"
                          : "requestDocument.commentSendMail"
                      )}
                      multiline={true}
                      className="inputDes"
                      inputProps={{ maxLength: 900 }}
                    />
                    {requestDocId ? (
                      <Button
                        className="btnSaveDescription"
                        onClick={() => {
                          this.editRequestDocument(requestDocId, {
                            description: docDescriptionEdit,
                          });
                        }}
                      >
                        <SaveMuiIcon />
                      </Button>
                    ) : (
                      <Button
                        className="btnSaveDescription btnSendDescription"
                        onClick={() => {
                          localStorage.removeItem("commentSendMail");
                          this.sendMailToCustomer();
                        }}
                      >
                        <CustomInlineSvg svgProps={{ src: SendIcon }} />
                      </Button>
                    )}
                  </div>
                </Popover>
              )}
            </div>
            <div className={classes.actionFilesUpload}>
              {Array.isArray(requestDocumentList) &&
                requestDocumentList.length > 0 && (
                  <>
                    <Button
                      className="btnAction btnShare"
                      onClick={this.shareAllFileDocument}
                    >
                      <CustomInlineSvg
                        svgProps={{
                          src: SaveIcon,
                        }}
                      />
                      <div className="btnText">
                        {this.props.t("requestDocument.btn.share")}
                      </div>
                    </Button>
                    <Button
                      className="btnAction btnSave"
                      onClick={this.downloadAllFileDocument}
                    >
                      <CustomInlineSvg
                        svgProps={{
                          src: SaveIcon,
                        }}
                      />
                      <div className="btnText">
                        {this.props.t("requestDocument.btn.save")}
                      </div>
                    </Button>
                  </>
                )}
              {/*<Button*/}
              {/*    className="btnAction btnSend"*/}
              {/*>*/}
              {/*    <CustomInlineSvg  svgProps={{*/}
              {/*        src: SendIcon*/}
              {/*    }}/>*/}
              {/*    <div className="btnText">*/}
              {/*        {this.props.t('requestDocument.btn.send')}*/}
              {/*    </div>*/}
              {/*</Button>*/}
            </div>
          </Grid>
        </Grid>
        {fileView && (
          <GeneralModal
            className={"previewModal"}
            open={true}
            handleClose={this.handleCloseModal}
            content={<ContentPreviewModal2 files={[fileView]} keyFile={0} />}
            saveButton={false}
            cancelButton={false}
          />
        )}
        <SuccessAlert
          snackbarProps={{
            open: this.state.openUploadDocSuccessAlert,
            onClose: () => {
              this.setState({
                openUploadDocSuccessAlert: false,
              });
            },
          }}
          message={t("modal.upload.uploadFileSuccess")}
        />
      </div>
    );
  }
}

FilesUploadBlock.defaultProps = {};

FilesUploadBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataUser: state.authReducer.dataUser,
    currency: state.applicationReducer.currency,
    documentRequestType: state.applicationReducer.documentRequestType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentRequestValid: () => dispatch(getDocumentRequestValid()),
    removeValueToRequestDocumentManagerNotSeenRedux: (value) =>
      dispatch(removeValueToRequestDocumentManagerNotSeenRedux(value)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  withRouter
)(FilesUploadBlock);
