import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

const styles = {
    mainBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999,
        backgroundColor: 'rgba(255,255,255,0.95)'
    },
    backgroundContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center'
    },
    contentTitle: {
        fontSize: '40px',
        color: 'blue',
        paddingBottom: 15
    }
};

class UploadingAction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props
        }
    }
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.mainBackground}>
                <div className={classes.backgroundContent}>
                    <div className={classes.contentTitle}>Chargement du/des fichier(s) en cours...</div>
                    {/*<CircularProgress variant="determinate" value={this.props.progress}/>*/}
                    <CircularProgress/>

                </div>
            </div>
        );
    }

}
UploadingAction.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UploadingAction);