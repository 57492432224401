import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import InformationPageCustom from "./InformationPageCustom";
import {Grid, Input, TextField, Tooltip} from "@material-ui/core";
import RowFormColumn from "../../theme/form/RowFormColumn";
import CustomInput from "../../theme/CustomElements/CustomInput";
import {NUNITO} from "../../constants/fontCss";
import userIcon from "../../images/profile/user.svg";
import imgSelection from "../../images/profile/imgSelect.svg";
import CustomButton from "../../theme/CustomElements/CustomButton";
import infoUserIcon from "../../images/infomation/user.svg"
import mailIcon from "../../images/infomation/mail.svg"
import phoneIcon from "../../images/infomation/phone.svg"
import addressIcon from "../../images/infomation/address.svg"
import homeIcon from "../../images/infomation/home.svg"
import villeIcon from "../../images/infomation/corporation.svg"
import statusIcon from "../../images/infomation/status.svg"
import timeIcon from "../../images/infomation/timeIcon.svg"
import {
    XL,
    MD,
    STRING,
    TRUE_NUMBER,
    FALSE_NUMBER,
    USER_TYPE_CLIENT,
    USER_TYPE_REPAIRER,
    USER_TYPE_ADMIN,
    USER_TYPE_SUPER_ADMIN,
    COUNTRY_DEFAULT, EMAIL_ADDRESS_BCC, EMAIL
} from "../../constants/constants";
import axios from "axios";
import {API_CREATE_USER, API_EDIT_USER, API_SHOW_USER} from "../../constants/apiSuffix";
import LoadingAction from "../../theme/LoadingAction";
import Dropzone from "react-dropzone";
import {errorsMessPerson} from "../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../functions/checkCondition";
import {NavLink, withRouter} from "react-router-dom";
import {CREATE_STAFF, EDIT_STAFF, HOME, MANAGEMENT_STAFF, PROFILE} from "../../constants/links";
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import moment from "moment";
import {ConvertToDateY_M_DH_m_s} from "../../functions/datetime";
import DocumentPdfIon from "../../images/staff/documentPdf.svg";
import docIcon from "../../images/staff/doc.svg";
import deleteIcon from "../../images/staff/delete.svg";
import excelIcon from "../../images/staff/excel.svg";
import uploadFileIcon from "../../images/profile/uploadFile.svg";
import {convertByteToNumberUnit} from "../../functions/functions";
import {Redirect} from "react-router";
import * as authActions from "../../_actions/auth";
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
// import AlertCustom from "../../theme/form/Alert/AlertCustom";
import countries from "i18n-iso-countries";
import AppCountryAutocomplete from "../../theme/form/Autocomplete/AppCountryAutocomplete";
import AppKeyboardDatePicker from "../../theme/form/DatePicker/AppKeyboardDatePicker";
// import FormControl from "@material-ui/core/FormControl";
import AppAutocomplete from "../../theme/form/Autocomplete/AppAutocomplete";
import * as applicationActions from "../../_actions/application";
const styles = theme => ({
    formStyle: {
        background: "#fff",
        borderRadius: 15,
        position: 'relative',
        filter: 'drop-shadow(0 0 0.5rem black)',
        padding: '2rem 2rem 2rem 0',


    },
    profileBlockWrapper: {
        padding: '0 0 2rem 2rem',
        // marginLeft: '1rem',

    },
    workBlock: {
        background: '#F5F5F5',
        borderRadius: 15,
        padding: '1rem 0',
        width: '100%',
        '& .itemRowLeft': {
            paddingRight: '0.5rem'
        },
        '& .itemRowRight': {
            paddingLeft: '0.5rem'
        },
        '& .selectBlockWork': {
            width: '100%',
            border: '1px solid #E0E0E0',
            background: '#fff',
            borderRadius: 6,
            overflow: 'hidden',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            '& .statusItem': {
                padding: '0.5rem 0 0.5rem  0.5rem',
                width: '100%',
                '& input': {
                    background: '#fff',
                    textAlign: 'center',
                    borderRadius: 15,
                    textTransform: 'uppercase',
                },
                '&.on': {
                    '& input': {
                        background: '#e1f2ea',
                        color: '#0DBE2A',
                    }
                },
                '&.off': {
                    '& input': {
                        background: '#f6e5eb',
                        color: '#DF373C',
                    }
                },
            },
        }
    },
    profileBlock: {
        background: '#F5F5F5',
        borderRadius: 15,
        padding: '1rem 0',
        width: '100%',
        '& .columnWrapperLeft':{
            alignItems: 'baseline'
        },
        '& .blockBodyWrapper': {
            width: '100%',
            padding: '1.25rem',
            '& .selectBlock':{
                alignItems: 'baseline'
            },
            '& .addressBlock':{
                alignItems: 'baseline'
            },
            '& .avatarWrapper': {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                '& .avatarBlock': {
                    borderRadius: '50%',
                    height: 100,
                    width: 100,
                    overflow: 'hidden',
                    '&:hover': {
                        '& .removeAvatar': {
                            display: "block"
                        }
                    },
                    '& .dropAvatar': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        borderRadius: '50%',
                        height: 100,
                        width: 100,
                        overflow: 'hidden',


                    },
                    '& .avatar': {
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        cursor: 'pointer',
                    },
                    '& .removeAvatar': {
                        display: "none",
                        '&:hover': {
                            background: '#929aae',
                        },
                        background: '#efefef',
                        height: 25,
                        marginTop: -25,
                        padding: '0 0.5rem',
                        fontWeight: 900,
                        cursor: 'pointer',
                        fontSize: '1rem',
                        color: '#ed1313',
                        textAlign: 'center',
                        width: '100%',
                        position: 'relative'
                    }
                },
                '& .avatarSelection': {
                    '&:hover': {
                        '&::before': {
                            background: '#e1e0e0',
                        }
                    },
                    marginTop: '0.5rem',
                    background: 'transparent !important',
                    borderRadius: 6,
                    border: '1px solid #E0E0E0',
                    '& span': {
                        color: '#001529'
                    }
                },
            }
        },
        '& .titleBlock': {
            width: '100%',
            fontFamily: NUNITO,
            fontSize: '1.125rem',
            fontWeight: 700,
            lineHeight: 1.4,
            height: '1.125rem',
            display: 'flex',
            flexDirection: 'row',
            color: '#001529',
            // padding: '0.5rem 0',
            '&:before': {
                borderBottom: '1px solid #E0E0E0',
                content: '""',
                // flex: '0.5',
                width: 20
            },
            '&:after': {
                borderBottom: '1px solid #E0E0E0',
                content: '""',
                flex: '4',
            },
            '& .titleHeaderContent': {
                padding: '0 0.3em'
            },
        },
        [theme.breakpoints.between(MD, XL)]: {
            '& .columnWrapperLeft': {
                paddingRight: '0.5rem'
            },
            '& .columnWrapperRight': {
                paddingLeft: '0.5rem'
            },
        },
        '& input': {
            background: '#fff'
        },
        '& .labelRowFormColumn': {
            fontFamily: NUNITO,
            fontSize: '1rem',
            fontWeight: 700,
            color: '#001529'
        },
        '& .inputRowFormColumn': {
            fontFamily: NUNITO,
            fontSize: '1rem',
            fontWeight: 400,
        }
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& button': {
            borderRadius: 15,
            padding: '1rem 1.5rem ',
            '& span': {
                fontSize: '1rem',
                fontWeight: 700
            }
        },
        '& a':{
            textDecoration: 'none',
            '& button': {
                background: '#cacaca !important',
                marginRight: '1rem',
                '&::before':{
                    background: '#9c9c9c !important',
                }
            }
        }
    },
    btnSubmit:{
        background: '#0000FF !important',

    },
    documentsWrapper: {
        padding: '0 0 2rem 2rem',
        '& .titleBlock': {
            // paddingLeft: '1rem',
            '&:before': {
                borderBottom: '1px solid #E0E0E0',
                content: '""',
                // flex: '0',
            },
        },
    },
    documentsOldWrapper: {
        padding: '1rem',
        '& .documentsOld': {
            background: '#fff',
            width: '100%',
            border: '1px solid #E0E0E0',
            borderRadius: 6,
            padding: '0.5rem',
            maxHeight: 200,
            overflow: 'auto',
        }
    },
    documentItem: {
        borderBottom: ' 1px solid #E0E0E0',
        boxSizing: 'border-box',
        // borderRadius: 15,
        padding: '0.5rem 0.5rem 0.5rem 1rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .docItemInfoWrapper': {
            display: 'flex',
            alignItems: 'center',
            width: '80%',
            '& img': {
                height: 40
            },
            '& .docItemInfo': {
                paddingLeft: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                '& .nameFile': {
                    fontSize: '1rem',
                    fontWeight: 700,
                    lineHeight: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    '-webkit-line-clamp': 2,
                    '-webkit-box-orient': 'vertical',
                    flexGrow: 1,
                    whiteSpace: "pre-line",
                    '& a': {
                        textDecoration: 'none'
                    }
                },
                '& .sizeFile': {
                    fontWeight: 600,
                    color: '#929AAE',
                    fontSize: '0.75rem'
                }
            }
        },
        '& .removeWrapper': {
            cursor: 'pointer',
            '&:hover': {
                '& svg>path': {
                    fill: '#f83232'
                }
            }
        }
    },
    documentsUploadWrapper: {
        padding: '1rem',
        width: '100%',
        '& .documentsUpload': {
            width: '100%',
            '& .dropDocumentWrapper': {
                width: '100%',
                padding: '1rem 0.5rem',
                background: ' #FFFFFF',
                border: '1px dashed #BDBDBD',
                boxSizing: 'border-box',
                borderRadius: 4,
                height: 200,
                overflow: 'auto',
                '& .uploadFileNewHiddenWrapper':{
                    display: 'none'
                },
                '& section':{
                    height: '100%',
                    '&.documentsNewUploadListWrapper': {
                        height: 'auto'
                    },
                },
                '& .documentsUploadListWrapper': {
                    height: '100%',
                    '& .documentsUploadList': {
                        height: '100%',
                        '& .documentNewItem': {}
                    },
                    '& .emptyDocWrapper': {
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        '& .dropFile':{
                            color: '#B6B6B6',
                            marginTop: 10
                        },
                        '& .browse':{
                            color:'#0000ff',
                            fontWeight: 700
                        }
                    }
                }
            }
        },
        '& .addFileUpload':{
            height:' 1.5rem',
            marginTop: '1rem'
        },
        '& .uploadFileNewHidden': {}
    }
})

const headTranslate = "profile.form.";
const FILE_2_MB_SIZE_BYTES = 2097152;

const formValueDefault = {
    email: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    currentPassword: "",
    password: "",
    confirmPassword: "",
    addressCity: "",
    addressPostalCode: "",
    addressCountry: "",
    addressDescription: "",
    avatar: null,
    group: null,
    enabled: null,
    startWorkAt: null,
    endWorkAt: null,
    documentsOld: [],
    documentsNew: [],
    documentsRemoveOld: [],
    countDocumentOld: 0,
    hasPassword: true,
}

const arrayEnabled = [
        {
            value: TRUE_NUMBER,
            label: "reactAdmin.user.detail.block1.enabledStatus.on"
        },
        {
            value: FALSE_NUMBER,
            label: "reactAdmin.user.detail.block1.enabledStatus.off"
        }
];

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: {...formValueDefault},
            errors: {},
            isLoading: true,
            arrGroupsUser: [],
            redirectToUrl: ''
        };
    }

    getUserShow = () => {
        const {dataUser, t, location, match} = this.props;
        let arrGroupsUser = [];

        if (dataUser.data && dataUser.data.group === USER_TYPE_ADMIN) {
            arrGroupsUser = [
                {
                    value: USER_TYPE_REPAIRER,
                    label: t("rolesUser." + USER_TYPE_REPAIRER)
                },
            ]
        } else if (dataUser.data && dataUser.data.group === USER_TYPE_SUPER_ADMIN) {
            arrGroupsUser = [
                {
                    value: USER_TYPE_REPAIRER,
                    label: t("rolesUser." + USER_TYPE_REPAIRER)
                },
                {
                    value: USER_TYPE_ADMIN,
                    label: t("rolesUser." + USER_TYPE_ADMIN)
                }
            ];
        }

        if (location.pathname !== CREATE_STAFF) {
            const userId = (location.pathname === PROFILE) ? dataUser.data.id : match.params.id;
            axios.get(API_SHOW_USER.replace("{id}", userId))
                .then(response => {
                    let userDetail = {};
                    if (response.status === 200 && response.data && response.data.item) {
                        const dataUserNew = response.data.item;
                        // console.log(dataUserNew)
                        const dataPerson = dataUserNew.person ?? {};
                        userDetail = {
                            ...this.state.formValue,
                            email: location.pathname === PROFILE && dataUserNew.instanceof === USER_TYPE_CLIENT ? (dataPerson.email ? dataPerson.email : "") : dataUserNew.email,
                            lastName: dataPerson.lastName ?? "",
                            firstName: dataPerson.firstName ?? "",
                            phoneNumber: dataPerson.phoneNumber ?? "",
                            currentPassword: "",
                            password: "",
                            confirmPassword: "",
                            addressCity: dataPerson.addressCity ?? "",
                            addressPostalCode: dataPerson.addressPostalCode ?? "",
                            addressCountry: dataPerson.addressCountry ?? COUNTRY_DEFAULT,
                            addressDescription: dataPerson.addressDescription ?? "",
                            avatar: dataPerson.avatar && dataPerson.avatar.pathUrl ? dataPerson.avatar.pathUrl : null,
                            group: dataUserNew.instanceof ? {value: dataUserNew.instanceof, label: t("rolesUser."+dataUserNew.instanceof)} : "",
                            instanceof: dataUserNew.instanceof,
                            enabled: dataUserNew.enabled ? {value: TRUE_NUMBER, label: t("reactAdmin.user.detail.block1.enabledStatus.on")} : {value: FALSE_NUMBER, label: t("reactAdmin.user.detail.block1.enabledStatus.off")},
                            startWorkAt: dataUserNew.startWorkAt ? moment(dataUserNew.startWorkAt) : null,
                            endWorkAt: dataUserNew.endWorkAt ? moment(dataUserNew.endWorkAt) : null,
                            documentsOld: dataPerson.documents ?? [],
                            countDocumentOld: dataPerson.documents && Array.isArray(dataPerson.documents)? dataPerson.documents.length:0,
                            hasPassword: dataUserNew.hasPassword
                        };
                    }
                    if (userDetail.group && arrGroupsUser.length < 1) {
                        arrGroupsUser = [
                            {
                                value: userDetail.group,
                                label: t("rolesUser." + userDetail.group)
                            }
                        ]
                    }
                    this.setState({
                        formValue: userDetail,
                        isLoading: false,
                        arrGroupsUser: arrGroupsUser
                    })
                })
        }else {
            const userDetail = {...this.state.formValue};
            // if (arrGroupsUser.length === 1) {
            //     userDetail.group = arrGroupsUser[0].value;
            // }
            this.setState({
                isLoading: false,
                arrGroupsUser: arrGroupsUser,
                formValue: userDetail
            })
        }
    }

    componentDidMount() {
        this.getUserShow()
    }

    handleChange(event, field) {
        let {errors, formValue} = this.state;
        let valueField = null;
        switch (field) {
            case "documentsNew":
                valueField = formValue.documentsNew.concat(event);
                break;
            case "addressCountry":
                valueField = event ?event.code:"";
                break;
            case "group":
            case "enabled":
            case "avatar":
                valueField = event;
                break;
            // case "enabled":
            //     valueField = event.target.checked;
            //     break;
            case 'lastName':
                valueField = event.target.value.toUpperCase();
                break;
            case 'firstName':
                valueField = event.target.value;
                break;
            case 'password':
                valueField = event.target.value;
                if (valueField === formValue.confirmPassword && errors.hasOwnProperty('confirmPassword') && valueField) {
                    delete (errors['confirmPassword']);
                }
                break;
            case 'confirmPassword':
                valueField = event.target.value;
                if (valueField === formValue.password && errors.hasOwnProperty(field) && valueField) {
                    delete (errors[field]);
                }
                break;
            case 'startWorkAt':
            case 'endWorkAt':
                if (event && moment(event).isValid()) {
                    valueField = moment(moment(event).format("YYYY-MM-DD 00:00:00"));
                } else {
                    valueField = event
                }
                break;
            default:
                valueField = event.target.value;
                break;
        }

        if (field !== "confirmPassword" && errors.hasOwnProperty(field) && valueField) {
            delete (errors[field]);
        }

        formValue[field] = valueField;
        this.setState({
            formValue: formValue,
            errors: errors
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let {formValue, errors} = this.state;

        const {t, dataUser, location, match} = this.props;
        let redirectToUrl = "";
        for (let [key, value] of Object.entries(formValue)) {
            if (errorsMessPerson.hasOwnProperty(key)) {
                errors = checkSubmitSendDataERROR(value, errorsMessPerson[key].type, errors, key, errorsMessPerson[key].errorByField, t)
            }
        }

        if (formValue["email"]) {
            errors = checkSubmitSendDataERROR(formValue["email"], EMAIL, errors, "email", ("errors.isNotValid"), t)
        }else {
            errors = checkSubmitSendDataERROR(formValue["email"], STRING, errors, "email", ("errors.isNotValid"), t)
        }

        if(!formValue.enabled){
            errors.enabled = t(headTranslate + "errors.enabled");
        }

        if(!formValue.group){
            errors.group = t(headTranslate + "errors.roles");
        }

        if(formValue["email"]){
            errors = checkSubmitSendDataERROR(formValue["email"], EMAIL, errors, "email", ("errors.isNotValid"),  this.props.t)
        }

        if (formValue.currentPassword || formValue.password || formValue.confirmPassword) {
            if (!formValue.currentPassword && location.pathname !== CREATE_STAFF && formValue.formValue) {
                errors.currentPassword = t(headTranslate + "errors.currentPasswordEmpty");
            }
            if (!formValue.password) {
                errors.password = t(headTranslate + "errors.passwordEmpty");
            }
            if (formValue.password !== formValue.confirmPassword) {
                errors.confirmPassword = t(headTranslate + "errors.confirmPasswordMatch");
            }
        } else if(formValue.password.length===0 && location.pathname === CREATE_STAFF) {
            errors.password = t(headTranslate + "errors.passwordEmpty");
        } else {
            delete errors["currentPassword"];
            delete errors["password"];
            delete errors["confirmPassword"];
        }
        if (Object.keys(errors) < 1) {
            this.setState({
                isLoading: true
            })
            let routerApiName = "";
            if (location.pathname !== CREATE_STAFF) {
                const userId = (location.pathname === PROFILE) ? dataUser.data.id : match.params.id;
                routerApiName = API_EDIT_USER.replace("{id}", userId);
            }else{
                routerApiName = API_CREATE_USER;
            }
            const formData = new FormData();
            formData.append('person[email]', formValue.email);
            formData.append('person[phoneNumber]', formValue.phoneNumber??"");
            formData.append('person[firstName]', formValue.firstName??"");
            formData.append('person[lastName]', formValue.lastName??"");
            formData.append('person[addressCity]', formValue.addressCity??"");
            formData.append('person[addressPostalCode]', formValue.addressPostalCode??"");
            formData.append('person[addressCountry]', formValue.addressCountry??"");
            formData.append('person[addressDescription]', formValue.addressDescription??"");
            formData.append('person[avatar][documentFile]', formValue.avatar ?? "");
            if(location.pathname === CREATE_STAFF){
                formData.append('person[user][userType]', formValue.group ? formValue.group.value ? formValue.group.value : formValue.group : "");
            }
            if (formValue.currentPassword || location.pathname === CREATE_STAFF) {
                if (location.pathname === PROFILE) {
                    formData.append('person[user][passwordOld]', formValue.currentPassword);
                }
                formData.append('person[user][password][first]', formValue.password);
                formData.append('person[user][password][second]', formValue.confirmPassword);
            } else if (!formValue.hasPassword) {
                formData.append('person[user][password][first]', formValue.password);
                formData.append('person[user][password][second]', formValue.confirmPassword);
            }
            if (location.pathname !== PROFILE) {
                formData.append('person[user][startWorkAt]', formValue.startWorkAt ? ConvertToDateY_M_DH_m_s(formValue.startWorkAt) : "");
                formData.append('person[user][endWorkAt]', formValue.endWorkAt ? ConvertToDateY_M_DH_m_s(formValue.endWorkAt) : "");
                formData.append('person[user][enabled]', formValue.enabled ? formValue.enabled.value ? formValue.enabled.value : formValue.enabled : "");
            }
            if (formValue.documentsNew && Array.isArray(formValue.documentsNew) && formValue.documentsNew.length) {
                formValue.documentsNew.map((item, key) => {
                        formData.append(`person[documents][${(key+formValue.countDocumentOld)}][documentFile]`, item);
                    }
                )
            }
            if (formValue.documentsRemoveOld && Array.isArray(formValue.documentsRemoveOld) && formValue.documentsRemoveOld.length) {
                formValue.documentsRemoveOld.map((item, key) => {
                        formData.append(`person[documentsRemoveOld][${key}]`, item.id);
                    }
                )
            }
            if (match.path === EDIT_STAFF) {
                formData.append('person[user][group]', formValue.group ? formValue.group.value ? formValue.group.value : formValue.group : "");
            }
            axios.post(routerApiName, formData)
                .then(response => {
                    if (response.status === 200) {
                        if(location.pathname === CREATE_STAFF){
                            redirectToUrl = EDIT_STAFF.replace(":id",response.data.item.user? response.data.item.user.id: null)
                        }
                        if(location.pathname === PROFILE){
                            let newDataUser = {...dataUser,
                                data:{...dataUser.data,
                                    avatar:response.data && response.data.item && response.data.item.avatar && response.data.item.avatar.pathUrl,
                                    firstName: response.data && response.data.item && response.data.item.firstName,
                                    lastName: response.data && response.data.item && response.data.item.lastName
                                }}
                            this.props.setDataUser(newDataUser)
                        }
                        this.setState({
                            formValue: {...formValueDefault},
                            redirectToUrl: redirectToUrl
                        }, function () {
                            this.props.setOpenNoticeSuccess()
                            this.getUserShow();
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.props.setOpenNoticeError()
                    if (error && ((error.errors && error.errors.children["plainPassword"]) || (error.response && error.response.status === 400 && error.response.response_code && error.response.response_code === 'CONFIRM_NEW_PASSWORD_INCORRECT'))) {
                        errors.password = this.props.t('profile.changePassword.validate.newPassword.confirm');
                        errors.confirmPassword = this.props.t('profile.changePassword.validate.confirmNewPassword.confirm');
                        this.setState({
                            isLoading: false,
                            errors: errors,
                        })
                    } else if (error.response && error.response.status === 400 && error.response.response_code && error.response.response_code === 'CONFIRM_NEW_PASSWORD_INCORRECT') {
                        errors.currentPassword = this.props.t('profile.form.errors.currentPasswordMatch');
                        this.setState({
                            isLoading: false,
                            errors: errors,
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                        })
                    }
                });
        } else {
            this.setState({
                isLoading: false,
                errors: errors,
            }, function (){
                this.props.setOpenNoticeError()
            })
        }
    }

    removeDocumentFormValue = (field, keyArray) => {
        const {formValue} = this.state;
        let formValueNew = {...formValue};
        if (Array.isArray(formValue[field])) {
            if(field === "documentsOld" && formValue[field][keyArray]){
                let documentsRemoveOld = [...formValue.documentsRemoveOld];
                documentsRemoveOld.push(formValue[field][keyArray]);
                formValueNew = {...formValueNew, documentsRemoveOld: documentsRemoveOld};
            }
            const arrayFilter = formValue[field].filter((item, key) => key !== keyArray);
             formValueNew = {...formValueNew, [field]: arrayFilter};
            this.setState({
                formValue: formValueNew
            })
        }

    }
    blockWorkRender = () => {
        const {
            classes, t
        } = this.props;
        const {formValue, errors, arrGroupsUser} = this.state;
        return (
            <div className={`${classes.profileBlock} ${classes.workBlock}`}>
                <div className={"titleBlock"}>
                    <div
                        className={"titleHeaderContent"}>{t(headTranslate + 'titleBlockWork')}</div>
                </div>
                <Grid container className={"blockBodyWrapper"}>
                    <Grid  className={"selectBlock"} container>
                        <Grid  item xs={6} className={"itemRowLeft"}>
                            <RowFormColumn
                                label={t(headTranslate + 'enabled')}
                                imgSrcLabel={statusIcon}
                                input={
                                    <AppAutocomplete
                                        className={"selectBlockWork"}
                                        options={arrayEnabled}
                                        getOptionLabel={(option) => t(option.label)}
                                        value={formValue.enabled}
                                        onChange={(event, value)  => this.handleChange(value, "enabled")}
                                        renderInput={params => (
                                            <div  className={"statusItem " + (formValue.enabled && formValue.enabled.value === FALSE_NUMBER ? "off" : "on")}>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            </div>
                                        )}

                                    />
                                }
                                textValidate={errors.enabled}
                            />
                        </Grid>
                        <Grid  item xs={6} className={"itemRowRight"}>
                            <RowFormColumn
                                label={t(headTranslate + 'roles')}
                                imgSrcLabel={infoUserIcon}
                                input={
                                    <AppAutocomplete
                                        className={"selectBlockWork"}
                                        options={arrGroupsUser}
                                        getOptionLabel={(option) => `${option.label}`}
                                        value={formValue.group}
                                        onChange={(event, value)  => this.handleChange(value, "group")}
                                        renderInput={(params) => <div  className={"statusItem "}>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        </div>}
                                    />
                                }
                                textValidate={errors.group}
                            />
                        </Grid>
                    </Grid>

                {/*    <Select*/}
                {/*        className={"selectBlockWork"}*/}
                {/*        value={formValue.group}*/}
                {/*        label="group"*/}
                {/*        onChange={(e) => this.handleChange(e, "group")}*/}
                {/*    >{*/}
                {/*        arrGroupsUser.map(item => {*/}
                {/*            return (<option value={item.value}>*/}
                {/*                {item.label}*/}
                {/*            </option>);*/}
                {/*        })*/}
                {/*    }*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                    <Grid  item xs={12}>
                        <RowFormColumn
                            label={t(headTranslate + 'startWorkAt')}
                            imgSrcLabel={timeIcon}
                            input={
                                <AppKeyboardDatePicker
                                    value={formValue.startWorkAt}
                                    onChange={(value) => this.handleChange(value, "startWorkAt")}
                                    emptyLabel={''}
                                />
                            }
                            textValidate={errors.startWorkAt}
                        />
                    </Grid>
                    <Grid  item xs={12}>
                        <RowFormColumn
                            label={t(headTranslate + 'endWorkAt')}
                            imgSrcLabel={timeIcon}
                            input={
                                <AppKeyboardDatePicker
                                    value={formValue.endWorkAt}
                                    onChange={(value) => this.handleChange(value, "endWorkAt")}
                                    emptyLabel={''}
                                />
                            }
                            textValidate={errors.endWorkAt}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    getLinkCancel = () => {
        const { match } = this.props;
        switch (match.path) {
            case PROFILE:
                return HOME;
            case CREATE_STAFF:
                return MANAGEMENT_STAFF;
            case EDIT_STAFF:
                return MANAGEMENT_STAFF;
                // return DETAIL_STAFF.replace(":id", match && match.params? match.params.id: null)
            default:
                return HOME;
        }
    }

    render() {
        const {
            classes, t, location,match
        } = this.props;
        const {formValue, errors, isLoading, redirectToUrl} = this.state;
        if(redirectToUrl){
            return <Redirect to={redirectToUrl}/>;
        }
        // console.log(formValue)
        return (
            <InformationPageCustom
                content={
                    <Grid className={classes.formStyle}>
                        {isLoading && <LoadingAction/>}
                        <Grid container>
                            <Grid container item xs={12} className={classes.profileBlockWrapper}>
                                <div className={classes.profileBlock}>
                                    <div className={"titleBlock"}>
                                        <div
                                            className={"titleHeaderContent"}>{t(headTranslate + 'titleBlockUser')}</div>
                                    </div>
                                    <Grid container item xs={12} className={"blockBodyWrapper"}>
                                        <Grid container item xs={12} sm={5} md={3} className={"avatarWrapper"}>
                                            <div className={"avatarBlock"}>
                                                <Dropzone
                                                    ref={(fileUpload) => {
                                                        this.fileUpload = fileUpload;
                                                    }}
                                                    accept={[".jpeg", ".jpg", ".png"]}
                                                    minSize={0}
                                                    //maxSize={FILE_2_MB_SIZE_BYTES}
                                                    maxFiles={10}
                                                    multiple={true}
                                                    onDrop={acceptedFiles => this.handleChange(acceptedFiles[0], "avatar")}>
                                                    {({getRootProps, getInputProps}) => {
                                                        const imgAvatar = formValue.avatar ? typeof formValue.avatar === STRING ? formValue.avatar : URL.createObjectURL(formValue.avatar) : userIcon
                                                        return (<section>
                                                            <div {...getRootProps()} className={"dropAvatar"}>
                                                                <input {...getInputProps()} />
                                                                <img src={imgAvatar} alt={""} className={"avatar"}/>
                                                            </div>
                                                        </section>)
                                                    }}
                                                </Dropzone>
                                                {formValue.avatar && <div className={"removeAvatar"}
                                                                          onClick={() => this.handleChange(null, "avatar")}>x</div>}
                                            </div>
                                            <CustomButton className={"avatarSelection"}
                                                          title={t(headTranslate + "selectAvatar")}
                                                          buttonProps={{
                                                              startIcon: <React.Fragment><img src={imgSelection}
                                                                                              alt=""
                                                                                              className={"iconAdd"}/></React.Fragment>
                                                          }}
                                                          isUploadFile={true}
                                                          onClick={(e) => this.fileUpload.open()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={9} className={"columnWrapperLeft"}>
                                            <Grid container >
                                            <Grid  item xs={12} md={6} className={"columnWrapperLeft"}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'firstName')}
                                                    imgSrcLabel={infoUserIcon}
                                                    input={
                                                        <CustomInput
                                                            value={formValue.firstName}
                                                            onChange={(e) => this.handleChange(e, "firstName")}
                                                            validate={errors.firstName}/>
                                                    }
                                                    textValidate={errors.firstName}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} md={6} className={"columnWrapperRight"}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'lastName')}
                                                    imgSrcLabel={infoUserIcon}
                                                    input={
                                                        <CustomInput
                                                            value={formValue.lastName}
                                                            onChange={(e) => this.handleChange(e, "lastName")}
                                                            validate={errors.lastName}/>
                                                    }
                                                    textValidate={errors.lastName}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} md={6} className={"columnWrapperLeft"}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'email')}
                                                    imgSrcLabel={mailIcon}
                                                    input={
                                                        <CustomInput
                                                            value={formValue.email}
                                                            onChange={(e) => this.handleChange(e, "email")}
                                                            disabled={(location.pathname !== CREATE_STAFF && (location.pathname === PROFILE && formValue.instanceof === USER_TYPE_CLIENT))}
                                                            validate={errors.email}/>
                                                    }
                                                    textValidate={errors.email}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} md={6} className={"columnWrapperRight"}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'phoneNumber')}
                                                    imgSrcLabel={phoneIcon}
                                                    input={
                                                        <CustomInput
                                                            value={formValue.phoneNumber}
                                                            onChange={(e) => this.handleChange(e, "phoneNumber")}
                                                            validate={errors.phoneNumber}/>
                                                    }
                                                    textValidate={errors.phoneNumber}
                                                />
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid container item xs={12} md={6} className={classes.profileBlockWrapper}>
                                <div className={classes.profileBlock}>
                                    <div className={"titleBlock"}>
                                        <div
                                            className={"titleHeaderContent"}>{t(headTranslate + 'titleBlockAddress')}</div>
                                    </div>
                                    <Grid  item xs={12} className={"blockBodyWrapper"}>
                                        <Grid  item xs={12}>
                                            <RowFormColumn
                                                label={t(headTranslate + 'addressDescription')}
                                                imgSrcLabel={villeIcon}
                                                input={
                                                    <CustomInput
                                                        value={formValue.addressDescription}
                                                        onChange={(e) => this.handleChange(e, "addressDescription")}
                                                        validate={errors.addressDescription}/>
                                                }
                                                textValidate={errors.addressDescription}
                                            />
                                        </Grid>
                                        <Grid  item xs={12} className={"addressBlock"}>
                                            <Grid container >
                                                <Grid  item xs={12} md={6} className={"columnWrapperLeft"}>
                                                    <RowFormColumn
                                                        label={t(headTranslate + 'addressPostalCode')}
                                                        imgSrcLabel={homeIcon}
                                                        input={
                                                            <CustomInput
                                                                value={formValue.addressPostalCode}
                                                                onChange={(e) => this.handleChange(e, "addressPostalCode")}
                                                                validate={errors.addressPostalCode}/>
                                                        }
                                                        textValidate={errors.addressPostalCode}
                                                    />
                                                </Grid>
                                                <Grid  item xs={12}  md={6} className={"columnWrapperRight"}>
                                                    <RowFormColumn
                                                        label={t(headTranslate + 'addressCity')}
                                                        imgSrcLabel={addressIcon}
                                                        input={
                                                            <CustomInput
                                                                value={formValue.addressCity}
                                                                onChange={(e) => this.handleChange(e, "addressCity")}
                                                                validate={errors.addressCity}/>
                                                        }
                                                        textValidate={errors.addressCity}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid  item xs={12}>
                                             <RowFormColumn
                                                label={t(headTranslate + 'addressCountry')}
                                                imgSrcLabel={addressIcon}
                                                input={
                                                    isLoading ? <div></div>:<AppCountryAutocomplete
                                                            defaultValue={formValue.addressCountry ? {
                                                                code: formValue.addressCountry,
                                                                label: countries.getName(formValue.addressCountry, this.props.t('translations.lang'))
                                                            } : undefined}
                                                            onChange={(event, value) => this.handleChange(value, "addressCountry")}
                                                            // className={classes.countryField}
                                                            inputPlaceHolder={""}
                                                        />
                                                }
                                                textValidate={errors.addressCountry}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            {location.pathname !== PROFILE && <Grid  item xs={12} md={6} className={classes.profileBlockWrapper}>
                                {this.blockWorkRender()}
                            </Grid>}
                            {location.pathname === PROFILE || location.pathname === CREATE_STAFF ? <Grid  item xs={12} md={location.pathname === CREATE_STAFF? 12:6} className={classes.profileBlockWrapper}>
                                    <div className={classes.profileBlock}>
                                        <div className={"titleBlock"}>
                                            <div
                                                className={"titleHeaderContent"}>{t(headTranslate + 'titleBlockPassword')}</div>
                                        </div>
                                        <Grid  item xs={12} className={"blockBodyWrapper"}>
                                            {location.pathname === PROFILE && formValue.hasPassword && <Grid  item xs={12}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'currentPassword')}
                                                    input={
                                                        <CustomInput
                                                            type={"password"}
                                                            value={formValue.currentPassword}
                                                            onChange={(e) => this.handleChange(e, "currentPassword")}
                                                            validate={errors.currentPassword}/>
                                                    }
                                                    textValidate={errors.currentPassword}
                                                />
                                            </Grid>}
                                            <Grid  item xs={12}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'password')}
                                                    input={
                                                        <CustomInput
                                                            type={"password"}
                                                            value={formValue.password}
                                                            onChange={(e) => this.handleChange(e, "password")}
                                                            validate={errors.password}/>
                                                    }
                                                    textValidate={errors.password}
                                                />
                                            </Grid>
                                            <Grid  item xs={12}>
                                                <RowFormColumn
                                                    label={t(headTranslate + 'confirmPassword')}
                                                    input={
                                                        <CustomInput
                                                            type={"password"}
                                                            value={formValue.confirmPassword}
                                                            onChange={(e) => this.handleChange(e, "confirmPassword")}
                                                            validate={errors.confirmPassword}/>
                                                    }
                                                    textValidate={errors.confirmPassword}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                            </Grid>:""}
                            {location.pathname !== PROFILE &&
                            this.uploadDocumentsRender()
                            }
                            <Grid item xs={12} className={classes.footer}>
                                <NavLink to={this.getLinkCancel()}><CustomButton
                                    className={classes.btnCancel}
                                    titleComponent={<div>{t("button.cancel")}</div>}
                                    // onClick={(e) => this.handleSubmit(e)}
                                /></NavLink>
                                <CustomButton
                                    className={classes.btnSubmit}
                                    titleComponent={<div>{t("button.save")}</div>}
                                    onClick={(e) => this.handleSubmit(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            />
        )
    }
    uploadDocumentsRender = () =>{
        const {
            classes, t
        } = this.props;
        const acceptDropzoneUploadDocument = [".jpeg", ".jpg", ".png", ".doc", ".docx",".dotx",".xls", ".xlsx", ".xlsm", ".pdf", ".txt"];
        const {formValue} = this.state;
        return (
            <Grid container item xs={12} className={classes.documentsWrapper}>
                <Grid container item xs={12} className={classes.profileBlock}>
                    <div className="titleBlock">
                        {t("profile.form.document")}
                    </div>
                    {Array.isArray(formValue.documentsOld) && formValue.documentsOld.length > 0 && <Grid container item sm={12} md={4} className={classes.documentsOldWrapper}>
                        <div className={"documentsOld"}>
                            {
                                formValue.documentsOld.map((item, key) => {
                                    return (
                                        <div className={`${classes.documentItem} documentOldItem`}>
                                            <div className={"docItemInfoWrapper"}>
                                                <img src={
                                                    item.pathUrl ?
                                                        (item.pathUrl.includes(".xls") || item.pathUrl.includes(".xlsm") || item.pathUrl.includes(".xlsx")) ?
                                                            excelIcon :
                                                            (item.pathUrl.includes(".doc") || item.pathUrl.includes(".docx") || item.pathUrl.includes(".dotx")) ?
                                                                docIcon : DocumentPdfIon
                                                        : DocumentPdfIon
                                                }
                                                     alt=""
                                                />
                                                <div className="docItemInfo">
                                                    <Tooltip title={item.originalName} arrow>
                                                        <div className={"nameFile"}><a className={"certificate"} target={"_blank"} rel="noreferrer"
                                                                                       href={item.pathUrl}>{item.originalName}</a></div>
                                                    </Tooltip>
                                                    <div className={"sizeFile"}>{convertByteToNumberUnit(item.fileSize)}</div>
                                                </div>
                                            </div>
                                            <div className={"removeWrapper"}
                                                 onClick={() => this.removeDocumentFormValue("documentsOld", key)}>
                                                <CustomInlineSvg
                                                    svgProps={{src:deleteIcon}}
                                                />
                                            </div>
                                        </div>);
                                })
                            }
                        </div>
                    </Grid>}
                    <Grid container item sm={12} md={Array.isArray(formValue.documentsOld) && formValue.documentsOld.length > 0?8:12} className={classes.documentsUploadWrapper}>
                        <div className={"documentsUpload"}>
                            <div className={"dropDocumentWrapper"}>
                                <Dropzone
                                    accept={acceptDropzoneUploadDocument}
                                    minSize={0}
                                    maxFiles={10}
                                    //maxSize={FILE_2_MB_SIZE_BYTES}
                                    multiple={true}
                                    onDrop={acceptedFiles => this.handleChange(acceptedFiles, "documentsNew")}>
                                    {({getRootProps, getInputProps}) => {
                                        let checkExistDocumentNew =  Array.isArray(formValue.documentsNew) && formValue.documentsNew.length > 0;
                                        // const imgAvatar = formValue.avatar ? typeof formValue.avatar === STRING ? formValue.avatar : URL.createObjectURL(formValue.avatar) : userIcon
                                        return (<section className={`${(checkExistDocumentNew)?"documentsNewUploadListWrapper":""}`}>
                                            <div {...getRootProps()} className={"documentsUploadListWrapper"}>
                                                {
                                                    checkExistDocumentNew ?
                                                        <div className={"documentsUploadList"}>
                                                            {formValue.documentsNew.map((item, key) => {
                                                               const urlLink =  URL.createObjectURL(item)
                                                                return (<div
                                                                    className={`${classes.documentItem} documentNewItem`}>
                                                                    <div
                                                                        className={"docItemInfoWrapper"}>
                                                                        <img src={
                                                                            item.type ?
                                                                                (item.type.includes("excel") || item.type.includes(".sheet")) ?
                                                                                    excelIcon :
                                                                                    (item.type.includes("msword") || item.type.includes(".wordprocessingml")) ?
                                                                                        docIcon : DocumentPdfIon
                                                                                : DocumentPdfIon
                                                                        }
                                                                             alt=""
                                                                        />
                                                                        <div className="docItemInfo">
                                                                            <div
                                                                                className={"nameFile"}><a className={"certificate"} target={"_blank"} rel="noreferrer"
                                                                                                          href={urlLink}>{item.name}</a></div>
                                                                            <div
                                                                                className={"sizeFile"}>{convertByteToNumberUnit(item.size)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"removeWrapper"}
                                                                         onClick={() => this.removeDocumentFormValue("documentsNew", key)}>
                                                                        <CustomInlineSvg
                                                                            svgProps={{src:deleteIcon}}
                                                                        />
                                                                    </div>
                                                                </div>);
                                                            })}
                                                        </div>
                                                        :
                                                        <div className={"emptyDocWrapper"}>
                                                            <input {...getInputProps()} />
                                                            <img src={uploadFileIcon} alt={""}
                                                                 className={"avatar"}/>
                                                            <div className={"dropFile"}>{t("profile.form.dropFileOrClickUpload")} <span className={"browse"}>{t("profile.form.browse")}</span></div>
                                                        </div>
                                                }
                                            </div>
                                        </section>)
                                    }}
                                </Dropzone>
                                {Array.isArray(formValue.documentsNew) && formValue.documentsNew.length > 0 &&
                                <CustomButton className={"addFileUpload"}
                                              title={t("profile.form.addFiles")}
                                              onClick={(e) => this.fileUploadDocumentPerson.open()}/>
                                }
                                <div className={"uploadFileNewHiddenWrapper"}>
                                    <Dropzone
                                        accept={acceptDropzoneUploadDocument}
                                        ref={(fileUpload) => {
                                            this.fileUploadDocumentPerson = fileUpload;
                                        }}
                                        minSize={0}
                                        maxFiles={10}
                                        //maxSize={FILE_2_MB_SIZE_BYTES}
                                        multiple={true}
                                        onDrop={acceptedFiles => this.handleChange(acceptedFiles, "documentsNew")}>
                                        {({getRootProps, getInputProps}) => {
                                            return (<section>
                                                <div {...getRootProps()}
                                                     className={"uploadFileNewHidden"}>
                                                    <input {...getInputProps()} />
                                                </div>
                                            </section>)
                                        }}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setDataUser: (dataUser)=>{
            dispatch(authActions.setDataUser(dataUser))
        },
        setOpenNoticeError: () => dispatch(applicationActions.setOpenNoticeError()),
        setOpenNoticeSuccess: () => dispatch(applicationActions.setOpenNoticeSuccess()),
    }
};

export default compose(connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(Profile);
