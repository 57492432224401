import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import { withWidth} from "@material-ui/core";

import {
    USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_CLIENT, USER_TYPE_REPAIRER, WAITING_FOR_REPLY
} from "../../../../constants/constants";
import {connect} from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { ACCORDION_B} from "./InfoForm";
import countries from "i18n-iso-countries";
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import copyIcon from "../../../../images/request/copy-icon.svg";
import moment from "moment";
import PopoverAlertTooltip from "../../../../theme/CustomElements/PopoverAlertTooltip";

const styles = theme => ({
    infoSummary: {
        'text-align': 'left',
        margin: 'auto',
        maxWidth: '9£0%',
        '& h3': {
            display: 'flex',
        },
        '& *': {
            maxWidth: '100%'
        },
        '& .identity': {
            'display': 'inline-block',
        },
        '& .bold': {
            fontWeight: 'bold'
        },
        '& .userInfo-container *': {
            cursor: 'pointer',
            width: 'max-content'
        },
        '& .addressInfo-container *': {
            cursor: 'pointer',
            width: 'max-content',
        },
        '& .addressInfo-billingAddress-container *': {
            cursor: 'pointer',
            width: 'max-content'
        },
        '& .complemenataryInfo-container *': {
            cursor: 'pointer',
            width: 'max-content'
        },
    },
    actionEditSubject:{
        width: 'fit-content',
        alignItems: 'center',
        display: 'flex',
        '& > div':{
            '&.btnSave, &.btnCopy':{
                '& svg':{
                    color: '#0022ff'
                }
            },
            padding: 3,
            borderRadius: 5,
            marginLeft:5,
            height: 30,
            width: 30,
            cursor: 'pointer',
            '&:hover':{
                background: 'rgba(0,0,0,0.1)'
            }
        },
        '& .dataCollapse2CopyAll': {
            fontSize: 1,
            width: 1,
            height: 0.2,
            overflow: 'hidden',
            lineHeight: 0.1,
            color: '#fff'
        },
        '&.btnCopyAllCollapse': {
            width: 30,
            display: 'block',
            height: 30,
            '& .btnCopy': {
                padding: 0,
                height: 23,
                width: 23
            },
            '& .popoverAlertAndButtonCustom': {
                width: 1,
                height: 0.2
            }
        }
    }
})

class AdminInfoSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false
        }
        this.copyAllCollapse2Ref = React.createRef()
        document.addEventListener('copy', this.newCopy)
        this.copyField = this.copyField.bind(this)
    }
    newCopy = (e) => {
        e?.clipboardData.setData('text/plain',e.target.innerText)
        e?.preventDefault();
        this.setState({
            copied: true
        }, function () {
            setTimeout(() => {
                this.setState({
                    copied: false
                })
            }, 1000)
        })
    }

    async copyField(e, text = null) {
        if (e?.hasOwnProperty("preventDefault")){
            e.preventDefault();
        }
        if (typeof text === "string"){
            await navigator.clipboard.writeText(text);
            this.newCopy();
        }
        else if (document.selection) {
            const range = document.body.createTextRange();
            range.moveToElementText(e.target || e);
            range.select().createTextRange();
            document.execCommand("copy");
        }
        else if (window.getSelection) {
            const range = document.createRange();
            range.selectNode(e.target || e);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
        }
    }

    renderShowButtonEditByTitle = (title) => {
        const {classes, groupBaseUser, valueForm} = this.props;
        const canEditValue = [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_REPAIRER].includes(groupBaseUser) ||
            (groupBaseUser === USER_TYPE_CLIENT && ( valueForm?.status?.statusType?.code) && valueForm.status.statusType.code === WAITING_FOR_REPLY && !(valueForm?.isAdminOrExpertCreated))
        return  canEditValue ?<div className={`${classes.actionEditSubject} btnHiddenBtnEditSubject`}>
            <div className={"btnSave"}
                 onClick={() => this.props.handleEditValue(title)}>
                <EditIcon/>
            </div>
        </div>:""
    }

    buttonCopyCollapse = () => {
        const {classes, valueForm, t} = this.props;
        const newOtherPhoneNumbersAddress = Array.isArray(valueForm?.otherPhoneNumbersAddress)  ? valueForm.otherPhoneNumbersAddress.filter(item=> item) : []
        let phoneNumberText = valueForm.phoneNumberAddress ? ` - ${valueForm.phoneNumberAddress}` : "";
        if(newOtherPhoneNumbersAddress.length){
            newOtherPhoneNumbersAddress.forEach((item, index)=>{
                phoneNumberText += `${(index === 0  && !valueForm.phoneNumberAddress ? " - " : (item ? "," : ""))} ${item}`;
            })
        }
        return  <div className={`${classes.actionEditSubject} btnCopyAllCollapse`}>
            <div className={"dataCollapse2CopyAll"} ref={this.copyAllCollapse2Ref}>
                {valueForm.addressFirstName} {valueForm.addressLastName}{phoneNumberText} - {valueForm.isThereAnElevator === '0' ? "Pas d'" : 'Avec '}{t("information.formTab1.isThereAnElevator")}{valueForm.floorNumber ? `, ${t("information.formTab1.floorNumber")} ${parseInt(valueForm.floorNumber) === 0 ? 'RDC' : valueForm.floorNumber}` : ''}{valueForm.doorPassword !== "" && valueForm.doorPassword !== null ? ` - ${t("information.formTab1.doorPassword")} : ${valueForm.doorPassword} ` : "" }
            </div>
            <div className={`btnCopy`} onClick={()=>this.copyField(this.copyAllCollapse2Ref.current)}>
                <CustomInlineSvg svgProps={{src: copyIcon} } />
            </div>
            {this.state.copied && <PopoverAlertTooltip title={t('request.infoCopied')} />}
        </div>
    }

    render() {
        const {classes, t, valueForm} = this.props;
        const fullAddress = `${valueForm.addressDescription}, ${valueForm.addressPostalCode} ${valueForm.addressCity} ${countries.getName(
            valueForm.addressCountry ?? "FR",
            this.props.t("translations.lang"))}`
        let otherPhoneNumbersAddressText = "";
        (Array.isArray(valueForm?.otherPhoneNumbersAddress) ? valueForm.otherPhoneNumbersAddress.filter(item => item) : []).forEach((item, index) => {
            otherPhoneNumbersAddressText += (`${(index === 0 && !valueForm.phoneNumberAddress ? "" : (item ? "," : ""))} ${item}`)
        })
        return (
            <div className={classes.infoSummary}>
                <h3>{t("information.formTab1.labelAddressRepair")} {this.renderShowButtonEditByTitle(ACCORDION_B)} {this.buttonCopyCollapse()}</h3>
                <div className={"addressInfo-container"}>
                    <div className={"addressInfo-name bold"}
                         onClick={this.copyField}>{valueForm.addressFirstName} {valueForm.addressLastName}</div>
                    {valueForm.addressCompany && <div className={"addressInfo-company bold"}
                         onClick={this.copyField}>{t("information.formTab1.company")} : {valueForm.addressCompany ?? ""}</div>}
                    {
                        valueForm.companyTaxCode?  <div className={"addressInfo-company bold"}
                                                        onClick={this.copyField}>{t("information.formTab1.vat")} : {valueForm.companyTaxCode}</div> :""}
                    <div className={"addressInfo-name bold"}
                         onClick={(e) => this.copyField(e, fullAddress ?? '')}>
                        {t("information.formTab1.addressInfo")} :
                    </div>
                    <div className={"addressInfo-description"}
                         onClick={(e) => this.copyField(e, fullAddress ?? '')}>{valueForm.addressDescription ?? ""}</div>
                    <div className={"addressInfo-full"}>
                        <span onClick={(e) => this.copyField(e, fullAddress ?? '')}>{valueForm.addressPostalCode ?? ""}</span> <span
                        onClick={(e) => this.copyField(e, fullAddress ?? '')}>{valueForm.addressCity ?? ""}</span> <span
                        onClick={(e) => this.copyField(e, fullAddress ?? '')}>{countries.getName(
                        valueForm.addressCountry ?? "FR",
                        this.props.t("translations.lang"))}</span>
                    </div>
                    {(!!(valueForm?.phoneNumberAddress) || ( otherPhoneNumbersAddressText)) && <div className={"addressInfo-name bold"} onClick={(e) => this.copyField(e, `${(valueForm.phoneNumberAddress ?? '')} ${otherPhoneNumbersAddressText}`)}>
                        {t("information.formTab1.phoneNumber")} :&#160;{valueForm.phoneNumberAddress ?? ""}{otherPhoneNumbersAddressText}
                    </div>}
                    {!!(valueForm?.addressEmail) && <div className={"addressInfo-name bold"} onClick={(e) => this.copyField(e, valueForm.addressEmail ?? '')}>
                        {t("information.formTab1.addressEmail")} :&#160;{valueForm.addressEmail}
                    </div>}
                    {/*<div className={"addressInfo-floorNumber"}*/}
                    {/*     onClick={this.copyField}>{t("information.formTab1.floorNumber")} : {valueForm.floorNumber ?? ""}</div>*/}

                    <div className={"addressInfo-elevator bold"}
                         onClick={this.copyField}>{valueForm.isThereAnElevator === '0' ? "Pas d'" : 'Avec '}{t("information.formTab1.isThereAnElevator")}{valueForm.floorNumber ? `, ${t("information.formTab1.floorNumber")} ${parseInt(valueForm.floorNumber) === 0 ? 'RDC' : valueForm.floorNumber}` : ''}</div>

                    {valueForm.doorPassword !== "" && valueForm.doorPassword !== null && <div className={"addressInfo-doorPassword bold"}
                         onClick={(e) => this.copyField(e, valueForm.doorPassword)}>{t("information.formTab1.doorPassword")} : {valueForm.doorPassword !== "" && valueForm.doorPassword !== null ? valueForm.doorPassword : ""}</div>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        groupBaseUser: state.authReducer.groupBaseUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withWidth(),
    withRouter
)(AdminInfoSummary);
