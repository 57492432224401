import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Switch, Tooltip, withWidth} from "@material-ui/core";
import userIcon from "../../../../images/infomation/user.svg";
import mailIcon from "../../../../images/infomation/mail.svg";
import phoneIcon from "../../../../images/infomation/phone.svg";
import homeIcon from "../../../../images/infomation/home.svg";
import addressIcon from "../../../../images/infomation/address.svg";
import corporationIcon from "../../../../images/infomation/corporation.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import {
    MD,
    LG,
    XS,
    SM,
    TRUE_NUMBER,
    FALSE_NUMBER,
    APP_ADVERTISE
} from "../../../../constants/constants";
import {MONTSERRAT, NUNITO} from "../../../../constants/fontCss";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withRouter} from "react-router-dom";
import * as links from "../../../../constants/links";
import AppCountryAutocomplete from "../../../../theme/form/Autocomplete/AppCountryAutocomplete";
import countries from "i18n-iso-countries";
import {API_GET_PARAMETER} from "../../../../constants/apiSuffix";
import qs from "qs";
import axios from "axios";
import CustomSvg from "../../../../theme/CustomElements/CustomSvg";
import CompanyIcon from "../../../../images/request/company.svg";
import HowknowUsIcon from "../../../../images/request/howknow.svg";
import tickIcon from "../../../../images/request/status/checktick.svg";
import ErrorIcon from "./../../../../images/requestNew/error.svg";
import { errorMessPanel2 } from "../../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../../functions/checkCondition";
import {value} from "lodash/seq";
const styles = theme => ({
    information: {
        '& .advertiseBlock':{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: '20px',
            paddingBottom: '16px',

            [theme.breakpoints.down(XS)]: {

                flexDirection: 'column',
                alignItems: 'flex-start',
            },


            '& .labelValue':{
                fontFamily: MONTSERRAT,
                fontSize: '1rem',
                color: '#3a3a3a',
                lineHeight: 1.2,
                paddingRight: 12,
                paddingTop: 5,
                paddingBottom: 5,
                //maxWidth: 'calc( 100% - 250px)',
                maxWidth: '350px',
                position: 'relative',
                paddingLeft: '0px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',

                '&:before':{
                    content:'""',
                    backgroundImage: `url(${HowknowUsIcon})`,
                    display:'block',
                    marginRight: '9px',
                    height: '41px',
                    minWidth: '40px',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                },


                '&~div':{
                    marginBottom: '10px',
                }



            },
            '& .selectPaginateAdvertise__multi-value__label':{
                fontSize: '0.875rem',
            },
            '& .selectPaginateAdvertise__control': {
                // border: 'none',
                minWidth: 250,
                //backgroundColor: '#eaf1fb',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 8,
                // margin: '14px 0',
                padding: '4px 7px',
                border: '1px solid #333',
                maxWidth: '450px',
                cursor: 'pointer',

                '&:hover': {
                    // backgroundColor: '#c9e1fb',
                    border: '1px solid #0000ff',

                    '& .css-1wa3eu0-placeholder':{
                        color: '#0000ff',
                    },
                    '& svg path':{
                        color: '#0000ff',
                    },
                    '& span':{
                        backgroundColor: '#0000ff',
                    }
                }
            },
            '& .selectPaginateAdvertise__multi-value':{
                backgroundColor: 'hsl(42.2, 100%, 85.5%)',
            }
        },
        '& .accordionError': {
            backgroundImage: 'linear-gradient(135deg, rgba(255, 132, 147, 0.53) 0%, rgba(177,244,225,0) 23%)',
            '& .titleHeaderContent': {

                '& svg': {
                    '& path': {
                        fill: '#ec3a51',
                        stroke: 'initial',

                    }
                }
            }
        },
        '& .accordionComplete': {
            backgroundImage: 'linear-gradient(135deg, rgba(177,244,225,1) 0%, rgba(177,244,225,0) 23%)',
            '& .titleHeaderContent': {
                '& svg': {
                    '& path': {
                        fill: '#00E1C2'
                    }
                }
            }
        },
        '& .userInfo': {
            width: '100%',
            margin: 'auto',
            maxWidth:'500px',
        },
        '& .authorizeShareEmail': {
            display: 'flex',
            alignItems: 'center',
            marginTop: 15,
            lineHeight: '16px',
        },
        '& .MuiAccordion-root': {
            //backgroundColor: '#eaf1fb',
            //   backgroundColor: '#e1ebfb',
            //  backgroundColor: ' #dfe8f7',
            backgroundColor: '#dbe6f7',
            boxShadow: 'none',
            borderRadius: 8,
            margin: '14px 0',
            padding: 8,
            '&:before': {
                display: 'none'
            },
            '&:not(.Mui-expanded):hover':{
                backgroundColor:'#c9e1fb',

                '& .titleHeaderContent':{
                    paddingLeft: '8px',
                    transition: '0.2s ease',
                }
            },


        },
        '& .titleHeaderContent': {
            padding: '0 0.3em',
            flexGrow: 1,
            // color: '#42464d',
            color: '#202d46',
            fontWeight: 700,
            //fontSize: '1rem',
            fontSize:  '1.2rem',
            lineHeight: '1.4rem',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            paddingLeft:0,
            transition: '0.2s ease',

            '& svg': {
                position: 'absolute',
                left: -56,
                width: 20,
                [theme.breakpoints.down(SM)]:{
                    left: '-43px',
                    width: '13px',
                },
                [theme.breakpoints.down(XS)]:{
                    left: '-51px',

                }
            },

            [theme.breakpoints.down(XS)]:{
                fontSize:  '1.1rem',
                lineHeight: '1.3rem',
            }


        },
        '& .rowFormColumn':{
            alignItems: 'flex-start',

            [theme.breakpoints.down(XS)]:{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }
        },
        '& .headerInfo': {
            fontFamily: MONTSERRAT,
            fontSize: '1.0625rem',
            fontWeight: 700,
            // lineHeight: 1.4,
            // height: '1.125rem',
            display: 'flex',
            flexDirection: 'row',
            color: '#001529',
            // padding: '1rem',
            marginBottom: '1rem',
            // '&:before': {
            //     borderBottom: '1px solid #E0E0E0',
            //     content: '""',
            //     flex: '1',
            // },
            // '&:after': {
            //     borderBottom: '1px solid #E0E0E0',
            //     content: '""',
            //     flex: '4',
            // },


        },
        '& .addressDetail': {
            display: 'flex',
            justifyContent: 'flex-start',
            '& .rowFormColumn': {
                width: 'unset'
            },
            [theme.breakpoints.down(XS)]: {
                flexDirection: 'column',
            },
        },
        '& .column': {
            paddingRight: '0.25rem',
        },
        '& .column1': {
            paddingRight: '0.5rem',
            [theme.breakpoints.between(XS, SM)]: {
                paddingRight: '0',
            },
            [theme.breakpoints.between(SM, MD)]: {
                paddingRight: '0.5rem',
            },

            [theme.breakpoints.between(MD, LG)]: {
                paddingRight: '0.5rem',
            },
        },
        '& .column2': {
            paddingLeft: '0.5rem',
            [theme.breakpoints.between(XS, SM)]: {
                paddingLeft: '0',
            },
            [theme.breakpoints.between(SM, MD)]: {
                paddingLeft: '0.5rem',
            },

            [theme.breakpoints.between(MD, LG)]: {
                paddingLeft: '0.5rem',
            },
        },
        '& .imgSrcLabelRowFormColumn': {
            // height: 25
        },
        '& .labelRowFormColumn': {
            fontFamily: MONTSERRAT,
            fontSize: '0.9rem',
            color: '#3a3a3a',
            paddingBottom: '6px',
            height: 40,
            lineHeight: '1.1rem',
            '& svg': {
                marginRight: 10,
                minWidth:'20px',
                '& path, rect': {
                    // fill: '#0022ff!important',
                    fill: '#3f4046 !important',
                },
                '& circle': {
                    fill: 'transparent!important'
                }
            },

            [theme.breakpoints.down(XS)]:{
                marginRight: '10px',
                minWidth: '138px',
                maxWidth: '138px',
                width: 'inherit',

                '& svg': {
                    marginRight: 5,
                }
            }

        },
        '& .inputRowFormColumn': {
            fontFamily: MONTSERRAT,
            fontSize: '0.875rem',
            fontWeight: 400,
            '& span':{
                color: '#3a3a3a'
            },

            [theme.breakpoints.down(XS)]: {
                width: '100%',
            },
            '& .otherPhoneNumber > div': {
                paddingTop: '10px',
                '&:first-child':{
                    paddingTop: 0
                }
            }
        },
        '& input': {
            backgroundColor: '#fff',
            borderRadius: 4,
            fontSize: '1rem',

        },
        '& .MuiFormControl-root':{
            border: '0 !important',
        },
        '& .MuiInputBase-root':{
            border: '0 !important',
        },

        '& .inputBlockDatePicker': {
            backgroundColor: '#fff',
            borderRadius: 4
        },
        '& .additionalInfo':{
            background: '#FFFFFF',
            border: '1px solid #E0E0E0',
            borderRadius: 6,
            // padding: '6px 10px 7px',
            fontSize: '0.875rem',
            '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                borderRadius: 4
            }
        },
        '& .leftHeader': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontWeight: 500,
            fontSize: '0.85rem',
            '& svg': {
                '& path': {
                    fill: '#0022ff'
                }
            },
            '& div' :{
                textAlign: 'right',
                lineHeight: '16px',
            }
        },

        '& .panel-header':{
            '& .MuiAccordionSummary-content':{
                margin:'0 0',

                [theme.breakpoints.down(XS)]: {
                    margin:'21px 0 21px 8px',
                },

            },
        },
        '& #panel1bh-content .MuiAccordionDetails-root':{
            padding: '40px 20px 55px 20px',
            [theme.breakpoints.down(XS)]: {
                padding: '20px 15px 60px 30px',
            },
        },
        '& #panel2bh-content .MuiAccordionDetails-root':{
            padding: '20px 50px 45px',
            [theme.breakpoints.down(XS)]: {
                padding: '40px 0px 40px 15px',

                '& .nameContent':{
                    marginBottom: '25px',
                    // border-bottom: 1px dashed #333;
                    paddingBottom: '24px !important',
                }
            },

        },
        '& #panel3bh-content .MuiAccordionDetails-root':{
            padding: '0px 80px 35px',
            [theme.breakpoints.down(XS)]: {
                padding: '40px 25px',
            },
        },
        '& #panel4bh-content .MuiAccordionDetails-root':{
            padding: '0px 30px 35px',

            [theme.breakpoints.down(SM)]: {
                padding: '40px 25px',

                '& .rowFormColumn':{
                    flexDirection: 'column',
                },



            },

            '& .grid-panel4-left >div>div:nth-child(2)':{
                //width: '100%',
                //marginLeft: '60px',

                '& .emailinfo':{
                    marginTop:5,
                    marginLeft:40,

                    '& .MuiInputBase-input.Mui-disabled':{
                        background: '#dee5f0',
                    },
                    '& input':{
                        maxWidth: '160px',
                    },
                    [theme.breakpoints.down(XS)]: {
                        flexDirection:'column',
                    },


                },

                [theme.breakpoints.down(XS)]: {
                    marginLeft: '0px',
                },

            },

            '& .grid-panel4-right':{
                '& .appDatePicker ':{
                    maxWidth: '190px',
                },
            },
            '& label':{
                //padding: '5px 0px',
                marginRight: 0,

                '& .MuiFormControlLabel-label':{
                    fontSize: '0.95rem',
                    lineHeight: '1rem',
                    marginBottom: '5px',

                }

            },
            '& .MuiCheckbox-root .MuiSvgIcon-root path':{
                color: '#0b48fd',
            }

        },

        [theme.breakpoints.down(SM)]: {
            //BTN SECTION
            '& .MuiFormGroup-row, .MuiFormGroup-root':{

                '& label':{
                    marginBottom: '6px',
                    marginRight: 0,
                    justifyContent: 'flex-start',


                    '& .MuiIconButton-label':{
                        display: 'none !important',
                    },
                },
                '& .MuiFormControlLabel-label':{
                    padding: '8px 17px !important',
                    borderRadius: '6px',
                    border: '1px solid #333 !important',
                    lineHeight: '1rem',
                    textAlign: 'center',
                    minWidth: 69,
                },
                '& .Mui-checked':{

                    '&~.MuiFormControlLabel-label':{
                        color: '#fff !important',
                        background: '#0b48fd !important',
                        border: '1px solid transparent !important',
                    },


                }

            },

        },

    },

    customRadio:{
        marginLeft: '0 !important',

        '& > span':{
            padding: '0 5px !important'
        }
    },
    isThereAnElevator: {
        '& span': {
            fontSize: '0.8rem',
            padding: '0 0 0 5px'
        }
    },

    isRequired: {
        fontWeight: 600,
        padding: '0 5px',
        color: '#0022ff !important',
    },
});


class AdminInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitError: false
        }
    }

    onChangeRegisteredObject = (event) => {
        this.props.handleChange(event.target.checked ,"registeredObject")
    }

    onChangeOtherPhoneNumberAddress = (event, index) => {
        const {valueForm} = this.props
        let otherPhoneNumberAddress = Array.isArray(valueForm.otherPhoneNumbersAddress) ? [...valueForm.otherPhoneNumbersAddress] : []
        const newValue = event.target.value
        this.props.handleChange(otherPhoneNumberAddress.hasOwnProperty(index) ? otherPhoneNumberAddress.map((item, key) => index !== key ? item : newValue) : otherPhoneNumberAddress.concat([newValue]), "otherPhoneNumbersAddress")
    }

    checkPanel2Complete() {
        const {
            valueForm,
            t
        } = this.props;
        for (let [key, value] of Object.entries(valueForm)) {
            if (errorMessPanel2.hasOwnProperty(key)) {
                if (Object.keys(checkSubmitSendDataERROR(value, errorMessPanel2[key].type, {}, key, errorMessPanel2[key].errorByField, t)).length) {
                    return false;
                }
            }
        }
        return true;
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isAdminInfoFormError) {
            let updateState = {
                isSubmitError: true
            };

            if (Object.keys(updateState).length > 0) {
                this.setState(updateState)
            }
        }
    }

    loadParameterOptions = async (search, loadedOptions, {page})=> {
        let params = {filters: {name: search}, page: page, code:APP_ADVERTISE};
        const url = API_GET_PARAMETER + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response?.data ?? {}
        let dataItems =data?.items ?? [];
        const options = dataItems.map((item) => {
            return {label: item.description, value: item.id};
        });
        return {
            options: options,
            hasMore: data.currentPage < data.nbPages,
            additional: {
                page: page + 1,
            },
        };
    }
    render() {
        const {classes, t, valueForm,errors, isModalShowClientDetail, location, isLoading} = this.props;
        const { isSubmitError} = this.state;
        const pathPage = location && location.pathname ? location.pathname : "";
        const isPageDetail = (pathPage.replace(/[0-9]/g, '') === links.REQUEST_INFORMATION_DETAIL.replace(":id", '') )|| isModalShowClientDetail
        const checkPanel2 = this.checkPanel2Complete();
        let errorsField =errors? {...errors}:{};
        console.log(valueForm.otherPhoneNumbersAddress)
        return (
            <div className={classes.information}>
                <Accordion className={checkPanel2 ? "accordionComplete" : isSubmitError ? "accordionError" : ""} expanded={true}>
                    <AccordionSummary
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="panel-header"
                    >
                        <div className={"titleHeaderContent"}>
                            {checkPanel2 ? <CustomSvg
                                svgProps={{
                                    src: tickIcon,
                                }}
                            /> : isSubmitError ? <CustomSvg
                                svgProps={{
                                    src: ErrorIcon,
                                }}
                            /> : ""}
                            {t("information.formTab1.labelAddressRepair")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid item sm={12} md={3}>
                                <Grid container className="nameContent">
                                    <Grid item xs={12} sm={6} md={6} lg={6} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.firstName")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: userIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="addressFirstName"
                                                    value={valueForm.addressFirstName}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressFirstName")
                                                        }
                                                    }}
                                                    validate={errorsField.addressFirstName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressFirstName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: userIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.lastName")}
                                            input={
                                                <CustomInput
                                                    name="addressLastName"
                                                    value={valueForm.addressLastName}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressLastName")
                                                        }
                                                    }}
                                                    validate={errorsField.addressLastName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressLastName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: CompanyIcon,
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.company")}
                                            input={
                                                <CustomInput
                                                    name="addressCompany"
                                                    value={valueForm.addressCompany}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressCompany")
                                                        }
                                                    }}
                                                    // validate={errorsField.addressCompany}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            // textValidate={errorsField.addressCompany}
                                            // isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.phoneNumber")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: phoneIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="phoneNumberAddress"
                                                    value={valueForm.phoneNumberAddress}
                                                    onChange={(e) => {
                                                        this.props.handleChange(e, "phoneNumberAddress")
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.otherPhoneNumber")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: phoneIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <div className={"otherPhoneNumber"}>
                                                    <CustomInput
                                                        name="phoneNumberAddress1"
                                                        value={valueForm.otherPhoneNumbersAddress[0] ?? ""}
                                                        onChange={(e) => this.onChangeOtherPhoneNumberAddress(e, 0)}
                                                    />
                                                    <CustomInput
                                                        name="phoneNumberAddress2"
                                                        value={valueForm.otherPhoneNumbersAddress[1] ?? ""}
                                                        onChange={(e) => this.onChangeOtherPhoneNumberAddress(e, 1)}
                                                    />
                                                    <CustomInput
                                                        name="phoneNumberAddress3"
                                                        value={valueForm.otherPhoneNumbersAddress[2] ?? ""}
                                                        onChange={(e) => this.onChangeOtherPhoneNumberAddress(e, 2)}
                                                    />
                                                </div>
                                            }
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressEmail")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: mailIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="phoneNumberAddress"
                                                    value={valueForm.addressEmail}
                                                    onChange={(e) => {
                                                        this.props.handleChange(e, "addressEmail")
                                                    }}
                                                    validate={errorsField.addressEmail}
                                                />
                                            }
                                            textValidate={errorsField.addressEmail}
                                            isRequired={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={1}>
                            </Grid>
                            <Grid item sm={12} md={8}>
                                <Grid container className="adressContent" style={{
                                    padding: '0 10px'
                                }}>
                                    <Grid item xs={12} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: addressIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.addressDescription")}
                                            input={
                                                <CustomInput
                                                    name="addressDescription"
                                                    value={valueForm.addressDescription}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressDescription")
                                                        }
                                                    }}
                                                    validate={errorsField.addressDescription}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressDescription}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item sm={12} md={3} lg={3} xl={3} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: homeIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.addressPostalCode")}
                                            input={
                                                <CustomInput
                                                    name="addressPostalCode"
                                                    value={valueForm.addressPostalCode}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressPostalCode")
                                                        }
                                                    }}
                                                    validate={errorsField.addressPostalCode}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressPostalCode}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item sm={12} md={5} lg={5} xl={5} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCity")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: corporationIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="addressCity"
                                                    value={valueForm.addressCity}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressCity")
                                                        }
                                                    }}
                                                    validate={errorsField.addressCity}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressCity}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item sm={12} md={4} lg={4} xl={4} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCountry")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: addressIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                isPageDetail ?
                                                    <CustomInput
                                                        name="addressCountry"
                                                        value={countries.getName(valueForm.addressCountry, this.props.t('translations.lang'))}
                                                    /> :
                                                    isLoading ? <div></div> : <AppCountryAutocomplete
                                                        defaultValue={valueForm.addressCountry ? {
                                                            code: valueForm.addressCountry,
                                                            label: countries.getName(valueForm.addressCountry, this.props.t('translations.lang'))
                                                        } : undefined}
                                                        onChange={(event, value) => {
                                                            if (!isPageDetail) {
                                                                this.props.handleChange(value, "addressCountry")
                                                            }
                                                        }}
                                                        // className={classes.countryField}
                                                        inputPlaceHolder={""}
                                                    />
                                            }
                                            textValidate={errorsField.addressCountry}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="addressDetail">
                                        <RowFormColumn
                                            label={t("information.formTab1.floorNumber")}
                                            input={
                                                <CustomInput
                                                    name="floorNumber"
                                                    value={valueForm.floorNumber}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "floorNumber")
                                                        }
                                                    }}
                                                    validate={errorsField.floorNumber}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.floorNumber}
                                            isRequired={true}
                                        />
                                        <RowFormColumn
                                            label={t("information.formTab1.isThereAnElevator")}
                                            input={
                                                <RadioGroup
                                                    className={classes.isThereAnElevator}
                                                    aria-label="position" name="position"
                                                    value={valueForm.isThereAnElevator}
                                                    // value={valueForm.isThereAnElevator===null || valueForm.isThereAnElevator===undefined?undefined:(!!valueForm.isThereAnElevator?TRUE_NUMBER:FALSE_NUMBER)}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "isThereAnElevator")
                                                        }
                                                    }}>
                                                    <FormControlLabel value={TRUE_NUMBER}
                                                                      className={classes.customRadio}
                                                                      control={<Radio />}
                                                                      label={t("information.formTab1.yes")}/>
                                                    <FormControlLabel value={FALSE_NUMBER}
                                                                      className={classes.customRadio}
                                                                      control={<Radio />}
                                                                      label={t("information.formTab1.no")}/>
                                                </RadioGroup>
                                            }
                                            textValidate={errorsField.isThereAnElevator}
                                            isRequired={true}
                                        />
                                        <RowFormColumn
                                            label={t("information.formTab1.doorPassword")}
                                            input={
                                                <CustomInput
                                                    name="doorPassword"
                                                    value={valueForm.doorPassword}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "doorPassword")
                                                        }
                                                    }}
                                                    validate={errorsField.doorPassword}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.doorPassword}
                                            isRequired={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {/*<div className={"advertiseBlock"}>*/}
                {/*    <div className={"labelValue"}>{t("information.formTab1.advertise")} <span className={classes.isRequired}>*</span></div>*/}
                {/*    <div>*/}
                {/*        <AppAsyncPaginate*/}
                {/*            classNamePrefix={"selectPaginateAdvertise"}*/}
                {/*            value={valueForm.advertise}*/}
                {/*            // isMulti*/}
                {/*            loadOptions={(search, loadedOptions, {page}) => this.loadParameterOptions(search, loadedOptions, {page})}*/}
                {/*            onChange={(value) => {*/}
                {/*                if (!isPageDetail) {*/}
                {/*                    this.props.handleChange(value, "advertise")*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            debounceTimeout={500}*/}
                {/*            additional={{*/}
                {/*                page: 1*/}
                {/*            }}*/}
                {/*            // placeholder={t("information.formTab1.leaseType")}*/}
                {/*        />*/}
                {/*        <ErrorInput textValidate={errors && errors.advertise}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

AdminInfoForm.defaultProps = {
    isModalShowClientDetail: false
}

AdminInfoForm.propTypes = {
    classes: PropTypes.object.isRequired,
    isModalShowClientDetail: PropTypes.bool
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withWidth(),
    withRouter
)(AdminInfoForm);
