import { withStyles, Button, CircularProgress } from "@material-ui/core";
import { Height } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import SignaturePad from "signature_pad";
import { NUNITO } from "../../../constants/fontCss";
import { convertToDateH_m_D_M_Y } from "../../../functions/datetime";
import moment from "moment";
import html2canvas from "html2canvas";
import CurrentTime from "./CurrentTime";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .signatureBoxWrapper": {
      border: "1px solid #afdbfd",
    },
    "& .signatureBox": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .signatureBoxText": {
        textAlign: "center",
        maxWidth: 300,
        padding: 10,
        margin: "0 20px",
        fontSize: "13px",
        fontWeight: 'bold'
      },
    },
    "& canvas": {},
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    "& button": {
      margin: "0 10px",
    },
    "& .btnClear": {
      border: "1px solid #ccc",
      boxShadow: "0px 4px 20px rgba(15, 15, 137, 0.1) !important",
      borderRadius: 30,
      backgroundSize: "200%",
      transition: "all .4s ease-in-out",
      textTransform: "none",
      padding: "10px 20px",
      "&:before": {
        background: "none!important",
      },
      "& .MuiButton-label": {
        fontFamily: NUNITO,
        lineHeight: "1.3rem",
        fontWeight: "700",
        color: "#0070c7",
        fontSize: "1rem",
      },
      "&:hover": {
        backgroundPosition: "100% 0",
        transition: "all .4s ease-in-out",
      },
    },
    "& .btnSave": {
      backgroundImage:
        "linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important",
      boxShadow: "0px 4px 20px rgba(15, 15, 137, 0.1) !important",
      borderRadius: 30,
      backgroundSize: "200%",
      transition: "all .4s ease-in-out",
      textTransform: "none",
      padding: "10px 20px",
      "&:before": {
        background: "none!important",
      },
      "& .MuiButton-label": {
        fontFamily: NUNITO,
        lineHeight: "1.3rem",
        fontWeight: "700",
        color: "#fff",
        fontSize: "1rem",
      },
      "&:hover": {
        backgroundPosition: "100% 0",
        transition: "all .4s ease-in-out",
      },
    },
  },
};
const SignaturePagApp = ({ classes, t, onSavePng }) => {
  const canvasRef = useRef(null);
  const signatureBoxRef = useRef(null);
  const sigPad = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    sigPad.current = new SignaturePad(canvasRef.current);
    if (sigPad?.current?.canvas) {
      // let canvas2d = sigPad.current.canvas.getContext("2d");
      // canvas2d.textAlign = "center";
      // canvas2d.font="13px sans-serif";
      // canvas2d.fillText(`${t("requestRepair.confirmed_repair_completed_at")} `, 155, 20)
      // canvas2d.fillText(`${moment().format("HH:mm DD/MM/YYYY")}`, 155, 40)
      // canvas2d.fillText(`${moment().format("HH:mm")} ${t("requestRepair.on")} ${moment().format(" MMMM DD YYYY")}`, 160, 40)
    }
  }, []);

  const onClear = () => {
    sigPad.current.clear();
    // if (sigPad?.current?.canvas) {
    //   let canvas2d = sigPad.current.canvas.getContext("2d");
    //   canvas2d.textAlign = "center";
    //   canvas2d.font = "13px sans-serif";
    //   canvas2d.fillText(
    //     `${t("requestRepair.confirmed_repair_completed_at")} `,
    //     155,
    //     20
    //   );
    //   canvas2d.fillText(`${moment().format("HH:mm DD/MM/YYYY")}`, 155, 40);
    // }
  };

  function base64ToFile(base64, filename) {
    // Decode the base64 string
    const byteString = atob(base64.split(",")[1]);

    // Create an array of 8-bit unsigned integers
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([ab], { type: "application/octet-stream" });

    // Create a File object from the Blob
    const file = new File([blob], filename, {
      type: "application/octet-stream",
    });

    return file;
  }
  const onSave = async () => {
    if (isLoading) return;
    if (signatureBoxRef.current) {
      setIsLoading(true);
      try {
        const canvas = await html2canvas(signatureBoxRef.current);
        const imgData = canvas.toDataURL("image/png");
        const dataUrl = sigPad.current.toDataURL("image/png");
        onSavePng(
          base64ToFile(imgData, "signature.png"),
          base64ToFile(dataUrl, "signature_without_text.png")
        );
      } catch (error) {
        console.error("Error converting element to image:", error);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className="signatureBoxWrapper">
        <div className="signatureBox" ref={signatureBoxRef}>
          <div className="signatureBoxText">
            {t("requestRepair.confirmed_repair_completed_at", {date: moment().format("DD/MM/YYYY")})}
            {/*<CurrentTime />*/}
          </div>
          <canvas ref={canvasRef} width={310} height={190} />
        </div>
      </div>
      <div className={classes.action}>
        <Button
          disabled={isLoading}
          className="btnClear"
          onClick={() => onClear()}
        >
          {t("button.clear")}
        </Button>
        <Button
          className="btnSave"
          disabled={isLoading}
          onClick={() => onSave()}
        >
          {t("button.save")}
        </Button>
      </div>
    </div>
  );
};

export default compose(withStyles(styles), withTranslation())(SignaturePagApp);
