// import i18n from "../i18n";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const DATA_USER = process.env.REACT_APP_DATA_USER_LOCAL_STORAGE
export const LOCAL_LANGUAGE = process.env.REACT_APP_LANGUAGE_LOCAL_STORAGE
export const TIMEZONE = process.env.REACT_APP_TIME_ZONE
// export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID
// export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID;
export const PAYMENT_PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYMENT_PAYPAL_CLIENT_ID;
export const CURRENCY_DEFAULT = process.env.REACT_APP_CURRENCY_DEFAULT;
export const PRICE_DEFAULT = process.env.REACT_APP_PRICE_DEFAULT;
export const PAYMENT_SOGE_COMMERCE_SHOP_ID = process.env.REACT_APP_PAYMENT_SOGE_COMMERCE_SHOP_ID;
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const REACT_APP_MECURE_URL = process.env.REACT_APP_MECURE_URL;
export const REACT_APP_DOMAIN_VIDEO_CALL = process.env.REACT_APP_DOMAIN_VIDEO_CALL;
export const DELAY = 1500;
export const SUPPORTED_LOCALES = ["fr", "en"]
export const CHANGE_PASSWORD = '/change-password/:token';
export const FACEBOOK = "facebook";
export const GOOGLE = "google";
export const TWITTER = "twitter";
export const COUNTRY_DEFAULT = "FR";
export const CURRENCY_LOCAL = 'CURRENCY_LOCAL';
/* start group user */
export const USER_TYPE_CLIENT = 'client';
export const USER_TYPE_REPAIRER = 'repairer';
export const USER_TYPE_ADMIN = 'admin';
export const USER_TYPE_SUPER_ADMIN = 'superAdmin';
export const ROLE_CHANGE_REQUEST_STATUS = "ROLE_CHANGE_REQUEST_STATUS"
export const ROLE_GROUP_ADMIN = [ROLE_CHANGE_REQUEST_STATUS];
export const USER_GROUPS = [
    USER_TYPE_CLIENT,
    USER_TYPE_REPAIRER,
    USER_TYPE_ADMIN,
    // USER_TYPE_SUPER_ADMIN
]
export const CALL_TIME_OPTIONS = [
    5, 15, 20, 30, 60
];

// mailType

export const MAIL_TYPE = "mailType";
export const REQUEST_MAIL_TYPE = "requestMailType";
export const MAIL_TYPE_FORGOT_PASSWORD = "MAIL_TYPE_FORGOT_PASSWORD";
export const MAIL_TYPE_CONFIRM_REGISTRATION = "MAIL_TYPE_CONFIRM_REGISTRATION";
export const MAIL_TYPE_AFFECT_REPAIRER = "MAIL_TYPE_AFFECT_REPAIRER";
export const MAIL_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER = "MAIL_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER";
export const MAIL_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER = "MAIL_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER";
export const MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER = "MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER";

export const MAIL_TYPE_PAID_CUSTOMER = "MAIL_TYPE_PAID_CUSTOMER";
export const MAIL_TYPE_CONFIRM_APPOINTMENT_CUSTOMER_GO_FREE = "MAIL_TYPE_CONFIRM_APPOINTMENT_CUSTOMER_GO_FREE";
export const MAIL_TYPE_REPAIRER_GO_FREE = "MAIL_TYPE_REPAIRER_GO_FREE";
export const MAIL_TYPE_CANCEL_APPOINTMENT_REPAIRER = "MAIL_TYPE_CANCEL_APPOINTMENT_REPAIRER";
export const MAIL_TYPE_CANCEL_APPOINTMENT_CUSTOMER = "MAIL_TYPE_CANCEL_APPOINTMENT_CUSTOMER";
export const MAIL_TYPE_UPLOAD_FILE_REPAIRER = "MAIL_TYPE_UPLOAD_FILE_REPAIRER";
export const MAIL_TYPE_UPLOAD_FILE_CUSTOMER = "MAIL_TYPE_UPLOAD_FILE_CUSTOMER";

const PARAM_EDIT_REQUEST_WITH_TOKEN_LINK = "%PARAM_EDIT_REQUEST_WITH_TOKEN_LINK%";
const PARAM_CONFIRM_REGISTRATION_LINK = "%PARAM_CONFIRM_REGISTRATION_LINK%";
const PARAM_CHANGE_PASSWORD_WITH_TOKEN_LINK = "%PARAM_CHANGE_PASSWORD_WITH_TOKEN_LINK%";
const PARAM_COMMENTS = "%PARAM_COMMENTS%";
const PARAM_PROBLEM_DESCRIPTION = "%PARAM_PROBLEM_DESCRIPTION%";
const PARAM_CUSTOMER_NAME = "%PARAM_CUSTOMER_NAME%";
const PARAM_REQUEST_DATE = "%PARAM_REQUEST_DATE%";
const PARAM_REQUEST_APPOINTMENT_TIME = "%PARAM_REQUEST_APPOINTMENT_TIME%";
const PARAM_CUSTOMER_FIRST_NAME = "%PARAM_CUSTOMER_FIRST_NAME%";
const PARAM_CUSTOMER_LAST_NAME = "%PARAM_CUSTOMER_LAST_NAME%";
const PARAM_REQUEST_APPOINTMENT_DATE = "%PARAM_REQUEST_APPOINTMENT_DATE%";
const PARAM_REQUEST_APPOINTMENT_DATETIME = "%PARAM_REQUEST_APPOINTMENT_DATETIME%";
const PARAM_REQUEST_ID = "%PARAM_REQUEST_ID%";


export const ARRAY_PARAMS_REQUEST_MAIL_TYPE = [PARAM_REQUEST_ID,PARAM_PROBLEM_DESCRIPTION, PARAM_CUSTOMER_NAME, PARAM_CUSTOMER_FIRST_NAME, PARAM_CUSTOMER_LAST_NAME,PARAM_REQUEST_DATE];
export const PARAMS_REQUEST_MAIL_TYPE_STATUS_REPAIRED = ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([PARAM_COMMENTS, PARAM_EDIT_REQUEST_WITH_TOKEN_LINK])
export const PARAMS_REQUEST_MAIL_TYPE_STATUS = ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([PARAM_COMMENTS]);
export const PARAMS_MAIL_TYPE_CONFIRM_REGISTRATION = [PARAM_CONFIRM_REGISTRATION_LINK]
export const PARAMS_MAIL_TYPE_FORGOT_PASSWORD = [PARAM_CHANGE_PASSWORD_WITH_TOKEN_LINK];
export const PARAMS_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER = ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([PARAM_REQUEST_APPOINTMENT_DATETIME, PARAM_REQUEST_APPOINTMENT_DATE, PARAM_REQUEST_APPOINTMENT_TIME]);
export const PARAMS_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER = ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([PARAM_REQUEST_APPOINTMENT_DATETIME, PARAM_REQUEST_APPOINTMENT_DATE, PARAM_REQUEST_APPOINTMENT_TIME]);
export const PARAMS_MAIL_TYPE_PAID_CUSTOMER = ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([PARAM_REQUEST_APPOINTMENT_DATETIME, PARAM_REQUEST_APPOINTMENT_DATE, PARAM_REQUEST_APPOINTMENT_TIME]);
export const PARAMS_MAIL_TYPE_AFFECT_REPAIRER = ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([PARAM_REQUEST_APPOINTMENT_DATETIME, PARAM_REQUEST_APPOINTMENT_DATE, PARAM_REQUEST_APPOINTMENT_TIME]);
export const PARAMS_MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER= ARRAY_PARAMS_REQUEST_MAIL_TYPE.concat([ PARAM_REQUEST_APPOINTMENT_DATE, PARAM_REQUEST_APPOINTMENT_TIME]);
export const PARAMS_REQUEST_MAIL_TYPE_UPLOAD_FILE = [...ARRAY_PARAMS_REQUEST_MAIL_TYPE].concat([PARAM_COMMENTS]);
export const PARAMS_REQUEST_MAIL_TYPE_CANCEL_APPOINTMENT = [...ARRAY_PARAMS_REQUEST_MAIL_TYPE];
export const PARAMS_REQUEST_MAIL_TYPE_GO_FREE = [...ARRAY_PARAMS_REQUEST_MAIL_TYPE].concat([PARAM_EDIT_REQUEST_WITH_TOKEN_LINK, PARAM_COMMENTS])
export const PARAMS_REQUEST_MAIL_TYPE_GO_DOC = [...ARRAY_PARAMS_REQUEST_MAIL_TYPE].concat([PARAM_EDIT_REQUEST_WITH_TOKEN_LINK, PARAM_COMMENTS])

// mailType



// START SMS

export const SMS_SEND_UPLOAD_FILE_REQUEST_URL = "SMS_SEND_UPLOAD_FILE_REQUEST_URL";

const PARAM_CUSTOMER_FULL_NAME = "%PARAM_CUSTOMER_FULL_NAME%";
const PARAM_COMPANY_NAME = "%PARAM_COMPANY_NAME%";
const PARAM_PHONE_NUMBER = "%PARAM_PHONE_NUMBER%";
const PARAM_UPLOAD_FILE_REQUEST_URL = "%PARAM_UPLOAD_FILE_REQUEST_URL%";
export const ARRAY_PARAMS_SMS_SEND_UPLOAD_FILE_REQUEST_URL = [PARAM_CUSTOMER_FULL_NAME, PARAM_CUSTOMER_FIRST_NAME, PARAM_CUSTOMER_LAST_NAME, PARAM_COMPANY_NAME, PARAM_PHONE_NUMBER, PARAM_UPLOAD_FILE_REQUEST_URL];

// end SMS

/* end group user */
export const  FORM_EDIT = "FORM_EDIT"
export const  FORM_CREATE = "FORM_CREATE"
export const  FORM_LIST = "FORM_LIST"

/* start status type */
export const GO_DOC = "go_doc";
export const REPAIRED = "repaired";
export const NOT_BE_REPAIRED = "not_be_repaired";
export const WAITING_FOR_REPLY = "waiting_for_reply";
export const MAYBE = "maybe";
export const STOP = "stop";
export const UNASSIGNED = "unassigned";
export const ASSIGNED = "assigned";
export const SAVED = "saved";
export const ARCHIVED = "archived";
export const UNARCHIVED = "unarchived";
export const STATUS_ALL = "all";
export const REPEAT = "repeat";
export const UNARCHIVED_AND_NOT_REPEAT = "unarchivedAndNotRepeat"
export const REPAIRING = 'repairing';
export const COMPLETE_REPAIR = 'complete_repair';
export const STATUS_ALL_LIST = [UNARCHIVED_AND_NOT_REPEAT, ARCHIVED,REPEAT];
/* end status type */

export const TRUE_NUMBER = "1";
export const FALSE_NUMBER = "0"
export const CODE_ERROR_503 = 'CODE_ERROR_503';
export const CODE_ERROR_500 = 'CODE_ERROR_500';
export const CODE_ERROR_404 = 'CODE_ERROR_404';
export const CODE_ERROR_400 = 'CODE_ERROR_400';
export const CODE_ERROR_403 = 'CODE_ERROR_403';

export const XS = 'xs';
export const SM = 'sm';
export const MD = 'md';
export const LG = 'lg';
export const XL = 'xl';

export const FORM_CONTROL = 'formcontrol';

export const LOGIN_PAGE = 'login_page';
export const SIGN_UP = 'sign_up';
export const RESET_PASSWORD = 'reset_password';
export const PASSWORD_COMPONENT = 'password_component'

export const toolbarOptionsReactQuill= [
    ["bold", "italic", "underline", "strike","link"],
    // [{ font: [] }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["blockquote", "code-block"],
    // [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // ["image"],
    // []
    ["clean"]
];

// start value type
export const OBJECT = "object";
export const ARRAY = "array";
export const EMAIL = "email";
export const STRING = "string";
export const NUMBER = "number";
export const FLOAT = "float";
export const INT = "int";
export const PHONE_NUMBER = "phoneNumber";
// end value type

export const ACTION_ADD = "ACTION_ADD";
export const ACTION_DELETE = "ACTION_DELETE"

//parameter
export const CURRENCY = "currency";
export const BREAK_TIME = "break_time";
export const GENERAL_DOCUMENT = 'general_document'
export const WORK_TIME = "work_time";
export const DAY_OF_WEEK = "day_of_week";
export const HOLIDAY = "holiday";
export const CALL_TIME = "call_time";
export const BLICKO_CONTACT = "blicko_contact";
export const PRICE_PAY_DEFAULT = 'price_pay_default';
export const EMAIL_ADDRESS_BCC = 'email_address_bcc';
export const IMPORT_INTERVENTION = 'import_intervention';
export const APP_ADVERTISE = 'app_advertise';
export const SHARE_DOCUMENT_EXPIRE_NUMBER_DAY= 'share_document_expire_number_day';
export const SMS_TOKEN= 'sms_token';
export const VOICE_MESSAGE_TOKEN= 'voice_message_token';
export const SMS_SENDER = 'sms_sender';
export const REGISTERED_PHONE_NUMBER = 'registered_phone_number';

export const  arrayParameters = [CURRENCY, BREAK_TIME,WORK_TIME,DAY_OF_WEEK,HOLIDAY,CALL_TIME, BLICKO_CONTACT, PRICE_PAY_DEFAULT, EMAIL_ADDRESS_BCC,APP_ADVERTISE, SHARE_DOCUMENT_EXPIRE_NUMBER_DAY];
//parameter

export const arrCurrentForm = [
 "value", "description"
];

export const arrBreakTimeForm = [
    "description", "startTime", "endTime"
];

export const arrWordTimeForm = arrBreakTimeForm;

export const arrDayOfWeekForm = [
   "value", "description", "startTime", "endTime"
];

export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 7;



export const arrHolidayForm = [
    "description", "startDate", "endDate"
];

export const arrCallTimeForm = [
    "description", "callTime"
];
export const arrBlickoContact = [
    "value", "description"
];

export const arrPricePayDefault = [
    "value", "description"
];
export const arrShareDocumentExpireNumberDay = [
    "value", "description"
];

export const arrEmailAddressBcc = [
    "value", "description"
];

export const arrAppAdvertisec = [
    "description"
];

export const PAYMENT_TYPE_SOGE_COMMERCE = 'sogeCommerce';
export const PAYMENT_TYPE_PAYPAL = 'paypal';
export const PAYMENT_CARD_VISA = "VISA";
export const PAYMENT_CARD_MASTERCARD = "MASTERCARD";


export const BYTE_TO_KILOBYTE= 1000

//start Unit size file
export const BYTE = 'B';
export const KILOBYTE = 'KB';
export const MEGABYTE = 'MB';
export const GIGABYTE = 'GB';
//end Unit size file

export const CLIENT_DOC_TAB_IMAGE_VIDEO = 'CLIENT_DOC_TAB_IMAGE_VIDEO';
export const CLIENT_DOC_TAB_BILL = 'CLIENT_DOC_TAB_BILL';
export const CLIENT_DOC_TAB_REPORT_QUOTATION = 'CLIENT_DOC_TAB_REPORT_QUOTATION';
export const CLIENT_DOC_TAB_OTHER = 'CLIENT_DOC_TAB_OTHER';


export const REQUEST_DOC_TYPE_VIDEO = "VIDEO";
export const REQUEST_DOC_TYPE_IMAGE = "IMAGE";
export const REQUEST_DOC_TYPE_REPORT = "REPORT";
export const REQUEST_DOC_TYPE_BILL = "BILL";
export const REQUEST_DOC_TYPE_OTHER = "OTHER";
export const REQUEST_DOC_TYPE_QUOTATION = "QUOTATION";
export const REQUEST_DOC_TYPE_CERTIFICATION_VAT = "CERTIFICATION_VAT";
export const FILE_2_MB_SIZE_BYTES = 2097152;


export const REQ_DOC_VIDEO = "VIDEO";
export const REQ_DOC_REPORT = "REPORT";
export const REQ_DOC_BILL = "BILL";
export const REQ_DOC_OTHER = "OTHER";
export const REQ_DOC_CERTIFICATION_VAT = "CERTIFICATION_VAT";
export const REQ_DOC_IMAGE = "IMAGE";

export const REQ_DOC_SIGNATURE_REPAIRER = 'signatureRepairer';
export const REQ_DOC_SIGNATURE_WITHOUT_TEXT_REPAIRER = 'signatureWithoutTextRepairer'
export const REQ_DOC_REPAIR_DOCUMENT = 'repairDocument';

export const FILE_TYPE_VIDEO = 'video';
export const FILE_TYPE_IMAGE = 'image';
export const FILE_TYPE_APPLICATION = 'application';
export const FILE_SUBTYPE_PDF = 'pdf';

// download type
export const DOWNLOAD_DOCUMENT= "downloadDocument";
export const DOWNLOAD_DOCUMENT_BY_REQUEST_AND_ZIP = "downloadDocumentByRequestAndZip";


export const IS_GO_FREE = "nonPayment";
export const SMALL_SUPPLIES = 'SMALL_SUPPLIES';
export const LARGE_SUPPLIES = 'LARGE_SUPPLIES';
export const TOOLS = 'TOOLS';

export const INFORMATION = 'information';
export const FIRST_ACTION = 'firstAction';
export const LITTLE_FURNITURE = 'little_furniture'
export const BIG_SUPPLY = 'bigSupply';
export const TOOL = 'tool';
export const GESTURE = 'gesture';
export const FINANCIAL = 'financial';
export const LIST_TAB_DETAIL = [
    INFORMATION,
    FIRST_ACTION,
    LITTLE_FURNITURE,
    BIG_SUPPLY,
    TOOL,
    GESTURE,
    FINANCIAL
]
export const listKeyGesture = [
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "AA",
    "AB",
    "AC",
    "AD",
    "AE",
    "AF",
    "AG",
    "AH",
    "AI",
    "AJ",
    "AL",
    "AM",
    "AN",
    "AO",
    "AP",
    "AQ",
    "AR"
];

export const dataDiagnosticTab = [
    {
        name: INFORMATION,
        title: 'Informations',
    },
    {
        name: FIRST_ACTION,
        title: 'Gestes 1er secours',
    },
    {
        name: LITTLE_FURNITURE,
        title: 'Petites fournitures',
    },
    {
        name: BIG_SUPPLY,
        title: 'Grosses fournitures',
    },
    {
        name: TOOL,
        title: 'Outillage',
    },
    {
        name: GESTURE,
        title: 'Gestes',
    },
    {
        name: FINANCIAL,
        title: 'Financier',
    },
];

export const TYPE_CREATE_REPORT = 'TYPE_CREATE_REPORT';
export const TYPE_DETAIL_REPORT = 'TYPE_DETAIL_REPORT';

export const DISCOUNT_PERCENT = 'DISCOUNT_PERCENT';
export const DISCOUNT_NOT_PERCENT= 'DISCOUNT_NOT_PERCENT';

export const IS_DRAFT = "isDraft";
export const IS_PDF = "isPdf";
export const IS_SENT_TO_CLIENT = "isSentToClient";
export const IS_TRASH = "isTrash";
export const IS_PAID = "isPaid";

export const PAYMENT_REPORT = 'PAYMENT_REPORT';
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const STATUS_WAITING = 'waiting';
export const STATUS_DONE = 'done';
export const STATUS_FAIL = 'failure';


export const OBJECT_COMPANY = "company";
export const OBJECT_PERSONAL = "personal";

export const UNIT_MINUTE = 'min';

export const ADD_PARAMS_FILTERS_LIST = "ADD_PARAMS_FILTERS_LIST"
export const REMOVE_PARAMS_FILTERS_LIST = "REMOVE_PARAMS_FILTERS_LIST"
export const VIEW_TYPES = {
    ACCORDION: 0,
    SUMMARY: 1
};

export const BUILT_FOR_OVER_2_YEARS_YES = 1;
export const BUILT_FOR_OVER_2_YEARS_NO = 2;
export const BUILT_FOR_OVER_2_YEARS_DONT_KNOW = 3;

export const REDIRECT = 'redirect';

export const FACEBOOK_ACCOUNT_NOT_EMAIL = 'facebook_account_not_email';
export const CALLING = "calling";
export const ENDED = "ended";
export const MEETING_VIDEO = 'meetingVideo';
