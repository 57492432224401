import moment from "moment";
import {ARRAY, EMAIL, NUMBER, OBJECT, PHONE_NUMBER} from "../constants/constants";

export const checkSubmitSendDataERROR = (value, type, errorsAll,field, errorByField, t) =>{
    const messError = t ? t(errorByField) : errorByField;
    // console.log(value)
    if(value===null || (typeof value === "string" && value.trim()==="") || typeof value === "undefined"){
        return{...errorsAll , [field]: messError};
    }else {
        switch (type) {
            case OBJECT:
                if (typeof value !== OBJECT || JSON.stringify(value) === "{}") {
                    errorsAll = Object.assign({},errorsAll , {[field]: messError});
                }
                break;
            case ARRAY:
                if (!Array.isArray(value) || value.length < 1) {
                    errorsAll=  Object.assign({},errorsAll , {[field]: messError});
                }
                break;
            case EMAIL:
                const regexEmail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
                if(value && regexEmail.test(value.toString()) === false){
                    errorsAll=  Object.assign({},errorsAll , {[field]: messError});
                }
                break;
            case NUMBER:
                const regexNumber = /^[0-9]*$/g;
                if(regexNumber.test(value.toString()) === false || parseInt(value) < 0){
                    errorsAll = {...errorsAll, [field]: messError};
                }
                break;
            case PHONE_NUMBER:
                const regexPhoneNumber = /^[0-9+\s]*$/g;
                if(regexPhoneNumber.test(value.toString()) === false){
                    errorsAll = {...errorsAll, [field]: messError};
                }
                break;
            default:
                break;
        }
        return errorsAll;
    }
}


export const checkDateTime1IsMoreThanDatetime2 = (datetime1, datetime2, checkByDateFormat) =>{
    if(datetime1 && datetime2){
        let momentDatetime1 = moment.isMoment(datetime1) ? datetime1 : moment(datetime1);
        let momentDatetime2 = moment.isMoment(datetime2) ? datetime2 : moment(datetime2);
        if(momentDatetime1.isValid() && momentDatetime2.isValid()){
            if(checkByDateFormat){
                momentDatetime1 = moment(momentDatetime1.format(checkByDateFormat));
                momentDatetime2 = moment(momentDatetime2.format(checkByDateFormat));
            }
            if(momentDatetime1.isValid() && momentDatetime2.isValid() && momentDatetime1.valueOf() > momentDatetime2.valueOf()){
                return true;
            }
        }
    }
    return false

}
