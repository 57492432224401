import { IconButton, Snackbar, Tooltip, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useState } from "react";
import ShareIcon from "@material-ui/icons/Share";
import { Alert } from "@material-ui/lab";
import UploadFile from "../../../theme/Modal/UploadFile";

const styles = (theme) => ({
  request_info: {
    display: "flex",
    flexDirection: "column",
    width: 600,
    maxWidth: "calc(100% - 40px)",
    background: "#fff",
    boxShadow: "0 3px 6px 0 rgba(13, 66, 159, 0.16)",
    borderRadius: 12,
    padding: 10,
    marginBottom: 10,
    color: "#605e72",
    "& .top": {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
      "& .left_wrapper": {
        display: "flex",
        alignItems: "flex-start",
        "& .shareBtn": {
          color: "#0071c7",
        },
      },
      "& .left": {
        padding: 10,
      },
    },
    "& .title": {
      fontWeight: "bold",
      fontSize: "18px",
    },
    "& .subject": {
      fontWeight: "bold",
    },
    "& .problem": {
      padding: "0 10px",
    },
  },
});
const RequestDesc = (props) => {
  const { data, t, classes } = props;
  const [isShowProblem, setIsShowProblem] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const onShareRepair = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(function () {
        // console.log("URL copied to clipboard");
        setLinkCopied(true);
      })
      .catch(function (error) {
        // console.error("Error copying URL to clipboard: ", error);
      });
  };
  if (!data) return <></>;

  const documentsList = data?.documents ?? [];
  return (
    <div
      className={classes.request_info}
      onClick={() => setIsShowProblem((prev) => !prev)}
    >
      <div className="top">
        <div className="left_wrapper">
          <Tooltip title={t("requestDocument.btn.share")}>
            <IconButton
              className="shareBtn"
              onClick={(event) => {
                event.stopPropagation();
                onShareRepair();
              }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
          <div className="left">
            <div className="title">
              {t("request.requestDetail.encounteredProblem")}
            </div>
            <div
              className="subject"
              dangerouslySetInnerHTML={{ __html: data.subject }}
            ></div>
          </div>
        </div>
        <div
          style={{
            padding: 10,
          }}
        >
          {isShowProblem ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </div>
      {/*{isShowProblem && (*/}
      {/*  <div*/}
      {/*    className="problem"*/}
      {/*    dangerouslySetInnerHTML={{ __html: data.problem }}*/}
      {/*  ></div>*/}
      {/*)}*/}
      {isShowProblem && (
        <div onClick={(event) => event.stopPropagation()}>
          <UploadFile
            hiddenUploadFile={true}
            isRequestNew={true}
            isUploadRepairDoc={true}
            initialData={{
              filesInitial: documentsList,
            }}
          />
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={linkCopied}
        autoHideDuration={2000}
        onClose={() => setLinkCopied(false)}
      >
        <Alert onClose={() => setLinkCopied(false)} severity="info">
          {t("requestRepair.link_copied")}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default compose(withStyles(styles), withTranslation())(RequestDesc);
