import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Switch, Tooltip, withWidth} from "@material-ui/core";
import userIcon from "../../../../images/infomation/user.svg";
import mailIcon from "../../../../images/infomation/mail.svg";
import phoneIcon from "../../../../images/infomation/phone.svg";
import homeIcon from "../../../../images/infomation/home.svg";
import addressIcon from "../../../../images/infomation/address.svg";
import corporationIcon from "../../../../images/infomation/corporation.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import {
    MD,
    LG,
    XS,
    SM,
    TRUE_NUMBER,
    FALSE_NUMBER,
    GO_DOC,
    OBJECT_COMPANY,
    BUILT_FOR_OVER_2_YEARS_YES,
    BUILT_FOR_OVER_2_YEARS_NO,
    BUILT_FOR_OVER_2_YEARS_DONT_KNOW,
    APP_ADVERTISE
} from "../../../../constants/constants";
import {MONTSERRAT, NUNITO} from "../../../../constants/fontCss";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
import {withRouter} from "react-router-dom";
import * as links from "../../../../constants/links";
import AppCountryAutocomplete from "../../../../theme/form/Autocomplete/AppCountryAutocomplete";
import countries from "i18n-iso-countries";
import AppAsyncPaginate from "../../../../theme/form/Select/AppAsyncPaginate";
import {API_GET_PARAMETER, GET_LEASE_TYPE_LIST} from "../../../../constants/apiSuffix";
import qs from "qs";
import axios from "axios";
import {getParamInUrl} from "../../../../functions/functions";
import AppKeyboardDatePicker from "../../../../theme/form/DatePicker/AppKeyboardDatePicker";
import CustomSvg from "../../../../theme/CustomElements/CustomSvg";
import PersonalIcon from "../../../../images/request/personal.svg";
import CompanyIcon from "../../../../images/request/company.svg";
import VatIcon from "../../../../images/request/vat.svg";
import checkOwnerIcon from "../../../../images/request/checkOwner.svg";
import HowknowUsIcon from "../../../../images/request/howknow.svg";
import tickIcon from "../../../../images/request/status/checktick.svg";
import builtForOver2YearsIcon from "../../../../images/request/builtForOver2Years.svg";
import startingDateOfStayIcon from "../../../../images/request/startingDateOfStay.svg";
import sentByAgencyIcon from "../../../../images/request/sentByAgency.svg";
import additionalInfoIcon from "../../../../images/request/additionalInfo.svg";
import ErrorIcon from "./../../../../images/requestNew/error.svg";
import {
    errorMessPanel1, errorMessPanel2, errorMessPanel4,
    errorsMessCreateRequestBillingAddress,
    errorsMessCreateRequestCompany
} from "../../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../../functions/checkCondition";
import {value} from "lodash/seq";
import moment from "moment";
const styles = theme => ({
    information: {
        '& .advertiseBlock':{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: '20px',
            paddingBottom: '16px',

            [theme.breakpoints.down(XS)]: {

                flexDirection: 'column',
                alignItems: 'flex-start',
            },


            '& .labelValue':{
                fontFamily: MONTSERRAT,
                fontSize: '1rem',
                color: '#3a3a3a',
                lineHeight: 1.2,
                paddingRight: 12,
                paddingTop: 5,
                paddingBottom: 5,
                //maxWidth: 'calc( 100% - 250px)',
                maxWidth: '350px',
                position: 'relative',
                paddingLeft: '0px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',

                '&:before':{
                    content:'""',
                    backgroundImage: `url(${HowknowUsIcon})`,
                    display:'block',
                    marginRight: '9px',
                    height: '41px',
                    minWidth: '40px',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                },


                '&~div':{
                    marginBottom: '10px',
                }



            },
            '& .selectPaginateAdvertise__multi-value__label':{
                fontSize: '0.875rem',
            },
            '& .selectPaginateAdvertise__control': {
                // border: 'none',
                minWidth: 250,
                //backgroundColor: '#eaf1fb',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 8,
                // margin: '14px 0',
                padding: '4px 7px',
                border: '1px solid #333',
                maxWidth: '450px',
                cursor: 'pointer',

                '&:hover': {
                   // backgroundColor: '#c9e1fb',
                   border: '1px solid #0000ff',

                   '& .css-1wa3eu0-placeholder':{
                       color: '#0000ff',
                   },
                   '& svg path':{
                        color: '#0000ff',
                   },
                   '& span':{
                        backgroundColor: '#0000ff',
                   }
                }
            },
            '& .selectPaginateAdvertise__multi-value':{
                backgroundColor: 'hsl(42.2, 100%, 85.5%)',
            }
        },
        '& .accordionError': {
            backgroundImage: 'linear-gradient(135deg, rgba(255, 132, 147, 0.53) 0%, rgba(177,244,225,0) 23%)',
            '& .titleHeaderContent': {

                '& svg': {
                    '& path': {
                        fill: '#ec3a51',
                        stroke: 'initial',

                    }
                }
            }
        },
        '& .accordionComplete': {
            backgroundImage: 'linear-gradient(135deg, rgba(177,244,225,1) 0%, rgba(177,244,225,0) 23%)',
            '& .titleHeaderContent': {
                '& svg': {
                    '& path': {
                        fill: '#00E1C2'
                    }
                }
            }
        },
        '& .userInfo': {
            width: '100%',
            margin: 'auto',
            maxWidth:'500px',
        },
        '& .authorizeShareEmail': {
            display: 'flex',
            alignItems: 'center',
            marginTop: 15,
            lineHeight: '16px',
        },
        '& .MuiAccordion-root': {
            //backgroundColor: '#eaf1fb',
          //   backgroundColor: '#e1ebfb',
           //  backgroundColor: ' #dfe8f7',
           backgroundColor: '#dbe6f7',
            boxShadow: 'none',
            borderRadius: 8,
            margin: '14px 0',
            padding: 8,
            '&:before': {
                display: 'none'
            },
            '&:not(.Mui-expanded):hover':{
                backgroundColor:'#c9e1fb',

                '& .titleHeaderContent':{
                    paddingLeft: '8px',
                    transition: '0.2s ease',
                }
            },


        },
        '& .titleHeaderContent': {
            padding: '0 0.3em',
            flexGrow: 1,
           // color: '#42464d',
            color: '#202d46',
            fontWeight: 700,
            //fontSize: '1rem',
            fontSize:  '1.2rem',
            lineHeight: '1.4rem',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            paddingLeft:0,
            transition: '0.2s ease',

            '& svg': {
                position: 'absolute',
                left: -56,
                width: 20,
                [theme.breakpoints.down(SM)]:{
                    left: '-43px',
                    width: '13px',
                },
                [theme.breakpoints.down(XS)]:{
                    left: '-51px',

               }
            },

            [theme.breakpoints.down(XS)]:{
                fontSize:  '1.1rem',
                lineHeight: '1.3rem',
           }


        },
        '& .rowFormColumn':{
            alignItems: 'flex-start',

            [theme.breakpoints.down(XS)]:{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
           },

            '& .otherPhoneNumber > div': {
                paddingTop: '10px',
                '&:first-child':{
                    paddingTop: 0
                }
            }
        },
        '& .headerInfo': {
            fontFamily: MONTSERRAT,
            fontSize: '1.0625rem',
            fontWeight: 700,
            // lineHeight: 1.4,
            // height: '1.125rem',
            display: 'flex',
            flexDirection: 'row',
            color: '#001529',
            // padding: '1rem',
            marginBottom: '1rem',
            // '&:before': {
            //     borderBottom: '1px solid #E0E0E0',
            //     content: '""',
            //     flex: '1',
            // },
            // '&:after': {
            //     borderBottom: '1px solid #E0E0E0',
            //     content: '""',
            //     flex: '4',
            // },


        },
        '& .addressDetail': {
            display: 'flex',
            justifyContent: 'flex-start',
            '& .rowFormColumn': {
                width: 'unset'
            },
            [theme.breakpoints.down(XS)]: {
                flexDirection: 'column',
            },
        },
        '& .column': {
            paddingRight: '0.25rem',
        },
        '& .column1': {
            paddingRight: '0.5rem',
            [theme.breakpoints.between(XS, SM)]: {
                paddingRight: '0',
            },
            [theme.breakpoints.between(SM, MD)]: {
                paddingRight: '0.5rem',
            },

            [theme.breakpoints.between(MD, LG)]: {
                paddingRight: '0.5rem',
            },
        },
        '& .column2': {
            paddingLeft: '0.5rem',
            [theme.breakpoints.between(XS, SM)]: {
                paddingLeft: '0',
            },
            [theme.breakpoints.between(SM, MD)]: {
                paddingLeft: '0.5rem',
            },

            [theme.breakpoints.between(MD, LG)]: {
                paddingLeft: '0.5rem',
            },
        },
        '& .imgSrcLabelRowFormColumn': {
            // height: 25
        },
        '& .labelRowFormColumn': {
            fontFamily: MONTSERRAT,
            fontSize: '0.9rem',
            color: '#3a3a3a',
            paddingBottom: '6px',
            height: 40,
            lineHeight: '1.1rem',
            '& svg': {
                marginRight: 10,
                minWidth:'20px',
                '& path, rect': {
                   // fill: '#0022ff!important',
                   fill: '#3f4046 !important',
                },
                '& circle': {
                    fill: 'transparent!important'
                }
            },

            [theme.breakpoints.down(XS)]:{
                marginRight: '10px',
                minWidth: '138px',
                maxWidth: '138px',
                width: 'inherit',

                '& svg': {
                    marginRight: 5,
                }
           }

        },
        '& .inputRowFormColumn': {
            fontFamily: MONTSERRAT,
            fontSize: '0.875rem',
            fontWeight: 400,
            '& span':{
                color: '#3a3a3a'
            },

            [theme.breakpoints.down(XS)]: {
                width: '100%',
            },
        },
        '& input': {
            backgroundColor: '#fff',
            borderRadius: 4,
            fontSize: '1rem',

        },
        '& .MuiFormControl-root':{
            border: '0 !important',
        },
        '& .MuiInputBase-root':{
            border: '0 !important',
        },

        '& .inputBlockDatePicker': {
            backgroundColor: '#fff',
            borderRadius: 4
        },
        '& .additionalInfo':{
            background: '#FFFFFF',
            border: '1px solid #E0E0E0',
            borderRadius: 6,
            // padding: '6px 10px 7px',
            fontSize: '0.875rem',
            '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                borderRadius: 4
            }
        },
        '& .leftHeader': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontWeight: 500,
            fontSize: '0.85rem',
            '& svg': {
                '& path': {
                    fill: '#0022ff'
                }
            },
            '& div' :{
                textAlign: 'right',
                lineHeight: '16px',
            }
        },

        '& .panel-header':{
            '& .MuiAccordionSummary-content':{
                margin:'0 0',

                [theme.breakpoints.down(XS)]: {
                    margin:'21px 0 21px 8px',
                },

            },
        },
        '& #panel1bh-content .MuiAccordionDetails-root':{
           padding: '40px 20px 55px 20px',
            [theme.breakpoints.down(XS)]: {
                padding: '20px 15px 60px 30px',
            },
        },
        '& #panel2bh-content .MuiAccordionDetails-root':{
            padding: '20px 50px 45px',
            [theme.breakpoints.down(XS)]: {
                padding: '40px 0px 40px 15px',

                '& .nameContent':{
                    marginBottom: '25px',
                   // border-bottom: 1px dashed #333;
                    paddingBottom: '24px !important',
                }
            },

        },
        '& #panel3bh-content .MuiAccordionDetails-root':{
            padding: '0px 80px 35px',
            [theme.breakpoints.down(XS)]: {
                padding: '40px 25px',
            },
        },
        '& #panel4bh-content .MuiAccordionDetails-root':{
            padding: '0px 30px 35px',

            [theme.breakpoints.down(SM)]: {
                padding: '40px 25px',

                '& .rowFormColumn':{
                    flexDirection: 'column',
                },



            },

            '& .grid-panel4-left >div>div:nth-child(2)':{
                //width: '100%',
                //marginLeft: '60px',

                    '& .emailinfo':{
                        marginTop:5,
                        marginLeft:40,

                        '& .MuiInputBase-input.Mui-disabled':{
                            background: '#dee5f0',
                        },
                        '& input':{
                            maxWidth: '160px',
                        },
                        [theme.breakpoints.down(XS)]: {
                            flexDirection:'column',
                        },


                    },

                    [theme.breakpoints.down(XS)]: {
                        marginLeft: '0px',
                    },

            },

            '& .grid-panel4-right':{
                '& .appDatePicker ':{
                    maxWidth: '190px',
                },
            },
            '& label':{
                //padding: '5px 0px',
                marginRight: 0,

                '& .MuiFormControlLabel-label':{
                    fontSize: '0.95rem',
                    lineHeight: '1rem',
                    marginBottom: '5px',

                }

            },
            '& .MuiCheckbox-root .MuiSvgIcon-root path':{
                color: '#0b48fd',
            }

        },

        [theme.breakpoints.down(SM)]: {
        //BTN SECTION
        '& .MuiFormGroup-row, .MuiFormGroup-root':{

            '& label':{
                marginBottom: '6px',
                marginRight: 0,
                justifyContent: 'flex-start',


                '& .MuiIconButton-label':{
                    display: 'none !important',
                },
            },
            '& .MuiFormControlLabel-label':{
                padding: '8px 17px !important',
                borderRadius: '6px',
                border: '1px solid #333 !important',
                lineHeight: '1rem',
                textAlign: 'center',
                minWidth: 69,
            },
            '& .Mui-checked':{

                '&~.MuiFormControlLabel-label':{
                    color: '#fff !important',
                    background: '#0b48fd !important',
                    border: '1px solid transparent !important',
                },


            }

          },

       },

    },

    leftContent: {
        paddingRight: '1rem',
        '& .leftContentWrapper': {
            background: '#F5F5F5',
            borderRadius: 15,
            // padding: '1rem 0',
            width: '100%',
            '& .leftContent': {
                // padding: '1rem',
            }
        },
        [theme.breakpoints.between(SM, MD)]: {
            paddingRight: '0.5rem',
        },
        [theme.breakpoints.between(XS, SM)]: {
            paddingRight: 0
        },
        '& input':{
            background: '#FFFFFF'
        }
    },
    blockInfo: {
        background: '#F5F5F5',
        borderRadius: 15,
        padding: '2rem',
        [theme.breakpoints.between(XS, MD)]:{
            padding: '1rem',
        }
    },
    rightContent: {
        paddingLeft: '1rem',
        '& .rightContentWrapper': {
            background: '#F5F5F5',
            borderRadius: 15,
            padding: '1rem 0',
            width: '100%',
            '& .rightContent': {
                padding: '1rem',
            }

        },
        [theme.breakpoints.between(SM, MD)]: {
            paddingLeft: '0.5rem',
        },
        [theme.breakpoints.between(XS, SM)]: {
            paddingLeft: 0,
            marginTop: '1rem'
        },
        '& input':{
            background: '#FFFFFF'
        },

    },
    appelVisio: {
        display: 'flex',
        color: '#001529',
        fontFamily: NUNITO,
        fontSize: '1rem',
        fontWeight: 700,
        padding: '0.3rem 0',
        '& img': {
            paddingRight: '0.5rem'
        }
    },
    question: {
        // '& .questionSentByAgency':{
        //     '& svg':{
        //         marginBottom: -3
        //     }
        // },
        '& .MuiFormLabel-root': {
            fontWeight: 700,
            fontFamily: NUNITO,
            fontSize: '1rem',
            color: '#001529',
            '& img': {
                paddingRight: '0.5rem'
            }
        },
        '& .MuiFormGroup-root': {
            paddingLeft: '1rem',
            '& span': {
                fontFamily: NUNITO,
                fontSize: '1rem',
                color: '#001034',
            }
        },
    },
    radioOwnerWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 25,
        paddingBottom: '0.5rem',
        '& .radioOwner': {
            minHeight: 48,
            '& .MuiFormGroup-root': {
                // paddingLeft: '1rem',
                '& span': {
                    fontFamily: NUNITO,
                    fontSize: '1rem',
                    color: '#001034',
                }
            }
        }
    },
    customRadio:{
        marginLeft: '0 !important',

        '& > span':{
            padding: '0 5px !important'
        }
    },
    footerContentInformation: {
        padding: '1.5rem 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between(XS, SM)]: {
            '& .btnSaveWrapper':{
                width: '100%',
            }
        },
        [theme.breakpoints.down(XS)]:{
            '& .btnSaveWrapper':{
                margin: '0 auto',
                justifyContent: 'center!important',
                padding: '1rem'
            }
        },
        '& .checkboxAccept': {
            display: 'flex',
            alignItems: 'flex-start',

            '& .labelCheckBox': {
                paddingTop: 12,
                fontFamily: NUNITO,
                fontSize: '0.875rem',
                color: '#001034',

            },
            '& .MuiCheckbox-root path':{
                color: '#0000ff !important',
            },

        },
        '& .certificate': {
            color: '#101dff',
            display: "block"
        },
        '& .btnSaveWrapper':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginLeft: 'auto'
        }
    },
    btnAddInformation: {
        background: '#0000FF !important',
        borderRadius: 15,
        height: 50,
        width: 145,
    },
    leaseType:{
        marginTop: 15,
        '&.errorInput':{
            '& .selectPaginate__control': {
                border: '1px solid #d01631'
            }
        },
        '& .selectPaginate__control':{
            borderRadius: 6
        },
        "& .selectPaginate__placeholder":{
            color: "hsl(0,0%,50%)"
        }
    },
    startDateOfStay: {
        '& .inputBlockDatePicker':{
            borderRadius: 6
        }
    },
    startDateOfStayDetail:{
        '& .Mui-disabled':{
            color: 'inherit'
        }
    },
    switchRole: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        padding: '1rem 0',
        // flexWrap: "wrap",
        [theme.breakpoints.down(XS)]:{
            marginBottom: '22px',
        },
        '& .optionSwitch': {
            padding: '0 1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '& svg': {
                '& path': {
                    fill: '#cbd2e5'
                }
            },
            '& .label': {
                paddingLeft: '0.5rem',
                fontWeight: 700,
                //color: '#cbd2e5',
                color: '#aabbec',
                textTransform: 'uppercase',
            },
            '&.active': {
                '& svg': {
                    '& path': {
                        fill: '#0022ff'
                    }
                },
                '& .label': {
                    color: '#0022ff'
                },
            },
            '&:not(.active):hover':{

                '& span':{
                color: '#7076a2',
                },
                '& svg path':{
                fill: '#7076a2',
                stroke: 'initial',
                },

            },

        }
    },
    lineObjectCompany: {
        borderBottom: '1px dashed #707070',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        width: "100%",
        height: "1rem"
    },
    isThereAnElevator: {
        '& span': {
            fontSize: '0.8rem',
            padding: '0 0 0 5px'
        }
    },
    itemRowWrapper:{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        // flexWrap: "wrap",
        padding: '0.5rem 0',
        [theme.breakpoints.down(SM)]: {
            flexDirection: 'column',
            marginBottom: 18,
            borderBottom: '1px dashed #333',
            marginTop: '0px',
            paddingBottom: '26px',
        },
    },

    lodgingItemLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: '1rem',
        padding: '0.25rem 0',
        maxWidth: 'calc(100% - 100px)',
        marginBottom: '1em',
        '& .icon': {
            width: 30,
            marginRight: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                '& path': {
                    fill: '#0022ff!important',
                    stroke: 'none !important',
                }
            }
        },
        '& .title': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 36px)',
            fontFamily: MONTSERRAT,
            fontSize: '0.9rem',
            color: '#3a3a3a',
            maxWidth: '226px',
            lineHeight: '1.2rem',
            padding: '0.25rem 0',
        },

        [theme.breakpoints.down(SM)]:{
            maxWidth: 'initial',
            marginBottom: '1em',
            alignItems: 'center',
            display: 'flex',

            '& .title': {
            width: '100% !important',
            minWidth: 'initial',
            lineHeight: '1.35rem',
            paddingBottom: '0em',
            paddingLeft: '7px',
            fontSize: '1.2rem',
            },
        },

    },
    isRequired: {
        fontWeight: 600,
        padding: '0 5px',
        color: '#0022ff !important',
    },
    lodgingItemInput: {
        padding: '0.5rem 0',
        minWidth: '130px',
        display: 'flex',
        justifyContent: 'flex-end',

        '& div, span':{
            fontFamily: MONTSERRAT,
            fontSize: '0.8125rem',
            color: '#3a3a3a',
            border: 0,
        },
        '& div[role="radiogroup"]': {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.down(SM)]:{
            justifyContent: 'flex-start',
        }
    },
    partnerCode:{
        marginTop: "0.5rem",
    },
    optionalField:{
        color: "#6b9bbf!important"
    }
});

export const OWNER = "owner"
export const TENANT = "tenant"

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 36,
        height: 16,
        padding: 0,
        display: 'flex',
        transform: "rotate(180deg)"
    },
    switchBase: {
        padding: 2,
        color: '#fff',
        '&$checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#0022ff',
                borderColor: '#0022ff',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: '1px solid #3a3a3a',
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#0022ff',
    },
    checked: {},
}))(Switch);

export const ACCORDION_A = 'ACCORDION_A';
export const ACCORDION_B = 'ACCORDION_B';
export const ACCORDION_C = 'ACCORDION_C';
export const ACCORDION_D = 'ACCORDION_D';
const closeAll = {
    [ACCORDION_A]: false,
    [ACCORDION_B]: false,
    [ACCORDION_C]: false,
    [ACCORDION_D]: false
}
class InfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: {
                [ACCORDION_A]: true,
                [ACCORDION_B]: false,
                [ACCORDION_C]: false,
                [ACCORDION_D]: false
            },
            isSubmitError: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.openTabInfoFormDefault && closeAll.hasOwnProperty(props.openTabInfoFormDefault)) {
            if(props.removeOpenTabInfoFormDefault && typeof props.removeOpenTabInfoFormDefault === "function") {
                props.removeOpenTabInfoFormDefault()
            }
            return {
                expanded: {
                    ...closeAll,
                    [props.openTabInfoFormDefault]: true
                }
            };
        }
        return null;
    }

    async loadLeaseTypeOptions(search, loadedOptions, {page}) {
        let params = {filters: {name: search}, page: page};
        const url = GET_LEASE_TYPE_LIST + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response.data;
        let dataItems = [];
        let hasMore = false;
        if (data.hasOwnProperty('items') && data.items) {
            dataItems = data.items;
            hasMore = data.currentPage < data.nbPages;
        } else if (data.hasOwnProperty('hydra:member')) {
            dataItems = data["hydra:member"];
            const hydraView = data["hydra:view"];
            const paramsCurrent = getParamInUrl(hydraView["@id"]);
            const paramsPageLast = getParamInUrl(hydraView["hydra:last"]);
            const currentPageList = paramsCurrent.hasOwnProperty("page") ? paramsCurrent.page : 1;
            const nbPagesList = paramsPageLast.hasOwnProperty("page") ? paramsPageLast.page : 1;
            hasMore = currentPageList < nbPagesList;

        }
        const options = dataItems.map((item) => {
            return {label: item.name, value: item.id, ...item};
        });

        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    onChangeRegisteredObject = (event) => {
        this.props.handleChange(event.target.checked ,"registeredObject")
    }

    renderLodgingItem = (props) => {
        const {
            classes,
            t
        } = this.props;
        const {
            icon,
            title,
            input,
            className,
            error,
            optional,
            isRequired
        } = props;

        return (
            <div className={classes.itemRowWrapper}>
                <div className={classes.lodgingItemLabel}>
                    <div className="icon">
                        <CustomSvg
                            svgProps={{
                                src: icon,
                            }}
                        />
                    </div>
                    <div className="title">{title}{!!optional && <span className={classes.optionalField}> ({t("label.optional_field")})</span>} {isRequired && <span className={classes.isRequired}>*</span>}</div>
                </div>
                <div className={classes.lodgingItemInput + " " + (className ? className : "")}>
                    {input}
                    {error &&<ErrorInput textValidate={error}/>}
                </div>
            </div>
        )
    }

    handleChange (expandedItem) {
        //Close All else expandedItem
        let newState = {
            expanded: {
                ...closeAll,
                [expandedItem]: !this.state.expanded[expandedItem]
            }
        }

        Object.keys(newState.expanded).forEach(item => {
            newState.expanded[item] = (item === expandedItem)
        })

        this.setState(newState)
    }

    onChangeOtherPhoneNumberAddress = (event, index) => {
        const {valueForm} = this.props
        let otherPhoneNumberAddress = Array.isArray(valueForm.otherPhoneNumbersAddress) ? [...valueForm.otherPhoneNumbersAddress] : []
        const newValue = event.target.value
        this.props.handleChange(otherPhoneNumberAddress.hasOwnProperty(index) ? otherPhoneNumberAddress.map((item, key) => index !== key ? item : newValue) : otherPhoneNumberAddress.concat([newValue]), "otherPhoneNumbersAddress")
    }

    checkPanel1Complete() {
        const {
            valueForm,
            t
        } = this.props;
        for (let [key, value] of Object.entries(valueForm)) {
            if (errorMessPanel1.hasOwnProperty(key)) {
                if (Object.keys(checkSubmitSendDataERROR(value, errorMessPanel1[key].type, {}, key, errorMessPanel1[key].errorByField, t)).length) {
                    return false;
                }
            }
        }
        if(!valueForm.registeredObject){
            return false;
        }else if(valueForm.registeredObject === OBJECT_COMPANY){
            for (let [key, value] of Object.entries(errorsMessCreateRequestCompany)) {
                if (Object.keys(checkSubmitSendDataERROR(valueForm[key], value.type, {}, key, value.errorByField, t)).length) {
                    return false;
                }
            }
        }
        return true;
    }

    checkPanel2Complete() {
        const {
            valueForm,
            t
        } = this.props;
        for (let [key, value] of Object.entries(valueForm)) {
            if (errorMessPanel2.hasOwnProperty(key)) {
                if (Object.keys(checkSubmitSendDataERROR(value, errorMessPanel2[key].type, {}, key, errorMessPanel2[key].errorByField, t)).length) {
                    return false;
                }
            }
        }
        return true;
    }

    checkPanel3Complete() {
        const {
            valueForm,
            t
        } = this.props;
        if(valueForm.otherBillingAddress){
            for (let [key, value] of Object.entries(errorsMessCreateRequestBillingAddress)) {
                if(Object.keys(checkSubmitSendDataERROR(valueForm[key], value.type, {}, key, value.errorByField, t)).length) {
                    return false;
                }
            }
        }
        return true;
    }

    checkPanel4Complete() {
        const {
            valueForm,
            t
        } = this.props;
        for (let [key, value] of Object.entries(valueForm)) {
            if (errorMessPanel4.hasOwnProperty(key)) {
                if (Object.keys(checkSubmitSendDataERROR(value, errorMessPanel4[key].type, {}, key, errorMessPanel4[key].errorByField, t)).length) {
                    return false;
                }
            }
        }
        if (!moment(valueForm.startingDateOfStay).isValid()) {
            return false;
        }
        if(valueForm.checkOwner === TENANT  && (!valueForm.leaseType || !valueForm.leaseType.value) && !valueForm.ownerEmail){
            return false;
        }

        if (valueForm.sentByAgency === "") {
            return false;
        }
        if(valueForm.sentByAgency === TRUE_NUMBER && !valueForm.realEstateCenterCode){
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isInfoFormError) {
            let checkTab = false;
            let updateState = {
                isSubmitError: true
            };
            let expanded = {}
            for (let tab = 1; tab < 5; tab ++) {
                switch (tab) {
                    case 1:
                        checkTab = !this.checkPanel1Complete();
                        if (checkTab) {
                            expanded = {
                                ...closeAll,
                                [ACCORDION_A]: true
                            }
                        }
                        break
                    case 2:
                        checkTab = !this.checkPanel2Complete();
                        if (checkTab) {
                            expanded = {
                                ...closeAll,
                                [ACCORDION_B]: true
                            }
                        }
                        break
                    case 3:
                        checkTab = !this.checkPanel3Complete();
                        if (checkTab) {
                            expanded = {
                                ...closeAll,
                                [ACCORDION_C]: true
                            }
                        }
                        break
                    case 4:
                        checkTab = !this.checkPanel4Complete();
                        if (checkTab) {
                            expanded = {
                                ...closeAll,
                                [ACCORDION_D]: true
                            }
                        }
                        break
                }
            }
            updateState = {
                ...updateState,
                expanded: {
                    ...this.state.expanded,
                    ...expanded
                }
            }
            if (Object.keys(updateState).length > 0) {
                this.setState(updateState)
            }
        }
    }

     loadParameterOptions = async (search, loadedOptions, {page})=> {
        let params = {filters: {name: search}, page: page, code:APP_ADVERTISE};
        const url = API_GET_PARAMETER + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response?.data ?? {}
        let dataItems =data?.items ?? [];
        const options = dataItems.map((item) => {
            return {label: item.description, value: item.id};
        });
        return {
            options: options,
            hasMore: data.currentPage < data.nbPages,
            additional: {
                page: page + 1,
            },
        };
    }
    render() {
        const {classes, t, valueForm,errors, pageEditNotLogin, paymentRequest, isModalShowClientDetail, location, isLoading} = this.props;
        const {expanded, isSubmitError} = this.state;
        const pathPage = location && location.pathname ? location.pathname : "";
        const isPageDetail = (pathPage.replace(/[0-9]/g, '') === links.REQUEST_INFORMATION_DETAIL.replace(":id", '') )|| isModalShowClientDetail
        const checkPanel1 = this.checkPanel1Complete();
        const checkPanel2 = this.checkPanel2Complete();
        const checkPanel3 = this.checkPanel3Complete();
        const checkPanel4 = this.checkPanel4Complete();
        let errorsField =errors? {...errors}:{};

        return (
            <div className={classes.information}>
                <Accordion className={checkPanel1 ? "accordionComplete" : isSubmitError ? "accordionError" : ""} expanded={expanded[ACCORDION_A]} onChange={() => this.handleChange(ACCORDION_A)}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="panel-header"
                    >
                        <div className={"titleHeaderContent"}>
                            {checkPanel1 ? <CustomSvg
                                svgProps={{
                                    src: tickIcon,
                                }}
                            /> : isSubmitError ? <CustomSvg
                                svgProps={{
                                    src: ErrorIcon,
                                }}
                            /> : ""}
                            {t("information.formTab1.userAccount")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="userInfo">
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.switchRole}>
                                        <div onClick={() => {
                                            if (valueForm.registeredObject === OBJECT_COMPANY) {
                                                this.props.handleChange(true ,"registeredObject")
                                            }
                                        }} className={"optionSwitch" + (valueForm.registeredObject !== OBJECT_COMPANY ? " active": "")}>
                                            <CustomSvg
                                                svgProps={{
                                                    src: PersonalIcon,
                                                }}
                                            />
                                            <span className="label">
                                                    {t("information.formTab1.personal")}
                                                </span>
                                        </div>
                                        <AntSwitch checked={valueForm.registeredObject !== OBJECT_COMPANY} onChange={this.onChangeRegisteredObject}/>
                                        <div onClick={() => {
                                            if (valueForm.registeredObject !== OBJECT_COMPANY) {
                                                this.props.handleChange(false ,"registeredObject")
                                            }
                                        }} className={"optionSwitch" + (valueForm.registeredObject === OBJECT_COMPANY ? " active": "")}>
                                            <CustomSvg
                                                svgProps={{
                                                    src: CompanyIcon,
                                                }}
                                                // scale="0.3"
                                            />
                                            <span className="label">
                                                    {t("information.formTab1.company")}
                                                </span>
                                        </div>
                                    </div>
                                </Grid>
                                {valueForm.registeredObject === OBJECT_COMPANY && <>
                                    <Grid item xs={12}>
                                        <RowFormColumn
                                            label={t("information.formTab1.socialReason")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: CompanyIcon,
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="companyName"
                                                    value={valueForm.companyName}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"companyName")}}}
                                                    validate={errorsField.companyName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.companyName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RowFormColumn
                                            label={t("information.formTab1.vat")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: VatIcon,
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="companyTaxCode"
                                                    value={valueForm.companyTaxCode}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"companyTaxCode")}}}
                                                    validate={errorsField.companyTaxCode}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.companyTaxCode}
                                        />
                                    </Grid>
                                    <div className={classes.lineObjectCompany}>

                                    </div>
                                </>}
                                <Grid container item xs={12}>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className={"column1"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.firstName")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: userIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="firstName"
                                                    value={valueForm.firstName}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"firstName")}}}
                                                    validate={errorsField.firstName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.firstName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className={"column2"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: userIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.lastName")}
                                            input={
                                                <CustomInput
                                                    name="lastName"
                                                    value={valueForm.lastName}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"lastName")}}}
                                                    validate={errorsField.lastName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.lastName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <RowFormColumn
                                            label={t("information.formTab1.email")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: mailIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="email"
                                                    value={valueForm.email}
                                                    disabled={true}
                                                    validate={errorsField.email}
                                                />
                                            }
                                            isRequired={true}
                                        />
                                        <RowFormColumn
                                            label={t("information.formTab1.phoneNumber")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: phoneIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="phoneNumber"
                                                    value={valueForm.phoneNumber}
                                                    onChange={(e)=>{this.props.handleChange(e ,"phoneNumber")}}
                                                    validate={errorsField.phoneNumber}
                                                />
                                            }
                                            textValidate={errorsField.phoneNumber}
                                            isRequired={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={checkPanel2 ? "accordionComplete" : isSubmitError ? "accordionError" : ""} expanded={expanded[ACCORDION_B]} onChange={() => this.handleChange(ACCORDION_B)}>
                    <AccordionSummary
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="panel-header"
                    >
                        <div className={"titleHeaderContent"}>
                            {checkPanel2 ? <CustomSvg
                                svgProps={{
                                    src: tickIcon,
                                }}
                            /> : isSubmitError ? <CustomSvg
                                svgProps={{
                                    src: ErrorIcon,
                                }}
                            /> : ""}
                            {t("information.formTab1.labelAddressRepair")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid item sm={12} md={3}>
                                <Grid container className="nameContent">
                                    <Grid item xs={12} sm={6} md={6} lg={6} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.firstName")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: userIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="addressFirstName"
                                                    value={valueForm.addressFirstName}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressFirstName")
                                                        }
                                                    }}
                                                    validate={errorsField.addressFirstName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressFirstName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: userIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.lastName")}
                                            input={
                                                <CustomInput
                                                    name="addressLastName"
                                                    value={valueForm.addressLastName}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressLastName")
                                                        }
                                                    }}
                                                    validate={errorsField.addressLastName}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressLastName}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: CompanyIcon,
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.company")}
                                            input={
                                                <CustomInput
                                                    name="addressCompany"
                                                    value={valueForm.addressCompany}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressCompany")
                                                        }
                                                    }}
                                                    // validate={errorsField.addressCompany}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            // textValidate={errorsField.addressCompany}
                                            // isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.phoneNumber")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: phoneIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="phoneNumberAddress"
                                                    value={valueForm.phoneNumberAddress}
                                                    onChange={(e) => {
                                                        this.props.handleChange(e, "phoneNumberAddress")
                                                    }}
                                                    validate={errorsField.phoneNumberAddress}
                                                />
                                            }
                                            textValidate={errorsField.phoneNumberAddress}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.otherPhoneNumber")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: phoneIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <div className={"otherPhoneNumber"}>
                                                    <CustomInput
                                                        name="phoneNumberAddress1"
                                                        value={valueForm.otherPhoneNumbersAddress[0] ?? ""}
                                                        onChange={(e) => this.onChangeOtherPhoneNumberAddress(e, 0)}
                                                    />
                                                    <CustomInput
                                                        name="phoneNumberAddress2"
                                                        value={valueForm.otherPhoneNumbersAddress[1] ?? ""}
                                                        onChange={(e) => this.onChangeOtherPhoneNumberAddress(e, 1)}
                                                    />
                                                    <CustomInput
                                                        name="phoneNumberAddress3"
                                                        value={valueForm.otherPhoneNumbersAddress[2] ?? ""}
                                                        onChange={(e) => this.onChangeOtherPhoneNumberAddress(e, 2)}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressEmail")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: mailIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="phoneNumberAddress"
                                                    value={valueForm.addressEmail}
                                                    onChange={(e) => {
                                                        this.props.handleChange(e, "addressEmail")
                                                    }}
                                                    validate={errorsField.addressEmail}
                                                />
                                            }
                                            textValidate={errorsField.addressEmail}
                                            isRequired={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={1}>
                            </Grid>
                            <Grid item sm={12} md={8}>
                                <Grid container className="adressContent" style={{
                                    padding: '0 10px'
                                }}>
                                    <Grid item xs={12} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: addressIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.addressDescription")}
                                            input={
                                                <CustomInput
                                                    name="addressDescription"
                                                    value={valueForm.addressDescription}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressDescription")
                                                        }
                                                    }}
                                                    validate={errorsField.addressDescription}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressDescription}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item sm={12} md={3} lg={3} xl={3} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: homeIcon
                                                    }}
                                                />
                                            }
                                            label={t("information.formTab1.addressPostalCode")}
                                            input={
                                                <CustomInput
                                                    name="addressPostalCode"
                                                    value={valueForm.addressPostalCode}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressPostalCode")
                                                        }
                                                    }}
                                                    validate={errorsField.addressPostalCode}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressPostalCode}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item sm={12} md={5} lg={5} xl={5} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCity")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: corporationIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                <CustomInput
                                                    name="addressCity"
                                                    value={valueForm.addressCity}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "addressCity")
                                                        }
                                                    }}
                                                    validate={errorsField.addressCity}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressCity}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid container item sm={12} md={4} lg={4} xl={4} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCountry")}
                                            imgSrcLabel={
                                                <CustomSvg
                                                    svgProps={{
                                                        src: addressIcon
                                                    }}
                                                />
                                            }
                                            input={
                                                isPageDetail ?
                                                    <CustomInput
                                                        name="addressCountry"
                                                        value={countries.getName(valueForm.addressCountry, this.props.t('translations.lang'))}
                                                    /> :
                                                    isLoading ? <div></div> : <AppCountryAutocomplete
                                                        defaultValue={valueForm.addressCountry ? {
                                                            code: valueForm.addressCountry,
                                                            label: countries.getName(valueForm.addressCountry, this.props.t('translations.lang'))
                                                        } : undefined}
                                                        onChange={(event, value) => {
                                                            if (!isPageDetail) {
                                                                this.props.handleChange(value, "addressCountry")
                                                            }
                                                        }}
                                                        // className={classes.countryField}
                                                        inputPlaceHolder={""}
                                                    />
                                            }
                                            textValidate={errorsField.addressCountry}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="addressDetail">
                                        <RowFormColumn
                                            label={t("information.formTab1.floorNumber")}
                                            input={
                                                <CustomInput
                                                    name="floorNumber"
                                                    value={valueForm.floorNumber}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "floorNumber")
                                                        }
                                                    }}
                                                    validate={errorsField.floorNumber}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.floorNumber}
                                            isRequired={true}
                                        />
                                        <RowFormColumn
                                            label={t("information.formTab1.isThereAnElevator")}
                                            input={
                                                <RadioGroup
                                                    className={classes.isThereAnElevator}
                                                    aria-label="position" name="position"
                                                    value={valueForm.isThereAnElevator}
                                                    // value={valueForm.isThereAnElevator===null || valueForm.isThereAnElevator===undefined?undefined:(!!valueForm.isThereAnElevator?TRUE_NUMBER:FALSE_NUMBER)}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "isThereAnElevator")
                                                        }
                                                    }}>
                                                    <FormControlLabel value={TRUE_NUMBER}
                                                                      className={classes.customRadio} control={<Radio/>}
                                                                      label={t("information.formTab1.yes")}/>
                                                    <FormControlLabel value={FALSE_NUMBER}
                                                                      className={classes.customRadio} control={<Radio/>}
                                                                      label={t("information.formTab1.no")}/>
                                                </RadioGroup>
                                            }
                                            textValidate={errorsField.isThereAnElevator}
                                            isRequired={true}
                                        />
                                        <RowFormColumn
                                            label={t("information.formTab1.doorPassword")}
                                            input={
                                                <CustomInput
                                                    name="doorPassword"
                                                    value={valueForm.doorPassword}
                                                    onChange={(e) => {
                                                        if (!isPageDetail) {
                                                            this.props.handleChange(e, "doorPassword")
                                                        }
                                                    }}
                                                    validate={errorsField.doorPassword}
                                                    disabled={isPageDetail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.doorPassword}
                                            isRequired={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={checkPanel3 ? "accordionComplete" : isSubmitError ? "accordionError" : ""} expanded={expanded[ACCORDION_C]} onChange={() => this.handleChange(ACCORDION_C)}>
                    <AccordionSummary
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                        className="panel-header"
                    >
                        <div className={"titleHeaderContent"}>
                            {checkPanel3 ? <CustomSvg
                                svgProps={{
                                    src: tickIcon,
                                }}
                            /> : isSubmitError ? <CustomSvg
                                svgProps={{
                                    src: ErrorIcon,
                                }}
                            /> : ""}
                            {t("information.formTab1.labelAddressBilling")}
                        </div>
                        <div className="leftHeader">
                            <div>
                                {t("information.formTab1.billingAddressSameAsRepairAddress")}
                            </div>
                            <Checkbox
                                checked={!valueForm.otherBillingAddress}
                                onChange={(event) => {if(!isPageDetail){this.props.handleChange(event, 'otherBillingAddress')}}}
                                onClick={(event) => {
                                    event.stopPropagation()
                                }}
                            />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {valueForm.otherBillingAddress ? <div className={"rightContent"}>
                            <Grid container>
                                <Grid container item xs={12} sm={6} md={6} lg={6} className={"column1"}>
                                    <RowFormColumn
                                        label={t("information.formTab1.invoiceFirstName")}
                                        imgSrcLabel={
                                            <CustomSvg
                                                svgProps={{
                                                    src: userIcon
                                                }}
                                            />
                                        }
                                        input={
                                            <CustomInput
                                                name="invoiceFirstName"
                                                value={valueForm.invoiceFirstName}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"invoiceFirstName")}}}
                                                validate={errorsField.invoiceFirstName}
                                                disabled={isPageDetail}
                                                className={isPageDetail ? 'inputDetail' : ""}
                                            />
                                        }
                                        textValidate={errorsField.invoiceFirstName}
                                        isRequired={true}
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={6} md={6} lg={6} className={"column2"}>
                                    <RowFormColumn
                                        imgSrcLabel={
                                            <CustomSvg
                                                svgProps={{
                                                    src: userIcon
                                                }}
                                            />
                                        }
                                        label={t("information.formTab1.invoiceLastName")}
                                        input={
                                            <CustomInput
                                                name="invoiceLastName"
                                                value={valueForm.invoiceLastName}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"invoiceLastName")}}}
                                                validate={errorsField.invoiceLastName}
                                                disabled={isPageDetail}
                                                className={isPageDetail ? 'inputDetail' : ""}
                                            />
                                        }
                                        textValidate={errorsField.invoiceLastName}
                                        isRequired={true}
                                    />
                                </Grid>
                                <Grid container item sm={6} md={6} lg={6} className={"column1"}>
                                    <RowFormColumn
                                        imgSrcLabel={
                                            <CustomSvg
                                                svgProps={{
                                                    src: addressIcon,
                                                }}
                                            />
                                        }
                                        label={t("information.formTab1.addressDescription")}
                                        input={
                                            <CustomInput
                                                name="billingAddressDescription"
                                                value={valueForm.billingAddressDescription}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"billingAddressDescription")}}}
                                                validate={errorsField.billingAddressDescription}
                                                disabled={isPageDetail}
                                                className={isPageDetail ? 'inputDetail' : ""}
                                            />
                                        }
                                        textValidate={errorsField.billingAddressDescription}
                                        isRequired={true}
                                    />
                                </Grid>
                                <Grid container item sm={6} md={6} lg={6} className={"column2"}>
                                    <RowFormColumn
                                        label={t("information.formTab1.addressCity")}
                                        imgSrcLabel={
                                            <CustomSvg
                                                svgProps={{
                                                    src: corporationIcon,
                                                }}
                                            />
                                        }
                                        input={
                                            <CustomInput
                                                name="billingAddressCity"
                                                value={valueForm.billingAddressCity}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"billingAddressCity")}}}
                                                validate={errorsField.billingAddressCity}
                                                disabled={isPageDetail}
                                                className={isPageDetail ? 'inputDetail' : ""}
                                            />
                                        }
                                        textValidate={errorsField.billingAddressCity}
                                        isRequired={true}
                                    />
                                </Grid>
                                <Grid container item sm={6} md={6} lg={6} className={"column1"}>
                                    <RowFormColumn
                                        imgSrcLabel={
                                            <CustomSvg
                                                svgProps={{
                                                    src: homeIcon,
                                                }}
                                            />
                                        }
                                        label={t("information.formTab1.addressPostalCode")}
                                        input={
                                            <CustomInput
                                                name="billingAddressPostalCode"
                                                value={valueForm.billingAddressPostalCode}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"billingAddressPostalCode")}}}
                                                validate={errorsField.billingAddressPostalCode}
                                                disabled={isPageDetail}
                                                className={isPageDetail ? 'inputDetail' : ""}
                                            />
                                        }
                                        textValidate={errorsField.billingAddressPostalCode}
                                        isRequired={true}
                                    />
                                </Grid>
                                <Grid container item sm={6} md={6} lg={6} className={"column2"}>
                                    <RowFormColumn
                                        label={t("information.formTab1.addressCountry")}
                                        imgSrcLabel={
                                            <CustomSvg
                                                svgProps={{
                                                    src: addressIcon,
                                                }}
                                            />
                                        }
                                        input={
                                            isPageDetail ?
                                                <CustomInput
                                                    name="billingAddressCountry"
                                                    value={countries.getName(valueForm.billingAddressCountry, this.props.t('translations.lang'))}
                                                /> :
                                                isLoading ? <div></div> :<AppCountryAutocomplete
                                                    defaultValue={valueForm.billingAddressCountry ? {
                                                        code: valueForm.billingAddressCountry,
                                                        label: countries.getName(valueForm.billingAddressCountry, this.props.t('translations.lang'))
                                                    } : undefined}
                                                    onChange={(event, value) => {if(!isPageDetail){this.props.handleChange(value, "billingAddressCountry")}}}
                                                    inputPlaceHolder={""}
                                                />
                                        }
                                        textValidate={errorsField.billingAddressCountry}
                                        isRequired={true}
                                    />
                                </Grid>
                            </Grid>
                        </div> : ''}
                    </AccordionDetails>
                </Accordion>
                <Accordion className={checkPanel4 ? "accordionComplete" : isSubmitError ? "accordionError" : ""} expanded={expanded[ACCORDION_D]} onChange={() => this.handleChange(ACCORDION_D)}>
                    <AccordionSummary
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        className="panel-header"
                    >
                        <div className={"titleHeaderContent"}>
                            {checkPanel4 ? <CustomSvg
                                svgProps={{
                                    src: tickIcon,
                                }}
                            /> : isSubmitError ? <CustomSvg
                                svgProps={{
                                    src: ErrorIcon,
                                }}
                            /> : ""}
                            {t("information.formTab1.logement")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container >
                            <Grid item xs={12} md={5} className="grid-panel4-left" style={{
                                padding: '0 10px'
                            }}>
                                {
                                    this.renderLodgingItem({
                                        icon: checkOwnerIcon,
                                        title: t("information.formTab1.situation"),
                                        error: errorsField.checkOwner,
                                        // className: "ownerWrapper",
                                        input: <div>
                                            <FormControl component="fieldset" className={"radioOwner"}>
                                                <RadioGroup row aria-label="position" name="position"
                                                            value={valueForm.checkOwner}
                                                            onChange={(e) => {
                                                                if (!isPageDetail) {
                                                                    this.props.handleChange(e, "checkOwner")
                                                                }
                                                            }}>
                                                    <FormControlLabel value={OWNER}
                                                                      className={classes.customRadio}
                                                                      control={<Radio />}
                                                                      label={t("information.formTab1.owner")}/>
                                                    <FormControlLabel value={TENANT}
                                                                      className={classes.customRadio}
                                                                      control={<Radio />}
                                                                      label={t("information.formTab1.tenant")}/>
                                                </RadioGroup>
                                                {/*<ErrorInput textValidate={errorsField.checkOwner}/>*/}
                                            </FormControl>
                                            {valueForm.checkOwner === TENANT &&
                                            <>
                                                <div className={`${classes.leaseType} ${errorsField.leaseType ? "errorInput" : ''}`}>
                                                    {isPageDetail ?
                                                        <CustomInput
                                                            name="leaseType"
                                                            value={valueForm.leaseType && valueForm.leaseType.label ? valueForm.leaseType.label : ""}
                                                            disabled
                                                            placeholder={t("information.formTab1.leaseType")}
                                                        /> :
                                                        <AppAsyncPaginate
                                                            classNamePrefix={"selectPaginate"}
                                                            value={valueForm.leaseType}
                                                            loadOptions={(search, loadedOptions, {page}) => this.loadLeaseTypeOptions(search, loadedOptions, {page})}
                                                            onChange={(value) => {if(!isPageDetail){this.props.handleChange(value, "leaseType")}}}
                                                            debounceTimeout={500}
                                                            additional={{
                                                                page: 1
                                                            }}
                                                            placeholder={t("information.formTab1.leaseType")}
                                                            // closeMenuOnSelect={false}
                                                        />
                                                    }
                                                    <ErrorInput textValidate={errorsField.leaseType}/>
                                                </div>
                                                {/* {!isModalShowClientDetail && <div className="authorizeShareEmail">
                                                    <Checkbox
                                                        checked={valueForm.authorizeShareEmail}
                                                        onChange={(event) => {this.props.handleChange(event, 'authorizeShareEmail')}}
                                                        color="#000000"
                                                    />
                                                    <div>{t("information.formTab1.authorizeShareEmail")}</div>
                                                </div>} */}
                                                {/* <RowFormColumn
                                                    className="emailinfo"
                                                    label={<div>
                                                        {t("information.formTab1.ownerEmail")}
                                                        <span> ({t("label.optional_field")})</span>
                                                    </div>}
                                                    input={
                                                        <CustomInput
                                                            name="ownerEmail"
                                                            value={valueForm.ownerEmail}
                                                            onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"ownerEmail")}}}
                                                            validate={errorsField.ownerEmail}
                                                            className={isPageDetail ? 'inputDetail' : ""}
                                                            disabled={isPageDetail}
                                                        />
                                                    }
                                                    textValidate={errorsField.ownerEmail}
                                                /> */}

                                            </>
                                            }
                                        </div>,
                                        isRequired: true
                                    })
                                }
                                {
                                    valueForm.checkOwner === TENANT && this.renderLodgingItem({
                                        title: t("information.formTab1.ownerEmail"),
                                        error: errorsField.ownerEmail,
                                        input:  <CustomInput
                                                    name="ownerEmail"
                                                    value={valueForm.ownerEmail}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"ownerEmail")}}}
                                                    validate={errorsField.ownerEmail}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                    disabled={isPageDetail}
                                               
                                        />,
                                        isRequired: true
                                    })
                                }
                            </Grid>
                            <Grid item xs={12} md={1}>
                            </Grid>
                            <Grid item xs={12} md={6} className="grid-panel4-right" style={{
                                padding: '0 10px'
                            }}>
                                {
                                    this.renderLodgingItem({
                                        icon: builtForOver2YearsIcon,
                                        title: t("information.formTab1.buildingHasBeenBuiltForOver2Years"),
                                        error: errorsField.builtForOver2Years,
                                        input: <React.Fragment>
                                            <RadioGroup row value={valueForm.builtForOver2Years}
                                                        aria-label="builtForOver2Years" name="builtForOver2Years"
                                                        onChange={(e) => {
                                                            if (!isPageDetail) {
                                                                this.props.handleChange(e, "builtForOver2Years")
                                                            }
                                                        }}>
                                                <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_YES}
                                                                  className={classes.customRadio}
                                                                  control={<Radio
                                                                      checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_YES}/>}
                                                                  label={t("information.formTab1.olderThan2Years")}/>
                                                <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_NO}
                                                                  className={classes.customRadio}
                                                                  control={<Radio
                                                                      checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_NO} />}
                                                                  label={t("information.formTab1.lessThan2Years")}/>
                                                <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_DONT_KNOW}
                                                                  className={classes.customRadio}
                                                                  control={<Radio
                                                                      checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_DONT_KNOW} />}
                                                                  label={t("information.formTab1.dontKnow")}/>
                                            </RadioGroup>
                                            {/*<ErrorInput textValidate={errorsField.builtForOver2Years}/>*/}
                                        </React.Fragment>,
                                        isRequired: true
                                    })
                                }
                                {
                                    this.renderLodgingItem({
                                        icon: startingDateOfStayIcon,
                                        title: t("information.formTab1.startingDateOfStay"),
                                        // className:{`${classes.startDateOfStay} ${isPageDetail? classes.startDateOfStayDetail : ''}`}
                                        error: errorsField.startingDateOfStay,
                                        input:  <AppKeyboardDatePicker
                                            value={valueForm.startingDateOfStay}
                                            onChange={(value) =>  {if(!isPageDetail){this.props.handleChange(value, "startingDateOfStay")}}}
                                            emptyLabel={''}
                                            // invalidDateMessage={""}
                                            disabled={isPageDetail}
                                            disableFuture={true}
                                        />,
                                        isRequired: true
                                    })
                                }
                                {
                                    this.renderLodgingItem({
                                        icon: sentByAgencyIcon,
                                        title: t("information.formTab1.sentByAgency"),
                                        // error: errorsField.sentByAgency,
                                        input: <React.Fragment>
                                            <div>
                                                <RadioGroup row aria-label="position" name="position" value={valueForm.sentByAgency}
                                                            onChange={(e) => {
                                                                if (!isPageDetail) {
                                                                    this.props.handleChange(e, "sentByAgency")
                                                                }
                                                            }}>
                                                    <FormControlLabel value={TRUE_NUMBER}
                                                                      className={classes.customRadio}
                                                                      control={<Radio />}
                                                                      label={t("information.formTab1.yes")}/>
                                                    <FormControlLabel value={FALSE_NUMBER}
                                                                      className={classes.customRadio}
                                                                      control={<Radio />}
                                                                      label={t("information.formTab1.no")}/>
                                                </RadioGroup>
                                                <ErrorInput textValidate={errorsField.sentByAgency}/>
                                            </div>
                                            {/* {
                                                valueForm.sentByAgency === TRUE_NUMBER &&
                                                <div className={classes.partnerCode}>
                                                    <Tooltip title={t("information.formTab1.questionRealEstateCenterCode")}>
                                                        <CustomInput
                                                            onChange={(event) => {
                                                                if (!isPageDetail) {
                                                                    this.props.handleChange(event, "realEstateCenterCode")
                                                                }
                                                            }}
                                                            name="questionRealEstateCenterCode"
                                                            value={valueForm.realEstateCenterCode ?? ""}
                                                            disabled={!!isPageDetail}
                                                            placeholder={t("information.formTab1.questionRealEstateCenterCode")}
                                                            validate={errorsField.realEstateCenterCode}
                                                        />
                                                    </Tooltip>
                                                    <ErrorInput textValidate={errorsField.realEstateCenterCode}/>
                                                </div>
                                            } */}
                                        </React.Fragment>,
                                        isRequired: true
                                    })
                                }
                                {
                                    valueForm.sentByAgency === TRUE_NUMBER && this.renderLodgingItem({
                                        title: t("information.formTab1.questionRealEstateCenterCode"),
                                        error: errorsField.realEstateCenterCode,
                                        input:  <React.Fragment>
                                            <CustomInput
                                                onChange={(event) => {
                                                    if (!isPageDetail) {
                                                        this.props.handleChange(event, "realEstateCenterCode")
                                                    }
                                                }}
                                                name="questionRealEstateCenterCode"
                                                value={valueForm.realEstateCenterCode ?? ""}
                                                disabled={!!isPageDetail}
                                                placeholder={t("information.formTab1.questionRealEstateCenterCode")}
                                                validate={errorsField.realEstateCenterCode}
                                            />
                                        </React.Fragment>,
                                        isRequired: true
                                    })
                                }
                                {
                                    this.renderLodgingItem({
                                        icon: additionalInfoIcon,
                                        title: t("information.formTab1.otherInfo"),
                                        optional: true,
                                        input:   <RowFormColumn
                                            input={
                                                isPageDetail ?
                                                    <CustomInput
                                                        multiline
                                                        className={"additionalInfo"}
                                                        rows={3}
                                                        rowsMax={3}
                                                        name="additionalInfo"
                                                        value={valueForm.additionalInfo}
                                                        disabled
                                                    /> :
                                                    <CustomInput
                                                        multiline
                                                        className={"additionalInfo"}
                                                        rows={3}
                                                        rowsMax={3}
                                                        name="additionalInfo"
                                                        value={valueForm.additionalInfo}
                                                        onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"additionalInfo")}}}
                                                    />
                                            }
                                        />,
                                        // isRequired: true
                                    })
                                }
                            </Grid>
                        </Grid>


                    </AccordionDetails>
                </Accordion>
                <div className={"advertiseBlock"}>
                    <div className={"labelValue"}>{t("information.formTab1.advertise")} <span className={classes.isRequired}>*</span></div>
                    <div>
                        <AppAsyncPaginate
                            classNamePrefix={"selectPaginateAdvertise"}
                            value={valueForm.advertise}
                            // isMulti
                            loadOptions={(search, loadedOptions, {page}) => this.loadParameterOptions(search, loadedOptions, {page})}
                            onChange={(value) => {
                                if (!isPageDetail) {
                                    this.props.handleChange(value, "advertise")
                                }
                            }}
                            debounceTimeout={500}
                            additional={{
                                page: 1
                            }}
                            // placeholder={t("information.formTab1.leaseType")}
                        />
                        <ErrorInput textValidate={errors && errors.advertise}/>
                    </div>
                </div>
            </div>
        )
    }
}

InfoForm.defaultProps = {
    isModalShowClientDetail: false
}

InfoForm.propTypes = {
    classes: PropTypes.object.isRequired,
    isModalShowClientDetail: PropTypes.bool
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withWidth(),
    withRouter
)(InfoForm);
